import { GET_DATA, GET_UPDATE } from './actions';
import { LOGOUT } from './auth/actions';
import { fromJS } from 'immutable';

export function fetchScrapers(data) {
  return {
    type: GET_DATA,
    payload: data
  };
}

export function agentUpdate(data) {
  return {
    type: GET_UPDATE,
    payload: data
  };
}

function validate(element) {
  const {
    hashCheck,
    hashChange,
    validDwellings,
    invalidDwellings,
    allDwellings,
    lastRun
  } = element;

  if (!lastRun || hashCheck === null || hashChange === null) {
    return 'invalid';
  }
  //more than 3 invalid dwellings
  if (invalidDwellings > 3) {
    return 'too-many-invalid-dwellings';
  }
  // invalid dwellings exceeds 33% of all dwellings && invalid dwellings exceeds 2
  if (invalidDwellings / allDwellings > 0.33 && invalidDwellings > 2) {
    return 'too-many-invalid-dwellings';
  }
  if (invalidDwellings > validDwellings) {
    return 'invalid-greater-than-valid';
  }
  // more than one hour since last check
  if (Date.now() - hashCheck > 3600000) {
    return 'lastcheck-too-old';
  }
  return 'ok';
}

function parseMeta(meta: any) {
  const { scraper_status } = meta || {};
  const statusSince = scraper_status?.timestamp && new Date(scraper_status?.timestamp);

  return { statusSince };
}

function parseAgents(target, agents) {
  if (agents) {
    return (Array.isArray(agents)
      ? agents
      : [agents]
    ) /*.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))*/
      .reduce((acc, { meta, ...agent }) => {
        const newMeta = meta ? parseMeta(meta) : {};
        return acc.set(
          agent.slug,
          fromJS({
            ...agent,
            ...newMeta,
            hashCheck: agent.hashCheck && new Date(agent.hashCheck),
            hashChange: agent.hashChange && new Date(agent.hashChange),
            lastRun: agent.lastRun && new Date(agent.lastRun),
            classname: validate(agent)
          })
        );
        // target[ agent.slug ] = {
        //   ...agent,
        //   hashCheck: agent.hashCheck && new Date(agent.hashCheck),
        //   hashChange: agent.hashChange && new Date(agent.hashChange),
        //   lastRun: agent.lastRun && new Date(agent.lastRun),
        //   classname: validate( agent )
        // };
      }, target);
  }
  return target;
}

function combine(scrapers, errors) {
  let activeDwellings = 0;
  return {
    agents: Object.keys(scrapers).map((slug) => {
      const {
        id = '-',
        status = 'waiting',
        link,
        name,
        checksum = {},
        lastRun = {},
        meta = {}
      } = scrapers[slug];

      activeDwellings += lastRun.properties ? lastRun.properties.valid : 0;
      return {
        id,
        status,
        link,
        name: name ? `${name} / ${slug}` : slug,
        hashCheck: checksum.lastCheck,
        hashChange: checksum.lastChange,
        lastRun: lastRun.start,
        validDwellings: lastRun.properties ? lastRun.properties.valid : 0,
        invalidDwellings: lastRun.properties ? lastRun.properties.invalid : 0,
        allDwellings: lastRun.properties ? lastRun.properties.raw : 0,
        deactivatedDwellings: lastRun.properties
          ? lastRun.properties.deactivated
          : 0,
        errors: errors[slug] || [],
        slug,
        ...parseMeta(meta)
      };
    }),
    activeDwellings: activeDwellings
  };
}

export function mergeStats(data, errors) {
  const scrapers = combine(data.scrapers, errors);
  const { agents, activeDwellings } = scrapers;
  return {
    agents,
    meta: {
      status: data.status,
      uptime: data.uptime,
      activeDwellings,
      class: data.status === 'OK' ? 'success' : 'warning'
    }
  };
}

const initialState = fromJS({
  meta: {},
  agents: {}
});

export default function scraper(state = initialState, action) {
  if (action.type === GET_DATA) {
    const { payload } = action;
    const { agents, meta } = mergeStats(payload.stats, payload.errors);
    return state.merge(
      fromJS({
        meta: {
          ...meta,
          uptime: new Date(Date.now() - meta.uptime)
        },
        agents: parseAgents(state.get('agents'), agents)
      })
    );
  } else if (action.type === GET_UPDATE && state.get('agents').size) {
    const { agent, errors } = action.payload;
    const update = combine(agent.scrapers, errors);
    return state.merge(
      fromJS({
        agents: parseAgents(state.get('agents'), update.agents[0])
      })
    );
  } else if (action.type === LOGOUT) {
    return initialState;
  }

  return state;
}
