import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
};

function modalStyle(top = 50, left = 50) {
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '95vh',
    maxWidth: '95vw',
    overflow: 'auto'
  };
}

const ModalComponent = ({ children, ...props }: Props): JSX.Element => {
  const [modalStyles] = useState(modalStyle());
  return (
    <Modal {...props}>
      <div style={modalStyles} className={styles.paper}>
        {children}
      </div>
    </Modal>
  );
};

export default ModalComponent;
