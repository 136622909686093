import React, { useState } from 'react';
import {
  GridListTile,
  GridListTileBar,
  IconButton,
  Tooltip
} from '@material-ui/core';
import Icon from '../Icon';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import styles from './styles.module.scss';
import { colors } from '../utils/colors';
// import Image from './Image';

type Image = { hash: string; status: string };

type Props = {
  hash: string;
  status: string;
  imgSize?: string | { height: string; width: string };
  onHide: (image: Image) => void;
  onDisplayImage?: (image: Image) => void;
  style?: {};
  tooltip?: string;
};

const ImgTile = (props: Props) => {
  const [showImg, setShowImg] = useState(true);
  const image: Image = { hash: props.hash, status: props.status };
  return (
    <GridListTile
      style={{
        ...props.style
      }}
      key={props.hash}
      classes={{
        tile: styles.image
      }}
    >
      <div
        onClick={() => props.onDisplayImage(image)}
        className={styles.bgImage}
        style={{
          opacity: showImg ? 1 : 0.3,
          backgroundImage: `url(
            https://img.akut-bolig.dk/${props.size || '90x90'}/${props.hash}.jpg
          )`
        }}
      >
        {props.status === 'DELETED' && (
          <>
            <span className={styles.cross1} />
            <span className={styles.cross2} />
          </>
        )}
      </div>
      {/* <Image
        onClick={() =>
          props.onImgClick({ hash: props.hash, status: props.status })
        }
        src={`https://img.akut-bolig.dk/90x90/${props.hash}.jpg`}
        srcset={`https://img.akut-bolig.dk/180x180/${props.hash}.jpg 2x`}
        alt={'dwelling image'}
      /> */}
      {props.status === 'ACTIVE' && (
        <GridListTileBar
          style={{
            background:
              'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
              'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
            height: '30px'
          }}
          titlePosition={'top'}
          actionIcon={
            <Tooltip
              style={{ margin: 0 }}
              title={`${showImg ? 'hide' : 'show'} image`}
              placement={'right'}
            >
              <IconButton
                size="small"
                onClick={() => {
                  props.onHide(image);
                  setShowImg((prev) => !prev);
                }}
                style={
                  showImg
                    ? { color: '#fff', opacity: 0.8 }
                    : { color: colors.red[500], opacity: 1 }
                }
              >
                <Icon icon={showImg ? Visibility : VisibilityOff} />
              </IconButton>
            </Tooltip>
          }
          actionPosition={'left'}
        />
      )}
    </GridListTile>
  );
};

export default ImgTile;
