import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TRow from './TRow';
import TCell from './TCell';
import styles from './styles.module.scss';
import Tag from '../Tags/Tag';
import { tagProps } from 'app/biz/mastertool/SingleView/Landlord/Note/NoteItem';
import Icon from '../Icon';
import { fromDate } from '../utils/serializeHelpers';
import { isToday, isTomorrow, isPast } from 'date-fns';

import { Row, RowAction } from './types';
import { Link } from 'app/components/Link';
import { Button } from '@material-ui/core';

const isLink = ['id', 'name'];

type Props = {
  rows: Row[];
  keys: { name: string; url?: string; link: string; exactLink: string, redirectLink?: string, valueType?: string, notLink?: boolean, simpleLinkText?: string, customValue?: (v) => any, serialize?: (v) => any, key?: string }[];
  url?: string;
  dimmedRows: string[];
  actions?: RowAction[];
  limitRow?: number;
  hideable?: boolean;
  detailOnClick?: (row: any) => JSX.Element;
};

function nextFollowUpAlert(nfuTime: string | null) {
  if (nfuTime === null) {
    return '';
  }
  if (isToday(new Date(nfuTime)) || isPast(new Date(nfuTime))) {
    return styles.danger;
  }

  if (isTomorrow(new Date(nfuTime))) {
    return styles.warning;
  }
}

function convertValue(valueType, value, customValue, row) {
  switch(valueType) {
    case 'array':
      return JSON.stringify(value);
    case 'stringify':
      return JSON.stringify(value);
    case 'boolean':
    case 'checkmark':
      return (<i
            className={
              value ? 'fa fa-fw fa-check' : 'fa fa-fw fa-times'
            }
            style={{ color: value ? 'green' : 'red' }}
              />);
    case 'number':
      return Number(value);
    case 'date':
      return value ? new Date(value).toLocaleString('da-DK', {
        timeZone: 'Europe/Copenhagen'
      }) : '(no value)';
    case 'custom':
      return customValue(value);
    case 'custom-row':
      return customValue(row);
    default:
      return value || '(no value)';
  }
}

const TBody = (props: Props) => {
  const [hidden, setHidden] = React.useState(props.limitRow ? true : false);
  const [minimized, setMinimized] = React.useState(false);
  const rows = minimized ? [] : hidden ? Array.from(props.rows).slice(0, props.limitRow) : props.rows;
  const hasMore = props.rows.length > props.limitRow;
   return (
    <TableBody>
      {rows.map((row) => {
        return (
          <TRow
            key={row.id}
            className={`${nextFollowUpAlert(row.nextFollowUp)} ${
              styles.hover
            } ${row.inactive ? styles.danger : ''} ${
              props.dimmedRows && props.dimmedRows.includes(row.id)
                ? styles.dimmed
                : ''
            }`}
            row={row}
            detailOnClick={props.detailOnClick}
          >
            <>
              {/* Change to map if there is coming more the one rowActions */}
              {props.actions && (
                <td>
                  {props.actions.map((action) => {
                    const {
                      component: Component,
                      onClick,
                      icon,
                      ...rest
                    } = action;
                    return (
                      <Component
                        key={row.id}
                        onClick={onClick ? () => onClick(row) : undefined}
                        {...rest}
                      >
                        {rest.fab && icon ? icon : null}
                      </Component>
                    );
                  })}
                </td>
              )}
              {props.keys.map(({ name, url, link, exactLink, redirectLink, notLink, valueType, simpleLinkText, customValue, serialize, key }) => {
                const isNull = row[name] === null;
                const value = isNull ? null : serialize ? valueType === 'array' ? row[name].map(serialize): serialize(row[name]) : row[name];
                if(simpleLinkText) {
                  return (
                    <TCell key={key || name}>
                      {exactLink ?
                          <a
                            href={row[exactLink]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {simpleLinkText}
                          </a> : row[link]?.length > 0 ? <Link href={`${url || props.url}/${row[link]}` }>{simpleLinkText}</Link> : null}
                    </TCell>
                  );
                }
                switch (name) {
                  case 'mood': {
                    const { icon, style } = tagProps(`${row[name]}`);
                    return (
                      <TCell key={key || name}>
                        {!isNull ? (
                          <Tag
                            label={`${row[name]}`}
                            size={'small'}
                            style={{ ...style, margin: 0 }}
                            icon={<Icon icon={icon} />}
                          />
                        ) : null}
                      </TCell>
                    );
                  }
                  case 'nextFollowUp':
                    return (
                      <TCell key={key || name}>
                        {!isNull
                          ? fromDate(new Date(row[name]), 'dd-MM-yyyy')
                          : null}
                      </TCell>
                    );
                  case 'zipcodes':
                    const showLimit = 10;
                    const limitedCodes = row[name].length < showLimit ? JSON.stringify(row[name]): JSON.stringify(row[name].splice(0, showLimit)) + ' + ' + (row[name].length - showLimit) + ' Zipcodes';
                    return (
                      <TCell
                        key={key || name}
                        link={
                          row[link] || isLink.includes(name)
                            ? {
                                to: `${props.url}/${row[link] ?? row.id}`
                              }
                            : null
                        }
                        label={limitedCodes}
                      >
                        {row[exactLink] && (
                          <a
                            href={row[exactLink]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {limitedCodes}
                          </a>
                        )}
                      </TCell>
                    );
                  case 'coordinates':
                    const coordinates = row['type'] === 'POLYGON' ? `Polygon with ${row[name][0].length} points` : JSON.stringify(row[name]);
                    return (
                      <TCell
                        key={key || name}
                        link={
                          row[link] || isLink.includes(name)
                            ? {
                                to: `${props.url}/${row[link] ?? row.id}`
                              }
                              : null
                        }
                        label={coordinates}
                      >
                        {row[exactLink] && (
                          <a
                            href={row[exactLink]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {coordinates}
                          </a>
                        )}
                      </TCell>
                    );
                  default: {
                    return (
                      <TCell
                        key={key || name}
                        label={convertValue(valueType, value, customValue, row)}
                      >
                        {redirectLink ? <Link href={`${redirectLink}/${value}`}>{value}</Link> : row[exactLink] && (
                          <a
                            href={row[exactLink]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {value}
                          </a>
                        ) || (row[link] || (!notLink && isLink.includes(name))) ?
                        (<Link href={`${url || props.url}/${row[link] ?? row.id}`}>{value}</Link>)
                            : null}
                      </TCell>
                    );
                  }
                }
              })}
            </>
          </TRow>
        );
      })}
      <TRow key={'hasMore'}>
        {hasMore && props.limitRow ? (<TCell>
          <Button size="small" style={{ margin: '4px' }} variant={'contained'} onClick={() => setHidden(!hidden) }>{hidden ? 'Show more' : 'Show less'} </Button>
        </TCell>): null}
      {props.hideable ? (<TCell>
          <Button size="small" style={{ margin: '4px' }} variant={'contained'} onClick={() => setMinimized(!minimized)}>{minimized ? 'Maximize' : 'Minimize'} </Button>
        </TCell>): null}
      </TRow>
    </TableBody>
  );
};

export default TBody;
