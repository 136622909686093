import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from './modal';

export default class ConfirmationButton extends Component {
  static propTypes = {
    bsSize: PropTypes.string,
    bsStyle: PropTypes.string,
    label: PropTypes.string,
    onSubmit: PropTypes.func,
    show: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: props.show || false
    };
  }

  onSubmit = (...args) => {
    this.props.onSubmit(...args);
    this.close();
  };

  open = () => {
    this.setState({
      showModal: true
    });
  };

  close = () => {
    this.setState({
      showModal: false
    });
  };

  render() {
    const {
      label,
      inline,
      className,
      disabled = false,
      bsStyle = disabled ? '' : 'danger',
      bsSize = 'sm'
    } = this.props;

    return (
      <div style={inline && { display: 'inline-block' }} className={className}>
        <button
          type="button"
          className={`btn btn-${bsStyle} btn-${bsSize}`}
          onClick={this.open}
          disabled={disabled}
        >
          {label} {this.state.show}
        </button>

        {this.state.showModal ? (
          <Modal
            show
            onSubmit={this.onSubmit}
            onClose={this.close}
            title={`Confirm: ${label}`}
            text={this.props.text}
            label={label}
          >
            {this.props.children}
          </Modal>
        ) : null}
      </div>
    );
  }
}
