import React from 'react';

import { Link } from 'react-router';
import Loading from '../common/loading';

import { GraphQLTableContainer } from '../common/table';

type Props = { loading: boolean };

class UsersTable extends GraphQLTableContainer<Props> {
  static defaultProps = {
    asyncFilter: false
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { rows = [] } = this.state;
    const {
      loading,
      location: { query }
    } = this.props;
    return (
      <div>
        <div className="table-responsive">
          <table className="table table-striped table-condensed table-hover">
            <thead>
              <tr>
                <th
                  onClick={this.sortBy}
                  data-column="id"
                  style={{ cursor: 'pointer' }}
                >
                  ID
                </th>
                <th
                  onClick={this.sortBy}
                  data-column="name"
                  style={{ cursor: 'pointer' }}
                >
                  Name
                </th>
                <th
                  onClick={this.sortBy}
                  data-column="email"
                  style={{ cursor: 'pointer' }}
                >
                  Email
                </th>
                <th
                  onClick={this.sortBy}
                  data-column="cellphone"
                  style={{ cursor: 'pointer' }}
                >
                  Cellphone
                </th>
                <th
                  onClick={this.sortBy}
                  data-column="facebookId"
                  style={{ cursor: 'pointer' }}
                >
                  Facebook ID
                </th>
                <th>Subscription plan</th>
                <th>Subscription</th>
              </tr>
              <tr>
                <th />
                <th>
                  <input
                    onChange={this.onAsyncFilterChange}
                    defaultValue={query.name || ''}
                    name="name"
                    className="form-control"
                    style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                    placeholder="Filter by name"
                  />
                </th>
                <th>
                  <input
                    onChange={this.onAsyncFilterChange}
                    defaultValue={query.email || ''}
                    name="email"
                    className="form-control"
                    style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                    placeholder="Filter by email"
                  />
                </th>
                <th>
                  <input
                    onChange={this.onAsyncFilterChange}
                    defaultValue={query.cellphone || ''}
                    name="cellphone"
                    className="form-control"
                    style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                    placeholder="Filter by cellphone"
                  />
                </th>
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {!loading &&
                rows.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <Link to={`/akutbolig/users/${user.id}`}>{user.id}</Link>
                    </td>
                    <td>
                      <Link to={`/akutbolig/users/${user.id}`}>
                        {user.name}
                      </Link>
                    </td>
                    <td>{user.email}</td>
                    <td>{user.cellphone}</td>
                    <td>{user.facebookId}</td>
                    <td>{user.subscriptionPlan?.id}</td>
                    <td>{user.subscription?.id}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          {loading ? <Loading /> : null}
        </div>
      </div>
    );
  }
}

export default UsersTable;
