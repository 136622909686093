import React, { FC } from 'react';
import { Paper } from 'app/components/Paper';
import Table from 'app/components/mui/Table';

enum ConnectionType {
  WEBSITE = 'WEBSITE',
  FACEBOOK = 'FACEBOOK',
  MASTERTOOL = 'MASTERTOOL',
  UDLEJER_DK = 'UDLEJER_DK',
  UDLEJER_AB = 'UDLEJER_AB',
  BOLIGPORTALEN = 'BOLIGPORTALEN',
  SCRAPER = 'SCRAPER',
  OTHER = 'OTHER'
}

type Dwelling = {
  id: string;
  headline: string;
  address: string;
  zip: string;
  city: string;
  rent: number;
  rooms: number;
  m2: number;
  status: 'inactive' | 'active';
  connection: ConnectionType;
};

type Props = {
  dwellings: Dwelling[];
};

export const Dwelling: FC<Props> = (props) => {
  return (
    <>
      <Table
        title="Dwellings"
        url="/dk/dwellings"
        rows={props.dwellings}
        columns={[
          //   { name: 'id', label: 'id' },
          { name: 'address', label: 'Address' },
          { name: 'headline', label: 'Headline' },
          { name: 'zip', label: 'Zip' },
          { name: 'city', label: 'City' },
          { name: 'rent', label: 'Rent' },
          { name: 'rooms', label: 'Rooms' },
          { name: 'm2', label: 'M2' },
          { name: 'status', label: 'Status' },
          { name: 'connection', label: 'Connection' }
        ]}
      />
    </>
  );
};
