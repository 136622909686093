import React, { useState } from 'react';
import dwellingQuery from './dwelling.graphql';
import mutateDwelling from './mutateDwelling.graphql';
import Layout from 'app/components/mui/Layout/Layout';
import {
  serializeDwelling,
  deserializeDwellingEconomy,
  deserializeDwellingInfo
} from '../../../../components/mui/utils/serializeDwelling';
import Gallery from 'app/components/mui/Gallery/Gallery';
import Map from 'app/components/mui/Map';
import Form from 'app/components/mui/Form/Form';
import Fields from 'app/components/mui/Fields';
import { Paper } from '@material-ui/core';
import { Title } from 'app/components/Title';
import Log from 'app/components/mui/Log/Log';
import Button from 'app/components/mui/Button';
import Footer from 'app/components/mui/Footer/Footer';
import { Query } from 'app/components/mui/Query';
import { Mutation } from 'app/components/graphql/Mutation';
import Banner from 'app/components/mui/Banner';
import { colors } from 'app/components/mui/utils/colors';
import { Error, Info } from '@material-ui/icons';
import OutgoingLink from 'app/components/mui/OutgoingLink';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ActionQueueList from 'app/microservices/actionqueue/list';

type Props = {
  loading: boolean;
  dwelling: { [key: string]: any };
  params: { dwellingId: string };
};

const DEACTIVATE = gql`
  mutation DeleteDwelling ($id: ID!, $key: String!, $deactivatedBy: String){
      deleteDwelling(id: $id, key: $key, deactivatedBy: $deactivatedBy)
    }
`;

const DwellingDK = (props: Props) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [newDwellingValues, setNewDwellingValues] = useState<{
    [key: string]: any;
  }>({});

  const [deactivate, { loading: deactivateLoading }] = useMutation<
    { review: boolean },
    { id: string, key: string, deactivatedBy: string }
  >(DEACTIVATE);

  const onBlurHandler = (values: { [key: string]: any }, valueAdded) => {
    setNewDwellingValues((prev) => {
      setTouched(valueAdded);
      return { ...prev, ...values };
    });
  };

  const onHideImgHandler = (image: string) => {
    window.alert(
      `mutate to hide image has not ben implemented yet, \nyou clicked ${image.hash}`
    );
  };

  return (
    <Query
      query={dwellingQuery}
      variables={{ id: String(props.params.dwellingId) }}
      serializer={{ fn: serializeDwelling, namespace: 'lease' }}
    >
      {({ lease: dwelling }, refetch) => {
        return (
          <Mutation mutation={mutateDwelling}>
            {({ mutate, options }) => {
              return (
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    const { agentName, ...dwellingInput} = newDwellingValues;
                    const variables = {
                      dwellingId: dwelling.technicalData.agentUnique,
                      dwellingInput,
                      partial: true,
                      interactive: true
                    };
                    mutate({ variables });
                  }}
                >
                  <Layout spacing={4}>
                    <Form
                      gridProps={{
                        col: { xs: 12, md: 4 }
                      }}
                      initValues={
                        dwelling.agent
                          ? {
                              agentName: dwelling.agent.name,
                              ...dwelling.contactInfo
                            }
                          : dwelling.contactInfo
                      }
                    >
                      {({ initialValues, values, dirty }) => {
                        return (
                          <Fields
                            onBlur={() => {
                              onBlurHandler(values, dirty);
                            }}
                            gridProps={{
                              col: { xs: 12, md: 4 }
                            }}
                            title={'Contact Info'}
                            fields={initialValues}
                            fieldOptions={{
                              isFormik: true,
                              agentName: { disabled: true },
                              phone1: { multiline: false },
                              phone2: { multiline: false },
                              email: { multiline: false },
                              url: {
                                multiline: false,
                                helperText: (
                                  <OutgoingLink
                                    text={'Åben udlejers link'}
                                    href={dwelling.contactInfo.url}
                                  />
                                )
                              }
                            }}
                          />
                        );
                      }}
                    </Form>
                    <Form
                      gridProps={{
                        col: { xs: 12, md: 4 }
                      }}
                      initValues={dwelling.description}
                    >
                      {({ initialValues, values, dirty }) => {
                        return (
                          <Fields
                            onBlur={() => {
                              onBlurHandler(values, dirty);
                            }}
                            title={'Description'}
                            fields={initialValues}
                            fieldOptions={{
                              isFormik: true
                            }}
                          />
                        );
                      }}
                    </Form>
                    <Gallery
                      imgTileProps={{ onHide: onHideImgHandler }}
                      gridProps={{ col: { xs: 12, md: 4 } }}
                      images={dwelling.images}
                      lease={dwelling.id}
                    />
                    <Form
                      gridProps={{ col: { xs: 12, md: 4 } }}
                      initValues={dwelling.location}
                    >
                      {({
                        initialValues: { geo, ...initialValues },
                        values: { geo: _, ...values },
                        dirty
                      }) => {
                        return (
                          <Fields
                            onBlur={() => {
                              onBlurHandler(values, dirty);
                            }}
                            title="Location"
                            fields={initialValues}
                            fieldOptions={{
                              isFormik: true
                            }}
                          >
                            <Map {...geo} />
                          </Fields>
                        );
                      }}
                    </Form>
                    <Form
                      gridProps={{
                        col: { xs: 12, md: 4 }
                      }}
                      initValues={dwelling.extra}
                    >
                      {({ initialValues, values, dirty }) => {
                        return (
                          <Fields
                            onBlur={() => {
                              onBlurHandler(
                                deserializeDwellingInfo(values),
                                dirty
                              );
                            }}
                            gridProps={{
                              col: { xs: 12, md: 4 }
                            }}
                            title={'Dwelling Info'}
                            fields={initialValues}
                            fieldOptions={{
                              isFormik: true,
                              animals: { options: true },
                              type: { disabled: true },
                              residenceduty: { options: true },
                              furnished: { options: true },
                              typeDwelling: {
                                options: [
                                  { value: 'UKENDT' },
                                  { value: 'VAERELSE' },
                                  { value: 'DELEBOLIG' },
                                  { value: 'BYTTEBOLIG' },
                                  { value: 'LEJLIGHED' },
                                  { value: 'RAEKKEHUS' },
                                  { value: 'VILLA' },
                                  { value: 'LANDEJENDOM' }
                                ]
                              },
                              typeTenant: {
                                options: [
                                  { value: 'UKENDT' },
                                  { value: 'ALMEN' },
                                  { value: 'UNGDOMSBOLIG' },
                                  { value: 'STUDIEBOLIG' },
                                  { value: 'FAMILIEBOLIG' },
                                  { value: 'SENIORBOLIG' },
                                  { value: 'AELDREBOLIG' }
                                ]
                              }
                            }}
                          />
                        );
                      }}
                    </Form>
                    <Form
                      gridProps={{ col: { xs: 12, md: 4 } }}
                      initValues={dwelling.economy}
                    >
                      {({ initialValues, values, dirty }) => {
                        return (
                          <Fields
                            onBlur={() => {
                              onBlurHandler(
                                deserializeDwellingEconomy(values),
                                dirty
                              );
                            }}
                            title={'Economy'}
                            fields={initialValues}
                            fieldOptions={{ isFormik: true }}
                          />
                        );
                      }}
                    </Form>

                    {dwelling.agent && (
                      <Fields
                        gridProps={{ col: { xs: 12, md: 4 } }}
                        title={'Agent'}
                        fields={dwelling.agent}
                        fieldOptions={{
                          id: { hide: true },
                          name: { link: { to: dwelling.agent.id } },
                          phone: { disabled: true },
                          email: { disabled: true },
                          contactNote: { disabled: true },
                          type: {
                            disabled: true,
                            options: [
                              { value: 'NONPROFIT' },
                              { value: 'COMPANY' },
                              { value: 'PERSONAL' },
                              { value: 'REALESTATE' },
                              { value: 'COMMERCIAL' }
                            ]
                          }
                        }}
                      />
                    )}
                    <Fields
                      gridProps={{ col: { xs: 12, md: 4 } }}
                      title={'Technical Data'}
                      fields={dwelling.technicalData}
                      fieldOptions={{
                        updatedAt: { disabled: true },
                        createdAt: { disabled: true },
                        expireAt: { disabled: true },
                        expireIn: { disabled: true },
                        deletedAt: { disabled: true },
                        deactivatedBy: { disabled: true },
                        liveCheck: { disabled: true },
                        agentUnique: { disabled: true }
                      }}
                    />
                    <Log
                      title={'Log'}
                      gridProps={{ col: { xs: 12, md: 4 } }}
                      logData={dwelling.actions}
                    />
                    <ActionQueueList
                      id={dwelling.id}
                    />
                    {dwelling.meta && (
                      <Paper
                        style={{ padding: '6px 16px 12px' }}
                        gridProps={{ col: { xs: 12, md: 4 } }}
                      >
                        <Title text="Meta" variant="h6" component="p" />
                        <pre>{dwelling.meta}</pre>
                      </Paper>
                    )}
                  </Layout>
                  <Footer
                    left={() => {
                      return (
                        <>
                          {dwelling.inactive && (
                            <Banner
                              color={colors.red[500]}
                              text={'THIS DWELLING IS INACTIVE'}
                              icon={Error}
                              bold
                            />
                          )}
                          <Banner
                            color={colors.blue[500]}
                            text={`Dwelling ${
                              dwelling.agent
                                ? 'from scraper'
                                : 'created manually'
                            }`}
                            icon={Info}
                          />
                          {!dwelling.technicalData.deletedAt ?
                           <Button
                                label={'DEACTIVATE'}
                                style={{ backgroundColor: 'red', marginRight: '15px' }}
                                disabled={deactivateLoading}
                                isLoading={deactivateLoading}
                                onClick={() => deactivate({ variables: { id: String(dwelling.id), key: 'property_id', deactivatedBy: 'ControlPanel' } }).then(() => refetch())}
                              /> : null}
                          {dwelling.rest?.contactAttempts && (
                            <div>
                              success: {dwelling.rest.contactAttempts.success}
                              <br />
                              forbidden:{' '}
                              {dwelling.rest.contactAttempts.forbidden}
                            </div>
                          )}
                        </>
                      );
                    }}
                    right={() => {
                      return (
                        <Button
                          disabled={!touched || options.loading}
                          isLoading={options.loading}
                          label={'save'}
                          type={'submit'}
                        />
                      );
                    }}
                  />
                </form>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default DwellingDK;
