import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

const chatColumns = [
  { label: 'Id', name: 'id', redirectLink: 'https://cp.bbintern.net/akutbolig/scraperChatTriggers' },
  { label: 'Trigger', name: 'trigger', valueType: 'string' },
  { label: 'Reason', name: 'reason', valueType: 'string' },
  { label: 'Active', name: 'active', valueType: 'boolean' },
];

export default class ScraperChatTriggers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    apiClient.post('/api/microservice', { data: { action: 'getScraperChatTriggers', data: {} } }).then((result) => {
      this.setState({ list: result.data });
    });
  }

  onChange(e, key) {
    this.setState({ filter: { [key]: e.target.value }});
  }

  render() {
    const { list = [] } = this.state;
    const limitRow = 50;

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
          {<Button style={{ margin: '6px' }} size={'small'} label={'Add Trigger'} onClick={() => this.props.router.push('/akutbolig/scraperChatTriggers/new')}/>}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Scraper Chat Triggers'}
                columns={chatColumns}
                limitRow={limitRow}
                hideable
                rows={list}
              />
          </div>
        </div>
      </div>
    );
  }
}
