import apiClient from 'app/utils/api-client';
import React, { useEffect } from 'react';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';
import TextField from '@material-ui/core/TextField';
import Image from 'app/components/mui/Gallery/Image';

const ImageDashboard: React.FC = () => {
    const [latestApproved, setLatestApproved] = React.useState([]);
    const [newList, setNewList] = React.useState([]);
    const [oldList, setOldList] = React.useState([]);
    const [leaseId, setLeaseId] = React.useState('');
    const [compare, setCompare] = React.useState(false);
    const [mysqlActive, setMysqlActive] = React.useState(0);
    const [mongodbActive, setMongodbActive] = React.useState(0);
    const [mysqlWaiting, setMysqlWaiting] = React.useState(0);
    const [mongodbWaiting, setMongodbWaiting] = React.useState(0);
    const [checkSynced, setCheckSynced] = React.useState(false);

  const refresh = () => {
    if(checkSynced && (!mysqlActive || !mongodbActive || !mysqlWaiting || !mongodbWaiting)) {
        apiClient.post('/api/dk/images', { data: { action: 'DbSynced', data: {} } }).then((result) => {
            setMysqlActive(result?.activeLegacy || []);
            setMongodbActive(result?.activeNew || []);
            setMysqlWaiting(result?.waitingLegacy || []);
            setMongodbWaiting(result?.waitingNew || []);
        });
        return;
    }
    if(!compare) {
        apiClient.post('/api/dk/images', { data: { action: 'Dashboard', data: {} } }).then((result) => {
            setLatestApproved(result?.latestApproved || []);
        });
        return;
    }
    apiClient.post('/api/dk/images', { data: { action: 'Compare', data: { leaseId } } }).then((result) => {
     if(result.newList) {
        setNewList(result.newList);
     }
     if(result.oldList) {
        setOldList(result.oldList);
     }
    });
  };

  const hideImg = (image: string) => {
    apiClient.post('/api/dk/images', { data: { action: 'hideImage', data: { image } } }).then((result) => {
      if(result === 'active') {
        window.alert('Image has not been hidden');
      } else if(result !== 'active') {
        window.alert('Image has been hidden');
      }
      refresh();
    });
  };

  const showImg = (image: string) => {
    apiClient.post('/api/dk/images', { data: { action: 'showImage', data: { image } } }).then((result) => {
      if(result === 'waiting') {
        window.alert('Image is now waiting');
      } else if(result !== 'waiting') {
        window.alert('Image is still hidden');
      }
      refresh();
    });
  };


  useEffect(() => {
    refresh();
  }, []);


  return (
    <div>
        <div style={{ display: 'flex' }}>
            <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
            <Button style={{ margin: '6px' }} size={'small'} label={compare ? 'Stop Compare' : 'Start Compare'} onClick={() => setCompare(!compare)}/> 
            <TextField
                id="leaseId"
                label="Lease ID"
                placeholder="Indsæt lejemåls id"
                type="text"
                value={leaseId}
                onChange={({ target: { value } }) => setLeaseId(value)}
                margin="normal"
                variant="outlined"
            />
            <Button style={{ margin: '6px' }} size={'small'} label={checkSynced ? 'Hide DB sizes' : 'Show DB sizes'} onClick={() => setCheckSynced(!checkSynced)}/> 
        </div>
        {checkSynced ? <div style={{ display: 'flex' }}>
          <TextField
                id="mysql"
                label="MYSQL Actives"
                type="text"
                disabled
                value={mysqlActive}
                margin="normal"
                variant="outlined"
            />
            <TextField
                id="mongodb"
                label="MongoDB Actives"
                type="text"
                disabled
                value={mongodbActive}
                margin="normal"
                variant="outlined"
            />
            <TextField
                id="mysql"
                label="MYSQL Waiting"
                type="text"
                disabled
                value={mysqlWaiting}
                margin="normal"
                variant="outlined"
            />
            <TextField
                id="mongodb"
                label="MongoDB Waiting"
                type="text"
                disabled
                value={mongodbWaiting}
                margin="normal"
                variant="outlined"
            />
        </div> : null}
        {compare ? <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'MYSQL'}
                columns={[
                    { label: 'Image', name: 'hash', valueType: 'custom', customValue: (hash) => {
                        return <Image src={`https://img.akut-bolig.dk/90x90/${hash}.jpg`}/>;
                    } },
                    { label: 'Hash', name: 'hash', valueType: 'string' },
                    { label: 'Lease', name: 'property_id', valueType: 'string', link: 'lease' },
                    { label: 'Status', name: 'status', valueType: 'string' },
                    { label: 'Primary', name: 'image_primary', valueType: 'boolean' },
                    { label: 'Priority', name: 'priority', valueType: 'number' },
                     { label: 'Hide', name: 'hash', valueType: 'custom', customValue: (hash) => {
                        return <Button label={'Hide Img'} onClick={() => hideImg(hash)}/>;
                    } },
                     { label: 'Show', name: 'hash', valueType: 'custom', customValue: (hash) => {
                        return <Button label={'Show Img'} onClick={() => showImg(hash)}/>;
                    } },
                ]}
                hideable
                rows={oldList}
              />
          </div>
          <div style={{ margin: '6px' }}>
            <Table
                title={'MONGO'}
                columns={[
                    { label: 'Image', name: 'hash', valueType: 'custom', customValue: (hash) => {
                        return <Image src={`https://img.akut-bolig.dk/90x90/${hash}.jpg`}/>;
                    } },
                    { label: 'Hash', name: 'hash', valueType: 'string' },
                    { label: 'Lease', name: 'leaseId', valueType: 'string', link: 'lease' },
                    { label: 'Status', name: 'status', valueType: 'string' },
                    { label: 'Primary', name: 'primary', valueType: 'boolean' },
                    { label: 'Type', name: 'type', valueType: 'string' },
                    { label: 'Priority', name: 'priority', valueType: 'number' },
                    { label: 'Url', name: 'url', simpleLinkText: 'See Image', exactLink: 'url', valueType: 'string' }
                ]}
                hideable
                rows={newList}
              />
          </div>
        </div> : <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Approved Images'}
                url="/dk/dwellings"
                columns={ [
                    { label: 'Image', name: 'hash', valueType: 'custom', customValue: (hash) => {
                        return <Image src={`https://img.akut-bolig.dk/90x90/${hash}.jpg`}/>;
                    } },
                    { label: 'Hash', name: 'hash', valueType: 'string' },
                    { label: 'Lease', name: 'lease', valueType: 'string', link: 'lease' },
                    { label: 'Status', name: 'status', valueType: 'string' },
                    { label: 'Primary', name: 'primary', valueType: 'boolean' },
                    { label: 'Type', name: 'type', valueType: 'string' },
                    { label: 'Priority', name: 'priority', valueType: 'number' },
                    { label: 'Url', name: 'url', simpleLinkText: 'See Image', exactLink: 'url', valueType: 'string' }
                    ]}
                limitRow={50}
                hideable
                rows={latestApproved}
              />
          </div>
        </div>}
    </div>);
};

export default ImageDashboard;
