import { fetcher } from 'app/utils/fetcher';
import React from 'react';
import { Panel, Table, Button } from 'react-bootstrap';
import { TOGGLE_ALERTS } from '../fragements';

type Props = {
  item: {
    active: boolean;
    type: string;
    id: string;
    lists: string[];
    meta: any;
    updatedAt: string;
    createdAt: string;
    disabledAt: string;
    disabledBy: string;
  };
};

const NotificationTargets: React.FC<Props> = (props) => {
  const [state, setState] = React.useState(props.item);

  const toggleAlert = () => {
    const input = {
      id: state.id,
      lists: state.lists.includes('INSTANT') ? [] : ['INSTANT'],
      active: !state.active
    };
    setState((prev) => ({ ...prev, ...input }));
    fetcher('/api/akutbolig/graph-proxy', {
      query: TOGGLE_ALERTS,
      variables: { input }
    })
      .then((data) => {
        if (data === null || data?._admin_updateNotificationTarget === false) {
          setState(props.item);
        }
      })
      .catch(() => setState(props.item));
  };

  const { active, type, id, lists, updatedAt, createdAt, disabledAt, disabledBy, meta } = state;

  return (
    <Panel
      collapsible
      bsStyle={active ? 'success' : 'warning'}
      header={type}
      key={id}
    >
      <Table striped condensed responsive hover fill>
        <tbody>
          <tr>
            <th style={{ width: '40%' }}>Status</th>
            <td>
              {active ? (
                <>
                  <i
                    className="fa fa-check-circle"
                    style={{ color: 'darkGreen' }}
                  />{' '}
                  Active
                </>
              ) : (
                <>
                  <i
                    className="fa fa-times-circle"
                    style={{ color: 'rgb(220, 0, 0)' }}
                  />{' '}
                  Inactive
                </>
              )}

              <Button
                className="pull-right"
                bsSize="xsmall"
                bsStyle={active ? 'danger' : 'success'}
                value={id}
                onClick={toggleAlert}
              >
                {active ? 'Deactivate' : 'Activate'}
              </Button>
            </td>
          </tr>
          <tr>
            <th>ID</th>
            <td>{id}</td>
          </tr>
          <tr>
            <th>Lists</th>
            <td>{lists.join(', ')}</td>
          </tr>
          {type === 'EMAIL' ? <tr>
            <th>Email</th>
            <td>{meta?.email}</td>
          </tr> : type === 'SMS' ? <tr>
            <th>Cellphone</th>
            <td>{meta?.cellphone}</td>
          </tr>: null}
          <tr>
            <th>Updated</th>
            <td>{updatedAt || '-'}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>{createdAt || '-'}</td>
          </tr>
          <tr>
            <th>Disabled</th>
            <td>{disabledAt || '-'}</td>
          </tr>
          <tr>
            <th>Disabled By</th>
            <td>{disabledBy || '-'}</td>
          </tr>
        </tbody>
      </Table>
    </Panel>
  );
};

export default NotificationTargets;
