import React from 'react';
import { Route, IndexRoute } from 'react-router';

import Root from './root';
import Agent from './data/agents/agent/agent';
import Agents from './data/agents/agents';
import Dwelling from './data/dwellings/dwelling/dwelling';
import Dwellings from './data/dwellings/dwellings';
import Geography from './data/geographies/geography/geography';
import Geographies from './data/geographies/geographies';
import Reports from './data/reports/reports';

import DwellingsFacebookPost from './data/DwellingsFacebookPost';
import ReviewerConnected from 'app/reviewer';
import Images from './data/images/images';
import ScraperSettings from './data/agents/agent/scraper-settings';
import LoadGeographies from './data/geographies/loadGeography/loadGeography';
import { LeaseReport } from './data/leaseReport';

export default (
  <Route path="dk" component={Root} name="DK">
    <Route path="reviewer" component={ReviewerConnected} name="Reviewer" />
    <Route path="agents" name="Agents">
      <Route path="new" component={Agent} name="new" />
      <Route path="scraper-settings" name="Scraper-Settings">
        <Route path=":slug" component={ScraperSettings}/>
        <IndexRoute component={Agents} />
      </Route>
      <Route path=":agentId" component={Agent} />
      <IndexRoute component={Agents} />
    </Route>
    <Route path="dwellings" name="Dwellings">
      <Route path=":dwellingId" component={Dwelling} />
      <IndexRoute component={Dwellings} />
    </Route>
    <Route path="leaseReport" name="Lease Report">
      <IndexRoute component={LeaseReport} />
    </Route>
    <Route path="geographies" name="Geographies">
      <Route path="new" component={Geography} name="new" />
      <Route path="load" component={LoadGeographies} name="load" />
      <Route path=":slug" component={Geography} />
      <IndexRoute component={Geographies} />
    </Route>
    <Route path="reports" name="Reports">
      <IndexRoute component={Reports} />
    </Route>
    <Route path="images" name="Images">
      <IndexRoute component={Images} />
    </Route>
    <Route
      path="data/latest_dwellings_fb_posts"
      name="Latest dwellings FB posts"
      component={DwellingsFacebookPost}
    />
  </Route>
);
