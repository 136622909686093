import React from 'react';
import apiClient from 'app/utils/api-client';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';
import SubscriptionDetailRow from './subscription-row';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const ONE_DAY = 86400000;

const columns = [
  { label: 'User', name: 'externalId', valueType: 'string', url: 'https://cp.bbintern.net/akutbolig/users', link: 'externalId' },
  { label: 'Provider', name: 'provider', valueType: 'string' },
  { label: 'Plan', name: 'plan', valueType: 'string' },
  { label: 'Next Payment', name: 'nextPaymentDate', valueType: 'date' },
  { label: 'Canceled Date', name: 'canceledAt', valueType: 'date' },
  { label: 'Latest Status', name: 'latestStatus', valueType: 'string' },
  { label: 'Latest Created', name: 'latestCreatedAt', valueType: 'date' },
  { label: 'Latest Due Date', name: 'latestDueDate', valueType: 'date' },
  { label: 'Latest Captured', name: 'latestCaptured', valueType: 'date' },
  { label: 'Total Transactions', name: 'totalTransactions', valueType: 'number' },
  { label: 'Total Captured', name: 'capturedTransactions', valueType: 'number' },
  { label: 'Total Value', name: 'totalValue', valueType: 'number' }
];

function parseDate(date) {
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    return `${date.getFullYear()}-${month}-${day}`;
}

export const SubscriptionDashboard = (props: Props): JSX.Element => {
    const now = new Date();
    const past = new Date(new Date().getTime() - 1 * ONE_DAY);
    const [list, setList] = React.useState([]);
    const [from, setFrom] = React.useState(parseDate(past));
    const [to, setTo] = React.useState(parseDate(now));

    const [status, setStatus] = React.useState('all');
    const [provider, setProvider] = React.useState('all');
    const [limit, setLimit] = React.useState('100');
    const [cursor, setCursor] = React.useState(null);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = (nextCursor?) => {
        const tempFrom = new Date(from);
        const tempTo = new Date(to);

        tempFrom.setHours(0, 0, 0);
        tempTo.setHours(0, 0, 0);
        setLoading(true);
         apiClient.post('/api/microservice', { data: { action: 'Subscriptions', data: { from: tempFrom, to: tempTo, status, provider, limit: Number(limit), cursor: nextCursor } } }).then((data) => {
          if(data?.data) {
            const { result, cursor } = data.data;
            if(nextCursor) {
              setList([].concat(list, result));
            }else {
              setList(result);
            }
            setCursor(cursor);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div>
        <span>From: </span>
        <TextField type="date" id="from" onChange={(ev) => setFrom(ev.target.value)} value={from} key="from" />
        <span>To: </span>
        <TextField type="date" id="to" onChange={(ev) => setTo(ev.target.value)} value={to} key="to" />
        <span>Status: </span>
        <Select value={status} onChange={(ev) => setStatus(ev.target.value)}>
          <MenuItem value="active">Aktive</MenuItem>
          <MenuItem value="inactive">Inaktive</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </Select>
        <span>Provider: </span>
        <Select value={provider} onChange={(ev) => setProvider(ev.target.value)}>
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="mobilepay">MobilePay</MenuItem>
          <MenuItem value="paylike">Paylike</MenuItem>
          <MenuItem value="stripe">Stripe</MenuItem>
        </Select>
        <span>Load limit: </span>
        <Select value={limit} onChange={(ev) => setLimit(ev.target.value)}>
          <MenuItem value="25">25</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
          <MenuItem value="150">150</MenuItem>
        </Select>
        <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
        {cursor ? <Button style={{ margin: '6px' }} size={'small'} label={'Load more'} onClick={() => refresh(cursor)}/> : null}
        </div>
        { loading ? <p>Loading...</p> : !list?.length ? <p>No subscriptions</p> : ( <Table
            title={`Subscriptions (${list.length})`}
            columns={columns}
            rows={list || []}
            detailOnClick={(row) => <SubscriptionDetailRow id={row.id}/> }
        />)}
        {list?.length && cursor ? <Button style={{ margin: '6px' }} size={'small'} label={'Load more'} onClick={() => refresh(cursor)}/> : null}
    </div>
  );
};
