import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setSettings } from '../redux/modules/auth';

class DisplaySettings extends Component {
  hide = () => {
    this.props.setSettings({ show: false });
  };

  toggle = () => {
    const { settings, setSettings } = this.props;
    setSettings({ notifications: !settings.notifications });
  };

  render() {
    const { settings } = this.props;

    return (
      <Modal
        show={settings.show}
        onHide={this.hide}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Button
                onClick={this.toggle}
                bsStyle={settings.notifications ? 'primary' : 'default'}
                active={settings.notifications}
                style={{ width: '4em', marginRight: '1em' }}
              >
                {settings.notifications ? 'On' : 'Off'}
              </Button>
              <b>Desktop notifications</b>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.hide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(({ auth: { settings } }) => ({ settings }), {
  setSettings
})(DisplaySettings);
