export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';

export const SESSION = 'auth/SESSION';
export const SESSION_SUCCESS = 'auth/SESSION_SUCCESS';
export const SESSION_FAILED = 'auth/SESSION_FAILED';

export const LOGOUT = 'auth/LOGOUT';

export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';
export const GET_USERS = 'auth/GET_USERS';
export const CREATE_USER = 'auth/CREATE_USER';

export const GET_USERS_INIT = 'auth/GET_USERS_INIT';
export const GET_USERS_SUCCESS = 'auth/GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'auth/GET_USERS_FAIL';

export const CREATE_USER_INIT = 'auth/CREATE_USER_INIT';
export const CREATE_USER_SUCCESS = 'auth/CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'auth/CREATE_USER_FAIL';

export const UPDATE_USER_INIT = 'auth/UPDATE_USER_INIT';
export const UPDATE_USER_SUCCESS = 'auth/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'auth/UPDATE_USER_FAIL';
export const CLEAR_UPDATE_USER_SUCCESS = 'auth/CLEAR_UPDATE_USER_SUCCESS';

export const GET_SETTINGS = 'GET_SETTINGS';
