import React from 'react';
import SwitchMUI from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type Props = {
  checked: boolean;
  onChange: () => void;
  value: string;
  label: string;
  color?: 'primary' | 'secondary';
  inputProps?: { [key: string]: any };
};

const Switch = (props: Props) => {
  return (
    <FormControlLabel
      control={
        <SwitchMUI
          checked={props.checked}
          onChange={props.onChange}
          value={props.value}
          color={props.color || 'primary'}
        />
      }
      label={props.label}
    />
  );
};

export default Switch;
