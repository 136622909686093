import { UPDATE_USER_SUCCESS, UPDATE_USER_FAIL } from './actions';
import { subscribeAction, defaultStyle } from '../notification';

subscribeAction({
  [UPDATE_USER_SUCCESS]: {
    onPage: {
      ...defaultStyle,
      headline: 'Update Sucessful',
      description: 'The user was updated successfully'
    }
  },
  [UPDATE_USER_FAIL]: {
    onPage: {
      ...defaultStyle,
      bsStyle: 'danger',
      headline: 'Update Failed',
      description: 'The user update failed'
    }
  }
});
