import React from 'react';
import TextField from '@material-ui/core/TextField';

const Comments = (props) => {
  return (
    <TextField
      id="comment"
      label={props.label}
      margin="dense"
      variant="outlined"
      multiline
      value={props.value}
      rows={props.rows}
      onChange={props.onChange}
      fullWidth={props.fullWidth}
    />
  );
};

export default Comments;
