import React, { FC } from 'react';
import { Query } from 'app/components/mui/Query';
import gql from 'graphql-tag';
import { Dwelling } from './Dwelling';

type Dwelling = {
  id: string;
  headline: string;
  address: string;
  zip: string;
  city: string;
  rent: number;
  rooms: number;
  m2: number;
  status: 'inactive' | 'active';
  connection: any;
};

type Props = {
  params: { landlordId: string };
};

const DWELLINGS = gql`
  query dwellings($landlordId: ID!) {
    dwellings(landlord: $landlordId) {
      id
      headline
      address
      zip
      city
      rent
      rooms
      m2
      status
      connection
    }
  }
`;

export const DwellingIndex: FC<Props> = (props) => {
  const landlordId = props.params.landlordId;
  return (
    <Query query={DWELLINGS} variables={{ landlordId }}>
      {({ dwellings }) => {
        return (
          <div>
            <Dwelling dwellings={dwellings} />
          </div>
        );
      }}
    </Query>
  );
};
