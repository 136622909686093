import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import lib from 'lib/format-date';

export default class Audits extends Component {
  static propTypes = {
    data: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data = [] } = this.props;

    return (
      <Table striped condensed hover responsive>
        <thead>
          <tr>
            <th>Action</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {data.map((audit) => (
            <tr key={audit._id}>
              <td>{audit.action}</td>
              <td>{lib.dateFormatStr(audit.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
