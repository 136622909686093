import format from 'date-fns/format';

const DATE_FORMAT = 'yyyy-MM-dd, HH:mm:ss';
// const DATE_FORMAT = 'DD. MMM YYYY, HH:mm';

export function boolFormat(boolean: boolean): string {
  return boolean ? 'Yes' : 'No';
}

export function dateFormatStr(
  date: string | number | Date,
  dateFormat = DATE_FORMAT
): string {
  if (isNil(date)) {
    return;
  }

  return format(typeof date === 'string' ? new Date(date) : date, dateFormat);
}

export function dateUnixStr(date: number): string | void {
  if (isNil(date)) {
    return;
  }
  return dateFormatStr(date * 1000);
}

function isNil(v) {
  return v == null || typeof v == undefined;
}

export default {
  boolFormat,
  dateFormatStr,
  dateUnixStr
};
