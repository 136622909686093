import React from 'react';
import lib from 'lib/format-date';

function toOption(opt) {
  return typeof opt === 'string' ? (
    <option value={!opt ? 'ENUM_' : opt.toUpperCase()}>{opt}</option>
  ) : (
    <option value={opt.value}>{opt.label}</option>
  );
}

export const formatDateStr = lib.dateFormatStr;

export const USER_STATUS_DELETED = 'UNSUBSCRIBED';

export const FIELDS = {
  id: {
    label: 'User ID',
    type: 'text',
    readOnly: true
  },
  emailStatus: {
    label: 'Status',
    type: 'select',
    children: [
      'active',
      'unsubscribed',
      'unconfirmed',
      'invalid',
      'bounce',
      'spam'
    ].map(toOption)
  },
  email: {
    label: 'Email',
    type: 'email'
  },
  name: {
    label: 'Name',
    type: 'text'
  },
  firstName: {
    label: 'First name',
    type: 'text'
  },
  middleName: {
    label: 'Middle name',
    type: 'text'
  },
  lastName: {
    label: 'Last name',
    type: 'text'
  },
  username: {
    label: 'Username',
    type: 'text'
  },
  cellphone: {
    label: 'Cellphone',
    type: 'tel'
  },
  facebookId: {
    label: 'Facebook ID',
    type: 'text',
    readOnly: true
  },
  facebookLink: {
    label: 'Facebook link',
    type: 'static',
    transform: (link) =>
      link && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      )
  },
  gender: {
    label: 'Gender',
    type: 'select',
    children: ['', 'male', 'female'].map(toOption)
  },
  locale: {
    label: 'Locale / Language',
    type: 'text',
    pattern: '^([a-z]{2}(_[A-Z]{2})?)?$'
  },
  createdAt: {
    label: 'Created',
    type: 'text',
    readOnly: true,
    transform: formatDateStr
  }
};
