/* eslint-disable react/no-multi-comp */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Title } from '../Title';
import { fieldBuilder } from 'app/components/mui/utils/fieldBuilder';
import InputField from './InputFields/InputField';
import { Field } from 'formik';
import { Link } from 'react-router';

type Props = {
  fields: { [key: string]: any };
  fieldOptions: any;
  onBlur?: () => void;
  onChange?: () => void;
  title?: JSX.Element | string;
  children?: JSX.Element | JSX.Element[];
  noPaper?: boolean;
  gridProps?: {
    col: {};
  };
};

function renderFields(isFormik, field, props) {
  return isFormik ? (
    <Field
      {...field}
      onBlur={props.onBlur || null}
      onChange={props.onChange || null}
      isFormik={props.fieldOptions.isFormik}
      name={field.inputProps.name}
      key={field.inputProps.id}
      component={field.inputProps.component || InputField}
    />
  ) : (
    <InputField key={field.inputProps.id} {...field} />
  );
}

function fieldGenerator(fields: any, props: Props) {
  return fieldBuilder(fields, props.fieldOptions).map((field) => {
    if (field.inputProps.hide) {
      return null;
    }
    if (field.inputProps.link) {
      const value = field.inputProps.value;

      return (
        <p style={{ fontSize: '1.4em' }}>
          <Link
            key={field.inputProps.id}
            to={`/dk/agents/${field.inputProps.link.to}`}
          >
            {`${value} (#${field.inputProps.link.to})`}
          </Link>
        </p>
      );
    }
    return renderFields(props.fieldOptions.isFormik, field, props);
  });
}

const Section = (props): JSX.Element => {
  return (
    <>
      <hr style={{ margin: '1em 0' }} />
      <p
        style={{
          color: '#aaa',
          fontSize: '0.9em',
          fontWeight: 400,
          letterSpacing: '0.1em'
        }}
      >
        {props.title}
      </p>
      {fieldGenerator(props.field, props.fieldOptions)}
    </>
  );
};

const Fields = (props: Props): JSX.Element => {
  const { subscription, ...fields } = props.fields;

  return !props.noPaper ? (
    <Paper style={{ padding: '6px 16px 12px' }}>
      {props.title && <Title variant="h6" component="p" text={props.title} />}
      {fieldGenerator(fields, props)}
      {Object.keys(props.fields).includes('subscription') && (
        <Section
          title="Payment"
          field={{ ...subscription, type: '', note: '' }}
          fieldOptions={props}
        />
      )}
      {props.children}
    </Paper>
  ) : (
    <>
      {props.title && <Title variant="h6" component="p" text={props.title} />}
      {fieldBuilder(props.fields, props.fieldOptions).map((field) => {
        return renderFields(props.fieldOptions.isFormik, field, props);
      })}
      {props.children}
    </>
  );
};

export default Fields;
