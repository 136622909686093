import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import Loading from '../../common/loading';

type Data = { [key: string]: any };

type Props = {
  query: DocumentNode;
  variables?: { [key: string]: string | number | boolean };
  children: (data: Data, refetch: () => void) => JSX.Element;
  serializer?: { fn: (data: any) => any; namespace: string };
};

export const Query = ({
  query,
  children,
  variables,
  serializer
}: Props): JSX.Element => {
  const { loading, error, data, refetch } = useQuery(query, { variables });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p style={{ color: 'red' }}>{`Error! ${error}`}</p>;
  }
  if (serializer) {
    const { fn, namespace } = serializer;
    const serializedData = fn(data[namespace]);
    return children({ ...data, [namespace]: serializedData }, refetch);
  }

  return children(data, refetch);
};
