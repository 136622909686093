import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { TableContainer } from '../common/table';
import { Table } from 'react-bootstrap';
import lib from 'lib/format-date';

import { getUnsubscriptions } from '../redux/modules/boligninja';

class UnsubscriptionsTable extends TableContainer {
  static fetchData({ getState, dispatch }) {
    if (!getState().boligninja.unsubscriptions.size) {
      return dispatch(getUnsubscriptions());
    }
  }

  render() {
    const { rows } = this.state;

    if (!this.props.rows || !this.props.rows.size) {
      return null;
    }

    return (
      <div>
        <p>{`Showing ${rows.size} of ${this.props.rows.size} users`}</p>

        <Table striped condensed hover>
          <thead>
            <tr>
              <th onClick={this.sortBy} data-column="uuid">
                UUID
              </th>
              <th onClick={this.sortBy} data-column="timestamp">
                Time
              </th>
              <th onClick={this.sortBy} data-column="name">
                Name
              </th>
              <th onClick={this.sortBy} data-column="email">
                Email
              </th>
              <th onClick={this.sortBy} data-column="phone">
                Phone
              </th>
              <th>Reason</th>
            </tr>
            <tr>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="uuid"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by UUID"
                />
              </th>
              <th />
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="name"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by name"
                />
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="email"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by email"
                />
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="phone"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by phone"
                />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.get('uuid')}>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {row.get('subscription') ? (
                      <Link
                        to={`/boligninja/subscriptions/${row.get(
                          'subscription'
                        )}`}
                      >
                        {row.get('uuid')}
                      </Link>
                    ) : (
                      row.get('uuid')
                    )}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {lib.dateFormatStr(row.get('timestamp'))}
                  </td>
                  <td>{row.get('name')}</td>
                  <td>{row.get('email')}</td>
                  <td>{row.get('phone')}</td>
                  <td>{row.get('reason')}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
export default connect(({ boligninja: { unsubscriptions: rows } }) => ({
  rows
}))(UnsubscriptionsTable);
