import React, { Component } from 'react';
//import {connect} from 'react-redux';
//import { changePassword } from '../redux/modules/auth';
import PasswordChange from './PasswordChange';

//@connect({ changePassword })
class User extends Component {
  // todo: pass a changePassword action to this method so that
  // we can remove some of its logic out into an action instead
  render() {
    return (
      <div>
        <PasswordChange /* onSubmit={ this.props.changePassword } */ />
      </div>
    );
  }
}

export default User;
