import PropTypes from 'prop-types';
import React from 'react';

import { Panel, Table } from 'react-bootstrap';

import { dateFormatStr } from 'lib/format-date';
import ConfirmationButton from 'app/common/confirmation/button';

import TrailPanel from './TrailPanel';
import TransactionsPanel from './TransactionsPanel';
import { CANCEL, EXTEND, REACTIVATE } from 'app/boligninja/fragements';
import { fetcher } from 'app/utils/fetcher';
import { serializeSub } from '../../../boligninja/user';

class SubscriptionPanel extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      subscription: props.item
    };
  }

  extendExpiry = async (confirmed, values) => {
    const { id, meta } = this.state.subscription;
    if (meta?.legacyId) {
      this.props.updateSubscription(
        { extend: Number(values.extend) },
        values.notes
      );
      return;
    }
    const data = await fetcher('/api/boligninja/graph-proxy', {
      query: EXTEND,
      variables: {
        id,
        days: Number(values.extend),
        meta: { message: values.notes }
      }
    });

    const sub = data && serializeSub([data?._admin_extendSubscription]);
    if (sub) {
      this.setState({ subscription: sub[0] });
    }
  };

  toggleSub = async (confirmed, values) => {
    const { renew, id, meta } = this.state.subscription;
    if (meta?.legacyId) {
      this.props.updateSubscription({ renew: !renew }, values.notes, id);
      return;
    }
    const query = renew ? CANCEL : REACTIVATE;
    const namespace = `_admin_${
      renew ? 'cancelSubscription' : 'reactivateSubscription'
    }`;
    const data = await fetcher('/api/boligninja/graph-proxy', {
      query,
      variables: {
        id,
        meta: { message: values.notes }
      }
    });
    const sub = data && serializeSub([data[namespace]]);
    if (sub) {
      this.setState({ subscription: sub[0] });
    }
  };

  render() {
    const { subscription } = this.state;
    const { customer } = subscription.meta || {};
    const btnExtend = !subscription.deletedAt ? (
      <ConfirmationButton
        label="Extend"
        onSubmit={this.extendExpiry}
        text="Please confirm, you want to extend the expiration date for the subscription"
        bsStyle="warning"
        bsSize="xs"
        inline
        className="pull-right"
      >
        <div>
          <div className="form-group">
            <label>Additional days</label>
            <div className="input-group">
              <input
                name="extend"
                type="number"
                pattern="^[0-9]+$"
                max="30"
                defaultValue="0"
                className="form-control"
                placeholder="Extend expiration date"
              />
              <div className="input-group-addon">days</div>
            </div>
          </div>
          <div className="form-group">
            <label>Reason / internal notes</label>
            <textarea
              className="form-control"
              name="notes"
              rows="2"
              required
              minLength="10"
            />
          </div>
        </div>
      </ConfirmationButton>
    ) : null;

    const btnToggleRenew = !subscription.deletedAt ? (
      <ConfirmationButton
        label={subscription.renew ? 'Deactivate' : 'Activate'}
        onSubmit={this.toggleSub}
        text="Please confirm, you want to change renewable status for the subscription"
        bsStyle={subscription.renew ? 'danger' : 'success'}
        bsSize="xs"
        inline
        className="pull-right"
      >
        <textarea
          className="form-control"
          name="notes"
          rows="2"
          required
          minLength="10"
        />
      </ConfirmationButton>
    ) : null;

    return (
      <Panel
        bsStyle={
          subscription.renew
            ? subscription.ends <= new Date().toISOString()
              ? 'warning'
              : 'success'
            : subscription.ends > new Date().toISOString()
            ? 'info'
            : 'default'
        }
        header={
          <h4>
            Subscription <small>{subscription.id}</small>{' '}
            {!subscription.meta?.legacyId && (
              <>
                (<small>with</small> <b> 2FA</b>)
              </>
            )}
          </h4>
        }
      >
        <Table striped condensed responsive hover fill>
          <tbody>
            <tr>
              <th>Plan</th>
              <td>{subscription.plan.id}</td>
            </tr>

            <tr>
              <th style={{ width: '40%' }}>Status</th>
              <td>
                {subscription.active ? (
                  <i
                    className="fa fa-check-circle"
                    style={{ color: 'darkGreen' }}
                  />
                ) : (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: 'rgb(220, 0, 0)' }}
                  />
                )}
                &nbsp;
                {subscription.active ? 'Valid' : 'Expired'}
              </td>
            </tr>
            <tr>
              <th>Starts</th>
              <td>{dateFormatStr(subscription.starts)}</td>
            </tr>
            <tr>
              <th>Ends</th>
              <td>
                {dateFormatStr(subscription.ends)}
                {btnExtend}
              </td>
            </tr>
            <tr>
              <th>Renew</th>
              <td>
                {subscription.renew ? (
                  <i
                    className="fa fa-check-circle"
                    style={{ color: 'darkGreen' }}
                  />
                ) : (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: 'rgb(220, 0, 0)' }}
                  />
                )}
                &nbsp;
                {subscription.renew ? 'Yes' : 'No'} {btnToggleRenew}
              </td>
            </tr>
            {subscription.canceledAt ? (
              <tr>
                <th>Canceled</th>
                <td>{dateFormatStr(subscription.canceledAt)}</td>
              </tr>
            ) : null}
          </tbody>
        </Table>

        <Panel collapsible header="Customer / Client">
          {customer ? (
            <Table striped condensed hover bordered fill>
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{customer.email}</td>
                </tr>

                <tr>
                  <td>Name</td>
                  <td>{customer.name}</td>
                </tr>

                <tr>
                  <td>Phone</td>
                  <td>{customer.phone}</td>
                </tr>

                <tr>
                  <td>Country</td>
                  <td>{customer.country}</td>
                </tr>

                <tr>
                  <td>City</td>
                  <td>{customer.city}</td>
                </tr>

                <tr>
                  <td>Zipcode</td>
                  <td>{customer.zipcode}</td>
                </tr>

                <tr>
                  <td>Address</td>
                  <td>{customer.address}</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            'No customer information'
          )}
        </Panel>

        <TrailPanel trail={subscription.trail} />

        <TransactionsPanel
          collapsible
          transactions={subscription.transactions}
        />
      </Panel>
    );
  }
}

export default SubscriptionPanel;
