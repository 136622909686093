import PropTypes from 'prop-types';
import React, { Component } from 'react';
import immutable from 'immutable';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setError } from '../redux/modules/displayError';
import { onPageNotify } from '../redux/modules/notification';
import { Link } from 'react-router';
import config from '../config';
import TableActions from './components/TableActions';
// import fetch from 'isomorphic-fetch';

import formatDistanceToNow from 'date-fns/formatDistanceToNow';

function fromNow(date) {
  return formatDistanceToNow(date, { addSuffix: true });
}

type Props = {
  setError: (error: string) => void;
  agent: any;
  onPageNotify: any;
};

type State = {};
type Actions = 'RELOAD' | 'FORCE_EXEC' | 'ENABLE' | 'DISABLE';

function parseStatus(agent) {
  const status = agent.get('status');

  let color = '#000000';
  let icon = 'question';
  let statusClassName = 'warning';

  switch (status) {
    case 'DISABLED': {
      color = 'black';
      icon = 'exclamation';
      statusClassName = '';
      break;
    }
    case '':
    case 'waiting':
    case 'WAITING': {
      color = 'black';
      icon = 'pause-circle';
      break;
    }
    case 'running':
    case 'RUNNING': {
      color = 'black';
      icon = 'play-circle';
      statusClassName = 'info';
      break;
    }
    case 'success':
    case 'OK':
    case 'ZERO_LISTINGS': {
      if (status === 'OK' && agent.get('validDwellings') === 0) {
        color = 'orange';
        icon = 'exclamation-circle';
        break;
      }
      color = 'green';
      icon = 'check-circle';
      statusClassName = 'success';
      break;
    }
    case 'warning':
    case 'WARNING': {
      color = 'orange';
      icon = 'exclamation-circle';
      break;
    }
    case 'error':
    case 'ERROR':
    case 'FAILED': {
      color = 'red';
      icon = 'exclamation-triangle';
      statusClassName = 'danger';
      break;
    }
    default: {
      color = '#000000';
      icon = 'question';
      break;
    }
  }

  if (statusClassName === 'success' && agent.get('invalidDwellings')) {
    statusClassName = 'warning';
  }

  return { icon, color, statusClassName };
}

async function sendAction(
  slug: string,
  action: Actions,
  closeMenu: () => void
) {
  try {
    const url = `https://${config.scrapers.socket}/${slug}`;

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ action })
      // mode: 'no-cors'
      // credentials: 'include'
    };
    const res = await fetch(url, options);
    const json = await res.json();
    console.log('sendAction data', json);
    return json;
  } catch (err) {
    console.log(err);
  } finally {
    closeMenu();
  }
}

class ScraperTableRow extends Component<Props, State> {
  static propTypes = {
    agent: PropTypes.instanceOf(immutable.Map)
  };

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.agent !== this.props.agent;
  }

  showErrors = () => {
    this.props.setError(this.props.agent.get('errors').toJS());
  };

  onActionTrigger = (slug: string, action: Actions, closeMenu: () => void) => {
    const res = sendAction(slug, action, closeMenu);

    console.log('res-->', res, 'Slug -->', slug, 'action -->', action);
    // this.props.onPageNotify({
    //   headline: 'Failed',
    //   description: 'Something went wrong while fetching for API',
    //   bsStyle: 'danger',
    //   dismissAfter: 5000
    // });
  };

  render() {
    const { agent } = this.props;
    const { icon, color, statusClassName } = parseStatus(agent);

    return (
      <tr className={statusClassName}>
        <td style={{ position: 'relative', minWidth: '36px' }}>
          <TableActions
            direction={'right'}
            onClick={this.onActionTrigger}
            slug={agent.get('slug')}
          />
        </td>
        <td>
          <Link to={`/dk/agents/${agent.get('id')}`}>{agent.get('id')}</Link>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">{agent.get('status')}</Tooltip>}
          >
            <i
              className={`fa fa-fw fa-${icon}`}
              style={{ color }}
              onClick={this.showErrors}
            />
          </OverlayTrigger>
        </td>
        <td>
          {agent.get('link') ? (
            <a
              href={agent.get('link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {agent.get('name')}
            </a>
          ) : (
            agent.get('name')
          )}
        </td>
        <td>{agent.get('statusSince') && fromNow(agent.get('statusSince'))}</td>
        <td>{agent.get('hashCheck') && fromNow(agent.get('hashCheck'))}</td>
        <td>{agent.get('hashChange') && fromNow(agent.get('hashChange'))}</td>
        <td>{agent.get('lastRun') && fromNow(agent.get('lastRun'))}</td>
        <td style={{ textAlign: 'right' }}>{agent.get('validDwellings')}</td>
        <td style={{ textAlign: 'right' }}>{agent.get('invalidDwellings')}</td>
      </tr>
    );
  }
}

export default connect(() => ({}), { setError, onPageNotify })(ScraperTableRow);
