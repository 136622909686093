import React from 'react';
import { Consent } from './Consent';
import gql from 'graphql-tag';
import { Query } from 'app/components/mui/Query';
import { Mutation } from 'app/components/graphql/Mutation';

type Props = {
  params: { landlordId: string };
};

const CREATE_CONSENT = gql`
  mutation($consent: ConsentInput!) {
    createConsent(consent: $consent) {
      id
      type
      landlord {
        id
      }
      approval {
        date
        contact
        admin
        note
      }
      withdrawn {
        date
        contact
        admin
        note
      }
    }
  }
`;

const CONSENTS = gql`
  query Consents($id: ID!) {
    consentTypes
    landlord(id: $id) {
      consents(withdrawn: true) {
        id
        type
        landlord {
          id
        }
        approval {
          date
          contact
          admin
          note
        }
        withdrawn {
          date
          contact
          admin
          note
        }
      }
    }
    consentTypes
  }
`;

export const ConsentIndex = (props: Props): JSX.Element => {
  const landlordId = props.params.landlordId;
  return (
    <Query query={CONSENTS} variables={{ id: landlordId }}>
      {({ landlord, consentTypes }) => {
        return (
          <Mutation mutation={CREATE_CONSENT}>
            {({ mutate }) => {
              return (
                <div>
                  <Consent
                    onSave={mutate}
                    landlordId={landlordId}
                    consents={landlord.consents}
                    {...{
                      consentTypes
                    }}
                  />
                </div>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};
