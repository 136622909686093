import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { getCurrentUser } from './api-client';

type Config = {
  cache?: {
    addTypename?: boolean;
  };
};

export function createClient(uri: string, config: Config = {}): any {
  const httpLink = createHttpLink({ uri });
  const middlewareLink = new ApolloLink((operation, forward) => {
    const user = getCurrentUser();
    operation.setContext({
      headers: {
        authorization: user && user.token ? `Bearer ${user.token}` : undefined
      }
    });
    return forward(operation);
  });

  const link = middlewareLink.concat(httpLink);

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      ...(config.cache || {})
    })
  });
}
