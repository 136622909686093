import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Panel, Table, Popover, Overlay } from 'react-bootstrap';

import AuditTableRow from './audit-table-row';
import { Checkbox } from '@material-ui/core';

export default class AuditPanel extends Component {
  static propTypes = {
    trail: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
        area: PropTypes.string,
        action: PropTypes.string.isRequired,
        data: PropTypes.string,
        remoteAddr: PropTypes.string
      })
    ).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
      filters: 0,
      emailBB: false,
      smsBB: false,
      emailWeekly:false,
      visitedBoligsoegerlisten: false,
    };
  }

  expand = (ev, data) => {
    try {
      const target = ev.target;
      const content = <pre>{JSON.stringify(data, null, 2)}</pre>;
      this.setState({ expanded: { target, content } });
    } catch (e) {
      if (__DEV__) {
        console.error(e);
      }
    }
  };

  collapse = (ev) => {
    this.setState({ expanded: null });
  };

  changeFilter = (ev, key) => {
    this.setState({ [key]: ev.target.checked });
    const filters = ev.target.checked ? this.state.filters + 1 : this.state.filters - 1;
    this.setState({ filters });
  }

  render() {
    const { trail = [], filters = [], title } = this.props;
    return trail.length ? (
      <div>
        <Panel collapsible header={`${title} (${trail.length})`}>
          <Panel collapsible header={`Filter (${this.state.filters}/${filters.length})`}>
              <ul>
                {filters.map((filter) => (<li>{filter.label} <Checkbox
                  checked={this.state[filter.key]}
                  onChange={(ev) => this.changeFilter(ev, [filter.key])}
                /></li>))}
              </ul>
          </Panel>
          <Table striped condensed responsive hover fill>
            <tbody>
              <tr>
                <th style={{ width: '40%' }}>Time</th>
                <th>Action</th>
                {/* <th>IP</th> */}
              </tr>
              {trail.filter((v) => {
                for (let idx = 0; idx < filters.length; idx++) {
                  const filter = filters[idx];
                  if(!this.state[filter.key] && v.action === filter.action) {
                    return false;
                  }
                }
                return true;
              }).map((t) => (
                <AuditTableRow
                  key={t.id}
                  item={t}
                  onExpand={this.expand}
                  user={this.props.user}
                />
              ))}
            </tbody>
          </Table>
        </Panel>

        {this.state.expanded && (
          <Overlay target={this.state.expanded.target} placement="top" show>
            <Popover onClick={this.collapse} id="popover-positioned-top">
              {this.state.expanded.content}
            </Popover>
          </Overlay>
        )}
      </div>
    ) : null;
  }
}
