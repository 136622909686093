import React from 'react';
import LineChart from './lineChart';
import BarChart from './barChart';
import PieChart from './pieChart';
import { deserializeDataset, createCharData } from './utils';
import Table from 'app/components/mui/Table';

export function tableContent(report, opts, withoutPre) {
  const dataSets = deserializeDataset(report.dataset);
  const table = dataSets['table'] || null;
  const dump = dataSets['dump'] || '';

  return (
    <>
      {<div style={{ float: 'left', width: '100%' }}>
        {dump ? (opts.charts || [opts.chart]).map((chart, idx) => {
          const chartData = createCharData(dump, opts, chart);
          switch (chart.type) {
            case 'BarChart':
              return <BarChart key={idx} {...chart} data={chartData} />;
            case 'PieChart':
              return <PieChart key={idx} {...chart} data={chartData} />;
            case 'LineChart':
            default:
              return <LineChart key={idx} {...chart} data={chartData} />;
          }
        }): null}
        <hr />
        { table ? <Table
              title={table.title}
              columns={table.columns}
              rows={table.rows}
            /> : null }
        {withoutPre ? null : <pre>{deserializeDataset(dump, false)}</pre>}
        </div>
      }
    </>
  );
}