import React, { Component } from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (labelOptions) => {
  return (options: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index
    } = options;
    const { percentageThreshold = 2, label = '[MissingLabel]', labels = [], displayLabel = true } = labelOptions || {};
    const radius = innerRadius + (outerRadius - innerRadius) * 0.50;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const currentLabel = labels.length ? labels[index] || label : label;

    const percentage = (percent * 100);
    return percentage > percentageThreshold ? (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={'middle'}
        dominantBaseline='central'
      >
        {`${options[currentLabel]}${displayLabel ? ' ' + currentLabel : ''}(${percentage.toFixed(0)}%)`}
      </text>
    ) : (
      <text>
      </text>
    );
  };
}; 

export default class Chart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={600}>
          <PieChart>
            <Pie
                data={this.props.data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel(this.props.labelOptions)}
                outerRadius="75%"
                fill="#8884d8"
                dataKey={this.props.dataKey}
            >
                {this.props.data.map((entry, index) => {
                  const colors = this.props.labelOptions?.colors || COLORS;

                  return (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                );
                })}
            </Pie>
        </PieChart>
      </ResponsiveContainer>);
  }
}