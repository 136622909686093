import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPage } from './redux';

class Page extends Component {
  static fetchData({ getState, params, dispatch }) {
    const { page } = getState().r6;
    if (!page || page.get('_id') !== params.id) {
      return dispatch(loadPage(params.id));
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      page: props.page
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.setState({ page: nextProps.page });
    }
  }

  render() {
    const { page } = this.state;

    if (!page) {
      return null;
    }

    return (
      <div>
        <pre>{JSON.stringify(page.toJS(), null, 2)}</pre>
      </div>
    );
  }
}

export default connect(({ r6: { page } }) => ({ page }))(Page);
