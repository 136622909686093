import React, { FC } from 'react';
import { Scraper } from './Scraper';
import gql from 'graphql-tag';
import { Query } from 'app/components/mui/Query';
import { Mutation } from 'app/components/graphql/Mutation';
import { Scraper as ScraperType } from './helpers';

type Props = {
  params: { landlordId: string };
  router: { push: (to: string) => void };
};

const SCRAPER = gql`
  query($id: ID!) {
    landlord(id: $id) {
      scrapers {
        agent
        active
        note
        isScrapeable
        config {
          slug
          project
          enabled
          dataDest
          maxItems
          listChecksum
          contactChecksum
        }
      }
    }
  }
`;

const CHANGE_DATA_DEST = gql`
  mutation($slug: ID!, $dataDestination: DataDestination!, $project: ID) {
    changeDataDestination(
      slug: $slug
      dataDestination: $dataDestination
      project: $project
    ) {
      id
    }
  }
`;

export const ScraperIndex: FC<Props> = (props): JSX.Element => {
  const landlordId = props.params.landlordId;
  return (
    <Query query={SCRAPER} variables={{ id: landlordId }}>
      {({ landlord }: { landlord: { scrapers: ScraperType[] } }) => {
        return (
          <Mutation mutation={CHANGE_DATA_DEST}>
            {({ mutate }) => (
              <div>
                <Scraper
                  scrapers={landlord.scrapers || []}
                  landlord={landlordId}
                  changeDataDest={mutate}
                />
              </div>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};
