import React from 'react';
import TableRow from '@material-ui/core/TableRow';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  detailOnClick?: (row: any) => any;
  row?: any;
};

const TRow = ({ children, detailOnClick, ...props }: Props) => {
  const [selected, setSelected] = React.useState(false);
  return [ <TableRow {...props} onClick={() => setSelected(!selected)}>{children}</TableRow>,
    selected && detailOnClick ? detailOnClick(props.row): null].filter(Boolean);
};

export default TRow;
