import React from 'react';
import Table from 'app/components/mui/Table';

const columns = [
    { label: 'UserId', name: 'userId', valueType: 'string' }
];

export function resultSerializer(result: { users: string[]}): number {
    return result.users.length;
}

export default (row) => {
    return (<tr>
      <td colSpan={1}/>
      <td colSpan={11}>
        <div>
          <Table
            columns={columns}
            rows={row.result.users.map((v) => ({ userId: v}))}
            />
        </div>
      </td>
    </tr>);
};