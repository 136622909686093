import React from 'react';
import InputField from 'app/components/mui/InputFields/InputField';
import DateTimePicker from 'app/biz/roster/Inputs/DateTimePicker';
import Button from 'app/components/mui/Button';
import { FormWithList } from 'app/components/FormWithList';
import { ConsentItem } from './ConsentItem';
import { Mutation } from 'app/components/graphql/Mutation';
import gql from 'graphql-tag';
//TODO: move linkModal to component and rename it modal. so more component can use it.
import LinkModal from 'app/biz/linktool/LinkUpload/LinkModal';
import Placement from 'app/components/Placement';
import { findAndReplace } from '../../helpers';

type ConsentType = 'OTHER' | 'IMAGES' | 'SCRAPER';

type FormState = {
  type?: ConsentType;
  landlord?: string;
  date?: Date;
  contact?: string;
  id?: null | string;
  note?: string;
};

type Props = {
  consentTypes: ConsentType;
  landlordId: string;
  onSave: (variables: { [key: string]: any }) => Promise<{ data: any }>;
  initValues?: FormState;
  consents: any[];
};

function inputs(options: { consentTypes: ConsentType }) {
  return [
    {
      id: 'type',
      label: 'type',
      type: 'text',
      options: options.consentTypes.map((consentType) => ({
        value: consentType
      })),
      name: 'type'
    },
    {
      id: 'date',
      label: 'date',
      name: 'date',
      type: 'date',
      format: 'dd-MM-yyyy'
    },
    {
      id: 'contact',
      label: 'contact',
      type: 'text',
      name: 'contact'
    },
    {
      id: 'note',
      label: 'note',
      type: 'text',
      name: 'note'
    }
  ];
}

const WITHDRAW_CONSENT = gql`
  mutation($id: ID!, $consent: ConsentActionInput!) {
    withdrawConsent(id: $id, consent: $consent) {
      id
      type
      landlord {
        id
      }
      approval {
        date
        contact
        admin
        note
      }
      withdrawn {
        date
        contact
        admin
        note
      }
    }
  }
`;

export const Consent = (props: Props): JSX.Element => {
  const date = new Date();
  const INPUTS = inputs({ consentTypes: props.consentTypes });
  const [open, setOpen] = React.useState(false);
  const [withdrawState, setWithdrawState] = React.useState<FormState>({
    landlord: props.landlordId,
    id: null,
    date: date
  });

  const handleChange = (
    ev: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    if (ev.currentTarget && ev.currentTarget.name) {
      const { name, value } = ev.currentTarget;
      setWithdrawState((prev) => ({ ...prev, [name]: value }));
    } else {
      setWithdrawState((prev) => ({ ...prev, date: ev }));
    }
  };

  const renderField = INPUTS.map((input) => {
    if (input.name === 'type') {
      return null;
    }
    return input.type !== 'date' ? (
      <InputField
        key={input.id}
        onChange={handleChange}
        inputProps={{
          ...input,
          value: withdrawState[input.name] || ''
        }}
      />
    ) : (
      <DateTimePicker
        key={input.id}
        onChange={handleChange}
        value={withdrawState[input.name]}
        {...input}
      />
    );
  });
  return (
    <>
      <div style={{ maxWidth: '725px' }}>
        <FormWithList
          title="Consents"
          nameSpace="createConsent"
          initValues={{
            landlord: props.landlordId,
            date: new Date()
          }}
          inputs={INPUTS}
          onSubmitReq={(values) =>
            props.onSave({
              variables: {
                consent: {
                  ...values,
                  landlord: props.landlordId,
                  date: values.date.toISOString()
                }
              }
            })
          }
          listProps={{
            lists: props.consents,
            component: ConsentItem,
            onActionClick: (id) => {
              setWithdrawState((prev) => ({ ...prev, id }));
              setOpen(true);
            }
          }}
          buttonProps={{
            label: 'Create'
          }}
        />
      </div>

      <LinkModal
        open={open}
        onClose={() => {
          setWithdrawState((prev) => ({ ...prev, id: null }));
          setOpen(false);
        }}
      >
        <Mutation mutation={WITHDRAW_CONSENT}>
          {({ mutate, options }) => {
            return (
              <>
                {renderField}
                <Placement style={{ marginTop: '8px' }} place="right">
                  <Button
                    type="submit"
                    size="small"
                    label="Witdraw"
                    isLoading={options.loading}
                    onClick={async () => {
                      if (withdrawState.id) {
                        try {
                          const { data } = await mutate({
                            variables: {
                              id: withdrawState.id,
                              consent: {
                                contact: withdrawState.contact,
                                date: withdrawState.date,
                                note: withdrawState.note
                              }
                            }
                          });
                          findAndReplace(props.consents, data.withdrawConsent);
                          setOpen(false);
                        } catch (error) {
                          alert(error);
                        }
                      } else {
                        alert('missing id for consent');
                      }
                    }}
                  />
                </Placement>
              </>
            );
          }}
        </Mutation>
      </LinkModal>
    </>
  );
};
