import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

type Props = {
  label: string;
  checked: boolean;
  onChange: () => void;
};

const ShowMore = (props: Props): JSX.Element => {
  return (
    <FormControlLabel
      control={<Switch checked={props.checked} onChange={props.onChange} />}
      label={props.label}
    />
  );
};

export default ShowMore;
