import './styles';
import React, { Component } from 'react';
import { Calendar } from 'react-big-calendar';
import { requirePermission } from '../../../shared/auth/permissions';
import apiClient from 'app/utils/api-client';
import api from './ApiMethod';
import ShiftForm from './ShiftForm';
import WorkingHoursInfo from './WorkingHoursInfo';
import { connect } from 'react-redux';
import { getUsers } from '../../redux/modules/auth';
import { createInitial, capitalize } from './helpers';
import Grid from '@material-ui/core/Grid';
import Alert from '../../components/mui/Snackbar';
import { localizer } from './localizers';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay
} from 'date-fns';
import colorGenerator from 'string-to-color';
import { FormControlLabel, Switch } from '@material-ui/core';
import { colors } from 'app/components/mui/utils/colors';

type Props = {} & any;

type CalendarView = 'month' | 'week' | 'day';

type State = {
  isAdmin: boolean;
  shifts: any[];
  status: string;
  comment: string | null;
  currentShift: { [key: string]: any } | null;
  currentDate: Date;
  currentRange: { start: Date; end: Date };
  snackbar: { open: boolean; message: string; type: string };
  calendarView: CalendarView;
  editMode: boolean;
  iCalLink: string | null;
};

function getCurrentRange(currentDate: Date, calendarView: CalendarView) {
  switch (calendarView) {
    case 'month':
      return {
        start: startOfMonth(currentDate),
        end: endOfMonth(currentDate)
      };
    case 'week':
      return {
        start: startOfWeek(currentDate, { weekStartsOn: 1 }),
        end: endOfWeek(currentDate, { weekStartsOn: 1 })
      };
    case 'day':
      return {
        start: startOfDay(currentDate),
        end: endOfDay(currentDate)
      };
    default:
      return {
        start: startOfMonth(currentDate),
        end: endOfMonth(currentDate)
      };
  }
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const isAdmin = __DEV__ ? true : this.isAdmin(props.user);

    this.state = {
      isAdmin: isAdmin,
      shifts: [],
      status: 'PENDING',
      comment: null,
      currentShift: null,
      currentDate: new Date(),
      currentRange: { ...getCurrentRange(new Date(), 'month') },
      snackbar: { open: false, message: '', type: 'error' },
      calendarView: 'month',
      editMode: false,
      iCalLink: null
    };

    if (!props.users.length) {
      props.getUsers();
    }

    this.fetchData = this.fetchData.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.iCal = this.iCal.bind(this);

    this.fetchData();
  }

  async fetchData() {
    const { start, end } = this.state.currentRange;
    const url = `/shifts?start=${format(start, 'yyyy-MM-dd')}&end=${format(
      end,
      'yyyy-MM-dd'
    )}`;

    try {
      const shifts = await api(url);
      const currentShift =
        this.state.currentShift &&
        shifts.find(shift => shift._id === this.state.currentShift._id);

      this.setState({
        shifts,
        currentShift: currentShift && {
          ...currentShift,
          start: new Date(currentShift.start),
          end: new Date(currentShift.end)
        }
      });
    } catch (err) {
      console.log(err);
      this.showSnackbar({ message: 'could fetch data', type: 'error' });
    }
  }

  isAdmin(user) {
    const { permissions } = user;
    return requirePermission('biz/roster:write', permissions);
  }

  toggleShiftHandler = shift => {
    this.setState({
      currentShift:
        !this.state.currentShift || this.state.currentShift._id !== shift._id
          ? shift
          : null
    });
  };

  showSnackbar = snackInfo => {
    this.setState({ snackbar: { ...snackInfo, open: true } });
  };

  closeSnackbar = (event, reason) => {
    if (reason !== 'clickaway') {
      this.setState({ snackbar: { ...this.state.snackbar, open: false } });
    }
  };

  toggleEditMode = () => {
    const editMode = this.state.editMode;
    this.setState({ editMode: !editMode });
  };

  getUserName(userId) {
    const user = this.props.users.find(user => user._id === userId);
    return (user && user.name) || '';
  }

  async iCal() {
    const { token: appToken } = await apiClient.get('/api/auth/app-token');
    const iCalLink = `${window.location.protocol}//${window.location.host}/api/biz/roster/ical.ics?token=${appToken}`;
    this.setState({ iCalLink });
  }

  render() {
    const { currentShift, isAdmin, shifts, snackbar, editMode } = this.state;
    return (
      <>
        {/* <button
          onClick={() => this.showSnackbar({ message: 'test', type: 'error' })}
        >
          Open Snackbar
        </button> */}
        <Alert
          message={snackbar.message}
          open={snackbar.open}
          onClose={this.closeSnackbar}
          autoHideDuration={4000}
          type={snackbar.type}
          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'left'
          // }}
        />
        <Grid container spacing={1} style={{ height: '100vh' }}>
          <Grid item sm={3}>
            <FormControlLabel
              value="top"
              control={
                <Switch checked={editMode} onChange={this.toggleEditMode} />
              }
              labelPlacement="end"
              label="Planlæg"
            />
            <ShiftForm
              showSnackbar={this.showSnackbar}
              key={currentShift && currentShift._id && currentShift.updatedAt}
              user={this.props.user._id}
              getUserName={this.getUserName}
              onCreated={this.fetchData}
              onUpdated={this.fetchData}
              shift={currentShift}
              shifts={shifts}
              admin={isAdmin}
              editMode={this.state.editMode}
            />
            <WorkingHoursInfo
              getUserName={this.getUserName}
              editMode={this.state.editMode}
              shifts={
                isAdmin
                  ? shifts
                  : shifts.filter(shift => shift.user === this.props.user._id)
              }
            />
            <hr />
            {this.state.iCalLink ? (
              <textarea style={{ width: '100%' }} value={this.state.iCalLink} />
            ) : (
              <button onClick={this.iCal}>Get iCal link</button>
            )}
          </Grid>

          <Grid item sm={9}>
            <Calendar
              localizer={localizer}
              tooltipAccessor={ev =>
                `${this.getUserName(ev.user)} / Work station: ${capitalize(
                  ev.workStation
                ) || ''}`
              }
              culture={'da'}
              popup
              views={['month', 'week', 'day']}
              defaultView="month"
              onView={calendarView => {
                this.setState(
                  {
                    calendarView,
                    currentRange: {
                      ...getCurrentRange(this.state.currentDate, calendarView)
                    }
                  },
                  this.fetchData
                );
              }}
              view={this.state.calendarView}
              eventPropGetter={ev => {
                const style: { background: string; opacity: string } = {
                  background: null,
                  opacity: '1'
                };
                if (this.state.editMode) {
                  switch (ev.status) {
                    case 'ACCEPTED':
                      style.background = colors.green[500];
                      break;
                    case 'PENDING':
                      style.background = colors.amber[500];
                      break;
                    case 'REJECTED':
                      style.background = '#b2bec3';
                      style.opacity = '0.5';
                      break;
                    default:
                      style.background = '#6c757d';
                  }
                } else {
                  style.background = colorGenerator(ev.user);
                }
                return { style };
              }}
              events={shifts
                .filter(shift => editMode || shift.status === 'ACCEPTED')
                .map(shift => {
                  const initials = createInitial(this.getUserName(shift.user));
                  const start = new Date(shift.start);
                  const end = new Date(shift.end);
                  const title =
                    this.state.calendarView === 'week'
                      ? `${initials}\n\n${
                          shift.workStation
                            ? `Work station: ${capitalize(shift.workStation)}`
                            : ''
                        }\n\n${shift.comment || ''}`.trim()
                      : `${initials}: ${format(
                          new Date(start),
                          'HH:mm'
                        )} - ${format(new Date(end), 'HH:mm')}${
                          shift.workStation
                            ? ` / ${capitalize(shift.workStation) || ''}`
                            : ''
                        }`;
                  return { ...shift, start, end, title };
                })}
              style={{ paddingTop: '0' }}
              onSelectEvent={this.toggleShiftHandler}
              onNavigate={currentDate => {
                this.setState(
                  {
                    currentDate,
                    currentRange: {
                      ...getCurrentRange(currentDate, this.state.calendarView)
                    }
                  },
                  this.fetchData
                );
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default connect(
  ({ auth } /*, router*/) => ({
    user: auth.user,
    users: auth.users
  }),
  { getUsers }
)(App);
