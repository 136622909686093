import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Link } from 'react-router';

import Loading from '../loading';

function keyToLabel(key) {
  return `${key[0].toUpperCase()}${key
    .substr(1)
    .replace(/[A-Z]/g, (m) => ` ${m.toLowerCase()}`)}`;
}

export default class Table extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    renderRow: PropTypes.func,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ).isRequired
  };

  static defaultProps = {
    loading: false,
    rows: []
  };

  constructor(props) {
    super(props);

    this.state = {
      keys: this.getKeys(props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.keys || this.props.keys !== nextProps.keys) {
      this.setState({ keys: this.getKeys(nextProps) });
    }
  }

  getKeys = (props) => {
    return (
      props.keys ||
      (props.rows.length
        ? Object.keys(props.rows[0]).filter((key) => {
            if (['__typename'].indexOf(key) !== -1) {
              return false;
            }
            return true;
          })
        : [])
    );
  };

  renderHead = () => {
    const keys = this.state.keys;
    return (
      <thead>
        <tr>
          {keys.map((key) => (
            <th key={key} style={{ whiteSpace: 'nowrap' }}>
              {keyToLabel(key)}
            </th>
          ))}
          {/* <th onClick={this.sortBy} data-column="id">ID</th>
          <th onClick={this.sortBy} data-column="name">Name</th>
          <th onClick={this.sortBy} data-column="phone">Phone</th>
          <th onClick={this.sortBy} data-column="email">Email</th> */}
        </tr>
        {/* <tr>
          <th />
          <th>
            <input onChange={ this.onFilterChange } name="search" className="form-control" style={{ margin: 0, fontWeight: 'normal', width: '100%' }} placeholder="Filter by name" />
          </th>
          <th />
          <th />
        </tr> */}
      </thead>
    );
  };

  renderRow = (row) => {
    const keys = this.state.keys;
    return (
      <tr key={row.id}>
        {keys.map((key) => {
          if (key === 'id' && this.props.link) {
            return (
              <td key={key}>
                <Link to={`${this.props.link}${row[key]}`}>{row[key]}</Link>
              </td>
            );
          }
          return <td key={key}>{row[key] || '-'}</td>;
        })}
      </tr>
    );
  };

  renderTable() {
    const { rows = [], renderRow = this.renderRow, loading } = this.props;
    return (
      <div>
        <div className="table-responsive">
          <table className="table table-striped table-condensed table-hover">
            {this.renderHead()}
            <tbody>{rows.map(this.renderRow)}</tbody>
          </table>

          {loading ? <Loading /> : null}
        </div>
      </div>
    );
  }

  render() {
    return this.renderTable();
  }
}
