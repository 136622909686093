import React from 'react';

type Props = {
  text?: string;
  target?: string;
  rel?: string;
  href: string;
  style?: { [key: string]: any };
  onClick?: () => void;
};

const OutgoingLink = (props: Props): JSX.Element => {
  return (
    <a
      rel={props.rel || 'noopener noreferrer'}
      target={props.target || '_blank'}
      href={props.href}
      style={props.style || {}}
      onClick={props.onClick}
    >
      {props.text || props.href}
    </a>
  );
};

export default OutgoingLink;
