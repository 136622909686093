import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './styles.module.scss';

type BaseProps = {
  margin?: 'dense';
  variant?: 'outlined';
  field?: any;
  onBlur?: (ev: any) => void;
  onChange?: (ev: any) => void;
  isFormik?: boolean;
};

export type TextFieldProps = BaseProps & {
  componentType?: 'textField';
  helperText?: JSX.Element | string;
  inputProps: {
    link?: { to: string | number };
    hide?: boolean;
    id: string;
    label: string;
    helperText?: JSX.Element | string;
    type: 'text' | 'number';
    fullWidth?: boolean;
    value: any;
    hasHelperText?: { [key: string]: any }[];
    disabled?: boolean;
    name: string;
    sectionName?: string;
    options?: { value: string; label?: string }[];
  };
};

type Props = TextFieldProps;

const InputField = ({
  field,
  inputProps: { options, ...inputProps },
  ...props
}: Props) => {
  const formikValue = field ? field.value : null;
  let value = formikValue !== null ? formikValue : inputProps.value;
  if (
    field &&
    field.value === -1 &&
    ['m2', 'rooms', 'rent'].includes(field.name.split('.')[1])
  ) {
    value = 'NO DATA';
  }
  const isSelect = Boolean(options);
  if (isSelect) {
    value = String(value) === 'null' ? '_null' : String(value);
  }

  const textFieldProps = Object.assign({}, inputProps);

  delete textFieldProps.hasHelperText;
  delete textFieldProps.sectionName;
  return (
    <TextField
      {...textFieldProps}
      {...(field || {})}
      onBlur={(ev) => {
        field && field.onBlur(ev);
        props.onBlur && props.onBlur(ev);
      }}
      onChange={(ev) => {
        field && field.onChange(ev);
        props.onChange && props.onChange(ev);
      }}
      helperText={inputProps.helperText || props.helperText}
      value={value || ''}
      select={isSelect}
      InputLabelProps={{
        shrink: true
      }}
      SelectProps={{
        className: styles.select
      }}
      margin={props.margin || 'dense'}
      variant={props.variant || 'outlined'}
    >
      {options &&
        options.map(({ label, value }) => {
          return (
            <MenuItem key={value} value={value}>
              {label || value}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

export default InputField;
