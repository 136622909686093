import { fromJS } from 'immutable';
import qs from 'qs';

import { LOGOUT } from '../redux/modules/auth/actions';

const NAMESPACE = 'akutbolig';
const ENDPOINT = `/api/${NAMESPACE}`;

const LOAD_USERS = `${NAMESPACE}/LOAD_USERS`;
const LOAD_USERS_SUCCESS = `${NAMESPACE}/LOAD_USERS_SUCCESS`;
const LOAD_USERS_FAILED = `${NAMESPACE}/LOAD_USERS_FAILED`;

const LOAD_USER = `${NAMESPACE}/LOAD_USER`;
const LOAD_USER_SUCCESS = `${NAMESPACE}/LOAD_USER_SUCCESS`;
const LOAD_USER_FAILED = `${NAMESPACE}/LOAD_USER_FAILED`;

const UPDATE_USER = `${NAMESPACE}/UPDATE_USER`;
const UPDATE_USER_SUCCESS = `${NAMESPACE}/UPDATE_USER_SUCCESS`;
const UPDATE_USER_FAILED = `${NAMESPACE}/UPDATE_USER_FAILED`;

const DELETE_USER = `${NAMESPACE}/DELETE_USER`;
const DELETE_USER_SUCCESS = `${NAMESPACE}/DELETE_USER_SUCCESS`;
const DELETE_USER_FAILED = `${NAMESPACE}/DELETE_USER_FAILED`;

const initialState = {
  users: fromJS([]),
  user: null
};

export default function r6(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USERS_SUCCESS: {
      return { ...state, users: fromJS(payload) };
    }
    case LOAD_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case DELETE_USER_SUCCESS: {
      return { ...state, user: fromJS(payload) };
    }
    case LOGOUT: {
      return initialState;
    }
  }

  return state;
}

export function getUsers(query) {
  return {
    types: [LOAD_USERS, LOAD_USERS_SUCCESS, LOAD_USERS_FAILED],
    promise: (client) =>
      client
        .get(`${ENDPOINT}/users${query ? `?${qs.stringify(query)}` : ''}`)
        .then(({ status, results }) => {
          if (status !== 'OK') {
            throw new Error('LOAD_USERS_FAILED');
          }
          return results;
        })
  };
}

export function getUser(id) {
  return {
    types: [LOAD_USER, LOAD_USER_SUCCESS, LOAD_USER_FAILED],
    promise: (client) =>
      client.get(`${ENDPOINT}/users/${id}`).then(({ status, result }) => {
        if (status !== 'OK') {
          throw new Error('LOAD_USER_FAILED');
        }
        return result;
      })
  };
}

export function updateUser(id, data) {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED],
    promise: (client) =>
      client
        .post(`${ENDPOINT}/users/${id}`, { data })
        .then(({ status, result }) => {
          if (status !== 'OK') {
            throw new Error('UPDATE_USER_FAILED');
          }
          return result;
        })
  };
}

export function deleteUser(id) {
  return {
    types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILED],
    promise: (client) =>
      client.delete(`${ENDPOINT}/users/${id}`).then(({ status, result }) => {
        if (status !== 'OK') {
          throw new Error('DELETE_USER_FAILED');
        }
        return result;
      })
  };
}
