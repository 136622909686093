import React, { SyntheticEvent } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TRow from './TRow';
import TCell from './TCell';
import SimpleInputField from '../InputFields/SimpleInputField';

type Props = {
  filters?: { [index: string]: string };
  onFilterChange: (ev: SyntheticEvent<HTMLInputElement>) => void;
  onSortReq: any;
  rows: {
    name: string;
    label: string;
    align?: 'left' | 'center' | 'right';
    // onSortReq?: (ev: any) => void;
  }[];
};

const THead = (props: Props) => {
  return (
    <TableHead>
      <TRow>
        {props.rows.map((row) => {
          return (
            <TCell
              onClick={props.onSortReq}
              key={row.name}
              label={row.label}
              align={row.align || 'left'}
              data-column={row.name}
            />
          );
        })}
      </TRow>
      {props.filters && (
        <TRow>
          {props.rows.map((row) => {
            const inputProps = Object.prototype.hasOwnProperty.call(
              props.filters,
              row.name
            )
              ? {
                  type: 'text',
                  name: row.name,
                  placeholder: 'Filter results',
                  defaultValue: props.filters[row.name],
                  onChange: props.onFilterChange
                }
              : {};
            if (!inputProps.name) {
              return <TCell key={row.name} />;
            }
            return (
              <TCell key={row.name}>
                <SimpleInputField {...inputProps} />
              </TCell>
            );
          })}
        </TRow>
      )}
    </TableHead>
  );
};

export default THead;
