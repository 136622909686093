import React, { Component } from 'react';
import { Link } from 'react-router';
import apiClient from 'app/utils/api-client';

export default class Reports extends Component {
  constructor(props) {
    super(props);

    const v2 = [
      { path: 'rentalpreferences', name: 'Rental Preferences' },
      { path: 'boligbeskeder', name: 'Boligbeskeder' },
      { path: 'lease-unwanted', name: 'Lease Unwanted' }
    ];
    this.state = { reports: [], v2 };
  }

  componentDidMount() {
    apiClient.get('/api/reports').then(({ results: reports }) => {
      this.setState({ reports });
    });
  }

  render() {
    const { reports, v2 } = this.state;
    return (
      <div>
        <h5>V2 Reports</h5>
      <ul>

        {v2.map(({path, name}, idx) => (
            <li key={idx}>
              <Link to={`/reports/${path}`}>
                <span>
                  {name}
                </span>
              </Link>
            </li>
          )
        )}
      </ul>
      <h5>V1 Reports</h5>
      <ul>
        {reports.filter((report) => !report.hide).map((report) => {
          return (
            <li key={report.key}>
              <Link to={`/reports/${report.key}`}>
                {report.icon ? (
                  <span>
                    <span className={`fa fa-fw fa-${report.icon}`} />{' '}
                    {report.label}
                  </span>
                ) : (
                  report.label
                )}
              </Link>
            </li>
          );
        })}
      </ul>
      </div>
    );
  }
}
