import {
  ChevronRight,
  ChevronLeft,
  FirstPage,
  LastPage,
  ArrowUpward,
  FilterList
} from '@material-ui/icons';

export const parentChildData = (row, rows) => {
  return rows.find((a) => a.id === row.parentId);
};

export const options = {
  draggable: false,
  grouping: false,
  actionsColumnIndex: -1,
  padding: 'dense',
  sorting: true,
  filtering: false,
  search: false,
  pageSize: 10,
  paging: false,
  showTitle: false,
  toolbar: false
};

export const icons = {
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  FirstPage: FirstPage,
  LastPage: LastPage,
  DetailPanel: ChevronRight,
  SortArrow: ArrowUpward,
  Filter: FilterList
};
