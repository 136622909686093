import React from 'react';
import Table from 'app/components/mui/Table';

const expiredLocallyColumns = [
    { label: 'ExpiredLocally', name: 'leaseId', valueType: 'string' }
];

const deactivatedColumns = [
    { label: 'Deactivated', name: 'leaseId', valueType: 'string' }
];

export function resultSerializer(result: { users: string[]}): number {
    return result.users.length;
}

export default (row) => {
    return (<tr>
      <td colSpan={1}/>
      <td colSpan={11}>
        <div>
          <Table
            columns={expiredLocallyColumns}
            rows={row.result.expiredLocally.map((v) => ({ leaseId: v}))}
            />
          <Table
            columns={deactivatedColumns}
            rows={row.result.result.map((v) => ({ leaseId: v}))}
            />
        </div>
      </td>
    </tr>);
};