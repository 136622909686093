import React, { Component } from 'react';
import Notification from './Notification';
import { connect } from 'react-redux';

class Notify extends Component {
  render() {
    const { notification } = this.props;
    return (
      notification.desktop.title && (
        <Notification timeout={5000} {...notification.desktop} />
      )
    );
  }
}

export default connect(({ notification }) => ({ notification }))(Notify);
