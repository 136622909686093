import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';

type Props = {
  style?: {};
  icon?: JSX.Element;
  size?: 'small' | 'medium';
  label?: string;
  onClick?: (tag: string) => void;
  picked?: boolean;
};

const Tag = (props: Props): JSX.Element => {
  const { onClick, picked, style, ...rest } = props;
  return (
    <Chip
      variant={'default'}
      {...rest}
      onClick={onClick && (() => onClick(rest.label))}
      style={{ ...style, display: rest.label ? 'inline-flex' : 'block' }}
      color={picked ? 'primary' : 'default'}
    />
  );
};

export default Tag;
