import React from 'react';
import Table from 'app/components/mui/Table';

const columns = [
    { label: 'Landlord', name: 'landlordId', valueType: 'string' },
    { label: 'Leases', name: 'leases', valueType: 'number' }
];

export default (row) => {
    return (<tr>
      <td colSpan={1}/>
      <td colSpan={11}>
        <div>
          <Table
            columns={columns}
            rows={row.result.mails.map((v) => ({ landlordId: v.landlordId, leases: v.leases.length }))}
            />
        </div>
      </td>
    </tr>);
};