import React from 'react';
import { Route } from 'react-router';

import Root from './root';
import ActionQueue from './actionqueue';
import Mailer from './mailer';
import Payment from './payment';
import Cron from './cron';
import Chat from './chat';
import { SubscriptionDashboard } from './payment/subscriptions';

export default (
  <Route path="microservices" component={Root} name="Microservices">
    <Route path="actionqueue" component={ActionQueue} name="ActionQueue" />
    <Route path="mailer" component={Mailer} name="Mailer" />
    <Route path="payment" component={Payment} name="Payment" />
    <Route path="subscriptions" component={SubscriptionDashboard} name="Subscriptions" />
    <Route path="cron" component={Cron} name="Cron" />
    <Route path="chat" component={Chat} name="Chat" />
  </Route>
);
