export const trailFragments = `timestamp
action
remoteAddr: ip
providerId
descriptor
message
status
`;

export const subscriptionFragments = `id
client
status
plan {
  id
}
externalId
provider
providerId
ends: nextPaymentDate
starts: startDate
createdAt
updatedAt
canceledAt
active
meta
trail { ${trailFragments} }
transactions {
  id
  provider
  providerId
  status
  balance
  currency
  amount
  capturedAt
  createdAt
  meta
  card {
    scheme
    bin
    last4
  }

  trail { ${trailFragments} }
}`;

export const GET_SUBSCRIPTIONS = `
  query Subscriptions($ids: [ID!]!) {
    _admin_subscriptions(ids: $ids) {
      ${subscriptionFragments}
    }
  }
`;

export const CANCEL = `
  mutation CancelSubscription($id: ID!, $meta: JSON, $cancelAt: Date, $approved: Boolean) {
    _admin_cancelSubscription(id: $id, meta: $meta, cancelAt: $cancelAt, approved: $approved) {
      ${subscriptionFragments}
    }
  }
`;

export const REACTIVATE = `
mutation ReactivateSubscription($id: ID!, $meta: JSON) {
  _admin_reactivateSubscription(id: $id, meta: $meta) {
    ${subscriptionFragments}
  }
}
`;
export const EXTEND = `
mutation ExtendSubscription($id: ID!, $days: Int!, $meta: JSON) {
  _admin_extendSubscription(id: $id, days: $days, meta: $meta) {
    ${subscriptionFragments}
  }
}
`;
