import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class MyComponent extends Component {
  static defaultProps = {
    disable: false
  };

  static propTypes = {
    disable: PropTypes.bool
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.disable !== this.props.disable;
  }

  render() {
    return this.props.disable ? null : (
      <div
        style={{ margin: '5em auto', fontSize: '24px', textAlign: 'center' }}
      >
        <i className="fa fa-spin fa-2x fa-spinner" />
        <br />
        <br />
        Loading...
      </div>
    );
  }
}
