import React from 'react';
import { Route } from 'react-router';

import RentalPreferenceReport from './rental-preference';
import BoligbeskederReport from './boligbeskeder';
import LeaseUnwantedReport from './lease-unwanted';

export default (
    <>
        <Route path="rentalpreferences" name={'rental-preferences'} component={RentalPreferenceReport} />
        <Route path="boligbeskeder" name={'boligbeskeder'} component={BoligbeskederReport} />
        <Route path="lease-unwanted" name={'lease-unwanted'} component={LeaseUnwantedReport} />
    </>
);
