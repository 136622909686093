import { combineReducers } from 'redux';
import notification from './notification';
import displayError from './displayError';
import auth from './auth';
import scrapers from './scrapers';

import r6 from '../../r6/redux';
import akutbolig from '../../akutbolig/redux';

import boligbesked from './boligbesked';
import boligninja from './boligninja';

const reducer = combineReducers({
  notification,
  displayError,
  auth,
  scrapers,
  r6,
  akutbolig,
  boligbesked,
  boligninja
});

export default reducer;
