import React, { Component } from 'react';
import ModalUpdateUser from './UserModal';

export default class UserTable extends Component {
  state = {
    updateUserModal: null
  };

  showUpdateUserModal = (userId) => {
    this.setState({ updateUserModal: userId });
  };
  closeUpdateUserModal = () => {
    this.setState({ updateUserModal: null });
  };

  render() {
    const { data: filteredRows } = this.props;
    return (
      <div>
        {filteredRows && filteredRows.length > 0 ? (
          <table className="table table-striped table-condensed">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((row) => (
                <tr key={row._id}>
                  <td>
                    <a onClick={() => this.showUpdateUserModal(row._id)}>
                      {row.name}
                    </a>
                  </td>
                  <td>{row.email}</td>
                  <td>{row.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data</p>
        )}

        {this.state.updateUserModal ? (
          <ModalUpdateUser
            onClose={this.closeUpdateUserModal}
            userId={this.state.updateUserModal}
          />
        ) : null}
      </div>
    );
  }
}
