import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

const chatColumns = [
  { label: 'Id', name: 'id', redirectLink: 'https://cp.bbintern.net/akutbolig/scraperChats'  },
  { label: 'User', name: 'userId', valueType: 'string', redirectLink: 'https://cp.bbintern.net/akutbolig/users' },
  { label: 'Lease', name: 'leaseId', valueType: 'string', redirectLink: 'https://cp.bbintern.net/dk/dwellings' },
  { label: 'Slug(Id)', name: 'scraperSlug', valueType: 'custom-row', customValue: (v) => `${v.scraperSlug}(${v.scraperId})` },
  { label: 'Reciever', name: 'recieverEmail', valueType: 'string' },
  { label: 'Message', name: 'text', valueType: 'string' },
  { label: 'Status', name: 'status', valueType: 'string' },
  { label: 'Reason', name: 'triggerReason', valueType: 'string' }
];

export default class ScraperChats extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: {} };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const filter = this.state.filter || {};
    apiClient.post('/api/microservice', { data: { action: 'getScraperChats', data: { filter } } }).then((result) => {
      this.setState({ list: result.data });
    });
  }

  onChange(e, key) {
    console.log(e.target);
    this.setState({ filter: { [key]: e.target.value }});
  }

  render() {
    const { list = [] } = this.state;
    const limitRow = 50;

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
          <input
            onChange={(e) => this.onChange(e, 'userId')}
            defaultValue={''}
            name="name"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by user"
            />
            <input
            onChange={(e) => this.onChange(e, 'leaseId')}
            defaultValue={''}
            name="name"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by lease"
            />
            <input
            onChange={(e) => this.onChange(e, 'scraperSlug')}
            defaultValue={''}
            name="name"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by slug"
            />
            <input
            onChange={(e) => this.onChange(e, 'scraperId')}
            defaultValue={''}
            name="name"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            placeholder="Filter by scraper id"
            />
            <select
            onChange={(e) => this.onChange(e, 'status')}
            defaultValue={''}
            placeholder={'Filter by status'}
            name="status"
            className="form-control"
            style={{ margin: 0, fontWeight: 'normal', width: '100%', marginRight: '10px' }}
            >
            {['', 'PENDING', 'INQUEUE', 'APPROVED', 'SENT', 'REJECTED'].map((val) => (
                <option key={val}>{val}</option>
            ))}
            </select>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Scraper Chat'}
                columns={chatColumns}
                limitRow={limitRow}
                hideable
                rows={list}
              />
          </div>
        </div>
      </div>
    );
  }
}
