function getDataDependency(component = {}, methodName) {
  return component.WrappedComponent
    ? getDataDependency(component.WrappedComponent, methodName)
    : component[methodName];
}

function fetchDataDependencies(components, args, deferred) {
  const methodName = deferred ? 'fetchDataDeferred' : 'fetchData';

  return components.reduce((promises, component) => {
    const fetchData = getDataDependency(component, methodName);
    if (fetchData) {
      promises.push(fetchData(args));
    }
    return promises;
  }, []);
}

export default function handleDataDependencies({
  location,
  store,
  renderProps,
  deferred = false,
  reload = false
}) {
  const { components = [], params = {} } = renderProps;
  if (components.length) {
    const { getState, dispatch } = store;
    return Promise.all(
      fetchDataDependencies(
        components,
        { getState, dispatch, location, params, reload },
        deferred
      )
    );
  }
}
