import apiClient from 'app/utils/api-client';
import React from 'react';
import Loading from 'app/common/loading';
import Table from 'app/components/mui/Table';
import Input from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import qs from 'qs';
import { useHashState } from 'app/hooks/useHashState';
import CitiesInput from './Cities';

type Props = {
  router: { push: (args: any) => void };
  location: { hash: string };
};

const LIMITS = ['10', '25', '50', '100', '200', '500', '1000', '2000', '5000'];
const SORTS = ['score', 'latest', 'oldest'];

// const latestPost = {
//   meta: {
//     link: 'https://www.akutbolig.dk/click/r6/gq91Tq',
//     pageId: '1582505825343059',
//     postId: '1582505825343059_2749446065315690',
//     primary: true,
//     repost: false
//   },
//   name: 'facebook-share',
//   status: true,
//   timestamp: '2021-01-05T08:00:32.494Z'
// };

function serialize(data) {
  if (!data) {
    return [];
  }
  return data.map((d) => {
    const { zip, city, latestPost, ...rest } = d;
    return {
      ...rest,
      address: city && zip ? `${city}, ${zip}` : null,
      addrLink: latestPost?.meta.link ?? null,
      post: latestPost?.meta?.postId ?? null,
      fbLink: latestPost?.meta?.postId
        ? `https://facebook.com/${latestPost?.meta?.postId}`
        : null
    };
  });
}

async function getData({ hours, ...rest }) {
  const query = qs.stringify(rest, {
    encode: false,
    addQueryPrefix: true
  });
  const res = await apiClient.get(`api/biz/suggestion/${hours}${query}`);

  return res.data;
}

type State = {
  hours: string;
  city: string;
  limit: '10' | '25' | '50' | '100' | '200' | '500' | '1000' | '2000' | '5000';
  sort: 'score' | 'latest' | 'oldest';
};
const SuggestTool: React.FC<Props> = (props) => {
  const timerRef = React.useRef();
  const [stillWaiting, setStillWaiting] = React.useState(false);
  const [values, setValues] = useHashState<State>({
    defaultValue: { hours: '24', limit: LIMITS[1], sort: SORTS[0] },
    router: props.router
  });
  const [state, setState] = React.useState({
    status: 'idle',
    suggestions: [],
    error: null
  });

  React.useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (!values.hours) {
      return;
    }
    timerRef.current = setTimeout(() => {
      setStillWaiting(true);
    }, 5000);

    setState({ status: 'pending' });
    (async () => {
      try {
        const data = await getData(values);
        setState({ suggestions: serialize(data), status: 'resolved' });
        if (stillWaiting === true) {
          setStillWaiting(false);
        }
        clearTimeout(timerRef.current);
      } catch (err) {
        setState({ error: err.message, status: 'rejected' });
      }
    })();
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [values.hours, values.limit, values.sort, values.city]);

  const onChangeHandler = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = ev.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div style={{ marginBottom: '.5em', display: 'flex' }}>
        <Input
          name="hours"
          type="number"
          label="Hours"
          variant="outlined"
          value={values.hours}
          onChange={onChangeHandler}
        />
        <span style={{ margin: '0 .5em' }} />
        <CitiesInput onChange={onChangeHandler} value={values.city} />
        <span style={{ margin: '0 .5em' }} />
        <Input
          select
          name="limit"
          label="Limit"
          variant="outlined"
          value={values.limit}
          onChange={onChangeHandler}
        >
          {LIMITS.map((limit) => {
            return (
              <MenuItem key={limit} value={limit}>
                {limit}
              </MenuItem>
            );
          })}
        </Input>
        <span style={{ margin: '0 .5em' }} />
        <Input
          select
          name="sort"
          label="Sort"
          variant="outlined"
          value={values.sort}
          onChange={onChangeHandler}
        >
          {SORTS.map((sort) => {
            return (
              <MenuItem key={sort} value={sort}>
                {sort}
              </MenuItem>
            );
          })}
        </Input>
      </div>
      {state.status === 'rejected' ? (
        <div style={{ color: 'red' }}>OH NO!: {state.error}</div>
      ) : state.status === 'pending' ? (
        <div style={{ textAlign: 'center' }}>
          {stillWaiting && (
            <p style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              We are working hard to get your data
            </p>
          )}
          <Loading />
        </div>
      ) : state.status === 'resolved' ? (
        <Table
          title="Dwelling Suggestions"
          url="/dk/dwellings"
          columns={[
            { label: 'Lejemåls ID', name: 'id', link: 'id' },
            { label: 'R6 link', name: 'post', exactLink: 'fbLink' },
            { label: 'Dwelling score', name: 'score' },
            { label: 'Adresse', name: 'address', exactLink: 'addrLink' },
            { label: 'Rent', name: 'rent' },
            { label: 'Rooms', name: 'rooms' },
            { label: 'Size', name: 'size' }
          ]}
          rows={state.suggestions}
        />
      ) : (
        <div>This message should not be possible 🤔, bad developer</div>
      )}
    </>
  );
};

export default SuggestTool;
