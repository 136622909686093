export const namespace = 'boligninja';

export const GET_USERS_INIT = 'boligninja/GET_USERS_INIT';
export const GET_USERS_SUCCESS = 'boligninja/GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'boligninja/GET_USERS_FAIL';

export const GET_USER_INIT = 'boligninja/GET_USER_INIT';
export const GET_USER_SUCCESS = 'boligninja/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'boligninja/GET_USER_FAIL';

export const GET_USER_SUBSCRIPTIONS_INIT =
  'boligninja/GET_USER_SUBSCRIPTIONS_INIT';
export const GET_USER_SUBSCRIPTIONS_SUCCESS =
  'boligninja/GET_USER_SUBSCRIPTIONS_SUCCESS';
export const GET_USER_SUBSCRIPTIONS_FAIL =
  'boligninja/GET_USER_SUBSCRIPTIONS_FAIL';

export const GET_USER_TRANSACTIONS_INIT =
  'boligninja/GET_USER_TRANSACTIONS_INIT';
export const GET_USER_TRANSACTIONS_SUCCESS =
  'boligninja/GET_USER_TRANSACTIONS_SUCCESS';
export const GET_USER_TRANSACTIONS_FAIL =
  'boligninja/GET_USER_TRANSACTIONS_FAIL';

export const GET_USER_BOLIGAGENTS_INIT = 'boligninja/GET_USER_BOLIGAGENTS_INIT';
export const GET_USER_BOLIGAGENTS_SUCCESS =
  'boligninja/GET_USER_BOLIGAGENTS_SUCCESS';
export const GET_USER_BOLIGAGENTS_FAIL = 'boligninja/GET_USER_BOLIGAGENTS_FAIL';

export const GET_TRANSACTIONS_INIT = 'boligninja/GET_TRANSACTIONS_INIT';
export const GET_TRANSACTIONS_SUCCESS = 'boligninja/GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAIL = 'boligninja/GET_TRANSACTIONS_FAIL';

export const GET_TRANSACTION_INIT = 'boligninja/GET_TRANSACTION_INIT';
export const GET_TRANSACTION_SUCCESS = 'boligninja/GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAIL = 'boligninja/GET_TRANSACTION_FAIL';

export const GET_SUBSCRIPTIONS_INIT = 'boligninja/GET_SUBSCRIPTIONS_INIT';
export const GET_SUBSCRIPTIONS_SUCCESS = 'boligninja/GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAIL = 'boligninja/GET_SUBSCRIPTIONS_FAIL';

export const GET_SUBSCRIPTION_INIT = 'boligninja/GET_SUBSCRIPTION_INIT';
export const GET_SUBSCRIPTION_SUCCESS = 'boligninja/GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAIL = 'boligninja/GET_SUBSCRIPTION_FAIL';

export const GET_USER_FOR_TRANSACTION_INIT =
  'boligninja/GET_USER_FOR_TRANSACTION_INIT';
export const GET_USER_FOR_TRANSACTION_SUCCESS =
  'boligninja/GET_USER_FOR_TRANSACTION_SUCCESS';
export const GET_USER_FOR_TRANSACTION_FAIL =
  'boligninja/GET_USER_FOR_TRANSACTION_FAIL';

export const UPDATE_USER_INIT = 'boligninja/UPDATE_USER_INIT';
export const UPDATE_USER_SUCCESS = 'boligninja/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FALSE = 'boligninja/UPDATE_USER_FALSE';

export const CANCEL_SUBSCRIPTION_INIT = 'boligninja/CANCEL_SUBSCRIPTION_INIT';
export const CANCEL_SUBSCRIPTION_SUCCESS =
  'boligninja/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FALSE = 'boligninja/CANCEL_SUBSCRIPTION_FALSE';

export const BOLIGAGENT_CHANGE_INIT = `${namespace}/BOLIGAGENT_CHANGE_INIT`;
export const BOLIGAGENT_CHANGE_SUCCESS = `${namespace}/BOLIGAGENT_CHANGE_SUCCESS`;
export const BOLIGAGENT_CHANGE_FAIL = `${namespace}/BOLIGAGENT_CHANGE_FAIL`;
export const BOLIGAGENT_PREF_CHANGE_INIT = `${namespace}/BOLIGAGENT_PREF_CHANGE_INIT`;
export const BOLIGAGENT_PREF_CHANGE_SUCCESS = `${namespace}/BOLIGAGENT_PREF_CHANGE_SUCCESS`;
export const BOLIGAGENT_PREF_CHANGE_FAIL = `${namespace}/BOLIGAGENT_PREF_CHANGE_FAIL`;

export const DELETE_USER_INIT = 'boligninja/DELETE_USER_INIT';
export const DELETE_USER_SUCCESS = 'boligninja/DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'boligninja/DELETE_USER_FAIL';

export const GET_UNSUBSCRIPTIONS_INIT = 'boligninja/GET_UNSUBSCRIPTIONS_INIT';
export const GET_UNSUBSCRIPTIONS_SUCCESS =
  'boligninja/GET_UNSUBSCRIPTIONS_SUCCESS';
export const GET_UNSUBSCRIPTIONS_FAIL = 'boligninja/GET_UNSUBSCRIPTIONS_FAIL';
