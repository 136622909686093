import React, { useState, useEffect } from 'react';
import Fields from 'app/components/mui/Fields';
import NoteItem from './NoteItem';
import DatePicker from 'app/components/mui/InputFields/DatePicker';
import noteMutation from './noteMutation.graphql';
import landlordQuery from '../landlord.graphql';
import { deserializeLandlordNote } from '../serializeLandlord';
import { Note as NoteType, Mood, Type } from 'app/components/mui/utils/tsTypes';
import FormWithDisplay from 'app/components/mui/Form/FormWithDisplay';
import { connect } from 'react-redux';
import { getUsers } from '../../../../../redux/modules/auth';

type Props = {
  data: NoteType[];
  title?: string;
  options: { moodTypes: Mood[]; noteTypes: Type[] };
  landlordId: string;
  onArchiveReq?: any;
  onError: (err: string) => void;
  buttonProps: {
    type?: 'button' | 'submit';
    label: string;
    size?: 'small' | 'large' | 'medium';
  };
  getUsers?: any;
};

const Note = (props: Props): JSX.Element => {
  const { landlordId, title, buttonProps, onError, options, data } = props;
  const [notes, setNotes] = useState(data);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setNotes(data);
  }, [data]);

  useEffect(() => {
    props.getUsers();
  }, []);

  function onArchiveReqHandler(noteId: string) {
    const confirm = window.confirm(`archive ${noteId}`);
    if (confirm === true) {
      props
        .onArchiveReq({
          variables: { noteId }
        })
        .then((res) => {
          if (res && res.data && res.data.archiveNote) {
            const filteredNotes = notes.filter((note) => note.id !== noteId);
            setNotes(filteredNotes);
          }
        });
    }
  }

  return (
    <FormWithDisplay
      title={title}
      style={{ maxWidth: '750px' }}
      buttonProps={buttonProps}
      toggleHiddenFields={() => setShow((prev) => !prev)}
      toggleState={show}
      mutationProps={{
        namespace: 'noteInput',
        mutation: noteMutation,
        variables: { landlord: landlordId },
        refetchQueries: [{ query: landlordQuery, variables: { landlordId } }],
        deserializer: deserializeLandlordNote
      }}
      setState={(data) => setNotes([data.createNote, ...notes])}
      onError={onError}
      initialValues={{
        type: '',
        mood: '',
        reference: '',
        nextFollowUp: null,
        comment: '',
        author: '',
        createdAt: null
      }}
      fieldRender={({
        initialValues: { type, mood, reference, author, createdAt, ...rest }
      }) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <Fields
                noPaper
                fieldOptions={{
                  isFormik: true,
                  reference: { fullWidth: true },
                  mood: {
                    fullWidth: true,
                    options: options.moodTypes.map((value) => ({ value }))
                  },
                  type: {
                    fullWidth: true,
                    required: true,
                    options: options.noteTypes.map((value) => ({ value }))
                  }
                }}
                fields={{ type, mood, reference }}
              />
            </div>
            <Fields
              noPaper
              fields={{ ...rest }}
              fieldOptions={{
                isFormik: true,
                nextFollowUp: {
                  component: DatePicker,
                  format: 'dd-MM-yyyy'
                }
              }}
            />
            <div style={{ display: 'flex' }}>
              <Fields
                noPaper
                fieldOptions={{
                  isFormik: true,
                  createdAt: {
                    component: DatePicker,
                    format: 'dd-MM-yyyy'
                  },
                  author: {
                    fullWidth: true,
                    options: props.users.map((user) => ({
                      value: user._id,
                      label: user.name
                    }))
                  }
                }}
                fields={show ? { author, createdAt } : {}}
              />
            </div>
          </>
        );
      }}
    >
      {(notes || []).map((note, idx) => (
        <NoteItem
          onArchiveReq={onArchiveReqHandler}
          key={`${note.reference}_${idx}`}
          note={note}
        />
      ))}
    </FormWithDisplay>
  );
};

export default connect(
  ({ auth } /*, router*/) => ({
    user: auth.user,
    users: auth.users
  }),
  { getUsers }
)(React.memo(Note));
