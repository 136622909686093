import React, { memo } from 'react';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SpeedDial from '@material-ui/lab/SpeedDial';
import Icon from 'app/components/mui/Icon';
import MoreVert from '@material-ui/icons/MoreVert';
import { IconButton, Button } from '@material-ui/core';
import { Refresh, DoubleArrow, Check, Cancel } from '@material-ui/icons';

const SPEED_DIAL_ACTIONS = [
  {
    name: 'Reload',
    action: 'RELOAD',
    icon: <Icon icon={Refresh} />
  },
  {
    name: 'Force execute',
    action: 'FORCE_EXEC',
    icon: <Icon icon={DoubleArrow} />
  },
  {
    name: 'Enable',
    action: 'ENABLE',
    icon: <Icon icon={Check} />
  },
  {
    name: 'Disable',
    action: 'DISABLE',
    icon: <Icon icon={Cancel} />
  }
];

type Props = {
  direction?: 'up' | 'down' | 'left' | 'right';
  onClick: (slug: string, actions: string, fn: () => void) => void;
  slug: string;
};

const TableActions = (props: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      {/* <Backdrop open={open} /> */}
      <ClickAwayListener onClickAway={handleClose}>
        <SpeedDial
          style={{
            position: 'absolute',
            top: '-3px',
            left: '0px'
          }}
          ariaLabel="scraper action"
          FabProps={{
            size: 'small',
            style: {
              margin: '0 auto',
              backgroundColor: 'unset',
              color: 'unset',
              boxShadow: 'unset',
              width: '36px',
              height: '36px'
            },
            component: (fabProps) => {
              return (
                <IconButton {...fabProps} size="small">
                  <Icon icon={MoreVert} size="inherit" />
                </IconButton>
              );
            }
          }}
          direction={props.direction || 'right'}
          onClick={handleOpen}
          open={open}
        >
          {SPEED_DIAL_ACTIONS.map((action) => (
            <SpeedDialAction
              FabProps={{
                size: 'small'
              }}
              style={{ fontSize: '0.5em' }}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement={'bottom'}
              onClick={() => {
                props.onClick(props.slug, action.action, handleClose);
              }}
            />
          ))}
        </SpeedDial>
      </ClickAwayListener>
    </>
  );
};

export default memo(TableActions);
