import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, } from 'react-apollo';

// import { Form, FormInput } from '../../common/form';
import Form from '../common/Form';
import FormInput from '../common/FormInput';
// import FormCellphone from '../common/FormCellphone';
import {
  Grid,
  Row,
  Col,
} from 'react-bootstrap';
import apiClient from 'app/utils/api-client';
import { FIELDS } from './scraperChatTrigger-helper';
import Loading from 'app/common/loading';

const NEW_TRIGGER = {
  id: null,
  trigger: '',
  reason: ''
};

class ScraperChatTrigger extends Component {
  static propTypes = {
    loading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      data: null,
      id: props.params?.id,
      loading: false
    };

    if (props.params?.id) {
      this.state.loading = true;
      this.getChat( props.params?.id);
    }else {
      this.setState({ data: NEW_TRIGGER });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id && nextProps.id !== this.props.id) {
      this.setState({ loading: true });
      this.getChat(nextProps.id);
    }
  }

  getChat = () => {
    if(this.props.params?.id) {
        apiClient.post('/api/microservice', { data: { action: 'getScraperChatTrigger', data: { id: this.props.params.id } } })
            .then((result) => {
                this.setState({ data: result.data });
                this.setState({ loading: false });
            })
            .catch((err) => {console.log(err); this.setState({ loading: false });});

        }
  };

  handleSubmit = (values) => {
    const data = {};
    values.forEach((value, key) => {
      data[key] = value;
    });

    this.setState({ updated: false });
    if(this.props.params?.id) {
        apiClient.post('/api/microservice', { data: { action: 'updateScraperChatTrigger', data: { id: this.props.params.id, input: data } } })
        .then((res) => {
            if(res.data.nModified) {
                this.setState({ updated: true });
            }
            this.getChat();
        })
        .catch((error) => alert(error));
    }else {
        apiClient.post('/api/microservice', { data: { action: 'addScraperChatTrigger', data } })
        .then((res) => {
            if(res.data.nModified) {
                this.setState({ updated: true });
            }
            this.getChat();
        })
        .catch((error) => alert(error));
    }

  };

  render() {
    if(this.state.loading) {
        return <Loading />;
    }
    const inputs = Object.keys(FIELDS).map((key) => {
      const { transform, ...props } = FIELDS[key];
      const value = this.state.data?.[key];

      if(props.type === 'checkbox') {
        return (
          <FormInput
            key={key}
            name={key}
            checked={value}
            {...props}
          />
        );
      }

      return (
        <FormInput
          key={key}
          name={key}
          defaultValue={transform ? transform(value) : value}
          {...props}
        />
      );
    });

    return (
      <div>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <Form onSubmit={this.handleSubmit} submitTxt="Update Trigger" updated={this.state.updated} onChange={() => this.setState({ updated: false })}>
                {inputs}
              </Form>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose()(ScraperChatTrigger);