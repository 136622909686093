import { format, parse, startOfWeek, getDay } from 'date-fns';
import dateFnsLocalizer from './localizer';
import da from 'date-fns/locale/da';

const locales = { da };

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});
