import React from 'react';
import { Route, IndexRoute } from 'react-router';

import User from './user';
import Users from './users';

export default (
  <Route path="boligbesked" name="Boligbesked">
    <Route path="users" name="Users">
      <Route path=":id" component={User} />
      <IndexRoute component={Users} />
    </Route>
    <IndexRoute component={Users} />
  </Route>
);
