import React, { useState, SyntheticEvent, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import styles from './styles.module.scss';

type Props = {
  lat: number;
  lng: number;
  precision: string;
};

function mapZoom(precision: string) {
  switch (precision) {
    case 'STREET_NO':
      return 17;
    case 'STREET':
      return 15;
    case 'ZIP':
      return 13;
    default:
      return 12;
  }
}

const Map = ({ lat, lng, precision }: Props): JSX.Element => {
  const [currentZoom, setZoom] = useState(mapZoom(precision));

  const changeZoom = useCallback((ev: SyntheticEvent<HTMLButtonElement>) => {
    const { value } = ev.currentTarget;
    setZoom(prev => prev + Number(value));
  }, []);

  const backgroundImage = `url(https://map.akut-bolig.dk/staticmap?center=${lat},${lng}&markers=${lat},${lng},lightblue1&zoom=${currentZoom}&maptype=mapnik)`;
  return (
    <Paper style={{ backgroundImage }} className={styles.map}>
      <div className={styles.actions}>
        <button value="+1" onClick={changeZoom}>
          +
        </button>
        <button value="-1" onClick={changeZoom}>
          -
        </button>
      </div>
    </Paper>
  );
};

export default Map;
