/*
- Liste over seneste lejemål, som kvalificeret til posting på en FB gruppe
- For hvert lejemål, visning af: id, timestamp, shortlink, klikbart link til fb gruppe
*/

import React from 'react';
import { Link } from 'react-router';
import { Table } from 'app/common/table';

export default class PostingList extends Table {
  renderRow = (row) => {
    const keys = this.state.keys;

    const rowProps = {
      key: row.id,
      className: row.inactive ? 'danger' : undefined
    };

    return (
      <tr {...rowProps}>
        {keys.map((key) => {
          const cellProps = { key, style: {} };

          if (typeof row[key] === 'number') {
            cellProps.style = { textAlign: 'right' };
          }

          let value = row[key] || '-';
          if (key === 'id' && this.props.link) {
            value = (
              <Link to={`${this.props.link}${row[key]}`}>{row[key]}</Link>
            );
          } else if (typeof row[key] === 'number') {
            value = row[key].toFixed(0);
          } else if (key === 'createdAt') {
            value = new Date(row[key]).toLocaleString();
          }

          return <td {...cellProps}>{value}</td>;
        })}
      </tr>
    );
  };

  render() {
    return this.renderTable();
  }
}

// import React from 'react';
//
// class PostingList extends React.Component {
//   render() {
//     console.log(this.props.data);
//     return <div>{this.props.data.length}</div>;
//   }
// }
//
// export default PostingList;
