import React from 'react';
import GridList from '@material-ui/core/GridList';

import ImgTile from './ImgTile';

type Props = {
  images: { hash: string; status: string }[];
  imagePerRow?: number;
  imgTileProps: {
    onHide: (image: {}) => void;
    onDisplayImage?: (image: {}) => void;
    imgSize?: string | { height: string; width: string };
  };
};

const ImgTiles = ({
  images,
  imgTileProps,
  imagePerRow
}: Props): JSX.Element => {
  return (
    <GridList cellHeight={'auto'} cols={imagePerRow || 5}>
      {images.map((img) => {
        return <ImgTile key={img.hash} {...img} {...imgTileProps} />;
      })}
    </GridList>
  );
};

export default ImgTiles;
