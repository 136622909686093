import React, { Component } from 'react';
import { formatDateStr } from '../user-helper';

export default class LeaseUnwantedRow extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <tr>
        <td>
          <small>{formatDateStr(this.props.item.createdAt)}</small>
        </td>
        <td>{this.props.item.leaseId}</td>
        <td>{this.props.item.from}</td>
      </tr>
    );
  }
}
