import React, { useState } from 'react';
import {
  ExpandLess,
  ExpandMore,
  LocationOn,
  Notifications,
  Image,
  Facebook,
  Sms,
  DevicesOther,
  Business,
  Cancel,
  CheckCircle,
  Repeat,
  Link,
  Info,
  Star,
  Email,
  LocalOffer
} from '@material-ui/icons';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Chip
} from '@material-ui/core';
import Icon from '../../../Icon';
import { format } from 'date-fns';
import { colors } from 'app/components/mui/utils/colors';

type Meta = {
  medium?: string;
  consumer?: string;
  accepted?: boolean;
  rejected?: boolean;
  primary?: boolean;
  repost?: boolean;
  success?: string[];
  failed?: string[];
  criteria?: string;
};

function icons(name: string, size = 'default') {
  switch (name) {
    case 'geocoding':
      return <Icon icon={LocationOn} />;
    case 'notification':
      return <Icon icon={Notifications} />;
    case 'images':
      return <Icon icon={Image} />;
    case 'EMAIL_BOLIGBESKED':
      return <Icon icon={Email} />;
    case 'SMS_BOLIGBESKED':
      return <Icon icon={Sms} />;
    case 'facebook-share':
      return <Icon icon={Facebook} />;

    case 'consumer':
      return <Icon size="" icon={Business} />;
    case 'medium':
      return <Icon icon={DevicesOther} />;
    case 'accepted':
    case 'success':
      return <Icon icon={CheckCircle} />;
    case 'rejected':
    case 'failed':
      return <Icon icon={Cancel} />;

    case 'link':
      return <Icon icon={Link} />;
    case 'pageId':
    case 'postId':
      return <Icon icon={LocalOffer} />;
    case 'primary':
      return <Icon icon={Star} />;
    case 'repost':
      return <Icon size={size} icon={Repeat} />;
    case 'criteria':
      return <Icon icon={Info} />;
    default:
      return null;
  }
}

function primaryTitle(type: string, meta: Meta): JSX.Element | string {
  const styles = {
    // color: '#fff',
    marginLeft: '0.3em',
    fontSize: '0.8em'
  };
  switch (type) {
    case 'EMAIL_BOLIGBESKED':
    case 'SMS_BOLIGBESKED':
      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {type === 'EMAIL_BOLIGBESKED' ? meta.criteria ? meta.criteria + ' email' : 'email' : 'sms'}
          <Chip
            // icon={icons('consumer', 'small')}
            style={{
              ...styles,
              backgroundColor: colors.blue[500],
              color: '#fff'
            }}
            label={'boligbesked'}
            size="small"
          />
          <Chip
            // icon={icons('accepted', 'small')}
            style={{
              ...styles,
              backgroundColor: colors.green[500]
            }}
            label={type === 'EMAIL_BOLIGBESKED' ? meta.success : meta.success.length}
            size="small"
          />
          <Chip
            style={{
              ...styles,
              backgroundColor: colors.red[500]
            }}
            label={type === 'EMAIL_BOLIGBESKED' ? meta.failed :meta.failed.length}
            size="small"
          />
        </span>
      );
    case 'notification':
      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {meta.medium}
          <Chip
            // icon={icons('consumer', 'small')}
            style={{
              ...styles,
              backgroundColor: colors.blue[500],
              color: '#fff'
            }}
            label={meta.consumer}
            size="small"
          />
          <Chip
            // icon={icons('accepted', 'small')}
            style={{
              ...styles
              // backgroundColor: colors.green[500]
            }}
            label={meta.accepted}
            size="small"
          />
          <Chip
            // icon={icons('rejected', 'small')}
            style={{
              ...styles
              // backgroundColor: colors.red[500]
            }}
            label={meta.rejected}
            size="small"
          />
        </span>
      );
    // return `${meta.medium}(${meta.consumer}, ${meta.accepted}/${meta.rejected})`;
    case 'facebook-share':
      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {type}
          <Chip
            icon={icons('primary', 'small')}
            style={{
              ...styles,
              backgroundColor: colors.amber[500],
              color: '#fff'
            }}
            label={`${meta.primary}`}
            size="small"
          />
          <Chip
            icon={icons('repost', 'small')}
            style={{
              ...styles
              // backgroundColor: meta.repost ? colors.green[500] : colors.red[500]
            }}
            label={`${meta.repost}`}
            size="small"
          />
        </span>
      );
    // return `${type}(${meta.primary}, ${meta.repost})`;
    default:
      return type;
  }
}

const isLink = ['link', 'postId'];

const ListItemView = (props: any) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItem
        button={Boolean(props.meta)}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <ListItemIcon>{icons(props.name)}</ListItemIcon>
        <ListItemText
          primary={primaryTitle(props.name, props.meta)}
          secondary={format(
            new Date(props.timestamp),
            'dd/MM/yyyy HH:mm:ss.SSS'
          )}
        />
        {props.meta &&
          (open ? <Icon icon={ExpandLess} /> : <Icon icon={ExpandMore} />)}
      </ListItem>
      {props.meta && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(props.meta).map((key) => {
              return (
                <ListItem key={key} style={{ paddingLeft: '2.5em' }}>
                  <ListItemIcon>{icons(key)}</ListItemIcon>
                  <ListItemText
                    primary={
                      isLink.includes(key) ? (
                        <a
                          target="_black"
                          rel="noopener noreferrer nofollow"
                          href={`${
                            key === 'postId' ? 'http://www.facebook.com/' : ''
                          }${props.meta[key]}`}
                        >
                          {props.meta[key]}
                        </a>
                      ) : (
                        `${props.meta[key]}`
                      )
                    }
                    secondary={key}
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default ListItemView;
