import React from 'react';
import styles from './styles.module.scss';
import { diff, formatters } from 'jsondiffpatch';
import 'jsondiffpatch/dist/formatters-styles/html.css';
import { ReviewableSerializer } from './type';
import Button from 'app/components/mui/Button';
import { colors } from 'app/components/mui/utils/colors';

type Diff = {
  left: ReviewableSerializer;
  right: ReviewableSerializer;
};

type Props = {
  upper: Diff;
  lower: JSON; // GETTING JSON_DIFF_PATCH DELTA FROM BACKEND
  onActionClick: (approved: boolean, values: ReviewableSerializer) => void;
  loading: boolean;
};

const SideOptions: React.FC<Props> = (props) => {
  const upperDiff = diff(props.upper.left, props.upper.right);

  const handleClick = (approved: boolean) => {
    if (
      approved === true &&
      upperDiff &&
      !window.confirm('There is a difference, are sure you want to save?')
    ) {
      return;
    }

    if (
      approved === false &&
      !window.confirm('Are you sure you want to reject this?')
    ) {
      return;
    }
    props.onActionClick(approved, props.upper.right);
  };

  return (
    <div className={styles.hints}>
      <div
        className={styles.diff}
        dangerouslySetInnerHTML={{
          __html: upperDiff && formatters.html.format(upperDiff)
        }}
      />
      <div
        className={styles.diff}
        dangerouslySetInnerHTML={{
          __html: props.lower && formatters.html.format(props.lower)
        }}
      />
      <div className={styles.actions}>
        <Button
          isLoading={props.loading}
          style={{ backgroundColor: colors.red[500] }}
          onClick={() => handleClick(false)}
          label="Reject"
        />
        <Button
          isLoading={props.loading}
          style={{ backgroundColor: colors.green[500] }}
          onClick={() => handleClick(true)}
          label="Approve"
        />
      </div>
    </div>
  );
};

export default SideOptions;
