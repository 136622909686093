import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { createClient } from 'app/utils/ApolloProvider';

export default class R6 extends Component {
  render() {
    const client = createClient('/api/graph/v1', {
      cache: { addTypename: false }
    });
    return (
      <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
    );
  }
}
