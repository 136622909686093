import React from 'react';
import { Panel as BootPanel } from 'react-bootstrap';

type Props = {
  list: [];
  ItemComponent: React.ReactNode;
  header: React.ReactElement;
  collapsible?: boolean;
  expanded?: boolean;
};

const Panel: React.FC<Props> = (props) => {
  const { list, ItemComponent, children, ...panelProps } = props;

  return (
    <BootPanel {...panelProps}>
      {ItemComponent && list.length
        ? list.map((item, idx) => {
            return item ? <ItemComponent key={item.id || idx} item={item} /> : null;
          }).filter(Boolean)
        : null}
      {children}
    </BootPanel>
  );
};

export default Panel;
