import React from 'react';
import { serializeLandlord } from './serializeLandlord';
import { Query } from 'app/components/mui/Query';
import Landlord from './Landlord';
import landlordQuery from './landlord.graphql';
import landlordMutationUpdate from './landlordMutationUpdate.graphql';
import landlordMutationCreate from './landlordMutationCreate.graphql';
import landlordOptions from './landlordOptions.graphql';
import { Mutation } from 'app/components/graphql/Mutation';

type Props = {
  params: { landlordId: string };
  router: { push: (to: string) => void };
};

const NEW_LANDLORD = {
  basic: {
    name: '',
    // createdInGit: '',
    // onBoarders: '',
    // BPSolution: '',
    // BPRefers: '',
    source: '',
    lastContact: '',
    nextFollowUp: '',
    // scraper: {
    //   isScrapeable: '',
    //   active: '',
    //   scraperNote: ''
    // },
    subscription: {
      type: '',
      note: ''
    }
    // parent: '',
    // caseWorker: ''
  },
  leases: [],
  contacts: [{ name: '', title: '', email: '', phone: '' }],
  connections: [{ type: '', value: '' }]
};

export const LandlordIndex = (props: Props): JSX.Element => {
  const landlordId = props.params.landlordId;
  return (
    <Mutation
      mutation={landlordId ? landlordMutationUpdate : landlordMutationCreate}
    >
      {({ mutate, options }) => {
        return (
          <Query
            {...(landlordId
              ? {
                  query: landlordQuery,
                  variables: { landlordId },
                  serializer: { fn: serializeLandlord, namespace: 'landlord' }
                }
              : { query: landlordOptions })}
          >
            {({
              landlord,
              leasingTags,
              moodTypes,
              noteTypes,
              connectionTypes,
              subscriptionTypes
            }) => {
              return (
                <Landlord
                  landlordId={landlordId}
                  mutation={{ mutate, options }}
                  data={landlordId ? landlord : NEW_LANDLORD}
                  {...{
                    connectionTypes,
                    noteTypes,
                    moodTypes,
                    leasingTags,
                    subscriptionTypes
                  }}
                  {...props}
                />
              );
            }}
          </Query>
        );
      }}
    </Mutation>
  );
};
