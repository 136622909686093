import {
  fromDate,
  toDate
} from '../../../../components/mui/utils/serializeHelpers';
import { Landlord, Note } from '../../../../components/mui/utils/tsTypes';

const fromBoolean = (val: true | false | '_null' | ''): true | false | null => {
  if (val === '_null' || val === '') {
    return null;
  }
  return val;
};

export const serializeLandlord = (landlord: Landlord) => {
  const {
    id,
    name,
    // scraper,

    source,
    mood,
    leases,
    lastContact,
    nextFollowUp,
    subscription,
    contacts,
    connections,
    notes
  } = landlord;
  return {
    basic: {
      id,
      name,

      source,
      lastContact: fromDate(lastContact),
      nextFollowUp: fromDate(nextFollowUp, 'dd-MM-yyyy'),
      // scraper: scraper
      //   ? {
      //       isScrapeable: scraper.isScrapeable,
      //       active: scraper.active,
      //       scraperNote: scraper.note
      //     }
      //   : { isScrapeable: '', active: '', scraperNote: '' },
      subscription
    },
    mood,
    leases,
    contacts,
    connections,
    notes
  };
};

export const deserializeLandlordNote = (note: Note) => {
  const { mood, ...rest } = note;
  return {
    mood: mood || null,
    ...rest
  };
};

export const deserializeLandlord = (landlord: Landlord) => {
  const {
    basic: {
      id,
      lastContact,
      nextFollowUp,
      // scraper: { active, isScrapeable, scraperNote: note },
      subscription,
      ...rest
    },
    contacts,
    connections,
    leases
  } = landlord;

  return {
    contacts: (contacts || []).filter(
      (row) => row.name || row.title || row.email || row.phone
    ),
    connections: (connections || []).filter((row) => row.type || row.value),
    leases: leases || [],
    lastContact: toDate(lastContact),
    nextFollowUp: toDate(nextFollowUp, 'dd-MM-yyyy'),
    // scraper:
    //   active !== '' && isScrapeable !== ''
    //     ? { active, isScrapeable, note }
    //     : null,
    subscription:
      subscription && (subscription.type || subscription.note)
        ? subscription
        : null,
    ...rest
  };
};
