import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Loading from '../../common/loading';
import createChart from '../charts';
import { createDetails } from './createDetails';

export default class DetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      report: null,
      options: null,
      slug: null,
      detailType: props.routeParams.detailType,
      detailKey: props.routeParams.detailKey
    };

    this.fetch({ data: {} });
  }

  async fetch({ data }) {
    try {
      this.setState({ loading: true, report: null });
      const { detailType, detailKey } = this.state;

      const report = await apiClient[
        data ? 'post' : 'get'
      ]('/api/reports/details', { data: { ...data, detailKey, detailType } });

      this.setState({
        report,
        loading: false
      });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();

    const { elements } = ev.target;

    const data = Object.keys(elements).reduce((acc, key) => {
      const element = elements[key];
      const { name, value, type } = element;
      if (name) {
        if (type === 'date') {
          if (value) {
            acc[name] = new Date(value);
          }
        } else if (type === 'number') {
          if (value) {
            acc[name] = Number(value);
          }
        } else {
          acc[name] = value;
        }
      }
      return acc;
    }, {});

    this.fetch({ data });
  };

  render()  {
    const { detailType, report } = this.state;

    if (!report) {
      return <Loading />;
    }

    const { options, dataset, displayText, details } = report || {};
    const content = options?.charts?.length ? createChart(report, options, true) : null;

    if (!displayText) {
      return <Loading />;
    }
    const form =
        options && options.args && options.args.length ? (
          <form onSubmit={this.handleSubmit}>
            {options.args.map((arg, idx) => {
              if (arg.type === 'enum') {
                return (<label key={idx} style={{ padding: '4px 8px' }}>
                  {arg.label || arg.name}
                  <br />
                  <select key={arg.name} name={arg.name}>
                    {arg.options.map(({ value, label = value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
                );
              }
              const {
                type = 'text',
                name,
                label,
                defaultValue: defVal,
                ...props
              } = arg;
              const defaultValue =
                typeof defVal === 'function' ? defVal() : defVal;
              return (
                <label key={idx} style={{ padding: '4px 8px' }}>
                  {label || name}
                  <br />
                  <input
                    style={{ minWidth: '100%' }}
                    key={name}
                    name={name}
                    type={type}
                    {...props}
                    defaultValue={defaultValue}
                  />
                </label>
              );
            })}
            <button type="submit">Submit</button>
          </form>
        ) : null;

    return (
      <div>
        {form}
        <hr />
        {createDetails(options?.details, details)}
        {content}
        <hr />
        <pre>{displayText}</pre>
      </div>
    );
  }
};