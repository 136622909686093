export function parseCamelCase(str: string): string {
  return str.replace(
    /[a-zæøå][A-ZÆØÅ]/g,
    (txt) => `${txt.charAt(0)} ${txt.charAt(1)}`
  );
}

export function titleCase(str: string): string {
  return parseCamelCase(str).replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}
