import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadUserPosts as load } from './redux';

import { dateFormatStr } from 'lib/format-date';
import { Table } from 'react-bootstrap';

const namespace = 'r6';
const section = 'userPosts';

class UserPosts extends Component {
  static fetchData({ getState, dispatch }) {
    if (!getState()[namespace][section].size) {
      return dispatch(load());
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rows !== this.props.rows) {
      this.setState({ rows: nextProps.rows });
    }
  }

  render() {
    const { rows } = this.state;

    if (!rows.size) {
      return null;
    }

    return (
      <div>
        <Table striped condensed hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>User</th>
              <th>Permalink</th>
              <th>Content</th>
              <th>Posted at</th>
              {/* <th>Page / Group</th> */}
            </tr>
            {/* <tr>
              <th><input onChange={ this.onFilterChange } name='name' className="form-control" style={{ margin: 0, fontWeight: 'normal', width: '100%' }} placeholder='Filter by name' /></th>
              <th><input onChange={ this.onFilterChange } name='email' className="form-control" style={{ margin: 0, fontWeight: 'normal', width: '100%' }} placeholder='Filter by email' /></th>
              <th><input onChange={ this.onFilterChange } name='cellphone' className="form-control" style={{ margin: 0, fontWeight: 'normal', width: '100%' }} placeholder='Filter by cellphone' /></th>
              <th />
              <th />
            </tr> */}
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.get('_id')}>
                <td>
                  {row.getIn(['content', 'link']) ? (
                    <a
                      href={row.getIn(['content', 'link'])}
                      title={row.getIn(['content', 'name'])}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>{row.get('type')}</b>
                    </a>
                  ) : (
                    row.get('type')
                  )}
                  &nbsp;/&nbsp;
                  {row.get('status_type')}
                </td>
                <td>
                  <a
                    href={`https://www.facebook.com/app_scoped_user_id/${row.getIn(
                      ['user', 'id']
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.getIn(['user', 'name'])}
                  </a>
                </td>
                <td>
                  <a
                    href={row.getIn(['permalink'])}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-fw fa-link" /> Open
                  </a>
                </td>
                <td>
                  {(
                    row.getIn(['content', 'message']) ||
                    row.getIn(['content', 'description']) ||
                    ''
                  ).substr(0, 75)}
                </td>
                <td>{dateFormatStr(row.getIn(['createdAt']))}</td>
                {/* <td>
                  <Link to={`/r6/pages/${row.get('feed')}`}>
                  {row.get('feed')}
                  </Link>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect(({ [namespace]: { [section]: rows } }) => ({ rows }))(
  UserPosts
);
