import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Alert } from 'react-bootstrap';

import ConfirmationButton from '../common/confirmation/button';

import Audits from './common/audits';

import UserInfo from './common/user-info';
import ProviderInfo from './common/provider-info';
import Boligagents from './common/boligagents';
import {
  getUser,
  getUserBoligagents,
  updateUser,
  cancelSubscription,
  deleteUser
} from '../redux/modules/boligninja';
import {
  UPDATE_USER_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS
} from '../redux/modules/boligninja/actions';

import SubPanels from 'app/components/bs/panels/SubPanels';
import SubscriptionPanel from 'app/components/bs/panels/SubscriptionPanel';
import { GET_SUBSCRIPTIONS } from './fragements';
import { fetcher } from 'app/utils/fetcher';
import Loading from '../common/loading';

export function serializeSub(subs) {
  if (!subs) {
    return;
  }

  return subs.map((sub) => {
    return {
      ...sub,
      renew: Boolean(!sub.canceledAt)
    };
  });
}

class User extends Component {
  static fetchData({ dispatch, params }) {
    return Promise.all([
      dispatch(getUser(params.id)),
      dispatch(getUserBoligagents(params.id))
    ]);
  }

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      subscriptions: null,
      loading: false
    };

    if (props.user?._id) {
      this.state.loading = true;
      this.getSub(props.user._id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user?._id && nextProps.user._id !== this.props.user?._id) {
      this.setState({ loading: true });
      this.getSub(nextProps.user._id);
    }
  }

  getSub = (id) => {
    fetcher('/api/boligninja/graph-proxy', {
      query: GET_SUBSCRIPTIONS,
      variables: { ids: [id] }
    })
      .then((data) => {
        this.setState(
          { subscriptions: serializeSub(data?._admin_subscriptions) },
          () => this.setState({ loading: false })
        );
      })
      .catch((err) => console.log(err));
  };

  verifyUser = (id, status) => {
    this.setState({ error: false });
    this.props.updateUser({ verified: status }, id).then((action) => {
      if (action.type !== UPDATE_USER_SUCCESS) {
        this.setState({ error: true });
      }
      return action;
    });
  };

  unsubscribe = (id) => {
    this.setState({ error: false });
    this.props.cancelSubscription(id).then((action) => {
      if (action.type !== CANCEL_SUBSCRIPTION_SUCCESS) {
        this.setState({ error: true });
      }
      return action;
    });
  };

  deleteUser = () => {
    this.props.deleteUser(this.props.user._id);
  };

  render() {
    const { user, userBoligagents } = this.props;

    const { error, subscriptions, loading } = this.state;

    return user ? (
      <Grid fluid>
        <Row>
          {error ? (
            <Alert bsStyle="danger">The action didn't succeed</Alert>
          ) : null}
          <Col md={4}>
            <h4>User Information</h4>
            <UserInfo user={user} verifyUser={this.verifyUser} />
            {!user.deletedAt ? (
              <div>
                <hr />
                <h4>Delete user?</h4>
                <ConfirmationButton
                  label="Delete user"
                  onSubmit={this.deleteUser}
                  text="Please confirm, you want to delete the user"
                  disabled={
                    subscriptions && subscriptions.filter((s) => s.renew).length
                  }
                />
                <br />
                <br />
              </div>
            ) : null}

            {user.facebook ? (
              <>
                <hr />
                <h4>Facebook provider Information</h4>
                <ProviderInfo provider={user.facebook} />
              </>
            ) : null}
          </Col>
          <Col md={4}>
            {loading ? (
              <Loading />
            ) : subscriptions ? (
              <SubPanels
                header="Subscriptions"
                list={subscriptions}
                ItemPanel={SubscriptionPanel}
                itemPanelProps={{
                  updateSubscription: (action, notes, id) => {
                    if (action.renew === false) {
                      this.unsubscribe(id);
                    }
                  }
                }}
              />
            ) : null}

            <h4>Boligagents</h4>
            {userBoligagents ? (
              <Boligagents boligagents={userBoligagents} />
            ) : (
              <p>No boligagents</p>
            )}
          </Col>
          <Col md={4}>
            {user.audits ? (
              <>
                <h4>Audit log</h4>
                <Audits data={user.audits} />
              </>
            ) : null}
          </Col>
        </Row>
      </Grid>
    ) : null;
  }
}

export default connect(
  ({ boligninja: { user, userBoligagents } }, router) => ({
    user,
    userBoligagents,
    userId: router.params.id
  }),
  {
    getUser,
    getUserBoligagents,
    updateUser,
    cancelSubscription,
    deleteUser
  }
)(User);
