import React from 'react';
import { Snackbar } from '@material-ui/core';
import SnackbarContent from './SnackbarContent';

type Props = {
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
};

const Alert = (props: Props): JSX.Element => {
  return (
    <Snackbar
      anchorOrigin={
        props.anchorOrigin || {
          vertical: 'top',
          horizontal: 'right'
        }
      }
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
    >
      <SnackbarContent
        onClose={props.onClose}
        variant={props.type || 'default'}
        message={props.message}
      />
    </Snackbar>
  );
};

export default Alert;
