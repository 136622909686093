import React from 'react';

type Props = {
  onClick?: () => void;
  src: string;
  srcSet?: string;
  alt?: string;
};

const Image = (props: Props): JSX.Element => {
  return <img {...props} />;
};

export default Image;
