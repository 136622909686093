import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import lib from 'lib/format-date';

class Info extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { provider } = this.props;

    return provider ? (
      <Table striped condensed hover bordered>
        <tbody>
          <tr>
            <td>ID</td>
            <td>
              <a
                href={`https://www.facebook.com/app_scoped_user_id/${provider.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {provider.id}
              </a>
            </td>
          </tr>

          <tr>
            <td>Email</td>
            <td>{provider.email}</td>
          </tr>

          <tr>
            <td>First name</td>
            <td>{provider.first_name}</td>
          </tr>

          <tr>
            <td>Middle Name</td>
            <td>{provider.middle_name}</td>
          </tr>

          <tr>
            <td>Last Name</td>
            <td>{provider.last_name}</td>
          </tr>

          <tr>
            <td>Gender</td>
            <td>{provider.gender}</td>
          </tr>

          <tr>
            <td>Verified</td>
            <td>{provider.verified ? 'Verified' : 'Not Verified'}</td>
          </tr>

          <tr>
            <td>timezone</td>
            <td>{provider.timezone}</td>
          </tr>

          <tr>
            <td>Locale</td>
            <td>{provider.locale}</td>
          </tr>

          <tr>
            <td>Updated - Time</td>
            <td>{lib.dateFormatStr(provider.updated_time)}</td>
          </tr>
        </tbody>
      </Table>
    ) : null;
  }
}

export default Info;
