import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';
import SimpleInputField from 'app/components/mui/InputFields/SimpleInputField';
import ActionRow from './actionRow';

const commonColumns = [
  { label: 'Reference', name: 'referenceId', valueType: 'string' },
  { label: 'Action', name: 'action', valueType: 'string' },
  { label: 'Status', name: 'status', valueType: 'string' }
];

const currentQueueColumn = [
  ...commonColumns,
  { label: 'Scheduled', name: 'scheduledTime', valueType: 'date' }
];

const newestActionsColumn = [
  ...commonColumns,
  { label: 'Created', name: 'createdAt', valueType: 'date' },
  { label: 'Scheduled', name: 'scheduledTime', valueType: 'date' }
];

const latestDiscardedColumn = [
  ...commonColumns,
  { label: 'Discarded', name: 'updatedAt', valueType: 'date' }
];

const latestFailedColumn = [
  ...commonColumns,
  { label: 'Failed', name: 'updatedAt', valueType: 'date' }
];

const latestExecutedColumn = [
  ...commonColumns,
  { label: 'Executed', name: 'executedAt', valueType: 'date' }
];

export default class ActionQueue extends Component {
  constructor(props) {
    super(props);

    this.state = { boligbeskeder: true };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh(data = {}) {
    const { boligbeskeder, action } = this.state;
    apiClient.post('/api/microservice', { data: { action: 'ActionQueue', data: { boligbeskeder, action } } }).then((result) => {
      this.setState({ ...result?.data || {} });
    });
  }

  toggleBoligbeskeder() {
    const { boligbeskeder } = this.state;
    this.setState({ boligbeskeder: !boligbeskeder });
    this.refresh();
  }

  render() {
    const { latestExecuted = [], currentQueue = [], newestActions = [], latestDiscarded = [], latestFailed = [] } = this.state;
    const limitRow = 10;

    return (
      <div>
        <div style={{ display: 'flex' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
          {this.toggleBoligbeskeder ? <Button style={{ margin: '6px' }} size={'small'} label={'Toogle BOLIGBESKEDER'} onClick={() => this.toggleBoligbeskeder()}/> : null}
          <SimpleInputField
            onChange={(ev) => {
              const { value } = ev.currentTarget;
              this.setState({ action: value });
            }}
            value={this.state.action}
            placeholder= {'Action'}
            name= {'action'}
            style={{ alignSelf: 'center' }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Currently in Queue'}
                columns={currentQueueColumn}
                limitRow={limitRow}
                hideable
                rows={currentQueue}
                detailOnClick={(row) => <ActionRow id={row.id} status={row.status} refresh={(() => this.refresh())}/>}
              />
          </div>
          <div style={{ margin: '6px' }}>
            <Table
                url={'actionqueue'}
                title={'Newest Actions'}
                columns={newestActionsColumn}
                limitRow={limitRow}
                hideable
                rows={newestActions}
                detailOnClick={(row) => <ActionRow id={row.id} status={row.status} refresh={(() => this.refresh())}/>}
              />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Discarded Actions'}
                columns={latestDiscardedColumn}
                limitRow={limitRow}
                hideable
                rows={latestDiscarded}
                detailOnClick={(row) => <ActionRow id={row.id} status={row.status} refresh={(() => this.refresh())}/>}
              />
          </div>
          <div style={{ margin: '6px' }}>
            <Table
                url={'actionqueue'}
                title={'Latest Executed Actions'}
                columns={latestExecutedColumn}
                limitRow={limitRow}
                hideable
                rows={latestExecuted}
                detailOnClick={(row) => <ActionRow id={row.id} status={row.status} refresh={(() => this.refresh())}/>}
              />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Failed Actions'}
                columns={latestFailedColumn}
                limitRow={limitRow}
                hideable
                rows={latestFailed}
                detailOnClick={(row) => <ActionRow id={row.id} status={row.status} refresh={(() => this.refresh())}/>}
              />
          </div>
        </div>
      </div>
    );
  }
}
