import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { client } from './apollo';
//TODO: could not get it to work, error: couldnt find Apollo(User)
// import { ApolloProvider } from '@apollo/react-hooks';
// import { createClient } from 'app/utils/ApolloProvider';

export default class Akutbolig extends Component {
  render() {
    // const client = createClient('/api/akutbolig/graph');
    return (
      <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
    );
  }
}
