import React from 'react';
import { Route } from 'react-router';
import Root from './root';
import roster from './roster';
import linktool from './linktool';
import mastertool from './mastertool';
import statistics from './statistics';
import SuggestTool from './suggest-tool';
import DisableTargetTool from './disable-target-tool';

export default (
  <Route path="biz" component={Root} name="Biz">
    <Route path="roster" component={roster} name="Roster" />
    <Route path="linktool" component={linktool} name="Linktool" />
    <Route path="mastertool" name="Mastertool">
      {mastertool}
    </Route>
    <Route path="suggesttool" component={SuggestTool} name="Suggesttool" />
    <Route path="statistics" component={statistics} name="Statistics" />
    <Route path="disabletargettool" component={DisableTargetTool} name="Disabletargettool" />
  </Route>
);
