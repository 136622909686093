import React from 'react';

import ApolloProvider from 'app/dk/root';
import PostingList from './list.connected';

class PostingProvider extends React.Component {
  render() {
    return (
      <ApolloProvider>
        <PostingList />
      </ApolloProvider>
    );
  }
}

export default PostingProvider;
