import React from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import styles from './styles.module.scss';
import { withRouter } from 'react-router';

type Props = {
  tabs: { label: string; to: string }[];
  router: { push: (to: string) => {} };
  location: { pathname: string };
};

const Tabs = (props: Props): JSX.Element => {
  const [value, setValue] = React.useState(props.location.pathname);
  const handleChange = (ev: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div style={{ paddingBottom: '16px' }}>
      <MuiTabs
        classes={{ root: styles.root }}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
      >
        {(props.tabs || []).map((tab) => {
          return (
            <MuiTab
              key={tab.to}
              label={tab.label}
              value={tab.to}
              id={tab.to}
              onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                props.router.push(tab.to);
              }}
            />
          );
        })}
      </MuiTabs>
    </div>
  );
};

export default withRouter(Tabs);
