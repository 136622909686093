import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SubPanels from './sub-panels';
import TransactionPanel from './transaction';

export default class TransactionsPanel extends Component {
  static propTypes = {
    collapsible: PropTypes.bool,
    transactions: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { transactions = [], collapsible, subscription } = this.props;

    return (
      <SubPanels
        list={transactions?.sort((a, b) => new Date(a.updatedAt || a.createdAt).getTime() - new Date(b.updatedAt || b.createdAt).getTime())}
        subscription={subscription}
        collapsible={collapsible}
        header="Transactions"
        ItemPanel={TransactionPanel}
      />
    );
  }
}
