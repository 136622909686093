import React from 'react';
import { Route, IndexRoute } from 'react-router';

import User from './user';
import Users from './users';
import Transactions from './transactions';
import Transaction from './transaction';
import Subscriptions from './subscriptions';
import Subscription from './subscription';
import Unsubscriptions from './unsubscriptions';
// import Unsubscription from './unsubscription';

export default (
  <Route path="boligninja" name="Boligninja">
    <Route path="users" name="Users">
      <IndexRoute component={Users} />
      <Route path=":id" component={User} />
    </Route>
    <Route path="transactions" name="Transactions">
      <IndexRoute component={Transactions} />
      <Route path=":id" component={Transaction} />
    </Route>
    <Route path="subscriptions" name="Subscriptions">
      <IndexRoute component={Subscriptions} />
      <Route path=":id" component={Subscription} />
    </Route>
    <Route path="unsubscriptions" name="Unsubscriptions">
      <IndexRoute component={Unsubscriptions} />
      {/* <Route path=":id" component={ Unsubscription } /> */}
    </Route>
    <IndexRoute component={Users} />
  </Route>
);
