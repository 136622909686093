import React, { FC } from 'react';
import InputField from 'app/components/mui/InputFields/SimpleInputField';
import Button from 'app/components/mui/Button';
import Placement from 'app/components/Placement';
import { Paper } from 'app/components/Paper';
import { colors } from 'app/components/mui/utils/colors';
import { Mutation } from 'app/components/graphql/Mutation';
import gql from 'graphql-tag';

type Props = {
  inputs: { [key: string]: any }[];
  initValues: { [key: string]: any };
  onSubmitReq: (values: any) => Promise<any>;
  loading?: boolean;
};

type FormState = {
  agent?: string;
  isScrapeable?: string | boolean;
  maxItems?: number;
  id?: string;
  enabled?: boolean;
};

const ENABLE_SCRAPER = gql`
  mutation($slug: ID!, $enable: Boolean!) {
    changeScraperStatus(slug: $slug, enabled: $enable) {
      scraper(slug: $slug) {
        config {
          enabled
        }
      }
    }
  }
`;

export const ScraperForm: FC<Props> = (props) => {
  const { inputs, initValues } = props;
  const [state, setState] = React.useState<FormState>(initValues);
  const [enable, setEnable] = React.useState(initValues.enabled);
  const handleChange = (
    ev: React.ChangeEvent<{ name: string; value: any }>
  ) => {
    const { name, value } = ev.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const fields = inputs.map((input) => {
    return (
      <div key={input.id} style={{ marginBottom: '1em' }}>
        <InputField
          onChange={handleChange}
          value={state[input.name || input.id] || ''}
          {...input}
        />
      </div>
    );
  });

  const buttonProps = !enable
    ? { label: 'Enable 🚀', style: { backgroundColor: colors.green[500] } }
    : { label: 'Disable', style: { backgroundColor: colors.red[500] } };

  return (
    <Paper title={state.id || 'Scraper'} style={{ marginBottom: '1em' }}>
      <form
        onSubmit={async (ev) => {
          ev.preventDefault();
          const res = await props.onSubmitReq(state);
          if (res && res.addScraper) {
            setState(props.initValues);
          }
        }}
      >
        {fields}
        <Placement style={{ marginTop: '8px' }} place="right">
          {initValues.id !== undefined && (
            <Mutation mutation={ENABLE_SCRAPER}>
              {({ mutate, options }) => (
                <Button
                  isLoading={options.loading}
                  size="small"
                  {...buttonProps}
                  onClick={async () => {
                    const isConfirm = confirm(
                      `sure you want to ${
                        !enable ? 'Enable' : 'Disable'
                      } the scraper`
                    );
                    if (isConfirm) {
                      const { data } = await mutate({
                        variables: { slug: state.id, enable: !enable }
                      });
                      setEnable(
                        data.changeScraperStatus.scraper.config.enabled
                      );
                    }
                  }}
                />
              )}
            </Mutation>
          )}
          <Button
            type="submit"
            size="small"
            isLoading={props.loading}
            label={initValues.id ? 'Update' : 'Create'}
            style={{ marginLeft: '1em' }}
          />
        </Placement>
      </form>
    </Paper>
  );
};
