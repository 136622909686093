import React from 'react';
import daLocale from 'date-fns/locale/da';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';

const DateTimePicker = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
      {props.timePicker ? (
        <KeyboardTimePicker
          margin="dense"
          id={props.id}
          inputVariant="outlined"
          label={props.label}
          fullWidth={props.fullWidth}
          autoOk={props.autoOk}
          value={props.value}
          ampm={false} // true -> 12h/ false -> 24h
          onChange={props.onChange}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
        />
      ) : (
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          fullWidth={props.fullWidth}
          format={props.format}
          margin="dense"
          id={props.id}
          label={props.label}
          autoOk={props.autoOk}
          value={props.value}
          onChange={props.onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePicker;
