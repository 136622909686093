/* eslint react/no-multi-comp: 0 */
import React, { FC } from 'react';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import { Paper } from './Paper';
import { Link } from './Link';

type List = {} & any;

type Props = {
  list: List[];
  title?: string;
  to?: {
    path: string;
    outGoing?: boolean;
  };
  type?: string;
};

const Items = (item: { id: string; name: string }) => {
  return (
    <MuiListItem button dense>
      <MuiListItemText id={item.id} primary={item.name} />
    </MuiListItem>
  );
};

export const List: FC<Props> = (props) => {
  return (
    <Paper title={props.title}>
      <MuiList component="div">
        {(props.list || []).map((item) => {
          return props.to ? (
            <Link
              outGoing={props.to.outGoing}
              href={`${props.to.path}/${item.id}`}
              key={item.id}
            >
              <Items {...item} />
            </Link>
          ) : (
            <Items key={item.id} {...item} />
          );
        })}
      </MuiList>
    </Paper>
  );
};
