import React, { useState, useEffect } from 'react';
import { getData } from '../fetchData';

const SelectField = (props) => {
  const { hostname, id, category } = props.data;
  const [value, setValue] = useState(category);

  useEffect(() => {
    setValue(category);
  }, [id, category]);

  const onChangeHandler = (value) => {
    setValue(value);
    getData({
      query: `
        mutation setCategory($hostname: String!, $category: Category!, $source: ID!) {
          createRule(hostname: $hostname, category: $category, source: $source) {
            category
          }
        }`,
      variables: { hostname, category: value, source: id }
    });
  };

  return (
    <select
      value={value || ''}
      onChange={({ target: { value } }) => onChangeHandler(value)}
    >
      <option value="" />
      {props.options.map(({ label, value, disabled }, idx) => {
        return (
          <option key={`${id}_${value}`} disabled={disabled} value={value}>
            {idx + 1}. {label}
          </option>
        );
      })}
    </select>
  );
};

export default SelectField;
