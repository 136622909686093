import React from 'react';
import { Route, IndexRoute } from 'react-router';

import Root from './root';
import Page from './page';
import Pages from './pages';
import UserPosts from './user-posts';
import PostingDwellings from './posting';
import { GroupPostingIndex as GroupPosting } from './posting/Group';

export default (
  <Route path="r6" name="Facebook" component={Root}>
    <Route path="pages" name="Pages">
      <Route path=":id" component={Page} />
      <IndexRoute component={Pages} />
    </Route>
    <Route path="user_posts" name="User posts" component={UserPosts} />
    <Route path="group_posting" name="Group posting" component={GroupPosting} />
    {/* <Route path=":id" component={ R6App } />
    <IndexRoute component={ R6App } /> */}
    <Route
      path="posting"
      name="Posting dwellings"
      component={PostingDwellings}
    />
  </Route>
);
