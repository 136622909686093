import { ReviewableSerializer, ReviewableInput, Reviewable } from './type';
import gql from 'graphql-tag';
import {
  fromBooleanToString,
  fromStringToBoolean,
  fromNumberToString,
  fromStringToNumber,
  fromDate,
  toDate
} from 'app/components/mui/utils/serializeHelpers';

export const serializer = (data: Reviewable): ReviewableSerializer => {
  const {
    geo,
    contact,
    images,
    timeframe,
    review,
    pets,
    residenceduty,
    floor,
    available,
    size,
    rooms,
    rent,
    expenses,
    expensesAc,
    expensesHeat,
    expensesWater,
    expensesElectricity,
    expensesTv,
    expensesImprovements,
    expensesMisc,
    prepaidRent,
    deposit,
    downpayment,
    furnished,
    ...rest
  } = data;
  return {
    ...rest,
    pets: fromBooleanToString(pets),
    furnished: fromBooleanToString(furnished),
    residenceduty: fromBooleanToString(residenceduty),
    geoType: geo?.type,
    geoCoordinates: geo?.coordinates?.join(','),
    floor: fromNumberToString(floor),
    size: fromNumberToString(size),
    rooms: fromNumberToString(rooms),
    rent: fromNumberToString(rent),
    available: fromDate(available, 'yyyy-MM-dd'),
    expenses: fromNumberToString(expenses),
    expensesAc: fromNumberToString(expensesAc),
    expensesHeat: fromNumberToString(expensesHeat),
    expensesWater: fromNumberToString(expensesWater),
    expensesElectricity: fromNumberToString(expensesElectricity),
    expensesTv: fromNumberToString(expensesTv),
    expensesImprovements: fromNumberToString(expensesImprovements),
    expensesMisc: fromNumberToString(expensesMisc),
    prepaidRent: fromNumberToString(prepaidRent),
    deposit: fromNumberToString(deposit),
    downpayment: fromNumberToString(downpayment),
    timeframeType: timeframe?.type,
    timeframeMin: timeframe?.min,
    timeframeMax: timeframe?.max,
    timeframeText: timeframe?.text,
    images: images.reduce((acc, { url }) => {
      acc += `${url}\n\n`;
      return acc;
    }, ''),
    contactAgentId: fromNumberToString(contact?.agentId),
    contactAgentRef: contact?.agentRef,
    contactName: contact?.name,
    contactPrimaryPhone: contact?.primaryPhone,
    contactSecondaryPhone: contact?.secondaryPhone,
    contactEmail: contact?.email,
    contactUrl: contact?.url,
    reviewReviewer: review?.reviewer,
    reviewApproved: review?.approved,
    reviewDifference: review?.difference
  };
};

export const deserializer = (data: ReviewableSerializer): ReviewableInput => {
  const {
    geoType,
    geoCoordinates,
    contactAgentId,
    contactAgentRef,
    contactName,
    contactPrimaryPhone,
    contactSecondaryPhone,
    contactEmail,
    contactUrl,
    images,
    timeframeType,
    timeframeMin,
    timeframeMax,
    timeframeText,
    floor,
    size,
    rooms,
    rent,
    expenses,
    expensesAc,
    expensesHeat,
    expensesWater,
    expensesElectricity,
    expensesTv,
    expensesImprovements,
    expensesMisc,
    prepaidRent,
    deposit,
    downpayment,
    residenceduty,
    pets,
    furnished,
    createdAt,
    reviewReviewer,
    reviewApproved,
    reviewDifference,
    ...rest
  } = data;
  return {
    ...rest,
    pets: fromStringToBoolean(pets),
    furnished: fromStringToBoolean(furnished),
    residenceduty: fromStringToBoolean(residenceduty),
    floor: fromStringToNumber(floor),
    size: fromStringToNumber(size),
    rooms: fromStringToNumber(rooms),
    rent: fromStringToNumber(rent),
    expenses: fromStringToNumber(expenses),
    expensesAc: fromStringToNumber(expensesAc),
    expensesHeat: fromStringToNumber(expensesHeat),
    expensesWater: fromStringToNumber(expensesWater),
    expensesElectricity: fromStringToNumber(expensesElectricity),
    expensesTv: fromStringToNumber(expensesTv),
    expensesImprovements: fromStringToNumber(expensesImprovements),
    expensesMisc: fromStringToNumber(expensesMisc),
    prepaidRent: fromStringToNumber(prepaidRent),
    deposit: fromStringToNumber(deposit),
    downpayment: fromStringToNumber(downpayment),
    geo: geoCoordinates && {
      type: geoType,
      coordinates: geoCoordinates.split(',').map(Number)
    },
    timeframe: {
      type: timeframeType,
      min: timeframeMin,
      max: timeframeMax,
      text: timeframeText
    },
    contact: {
      agentId: fromStringToNumber(contactAgentId),
      agentRef: contactAgentRef,
      name: contactName,
      primaryPhone: contactPrimaryPhone,
      secondaryPhone: contactSecondaryPhone,
      email: contactEmail,
      url: contactUrl
    },
    images: images
      .split('\n\n')
      .map((url) => ({
        url
      }))
      .filter((image) => Boolean(image.url))
  };
};

export const REVIEWABLES = gql`
  query Reviewables($includeApproved: Boolean, $includeDisapproved: Boolean) {
    reviewables(
      includeApproved: $includeApproved
      includeDisapproved: $includeDisapproved
    ) {
      agentUnique
      headline
      description
      snippet
      address
      street
      streetNo
      floor
      zip
      city
      geo {
        type
        coordinates
      }
      geoPrecision
      size
      rooms
      rent
      expenses
      expensesAc
      expensesHeat
      expensesWater
      expensesElectricity
      expensesTv
      expensesImprovements
      expensesMisc
      prepaidRent
      deposit
      downpayment
      available
      timeframe {
        type
        min
        max
        text
      }
      remarks
      meta
      typeDwelling
      typeTenant
      residenceduty
      pets
      furnished
      contact {
        agentId
        agentRef
        name
        primaryPhone
        secondaryPhone
        email
        url
      }
      createdAt
      images {
        hash
        type
        url
      }
      listingType
      listingFlags
      review {
        reviewer
        approved
        difference
      }
    }
  }
`;

export const APPROVE_REVIEW = gql`
  mutation Review(
    $reviewable: ReviewableInput!
    $approved: Boolean!
    $reviewer: String
  ) {
    review(reviewable: $reviewable, approved: $approved, reviewer: $reviewer)
  }
`;
