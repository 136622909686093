import React from 'react';
import Table from 'app/components/mui/Table';
import Landlords from './landlords.graphql';
import Button from 'app/components/mui/Button';
import { Query } from 'app/components/mui/Query';

type Props = {
  router: {
    push: (to: string) => void;
  };
};

function mapLandlords(landlords: {
  subscription?: { type: string };
  contacts: [{ email: string; name: string; phone: string }];
}): any {
  return landlords.map((landlord) => {
    const { subscription, contacts: _contacts, ...rest } = landlord;
    const contacts = [];

    _contacts?.map((contact) => {
      if (contact.email !== '') {
        contacts.push(contact.email);
      }
      if (contact.name !== '') {
        contacts.push(contact.name);
      }

      if (contact.phone !== '') {
        contacts.push(contact.phone);
      }
    });
    return {
      payment: subscription?.type || null,
      contacts,
      ...rest
    };
  });
}

const MasterTool = (props: Props) => {
  return (
    <Query query={Landlords}>
      {({ landlords }) => {
        return (
          <Table
            persistState
            filterable
            createNew={
              <Button
                style={{ height: '3em', margin: '.6em' }}
                onClick={() => props.router.push('/biz/mastertool/new')}
                label={'Create'}
                size={'small'}
              />
            }
            url="/biz/mastertool"
            title="Master Tool"
            columns={[
              { label: 'Name', name: 'name', filter: true },
              { label: 'Mood', name: 'mood' },
              { label: 'Next Follow up', name: 'nextFollowUp' },
              { label: 'Payment', name: 'payment' }
            ]}
            rows={mapLandlords(landlords)}
          />
        );
      }}
    </Query>
  );
};

export default MasterTool;
