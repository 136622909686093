import React from 'react';
import { Paper } from '@material-ui/core';
import { Title } from '../../Title';
import { Mutation } from '../../graphql/Mutation';
import { Formik, Form } from 'formik';
import { DocumentNode } from 'graphql';
import Placement from '../../Placement';
import Button from 'app/components/mui/Button';
import Fields from '../Fields';

type Props = {
  title?: string;
  mutationProps: {
    mutation: DocumentNode;
    variables: any;
    refetchQueries?: any[];
    deserializer?: any;
    namespace: string;
  };
  initialValues: { [key: string]: any };
  fieldRender?: (formikProps: any) => JSX.Element;
  onError: (err: string) => void;
  setState?: (data: any) => void;
  buttonProps: {
    label: string;
    type?: 'button' | 'submit';
    size?: 'small' | 'large' | 'medium';
  };
  children: JSX.Element | JSX.Element[];
  style?: { [key: string]: string };
  toggleHiddenFields?: () => void;
  toggleState?: boolean;
};

const FormWithDisplay = (props: Props): JSX.Element => {
  const { mutationProps, style = {} } = props;
  return (
    <Paper style={{ ...style, padding: '6px 16px 12px' }}>
      {props.title && <Title variant="h6" component="p" text={props.title} />}
      <Mutation mutation={mutationProps.mutation}>
        {({ mutate, options: { loading } }) => {
          return (
            <Formik
              initialValues={props.initialValues}
              onSubmit={(values, actions) => {
                const variables = {
                  // ...mutationProps.variables,
                  [mutationProps.namespace]: {
                    ...mutationProps.variables,
                    ...(mutationProps.deserializer
                      ? mutationProps.deserializer(values)
                      : values)
                  }
                };
                const refetchQueries = mutationProps.refetchQueries || [];
                mutate({ variables, refetchQueries })
                  .then(({ data }) => {
                    if (data) {
                      props.setState(data);
                    }
                  })
                  .catch((err) => {
                    props.onError(err.message);
                  });
                actions.resetForm();
              }}
            >
              {(formikProps) => {
                return (
                  <Form>
                    {(props.fieldRender && props.fieldRender(formikProps)) || (
                      <Fields
                        noPaper
                        fieldOptions={{
                          isFormik: true
                        }}
                        fields={formikProps.initialValues}
                      />
                    )}
                    <Placement
                      style={{ marginTop: '8px' }}
                      place="space-between"
                    >
                      <Button
                        label={`${props.toggleState ? 'hide' : 'show'} extra`}
                        onClick={props.toggleHiddenFields}
                        type="button"
                        color="default"
                        variant="text"
                        size="small"
                      />
                      <Button
                        disabled={loading}
                        type="submit"
                        size="small"
                        {...props.buttonProps}
                      />
                    </Placement>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
      <hr />
      {props.children}
    </Paper>
  );
};

export default FormWithDisplay;
