export default function isActiveSub(subs) {
  const now = new Date().toISOString();
  if (subs.renew && subs.ends > now) {
    return 'success';
  }
  if (subs.renew && subs.ends <= now) {
    return 'warning';
  }
  if (!subs.renew && subs.ends > now) {
    return 'info';
  }
  return undefined;
}
