import React from 'react';
import geographiesQuery from './geographies.graphql';

import { browserHistory } from 'react-router';

import { GraphQLTableContainer } from '../../../common/table';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';
import { Query } from 'app/components/mui/Query';

const LINK_PATH = '/dk/geographies';
const NEW_LINK_PATH = '/dk/geographies/new';
const LOAD_PATH = '/dk/geographies/load';

class Geographies extends GraphQLTableContainer {
  findById = (ev) => {
    ev.preventDefault();

    const slug = ev.target.slug.value;

    browserHistory.push(`${LINK_PATH}${slug}`);

    return false;
  };

  render() {
    const columns = [
      { label: 'Slug', name: 'slug', link: 'slug' },
      { label: 'Area', name: 'area' },
      { label: 'Base', name: 'base' },
      { label: 'Type', name: 'type' },
      { label: 'Zipcodes', name: 'zipcodes', valueType: 'stringify' },
      { label: 'Coordinates', name: 'coordinates', valueType: 'stringify' },
      { label: 'Radius', name: 'radius', valueType: 'number' },
      { label: 'Listed', name: 'listed', valueType: 'checkmark' },
      { label: 'Suggested', name: 'suggest', valueType: 'checkmark' },
      { label: 'Homepage', name: 'homepage', valueType: 'checkmark' },
      { label: 'RentalPreference', name: 'rentalpreference', valueType: 'checkmark' }
    ];
    return (
      <div>
        <Query
          query={geographiesQuery}
          variables={{ filter: {} }}
        >
          {({ geographies: rows }) => (
            <Table
              url={LINK_PATH}
              title={'Geographies'}
              columns={columns}
              rows={rows}
              load={
                <Button
                  style={{ height: '3em', margin: '.6em' }}
                  onClick={() => this.props.router.push(LOAD_PATH)}
                  label={'Load Geographies'}
                  size={'small'}
                />
              }
              createNew={
                <Button
                  style={{ height: '3em', margin: '.6em' }}
                  onClick={() => this.props.router.push(NEW_LINK_PATH)}
                  label={'Create'}
                  size={'small'}
                />
              }
            />
          )}
        </Query>
      </div>
    );
  }
}

export default Geographies;
