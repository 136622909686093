import React, { useState } from 'react';
import geographiesQuery from './geography.graphql';
import updateGeography from './updateGeography.graphql';
import addGeography from './addGeography.graphql';
import Fields from 'app/components/mui/Fields';
import Form from 'app/components/mui/Form/Form';
import styles from './styles.module.scss';
import Footer from 'app/components/mui/Footer/Footer';
import Button from 'app/components/mui/Button';
import { Query } from 'app/components/mui/Query';
import { Mutation } from 'app/components/graphql/Mutation';
import CustomLayout from 'app/components/mui/Layout/CustomLayout';
import { deserializeGeography, serializeGeography } from './serializeGeography';
import GeographyPageMeta from './pagemeta';
import { Paper } from '@material-ui/core';
import { fetcher } from 'app/utils/fetcher';

type Props = {
  loading: boolean;
  geography: any;
  params: { slug: string };
  updateGeography: any;
};

const NEW_GEOGRAPHY = {
  name: '',
  slug: '',
  base: '',
  zipcodes: [],
  coordinates: [],
  radius: 0,
  type: '',
  active: '',
  listingTypes: {
    RAEKKEHUS: false,
    HUS: false,
    LEJLIGHED: false,
    VAERELSE: false,
    DELEBOLIG: false,
    ROOM1: false,
    ROOM2: false,
    ROOM3: false,
    ROOM4: false,
    ROOM5: false,
    ALMEN: false,
    UNGDOMSBOLIG: false,
    STUDIEBOLIG: false,
    SENIORBOLIG: false,
    AELDREBOLIG: false,
    FAMILIEBOLIG: false
  },
  listed: '',
  suggest: '',
  homepage: '',
  rentalpreference: ''
};

const NEW_PAGEMETA = {
  slug: '',
  type: '',
  active: false,
  metaTitle: '',
  metaDescription: '',
  headline: '',
  description: ''
};

const GEOGRAPHY_PAGEMETAS = `query GeographyPageMetas($slug: String!, $type: String) {
  geographyPageMetas(slug: $slug, type: $type) {
    slug
    type
    active
    metaTitle
    metaDescription
    headline
    description
  }
}`;

const Geography = (props: Props) => {
  const slug = props.params.slug;
  const [touched, setTouched] = useState<boolean>(false);
  const [newGeographyValues, setNewGeographyValues] = useState<{ [key: string]: any }>(
    {}
  );

  const [newListingTypes, setListingTypes] = useState<{ [key: string]: any }>({});
  const [selectedPageMeta, setSelectedPageMeta] = useState<{ [key: string]: any }>(null);

  const valueHandler = (values) => {
    setNewGeographyValues({ ...newGeographyValues, ...values });
    if (!touched) {
      setTouched(true);
    }
  };

  const listingTypeHandler = (values, ev) => {
    const key = ev.target.name;
    const value = ev.target.checked;

    setListingTypes({ ...values.listingTypes, ...(newListingTypes || {}), [key]: value });
    if (!touched) {
      setNewGeographyValues({ ...newGeographyValues, ...values });
      setTouched(true);
    }
  };

  const pageMetaHandler = async (ev) => {
    if(ev.target.value === '-') {
      setSelectedPageMeta(null);
      return;
    }

    const type = ev.target.value;
    const res = slug && type ? await fetcher('/api/graph/v1/dk/', {
      query: GEOGRAPHY_PAGEMETAS,
      variables: { slug, type }
    }) : null;

    if (res?.geographyPageMetas?.[0]) {
      setSelectedPageMeta(res?.geographyPageMetas?.[0]);
    } else {
      setSelectedPageMeta({ ...NEW_PAGEMETA, slug, type });
    }
  };

  return slug ? (
    <Query
      query={geographiesQuery}
      variables={{
        filter: {
          slug: String(slug)
        }
      }}
    >
      {({ geographies }) => {
        const row = serializeGeography(geographies[0]);
        return (
          <Mutation mutation={updateGeography}>
            {({ mutate, options }) => {
              return (
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    const variables = {
                      input: deserializeGeography(newGeographyValues, newListingTypes)
                    };
                    mutate({ variables });
                  }}
                >
                  <CustomLayout
                    cssStyles={{
                      container: styles.container
                    }}
                    innerStyle={{ display: 'contents' }}>
                        <Form initValues={slug ? row : NEW_GEOGRAPHY}>
                      {({ initialValues, values }) => {
                        const { listingTypes, ...rest } = initialValues;
                        return (<div>
                            <div style={{ display: 'flex' }}>
                          <Fields
                              onBlur={() => {
                                valueHandler(values);
                              }}
                              onChange={() => {
                                valueHandler(values);
                              }}
                              fields={rest}
                              fieldOptions={{
                                isFormik: true,
                                slug: { type: 'string', disabled: true },
                                name: { type: 'string' },
                                base: { type: 'string' },
                                zipcodes: { type: 'string' },
                                coordinates: { type: 'string' },
                                radius: { type: 'number' },
                                type: {
                                  options: [
                                    { value: 'ZIPCODE' },
                                    { value: 'RADIUS' },
                                    { value: 'POLYGON' }
                                  ]
                                },
                                active: {
                                  options: true
                                },
                                listed: {
                                  options: true
                                },
                                suggest: {
                                  options: true
                                },
                                homepage: {
                                  options: true
                                },
                                rentalpreference: {
                                  options: true
                                }
                              }}
                              title="Geography"
                            />
                            <Paper style={{ display: 'grid', padding: '6px 16px 12px', marginLeft: '12px' }}>
                              <h3 style={{ width: 'max-content' }}>Listing Types</h3>
                              {Object.keys(listingTypes).map((key) => {
                                return (
                                <div key={key + 'label'} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <label style={{}}>
                                    {key}
                                  </label>
                                  <input
                                    style={{ minWidth: '20%', alignSelf: 'baseline' }}
                                    key={key}
                                    name={key}
                                    type={'checkbox'}
                                    defaultChecked={listingTypes[key]}
                                    onChange={(ev) => listingTypeHandler(values, ev)}
                                  />
                              </div>);
                              })}
                            </Paper>
                          </div>
                          <Paper style={{ display: 'grid', padding: '6px 16px 12px', marginTop: '12px', marginBottom: '70px' }}>
                            <h3 style={{ width: 'max-content' }}>Page Meta</h3>
                            <div key={'pageMeta'} style={{ display: 'flex' }}>
                                <label style={{ marginRight: '50px' }}>
                                  {'Select listing type'}
                                </label>
                                <select style={{ alignSelf: 'baseline' }} name="currentPageMeta" id="currentPageMeta" onChange={pageMetaHandler}>
                                  <option key={'empty'}>{'-'}</option>
                                  {Object.keys(NEW_GEOGRAPHY.listingTypes).map((key) => <option key={key}>{key}</option>)}
                                </select>
                            </div>
                            {selectedPageMeta ? <GeographyPageMeta
                              slug={selectedPageMeta.slug}
                              type={selectedPageMeta.type}
                              metaTitle={selectedPageMeta.metaTitle}
                              metaDescription={selectedPageMeta.metaDescription}
                              headline={selectedPageMeta.headline}
                              description={selectedPageMeta.description}
                              active={selectedPageMeta.active}
                            /> : null}
                          </Paper>
                        </div>);
                      }}
                      </Form>
                  </CustomLayout>
                  <Footer
                    right={() => {
                      return (
                        <Button
                          disabled={!touched || options.loading}
                          isLoading={options.loading}
                          label={'save'}
                          type={'submit'}
                        />
                      );
                    }}
                  />
                </form>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  ) : (
    <Mutation mutation={addGeography}>
      {({ mutate, options }) => {
        return (
          <form
            onSubmit={(ev) => {
              ev.preventDefault();

              const variables = {
                input: deserializeGeography(newGeographyValues, newListingTypes)
              };
              mutate({ variables }).then(({ data }) => {
                if (data) {
                  props.router.push(`/dk/geographies/${data.addGeography.slug}`);
                }
              });
            }}
          >
            <CustomLayout
              cssStyles={{
                container: styles.container
              }}
              innerStyle={{ display: 'contents' }}>
                  <Form initValues={NEW_GEOGRAPHY}>
                {({ initialValues, values }) => {
                  const { listingTypes, ...rest } = initialValues;
                  return (<div>
                      <div style={{ display: 'flex' }}>
                    <Fields
                        onBlur={() => {
                          valueHandler(values);
                        }}
                        onChange={() => {
                          valueHandler(values);
                        }}
                        fields={rest}
                        fieldOptions={{
                          isFormik: true,
                          slug: { type: 'string' },
                          name: { type: 'string' },
                          base: { type: 'string' },
                          zipcodes: { type: 'string' },
                          coordinates: { type: 'string' },
                          radius: { type: 'number' },
                          type: {
                            options: [
                              { value: 'ZIPCODE' },
                              { value: 'RADIUS' },
                              { value: 'POLYGON' }
                            ]
                          },
                          active: {
                            options: true
                          },
                          listed: {
                            options: true
                          },
                          suggest: {
                            options: true
                          },
                          homepage: {
                            options: true
                          },
                          rentalpreference: {
                            options: true
                          }
                        }}
                        title="New Geography"
                      />
                      <Paper style={{ display: 'grid', padding: '6px 16px 12px', marginLeft: '12px' }}>
                        <h3 style={{ width: 'max-content' }}>Listing Types</h3>
                        {Object.keys(listingTypes).map((key) => {
                          return (
                          <div key={key + 'label'} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label style={{}}>
                              {key}
                            </label>
                            <input
                              style={{ minWidth: '20%', alignSelf: 'baseline' }}
                              key={key}
                              name={key}
                              type={'checkbox'}
                              defaultChecked={listingTypes[key]}
                              onChange={(ev) => listingTypeHandler(values, ev)}
                            />
                        </div>);
                        })}
                      </Paper>
                    </div>
                    <Paper style={{ display: 'grid', padding: '6px 16px 12px', marginTop: '12px', marginBottom: '70px' }}>
                      <h3 style={{ width: 'max-content' }}>Page Meta</h3>
                      <div key={'pageMeta'} style={{ display: 'flex' }}>
                          <label style={{ marginRight: '50px' }}>
                            {'Select listing type'}
                          </label>
                          <select style={{ alignSelf: 'baseline' }} name="currentPageMeta" id="currentPageMeta" onChange={pageMetaHandler}>
                            <option key={'empty'}>{'-'}</option>
                            {Object.keys(NEW_GEOGRAPHY.listingTypes).map((key) => <option key={key}>{key}</option>)}
                          </select>
                      </div>
                      {selectedPageMeta ? <GeographyPageMeta
                        slug={selectedPageMeta.slug}
                        type={selectedPageMeta.type}
                        metaTitle={selectedPageMeta.metaTitle}
                        metaDescription={selectedPageMeta.metaDescription}
                        headline={selectedPageMeta.headline}
                        description={selectedPageMeta.description}
                        active={selectedPageMeta.active}
                      /> : null}
                    </Paper>
                  </div>);
                }}
                </Form>
            </CustomLayout>
            <Footer
              right={() => (
                <Button
                  disabled={!touched || options.loading}
                  isLoading={options.loading}
                  label="Create"
                  type="submit"
                />
              )}
            />
          </form>
        );
      }}
    </Mutation>
  );
};

export default Geography;
