import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

const columns = [
  { label: 'Action', name: 'action', valueType: 'string' },
  { label: 'Status', name: 'status', valueType: 'string' },
  { label: 'Scheduled', name: 'scheduledTime', valueType: 'date' },
  { label: 'Executed', name: 'executedAt', valueType: 'date' }
];


export default class ActionQueueList extends Component {
  constructor(props) {
    super(props);

    this.state = { id: props.id };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh(data = {}) {
    const { id } = this.state;
    apiClient.post('/api/microservice', { data: { action: 'ActionQueue', data: { referenceId: `lease/${id}` } } }).then((result) => {
      this.setState({ actions: result?.data || [] });
    });
  }

  render() {
    const { actions } = this.state;
    const limitRow = 10;

    return (
      <div>
        <div style={{ display: 'flex' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Actions in Queue'}
                columns={columns}
                limitRow={limitRow}
                hideable
                rows={actions}
              />
          </div>
        </div>
      </div>
    );
  }
}
