import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Table } from 'react-bootstrap';

import {
  getUser,
  updateUser,
  clearUser,
  deleteUser
} from '../redux/modules/boligbesked';
// import { Form, FormInput } from '../../common/form';
import Form from '../common/Form';
import FormInput from '../common/FormInput';
import FormCellphone from '../common/FormCellphone';
import lib from 'lib/format-date';
import ConfirmationButton from '../common/confirmation/button';

class User extends Component {
  static fetchData({ dispatch, params }) {
    return dispatch(getUser(params.id));
  }

  constructor() {
    super();
    this.state = {};
    this.data = {};
  }

  deleteUser = () => {
    this.props.deleteUser(this.props.user._id);
  };

  handleSubmit = (values) => {
    // Map cellphonestatus & emailStatus to unverified,
    // since that is the required type for false in mongoose as defined by the type enum
    function handleVerifiedField(key) {
      const VERIFIED = 'verified';
      const UNVERIFIED = 'unverified';
      const status = values.get(key);
      if (typeof status !== 'undefined') {
        const value =
          status === true || status === VERIFIED ? VERIFIED : UNVERIFIED;
        values.set(key, value);
      }
    }

    handleVerifiedField('cellphoneStatus');
    handleVerifiedField('emailStatus');

    const data = {};
    values.forEach((value, key) => {
      data[key] = value;
    });

    this.props.updateUser(this.props.user._id, data).catch((err) => {
      console.log(err);
    });
  };

  render() {
    const { user } = this.props;

    return user ? (
      <div>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <h4>User ID: {user._id} </h4>

              <Form
                className="bbUserInput"
                onSubmit={this.handleSubmit}
                submitTxt="Update User"
              >
                <FormInput label="Name" name="name" defaultValue={user.name} />
                <FormInput
                  type="email"
                  label="Email"
                  name="email"
                  defaultValue={user.email}
                />

                <FormCellphone
                  defaultCallingCode={user.callingCode}
                  defaultCellphone={user.cellphone}
                />

                <FormInput
                  type="checkbox"
                  label="Cellphone verified?"
                  name="cellphoneStatus"
                  defaultChecked={user.cellphoneStatus === 'verified'}
                />
                <FormInput
                  type="checkbox"
                  label="Email verified?"
                  name="emailStatus"
                  defaultChecked={user.emailStatus === 'verified'}
                />

                <FormInput
                  type="select"
                  label="Role"
                  name="role"
                  className="form-control"
                  placeholder="user"
                >
                  <option value="guest">Guest</option>
                </FormInput>

                <FormInput
                  type="checkbox"
                  label="Seeking"
                  name="seeking"
                  defaultChecked={user.seeking}
                />
              </Form>

              {!user.deletedAt ? (
                <div>
                  <hr />
                  <h4>Delete user?</h4>
                  <ConfirmationButton
                    label="Delete user"
                    onSubmit={this.deleteUser}
                    text="Please confirm, you want to delete the user"
                  />
                </div>
              ) : null}
            </Col>
            <Col sm={6}>
              <h4>Other User Information</h4>
              <Table striped condensed hover>
                <tbody>
                  <tr>
                    <td>Created</td>
                    <td>{lib.dateFormatStr(user.createdAt)}</td>
                  </tr>
                  {user.deletedAt ? (
                    <tr>
                      <td>Deleted</td>
                      <td>{lib.dateFormatStr(user.deletedAt)}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>Provider</td>
                    <td>{user.provider}</td>
                  </tr>
                  <tr>
                    <td>Market</td>
                    <td>{user.market}</td>
                  </tr>
                </tbody>
              </Table>

              {user.preferences ? (
                <div>
                  <h4>User Preferences</h4>
                  <Table striped condensed hover>
                    <tbody>
                      <tr>
                        <td>Areas</td>
                        <td>
                          {user.preferences.areas.reduce((acc, area) => {
                            acc += area + ', ';
                            return acc;
                          }, '')}{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>{user.preferences.size}</td>
                      </tr>
                      <tr>
                        <td>Rent</td>
                        <td>{user.preferences.rent}</td>
                      </tr>
                      <tr>
                        <td>Rooms</td>
                        <td>{user.preferences.rooms}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : null}
            </Col>
          </Row>
        </Grid>
      </div>
    ) : null;
  }
}

export default connect(({ boligbesked: { user } }) => ({ user }), {
  updateUser,
  clearUser,
  deleteUser
})(User);
