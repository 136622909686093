import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setError } from '../redux/modules/displayError';

class DisplayError extends Component {
  hide = () => {
    const { dispatch } = this.props;
    dispatch(setError([]));
  };

  getLevel(level) {
    const LEVELS = {
      '60': 'Fatal',
      '50': 'Error',
      '40': 'Warning',
      '30': 'Info'
    };
    return LEVELS[level] || 'Error';
  }

  render() {
    const { displayError: errors } = this.props;

    if (!errors.length) {
      return null;
    }

    const scraper =
      errors.length ===
      errors.filter((e) => e.scraper === errors[0].scraper).length
        ? errors[0].scraper
        : null;
    const headline = scraper ? `Errors for ${scraper}` : 'Errors';

    return (
      <Modal show onHide={this.hide} aria-labelledby="contained-modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">{headline}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error, idx) => (
            <div key={idx} style={{ marginBottom: 15 }}>
              {/* { idx ? <hr /> : null } */}
              <table
                className="table table-condensed table-bordered"
                style={{ marginBottom: 0 }}
              >
                <tbody>
                  <tr
                    className={this.getLevel(error.level)
                      .toLowerCase()
                      .replace('error', 'danger')}
                  >
                    <th>{this.getLevel(error.level)}</th>
                    <td>{error.time || ''}</td>
                  </tr>
                  {!scraper ? (
                    <tr>
                      <th>Scraper</th>
                      <td>{error.scraper || ''}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <pre style={{ fontSize: '0.8em', margin: '5px 0 0' }}>
                {error.msg || ''}
              </pre>
              {error.err ? (
                <pre style={{ fontSize: '0.8em', margin: '5px 0 0' }}>
                  {JSON.stringify(error.err, null, 2).replace(/\\n/g, '\n')}
                </pre>
              ) : null}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.hide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(({ displayError }) => ({ displayError }))(DisplayError);
