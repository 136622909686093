import React from 'react';
import { format } from 'date-fns';
import styles from '../../styles.module.scss';
import { Note } from 'app/components/mui/utils/tsTypes';
import Tag from 'app/components/mui/Tags/Tag';
import { colors } from 'app/components/mui/utils/colors';
import {
  SentimentVerySatisfied,
  SentimentVeryDissatisfied,
  SentimentSatisfied,
  Archive,
  SvgIconComponent
} from '@material-ui/icons';
import Icon from 'app/components/mui/Icon';
import { IconButton, Tooltip } from '@material-ui/core';

type Props = {
  note: Note;
  onArchiveReq: (id: string) => void;
};

export function tagProps(mood: string) {
  const props: {
    style: {
      backgroundColor?: string;
      color: string;
      margin: string;
      [key: string]: any;
    };
    icon?: SvgIconComponent;
  } = {
    style: {
      margin: '0 10px 0 0',
      color: '#fff'
    }
  };
  switch (mood) {
    case 'POSITIVE': {
      props.style.backgroundColor = colors.green[500];
      props.icon = SentimentVerySatisfied;
      break;
    }
    case 'NEGATIVE': {
      props.style.backgroundColor = colors.red[500];
      props.icon = SentimentVeryDissatisfied;
      break;
    }
    case 'NEUTRAL': {
      props.style.backgroundColor = colors.amber[500];
      props.icon = SentimentSatisfied;
      break;
    }
    default: {
      props.style.backgroundColor = '';
      props.style.color = '';
      props.icon = null;
      break;
    }
  }
  return props;
}

const NoteItem = React.memo(
  (props: Props): JSX.Element => {
    const { note } = props;
    const { icon, style } = tagProps(note.mood);
    return (
      <>
        <div>
          <div className={styles.noteInfoLine}>
            <p className={styles.createAt}>{`${format(
              new Date(note.createdAt),
              'yyyy-MM-dd HH:mm'
            )} (${note.author})`}</p>
            <p className={styles.type}>
              {note.type}{' '}
              <a href="#">
                {note.reference ? '#' : null}
                {note.reference}
              </a>
            </p>
            {note.mood && (
              <Tag size={'small'} style={style} icon={<Icon icon={icon} />} />
            )}
            <Tooltip title="archive" placement="top">
              <IconButton
                onClick={() => props.onArchiveReq(note.id)}
                aria-label="archive"
                size="small"
              >
                <Icon
                  icon={Archive}
                  fontSize="inherit"
                  style={{ color: '#ccc' }}
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className={styles.comment}>
            <p>{note.comment}</p>
          </div>
        </div>
        <hr />
      </>
    );
  }
);

export default NoteItem;
