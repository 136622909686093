import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Panel, Table } from 'react-bootstrap';

import ConfirmationDialog from 'app/common/confirmation/modal';

import { graphql, compose } from 'react-apollo';
import update from 'immutability-helper';
import mutateUserMeta from '../mutateUserMeta.graphql';

const fields = {
  allow_partner_message: {
    options: () => ['0', '1']
  },
  subscription_plan: {
    options: (meta) => {
      const isSubscriber = meta.filter((m) => m.key === 'subscription').length;
      if (!isSubscriber) {
        return [
          '',
          'bsl_7d_89_0',
          'bsl_7d_79_0',
          'bsl_28d_298_27',
          'bsl_28d_98_0',
          'bsl_28d_298_29',
          'bsl_28d_349_5'
          // 'bsl_30d_299_19',
          // 'bsl_30d_299_29',
          // 'bsl_30d_100_200',
          // '_bn'
        ];
      }
      return null;
    }
  }
};

class UserMetaPanel extends Component {
  static propTypes = {
    user: PropTypes.shape({
      // id: PropTypes.number.isRequired,
      meta: PropTypes.array.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      confirm: null
    };
  }

  handleChange = (ev) => {
    const { name, value } = ev.target;

    const confirm = { name, value };

    this.setState({ confirm });
  };

  update = (_, values) => {
    const { name, value, notes } = values;
    this.props.updateMeta(name, value, notes);
    this.setState({ confirm: null });
  };

  render() {
    const { confirm } = this.state;
    const { user } = this.props;

    const data = user?.meta;

    if (!data) {
      return null;
    }

    if (!data.find((meta) => meta.key === 'subscription_plan')) {
      data.push({ __typename: '', key: 'subscription_plan', value: '' });
    }

    return (
      <Panel header={<h4>User meta and settings</h4>}>
        <Table condensed responsive hover fill>
          <tbody>
            {data.map((meta) => {
              let value = meta.value;
              const field = fields[meta.key];
              if (field) {
                const options = field.options?.(data);
                if (options?.includes(meta.value)) {
                  value = (
                    <select
                      name={meta.key}
                      value={meta.value}
                      onChange={this.handleChange}
                    >
                      {options.map((opt) => (
                        <option key={opt}>{opt}</option>
                      ))}
                    </select>
                  );
                }
              }

              return (
                <tr key={meta.key}>
                  <td style={{ width: '40%' }}>{meta.key}</td>
                  <td>{value}</td>
                </tr>
              );
            })}
            {user.pendingEmail ? <tr key={'pendingEmail'}>
                  <td style={{ width: '40%' }}>{'Pending Email'}</td>
                  <td>{user.pendingEmail}</td>
                </tr> : null}

            {user.pendingCellphone ? <tr key={'pendingCellphone'}>
                  <td style={{ width: '40%' }}>{'Pending Cellphone'}</td>
                  <td>{user.pendingCellphone}</td>
                </tr> : null}
          </tbody>
        </Table>

        {confirm && (
          <ConfirmationDialog
            show
            requireNote
            onSubmit={this.update}
            onClose={() => this.setState({ confirm: null })}
            title={'Update user meta'}
            // text={'Please confirm, you want to update the user meta'}
            label={'Update'}
          >
            <div className="form-group">
              <label>{confirm.name}</label>
              <input
                name="value"
                value={confirm.value}
                readOnly
                className="form-control"
              />
              <input name="name" value={confirm.name} type="hidden" />
            </div>
          </ConfirmationDialog>
        )}
      </Panel>
    );
  }
}

export default compose(
  graphql(mutateUserMeta, {
    props: ({ ownProps, mutate }) => ({
      ...ownProps,
      updateMeta: (key, value, notes) =>
        mutate({
          variables: { user: ownProps.user.id, key, value, notes },
          updateQueries: {
            User: (prev, args) => {
              const { mutationResult } = args;
              const next = mutationResult.data.updateUserMeta;

              return update(prev, { user: { meta: { $set: next.meta } } });
            }
          }
        })
    })
  })
)(UserMetaPanel);
