import React, { Component } from 'react';
import { Link } from 'react-router';

export default class UsersRow extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.user !== nextProps.user;
  }

  toggleSeeking = () => {
    if (typeof this.props.updateUser === 'function') {
      const { user } = this.props;
      this.props.updateUser(user.get('_id'), { seeking: !user.get('seeking') });
    }
  };

  render() {
    const { user } = this.props;
    const style = user.get('deletedAt') ? { opacity: 0.35 } : null;
    return (
      <tr style={style}>
        <td>
          <Link to={`/boligbesked/users/${user.get('_id')}`}>{`...${user
            .get('_id')
            .substr(-12)}`}</Link>
        </td>
        <td>{user.get('name')}</td>
        <td>
          <Link to={`/boligbesked/users/${user.get('_id')}`}>
            {user.get('email')}
          </Link>
        </td>
        <td>{user.get('cellphone')}</td>
        <td>{user.get('market')}</td>
        <td>
          <i
            className={
              user.get('seeking') ? 'fa fa-fw fa-check' : 'fa fa-fw fa-times'
            }
            style={{ color: user.get('seeking') ? 'green' : 'red' }}
            onClick={this.toggleSeeking}
          />
        </td>
      </tr>
    );
  }
}
