import React from 'react';
import Tag from './Tag';
import styles from './styles.module.scss';

type Props = {
  labels: string[];
  values: string[];
  size?: 'small' | 'medium';
  onClick: (tag?: string) => void;
};

const Tags = (props: Props): JSX.Element => {
  return (
    <div className={styles.tags}>
      {props.labels.map(
        (option): JSX.Element => {
          return (
            <Tag
              key={option}
              style={{ marginRight: '.3em' }}
              size={props.size}
              onClick={props.onClick}
              picked={props.values.includes(option)}
              label={option}
            />
          );
        }
      )}
    </div>
  );
};

export default Tags;
