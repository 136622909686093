import React from 'react';
import MuiPaper from '@material-ui/core/Paper';
import { Title } from './Title';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

export const Paper = (props: Props): JSX.Element => {
  return (
    <MuiPaper
      style={{ ...(props.style || {}), padding: !props.dense && '6px 12px' }}
    >
      {props.title && <Title addPadding text={props.title} />}
      {props.children}
    </MuiPaper>
  );
};
