import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../redux/modules/auth';

import Login from 'app/core/login';

const internalLinks = [
  {
    title: 'Staging',
    links: [
      { label: 'Akutbolig', href: 'https://ab.stage.bbintern.net/' },
      {
        label: 'Udlejer dashboard',
        href: 'https://udlejning.stage.bbintern.net/'
      }
    ]
  },
  {
    title: 'Dev tools',
    links: [
      { label: 'Kibana', href: 'https://kibana.bbintern.net/' },
      { label: 'GitLab', href: 'https://git.bbintern.net/' }
    ]
  }
];

class Dashboard extends Component {
  logout = () => this.props.logout();

  render() {
    const { user } = this.props;

    return (
      <div className={'container'}>
        {!user.token ? (
          <div style={{ margin: 'auto', maxWidth: 400 }}>
            <Login />
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div>
              <p>You are currently logged in as {user.name}.</p>
              <button className="btn btn-danger" onClick={this.logout}>
                <i className="fa fa-sign-out" /> Sign Out
              </button>
            </div>
            <ul>
              {internalLinks.map((internalLink) => {
                return (
                  <>
                    <h4>{internalLink.title}</h4>
                    {internalLink.links.map((link) => {
                      return (
                        <li>
                          <a href={link.href}>{link.label}</a>
                        </li>
                      );
                    })}
                  </>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default connect(({ auth: { user } }) => ({ user }), { logout })(
  Dashboard
);
