import React from 'react';
import { Button, Fab } from '@material-ui/core';

type Props = {
  isLoading?: boolean;
  style?: { [key: string]: any };
  disabled?: boolean;
  onClick?: () => void;
  label?: string | JSX.Element;
  color?: 'primary' | 'secondary' | 'default' | 'inherit';
  type?: 'submit' | 'button' | 'reset';
  size?: 'small' | 'medium' | 'large';
  fab?: boolean;
  variant?: 'contained' | 'text';
};

const MuiButton: React.FC<Props> = ({
  children,
  fab,
  label,
  isLoading,
  ...props
}) => {
  return !fab ? (
    <Button
      type={'button'}
      variant={'contained'}
      color={'primary'}
      size={'large'}
      {...props}
    >
      {isLoading ? <i className="fa fa-spin fa-2x fa-spinner" /> : label}
    </Button>
  ) : (
    <Fab size={'small'} color="primary" {...props}>
      {children}
    </Fab>
  );
};

export default MuiButton;
