import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SubPanels from './SubPanels';
import TransactionPanel from './TransactionPanel';

export default class TransactionsPanel extends Component {
  static propTypes = {
    collapsible: PropTypes.bool,
    transactions: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { transactions, collapsible } = this.props;

    return (
      <SubPanels
        list={transactions}
        collapsible={collapsible}
        header="Transactions"
        ItemPanel={TransactionPanel}
      />
    );
  }
}
