import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onPageHide } from '../redux/modules/notification';

class OnPageNotify extends Component {
  constructor(props) {
    super(props);

    if (props.dismissAfter) {
      this.setTimer(props.dismissAfter);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.dismissAfter &&
      this.props.__notificationCounter !== nextProps.__notificationCounter
    ) {
      this.setTimer(nextProps.dismissAfter);
    }
  }

  componentWillUnmount() {
    if (this.dismissTimer) {
      clearTimeout(this.dismissTimer);
    }
  }

  setTimer = (dismissAfter) => {
    this.dismissTimer = setTimeout(this.handleAlertDismiss, dismissAfter);
  };

  handleAlertDismiss = () => {
    this.props.onPageHide();
  };

  render() {
    const props = this.props;

    const classStyle = props.big
      ? 'notificationBig'
      : props.small
      ? 'notificationSmall'
      : 'notification';

    return props.show ? (
      <Alert
        className={classStyle}
        bsStyle={props.bsStyle}
        onDismiss={this.handleAlertDismiss}
      >
        {props.headline ? (
          <h4 style={{ whiteSpace: 'pre' }}>{props.headline}</h4>
        ) : (
          ''
        )}
        {props.description ? (
          <span style={{ whiteSpace: 'pre-line' }}>{props.description}</span>
        ) : (
          ''
        )}
      </Alert>
    ) : null;
  }
}

export default connect(({ notification: { onPage } }) => ({ ...onPage }), {
  onPageHide
})(OnPageNotify);
