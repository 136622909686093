import { fromJS } from 'immutable';

import { LOGOUT } from '../redux/modules/auth/actions';

const NAMESPACE = 'r6';
const ENDPOINT = `/api/${NAMESPACE}`;

const LOAD_PAGES = `${NAMESPACE}/LOAD_PAGES`;
const LOAD_PAGES_SUCCESS = `${NAMESPACE}/LOAD_PAGES_SUCCESS`;
const LOAD_PAGES_FAILED = `${NAMESPACE}/LOAD_PAGES_FAILED`;
const LOAD_PAGE = `${NAMESPACE}/LOAD_PAGE`;
const LOAD_PAGE_SUCCESS = `${NAMESPACE}/LOAD_PAGE_SUCCESS`;
const LOAD_PAGE_FAILED = `${NAMESPACE}/LOAD_PAGE_FAILED`;

const LOAD_USER_POSTS = `${NAMESPACE}/LOAD_USER_POSTS`;
const LOAD_USER_POSTS_SUCCESS = `${NAMESPACE}/LOAD_USER_POSTS_SUCCESS`;
const LOAD_USER_POSTS_FAILED = `${NAMESPACE}/LOAD_USER_POSTS_FAILED`;

const initialState = {
  pages: fromJS([]),
  page: null,
  userPosts: fromJS([])
};

export default function r6(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_PAGES_SUCCESS: {
      return { ...state, pages: fromJS(payload) };
    }
    case LOAD_PAGE_SUCCESS: {
      return { ...state, page: fromJS(payload) };
    }
    case LOAD_USER_POSTS_SUCCESS: {
      return { ...state, userPosts: fromJS(payload) };
    }
    case LOGOUT: {
      return initialState;
    }
  }

  return state;
}

export function loadPages() {
  return {
    types: [LOAD_PAGES, LOAD_PAGES_SUCCESS, LOAD_PAGES_FAILED],
    promise: (client) =>
      client.get(`${ENDPOINT}/pages`).then(({ status, results }) => {
        if (status !== 'OK') {
          throw new Error('LOAD_PAGES_FAILED');
        }
        return results;
      })
  };
}
export function loadPage(id) {
  return {
    types: [LOAD_PAGE, LOAD_PAGE_SUCCESS, LOAD_PAGE_FAILED],
    promise: (client) =>
      client.get(`${ENDPOINT}/pages/${id}`).then(({ status, result }) => {
        if (status !== 'OK') {
          throw new Error('LOAD_PAGE_FAILED');
        }
        return result;
      })
  };
}

export function loadUserPosts() {
  return {
    types: [LOAD_USER_POSTS, LOAD_USER_POSTS_SUCCESS, LOAD_USER_POSTS_FAILED],
    promise: (client) =>
      client.get(`${ENDPOINT}/user_posts`).then(({ status, results }) => {
        if (status !== 'OK') {
          throw new Error('LOAD_USER_POSTS_FAILED');
        }
        return results;
      })
  };
}

// export const RESET_CLICKS = 'r6/_RESET_CLICKS';
//
// const LOAD = 'r6/CLICKS/LOAD';
// const LOAD_SUCCESS = 'r6/CLICKS/LOAD_SUCCESS';
// const LOAD_FAIL = 'r6/CLICKS/LOAD_FAIL';
// const SINGLE = 'r6/CLICKS/SINGLE';
// const SINGLE_SUCCESS ='r6/CLICKS/SINGLE_SUCCESS';
// const SINGLE_FAIL = 'r6/CLICKS/SINGLE_FAIL';
//
// const POSTS_LOAD = 'r6/POSTS/LOAD';
// const POSTS_LOAD_SUCCESS = 'r6/POSTS/LOAD_SUCCESS';
// const POSTS_LOAD_FAIL = 'r6/POSTS/LOAD_FAIL';
//
// const CRON = 'r6/CRON';
// const CRON_SUCCESS = 'r6/CRON_SUCCESS';
// const CRON_FAIL = 'r6/CRON_FAIL';
//
// const ENDPOINT = '/api/r6';
//
// const initialState = {
//   aggregated: null,
//   single: null,
//   posts: []
// };
//
// function trailingAverage(graphData, idx, amount) {
//   const index = idx - amount;
//   const start = index >= 0 ? index : 0;
//   const limit = index < 0 ? (amount + index) : amount;
//
//   const data = graphData.slice(start, start + limit);
//
//   return data.reduce((sum, item) => (sum + item.click), 0) / limit;
// }
//
// export default function r6Clicks(state = initialState, action){
//   const { type, payload } = action;
//
//   if (type === LOAD_SUCCESS) {
//     return {
//       ...state,
//       aggregated: payload.map((item, idx) => ([
//         new Date(item.date),
//         item.click,
//         trailingAverage(payload, idx, 7),
//         trailingAverage(payload, idx, 28)
//       ]))
//     };
//   }
//   if (type === SINGLE_SUCCESS) {
//     return {
//       ...state,
//       single: payload.map((item) => ([
//         new Date(item.date),
//         item.click
//       ]))
//     };
//   }
//
//   if (action.type === POSTS_LOAD_SUCCESS) {
//     return {
//       ...state,
//       posts: (action.payload || []).map(post => {
//         return {
//           id: post._id,
//           url: post.url,
//           ...post.meta,
//           ...post.post
//         };
//       })
//     };
//   }
//   if (action.type === RESET_CLICKS) {
//     return state.map(element => element.slice(0, 4));
//   }
//   if (action.type === LOGOUT) {
//     return initialState;
//   }
//
//   return state;
// }
//
// export function postsIsLoaded(globalState) {
//   return globalState.r6.posts.length;
// }
//
// export function getR6TableData() {
//   return {
//     types: [POSTS_LOAD, POSTS_LOAD_SUCCESS, POSTS_LOAD_FAIL],
//     promise: client => client.get(`${ENDPOINT}/shortlink`).then(data => data.result)
//   };
// }
//
// export function getAll() {
//   return {
//     types: [ LOAD, LOAD_SUCCESS, LOAD_FAIL ],
//     promise: client => client.get(`${ENDPOINT}/click`).then(data => data.result)
//   };
// }
//
// export function getById({ id }) {
//   return {
//     types: [ SINGLE, SINGLE_SUCCESS, SINGLE_FAIL ],
//     promise: client => client.get(`${ ENDPOINT }/click/${ id }`).then(data => data.result)
//   };
// }
//
// export function generateStats(date = '') {
//   return {
//     types: [ CRON, CRON_SUCCESS, CRON_FAIL ],
//     promise: client => client.get(`${ ENDPOINT }/cron/init?date=${date || '2016-01-01'}`)
//   };
// }
//
// export function resetClicks() {
//   return {
//     type: RESET_CLICKS
//   };
// }
