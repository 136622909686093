import React from 'react';
import Reviewer from './Reviewer';
import {
  ReviewableSerializer,
  User,
  Reviewable,
  ReviewableInput,
  Variables
} from './type';
import {
  serializer,
  REVIEWABLES,
  APPROVE_REVIEW,
  deserializer
} from './helpers';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loading from 'app/common/loading';
import Alert from 'app/components/mui/Snackbar';
import styles from './styles.module.scss';

type Props = {
  user: User;
};

const goodJobTexts = [
  'Good job, you deserve a cup of coffee 🥳☕️',
  'Holy Moly, already done? 🤩 Go get a coffee! 💃☕️',
  'The end of the list already? Coffee time 🤤☕️'
];
const randomNumber = Math.floor(Math.random() * goodJobTexts.length);

const ReviewerConnected: React.FC<Props> = (props) => {
  const { data, loading, error } = useQuery<
    { reviewables: Reviewable[] },
    { includeApproved?: boolean; includeDisapproved?: boolean }
  >(REVIEWABLES);

  const [mutate, { loading: mutateLoading }] = useMutation<
    { review: boolean },
    { reviewable: ReviewableInput; approved: boolean; reviewer?: string }
  >(APPROVE_REVIEW);

  const [alert, setAlert] = React.useState(null);

  const approveHandler = async (
    approved: boolean,
    values: ReviewableSerializer
  ): Promise<boolean> => {
    const variables: Variables = {
      reviewable: deserializer(values),
      approved,
      reviewer: props.user.name
    };
    const res = await mutate({ variables });
    if (res.data?.review === true) {
      setAlert(`Dwelling is ${approved ? 'approved' : 'rejected'}`);
      return true;
    }
    alert(res.errors ? JSON.stringify(res.errors) : 'Der skete en fejl');
    return false;
  };

  const reviewables: ReviewableSerializer[] = (
    data?.reviewables || []
  ).map((reviewable) => serializer(reviewable));

  if (error) {
    return <pre>ERROR {JSON.stringify(error, null, 2)}</pre>;
  }
  if (loading) {
    return <Loading />;
  }
  if (reviewables.length <= 0) {
    return <p className={styles.noData}>{goodJobTexts[randomNumber]}</p>;
  }

  return (
    <div>
      <Alert
        message={alert}
        open={Boolean(alert)}
        onClose={() => setAlert(null)}
        autoHideDuration={4000}
        type={'success'}
      />
      <Reviewer
        loading={mutateLoading}
        reviewables={reviewables}
        onSubmit={approveHandler}
      />
    </div>
  );
};

export default connect(({ auth }) => ({
  user: auth.user
}))(ReviewerConnected);
