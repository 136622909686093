import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ButtonInput } from 'react-bootstrap';
import classNames from 'classnames';

class Form extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    className: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    submitTxt: PropTypes.string,
    updated: PropTypes.bool,
    noButton: PropTypes.bool,
    alignLeft: PropTypes.bool
  };

  constructor() {
    super();
    this.data = new Map();
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.onSubmit(this.data);
  };

  handleChange = (ev) => {
    const target = ev.target;
    const type = target.getAttribute('type');
    const key = target.getAttribute('name');
    const value = type === 'checkbox' ? target.checked : target.value;
    this.data.set(key, value);
    if(this.props.onChange) {
      this.props.onChange(key, value);
    }
  };

  render() {
    const validFormComponents = ['FormInput', 'FormCellphone'];
    const children = React.Children.map(this.props.children, (child) => {
      if (validFormComponents.indexOf(child.type.displayName) != -1) {
        return React.cloneElement(child, { onChange: this.handleChange });
      } else {
        return child;
      }
    });

    const { submitTxt, updated, noButton, alignLeft, ...props } = this.props;
    const formClass = alignLeft ? '' : classNames('form-horizontal', this.props.className);

    return (
      <form {...props} className={formClass} onSubmit={this.handleSubmit}>
        {children}

        {noButton ? null : <div className="row form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <button type="submit" className="btn btn-primary">
              {submitTxt || 'Save'}
            </button>
            {updated ?  <p>Updated!</p>: null}
          </div>
        </div>}
      </form>
    );
  }
}

export default Form;
