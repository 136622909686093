import { StringBoolean, DwellingType, TenantType } from 'app/reviewer/type';

export const booleanOptions = [
  { value: StringBoolean._TRUE, label: 'Yes' },
  { value: StringBoolean._FALSE, label: 'No' },
  { value: StringBoolean._NULL, label: 'Unknown' }
];

export const dwellingTypeOptions = [
  { value: DwellingType.ROOM, label: 'Room' },
  { value: DwellingType.EXCHANGE, label: 'Exchange' },
  { value: DwellingType.APARTMENT, label: 'Apartment' },
  { value: DwellingType.SHARED, label: 'Shared' },
  { value: DwellingType.TERRACED, label: 'Terraced' },
  { value: DwellingType.VILLA, label: 'Villa' }
];
export const tenantTypeOptions = [
  { value: TenantType.ELDER, label: 'Elder' },
  { value: TenantType.FAMILY, label: 'Family' },
  { value: TenantType.PUBLIC, label: 'Public' },
  { value: TenantType.SENIOR, label: 'Senior' },
  { value: TenantType.STUDENT, label: 'Student' },
  { value: TenantType.YOUTH, label: 'Youth' }
];
