import React, { Component } from 'react';
import api from './ApiMethod';
import OptionsPanel from './OptionsPanel';
import Comments from './Inputs/Comments';
import DateTimePicker from './Inputs/DateTimePicker';
import { format } from 'date-fns';
import { parseShift, withInLunch, capitalize } from './helpers';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import SimpleInputField from 'app/components/mui/InputFields/SimpleInputField';

function validTime(date) {
  return !isNaN(date.getTime());
}

function timeChange(prev, next) {
  return (
    !validTime(prev) ||
    !validTime(next) ||
    prev.toISOString() !== next.toISOString()
  );
}

enum WorkStation {
  LANDLORD = 'LANDLORD',
  TENANT = 'TENANT',
  OTHER = 'OTHER'
}

type Shift = {
  _id: string;
  startTime: Date;
  endTime: Date;
  date: Date;
  start: string;
  end: string;
  workStation: WorkStation;
  lunchBreak: boolean;
  lunchScheme: boolean;
  comment: string;
  user: Props['user'];
};
type Props = {
  shift: Shift;
  shifts: Shift[];
  user: string;
  _id: string;
  admin: boolean;
  onCreated: () => void;
  onUpdated: () => void;
  getUserName: (user: Props['user']) => string;
  showSnackbar: (arg: { message: string; type: 'error' | 'success' }) => void;
};

type State = {} & Shift;

class ShiftForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { date, startTime, endTime, ...rest } = parseShift(props);

    this.state = { ...rest, date, startTime, endTime };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prev = this.props.shift && this.props.shift._id;
    const next = nextProps.shift && nextProps.shift._id;
    if (prev !== next || (!next && this.props.user !== nextProps.user)) {
      this.setState({ ...parseShift(nextProps) });
    }
  }

  onChange(partialState) {
    if (partialState.startTime || partialState.endTime) {
      const startTime = partialState.startTime || this.state.startTime;
      const endTime = partialState.endTime || this.state.endTime;

      if (
        timeChange(this.state.startTime, startTime) ||
        timeChange(this.state.endTime, endTime)
      ) {
        const lunchBreak =
          validTime(startTime) &&
          validTime(endTime) &&
          withInLunch(startTime, endTime);
        return this.setState({
          ...partialState,
          lunchBreak,
          lunchScheme: lunchBreak
        });
      }
    }
    this.setState(state => ({ ...state, ...partialState }));
  }

  getCurrentShift = (): Shift => {
    const { date, startTime, endTime } = this.state;
    const start = new Date(date);
    start.setHours(...format(startTime, 'HH:mm:ss:SSS').split(':'));

    const end = new Date(date);
    end.setHours(...format(endTime, 'HH:mm:ss:SSS').split(':'));

    const shift = {
      ...this.state,
      start: start.toISOString(),
      end: end.toISOString()
    };
    return shift;
  };

  createShift = () => {
    const shift = this.getCurrentShift();
    const identicalShift = this.props.shifts.find(
      prevShift =>
        prevShift.date === shift.date &&
        prevShift.start === shift.start &&
        prevShift.end === shift.end &&
        prevShift.user === shift.user
    );
    if (!identicalShift) {
      api('/shifts', { body: shift })
        .then(() => {
          this.props.onCreated();
          this.props.showSnackbar({
            message: 'Din vagt er oprettet. Afventer godkendelse',
            type: 'success'
          });
        })
        .catch(err => {
          console.log(err);
          this.props.showSnackbar({
            message: 'Falid to create',
            type: 'error'
          });
        });
    } else {
      this.props.showSnackbar({
        message: 'Identical shift',
        type: 'error'
      });
    }
  };

  updateShift = body => {
    if (body && body.status === 'ACCEPTED') {
      const currentShift = this.getCurrentShift();
      body = {
        ...currentShift,
        ...body
      };
    }
    api(`/shifts/${this.state._id}`, { body })
      .then(() => {
        this.props.onUpdated();
        this.props.showSnackbar({
          message: 'Vagt opdateret',
          type: 'success'
        });
      })
      .catch(err => {
        this.props.showSnackbar({
          message: 'Failed to update',
          type: 'error'
        });
        console.log(err);
      });
  };

  updateStatus = status => {
    return this.updateShift({ _id: this.state._id, status });
  };

  deleteShift = () => {
    api(`/shifts/${this.state._id}`, { method: 'DELETE' })
      .then(() => {
        this.props.onUpdated();
        this.props.showSnackbar({ message: 'Vagt slettet', type: 'success' });
      })
      .catch(err => {
        console.log(err);
        this.props.showSnackbar({ message: 'Failed to delete', type: 'error' });
      });
  };

  onChangeHandler = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    this.onChange({ [name]: value });
  };

  render() {
    const shift = this.state || {};
    return (
      <>
        <p className="lead">
          Medarbejder: {this.props.getUserName(shift.user)}
        </p>
        {[
          {
            onChange: date => this.onChange({ date }),
            value: shift.date,
            format: 'dd-MM-yyyy',
            label: 'Dato',
            timePicker: false,
            id: 'date-picker'
          },
          {
            onChange: startTime => this.onChange({ startTime }),
            value: shift.startTime,
            format: 'HH:ss',
            label: 'Start tid',
            timePicker: true,
            id: 'time-picker-start'
          },
          {
            onChange: endTime => this.onChange({ endTime }),
            value: shift.endTime,
            format: 'HH:ss',
            label: 'Slut tid',
            timePicker: true,
            id: 'time-picker-end'
          }
        ].map(input => (
          <DateTimePicker key={input.id} {...input} fullWidth autoOk />
        ))}

        {this.props.admin && this.props.editMode ? (
          <SimpleInputField
            label="Station"
            name="workStation"
            style={{ marginTop: '8px' }}
            value={shift.workStation || ''}
            options={[
              { label: 'Landlord', value: WorkStation.LANDLORD },
              { label: 'Tenant', value: WorkStation.TENANT },
              { label: 'Other', value: WorkStation.OTHER }
            ]}
            onChange={this.onChangeHandler}
          />
        ) : (
          <p className="lead">
            Work station: {capitalize(shift.workStation) || '-'}
          </p>
        )}

        {[
          {
            id: 'lunchBreak',
            label: 'Frokostpause',
            checked: shift.lunchBreak,
            onChange: ev => this.setState({ lunchBreak: ev.target.checked })
          },
          {
            id: 'lunchScheme',
            label: 'Frokostordning',
            checked: shift.lunchScheme,
            onChange: ev => this.setState({ lunchScheme: ev.target.checked })
          }
        ].map(input => {
          return (
            <FormControlLabel
              key={input.id}
              control={
                <Checkbox
                  checked={input.checked === true}
                  onChange={input.onChange}
                />
              }
              label={input.label}
              labelPlacement="end"
            />
          );
        })}

        <Comments
          label="Kommentar"
          onChange={({ target: { value } }) =>
            this.setState({ comment: value })
          }
          rows={4}
          fullWidth
          value={shift.comment}
        />
        {this.props.editMode && (
          <OptionsPanel
            accountUser={this.props.user}
            shift={this.props.shift}
            updateShift={this.updateShift}
            currentShift={this.getCurrentShift}
            create={this.createShift}
            update={this.updateShift}
            updateStatus={this.updateStatus}
            remove={this.deleteShift}
            admin={this.props.admin}
            id={this.state._id}
          />
        )}
      </>
    );
  }
}

export default ShiftForm;
