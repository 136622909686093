import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Panel extends Component {
  static propTypes = {
    collapsible: PropTypes.bool,
    headline: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsible
    };
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { collapsed } = this.state;
    const { bsStyle, children, collapsible, header } = this.props;

    const headerLinkProps = {
      'aria-expanded': collapsed ? 'false' : 'true',
      'aria-selected': collapsed ? 'false' : 'true'
    };

    return (
      <div className={`panel panel-${bsStyle || 'default'}`}>
        {header ? (
          <div className="panel-heading">
            <h4 className="panel-title" role="presentation">
              {collapsible ? (
                <a
                  {...headerLinkProps}
                  onClick={this.toggle}
                  style={{ cursor: 'pointer' }}
                >
                  {header}
                </a>
              ) : (
                header
              )}
            </h4>
          </div>
        ) : null}
        <div
          className={
            collapsible
              ? `panel-collapse collapse ${collapsed ? '' : 'in'}`
              : ''
          }
          aria-hidden={collapsed ? 'true' : 'false'}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Panel;
