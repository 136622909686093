import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Panel, Button } from 'react-bootstrap';
import lib from 'lib/format-date';
import { Link } from 'react-router';

export default class Subscription extends Component {
  static propTypes = {
    sub: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { sub, _raw = {}, unsubscribe } = this.props;

    return (
      <div>
        <Table striped condensed responsive hover fill bordered>
          <tbody>
            {sub.renew ? (
              <tr>
                <td>Unsubscribe</td>
                <td>
                  <Button
                    bsStyle="danger"
                    bsSize="sm"
                    onClick={() => unsubscribe(sub._id)}
                    style={{ marginBottom: 10 }}
                  >
                    Unsubscribe
                  </Button>
                </td>
              </tr>
            ) : null}

            <tr>
              <td>ID</td>
              <td>
                <Link to={`/boligninja/subscriptions/${sub._id}`}>
                  {sub._id}
                </Link>
              </td>
            </tr>

            {sub._userid ? (
              <tr>
                <td>User</td>
                <td>
                  <Link to={`/boligninja/users/${sub._userid}`}>
                    {sub._userid}
                  </Link>
                </td>
              </tr>
            ) : null}

            <tr>
              <td>Status</td>
              <td>{_raw.status}</td>
            </tr>

            <tr>
              <td>Created</td>
              <td>{lib.dateFormatStr(sub.createdAt || sub.created)}</td>
            </tr>

            <tr>
              <td>Updated</td>
              <td>{lib.dateFormatStr(sub.updatedAt)}</td>
            </tr>

            <tr>
              <td>Offer</td>
              <td>{sub.offer}</td>
            </tr>

            <tr>
              <td>Starts</td>
              <td>{lib.dateFormatStr(sub.starts)}</td>
            </tr>

            {sub.canceledAt ? (
              <tr>
                <td>Canceled</td>
                <td>{lib.dateFormatStr(sub.canceledAt)}</td>
              </tr>
            ) : null}

            {_raw.canceled_at ? (
              <tr>
                <td>Canceled</td>
                <td>{lib.dateUnixStr(_raw.canceled_at)}</td>
              </tr>
            ) : null}

            <tr>
              <td>Ends</td>
              <td>{lib.dateFormatStr(sub.ends)}</td>
            </tr>

            <tr>
              <td>Renew</td>
              <td>{lib.boolFormat(sub.renew)}</td>
            </tr>

            <tr>
              <td>Terms accepted</td>
              <td>{lib.boolFormat(sub.terms)}</td>
            </tr>

            <tr>
              <td>Provider ID</td>
              <td>{sub.providerid}</td>
            </tr>

            <tr>
              <td>IP / Remote address</td>
              <td>{sub.remoteAddr}</td>
            </tr>
          </tbody>
        </Table>

        <div className="panel-body">
          <div>
            <Panel collapsible header="Customer Information">
              {sub.customer ? (
                <Table striped condensed hover bordered>
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>{sub.customer.email}</td>
                    </tr>

                    <tr>
                      <td>Name</td>
                      <td>{sub.customer.name}</td>
                    </tr>

                    <tr>
                      <td>Phone</td>
                      <td>{sub.customer.phone}</td>
                    </tr>

                    <tr>
                      <td>Country</td>
                      <td>{sub.customer.country}</td>
                    </tr>

                    <tr>
                      <td>City</td>
                      <td>{sub.customer.city}</td>
                    </tr>

                    <tr>
                      <td>Zipcode</td>
                      <td>{sub.customer.zipcode}</td>
                    </tr>

                    <tr>
                      <td>Address</td>
                      <td>{sub.customer.address}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                'No customer information'
              )}
            </Panel>
          </div>

          <div>
            <Panel collapsible header="Payment Information">
              {_raw.payment ? (
                <Table striped condensed hover bordered>
                  <tbody>
                    <tr>
                      <td>Payment ID</td>
                      <td>{_raw.payment.id}</td>
                    </tr>
                    <tr>
                      <td>Card holder</td>
                      <td>{_raw.payment.card_holder}</td>
                    </tr>
                    <tr>
                      <td>Reccuring</td>
                      <td>{lib.boolFormat(_raw.payment.is_recurring)}</td>
                    </tr>
                    <tr>
                      <td>Created at</td>
                      <td>{lib.dateUnixStr(_raw.payment.created_at)}</td>
                    </tr>
                    <tr>
                      <td>Updated at</td>
                      <td>{lib.dateUnixStr(_raw.payment.updated_at)}</td>
                    </tr>

                    <tr>
                      <td>Type</td>
                      <td>{_raw.payment.type}</td>
                    </tr>
                    <tr>
                      <td>Card type</td>
                      <td>{_raw.payment.card_type}</td>
                    </tr>
                    <tr>
                      <td>Last 4 numbers of credit card</td>
                      <td>{_raw.payment.last4}</td>
                    </tr>
                    <tr>
                      <td>Expire month</td>
                      <td>{_raw.payment.expire_month}</td>
                    </tr>
                    <tr>
                      <td>Expire year</td>
                      <td>{_raw.payment.expire_year}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{_raw.payment.country}</td>
                    </tr>

                    <tr>
                      <td>App ID</td>
                      <td>{_raw.payment.app_id}</td>
                    </tr>
                    <tr>
                      <td>Client</td>
                      <td>{_raw.payment.client}</td>
                    </tr>
                    <tr>
                      <td>Payment usable for preauthorization</td>
                      <td>
                        {lib.boolFormat(
                          _raw.payment.is_usable_for_preauthorization
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                'No payment information'
              )}
            </Panel>
          </div>

          <div>
            <Panel collapsible header="Client Information">
              {_raw.client ? (
                <div>
                  <Table striped condensed hover bordered>
                    <tbody>
                      <tr>
                        <td>Email</td>
                        <td>{_raw.client.email}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{_raw.client.description}</td>
                      </tr>
                      <tr>
                        <td>Subscription</td>
                        <td>{JSON.stringify(_raw.client.subscription)}</td>
                      </tr>
                      <tr>
                        <td>Created at</td>
                        <td>{lib.dateUnixStr(_raw.client.created_at)}</td>
                      </tr>
                      <tr>
                        <td>Updated at</td>
                        <td>{lib.dateUnixStr(_raw.client.updated_at)}</td>
                      </tr>
                      <tr>
                        <td>Client App ID</td>
                        <td>{_raw.client.app_id}</td>
                      </tr>
                      <tr>
                        <td>Client ID</td>
                        <td>{_raw.client.id}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <h5>Client Payments</h5>
                  {_raw.client.payment ? (
                    <Table striped condensed hover bordered>
                      <tbody>
                        {_raw.client.payment.map((payment, i) => {
                          return (
                            <tr key={payment}>
                              <td>{`Payment #${i + 1}`}</td>
                              <td>{payment}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    'No payments found'
                  )}
                </div>
              ) : (
                'No client information'
              )}
            </Panel>
          </div>

          <div>
            <Panel collapsible header="Offer Information">
              {_raw.offer ? (
                <div>
                  <Table striped condensed hover bordered>
                    <tbody>
                      <tr>
                        <td>Offer App ID</td>
                        <td>{_raw.offer.app_id}</td>
                      </tr>
                      <tr>
                        <td>Updated</td>
                        <td>{lib.dateUnixStr(_raw.offer.updated_at)}</td>
                      </tr>
                      <tr>
                        <td>Created</td>
                        <td>{lib.dateUnixStr(_raw.offer.created_at)}</td>
                      </tr>
                      <tr>
                        <td>Period Days</td>
                        <td>{_raw.offer.trial_period_days}</td>
                      </tr>
                      <tr>
                        <td>Interval</td>
                        <td>{_raw.offer.interval}</td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>{_raw.offer.currency}</td>
                      </tr>
                      <tr>
                        <td>Amount</td>
                        <td>{_raw.offer.amount}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{_raw.offer.name}</td>
                      </tr>
                      <tr>
                        <td>Offer ID</td>
                        <td>{_raw.offer.id}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <h5>Subscription count</h5>
                  <Table striped condensed hover bordered>
                    <tbody>
                      <tr>
                        <td>Active</td>
                        <td>{_raw.offer.subscription_count.inactive}</td>
                      </tr>
                      <tr>
                        <td>Inactive</td>
                        <td>{_raw.offer.subscription_count.active}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                'No offer information'
              )}
            </Panel>
          </div>

          <div>
            <Panel collapsible header="Rest of Raw Information">
              {_raw ? (
                <Table striped condensed hover bordered>
                  <tbody>
                    <tr>
                      <td>Mandate Reference</td>
                      <td>{_raw.mandate_reference}</td>
                    </tr>
                    <tr>
                      <td>Deleted</td>
                      <td>{lib.boolFormat(_raw.deleted)}</td>
                    </tr>
                    <tr>
                      <td>Canceled</td>
                      <td>{lib.boolFormat(_raw.canceled)}</td>
                    </tr>
                    <tr>
                      <td>App ID</td>
                      <td>{_raw.app_id}</td>
                    </tr>
                    <tr>
                      <td>Canceled at</td>
                      <td>{lib.dateUnixStr(_raw.canceled_at)}</td>
                    </tr>
                    <tr>
                      <td>Updated at</td>
                      <td>{lib.dateUnixStr(_raw.updated_at)}</td>
                    </tr>
                    <tr>
                      <td>Created at</td>
                      <td>{lib.dateUnixStr(_raw.created_at)}</td>
                    </tr>
                    <tr>
                      <td>Next capture at</td>
                      <td>{lib.dateUnixStr(_raw.next_capture_at)}</td>
                    </tr>
                    <tr>
                      <td>End of period</td>
                      <td>{_raw.end_of_period}</td>
                    </tr>
                    <tr>
                      <td>Period of validity</td>
                      <td>{_raw.period_of_validity}</td>
                    </tr>
                    <tr>
                      <td>Trial Start</td>
                      <td>{lib.dateUnixStr(_raw.trial_start)}</td>
                    </tr>
                    <tr>
                      <td>Trial end</td>
                      <td>{lib.dateUnixStr(_raw.trial_end)}</td>
                    </tr>
                    <tr>
                      <td>Interval</td>
                      <td>{_raw.interval}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{_raw.name}</td>
                    </tr>
                    <tr>
                      <td>Currency</td>
                      <td>{_raw.currency}</td>
                    </tr>
                    <tr>
                      <td>Temporary amount</td>
                      <td>{_raw.temp_amount}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>{_raw.amount}</td>
                    </tr>
                    <tr>
                      <td>Livemode</td>
                      <td>{lib.boolFormat(_raw.livemode)}</td>
                    </tr>
                    <tr>
                      <td>raw Id</td>
                      <td>{_raw.id}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                'No Raw information'
              )}
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}
