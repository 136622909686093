import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import FormInput from '../FormInput';

export default class ConfirmationModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    requireNote: PropTypes.bool,
    show: PropTypes.bool,
    text: PropTypes.string
  };

  constructor(props) {
    super(props);

    this._form = null;

    this.state = {
      confirmed: !props.text,
      values: {},
      show: props.show || false
    };
  }

  getInputValue(element) {
    const { value, type, checked } = element;

    if (type === 'checkbox') {
      return checked;
    }
    return value;
  }

  onChange = (ev) => {
    const { values } = this.state;
    const { name } = ev.target;
    const value = this.getInputValue(ev.target);

    values[name] = value;

    this.setState({ values });
  };

  confirm = (ev) => {
    this.setState({
      confirmed: ev.target.checked
    });
  };

  submit = (ev) => {
    ev.preventDefault();
    const { confirmed, values } = this.state;
    if (confirmed === true) {
      const elements = this._form.elements;
      const len = elements.length;
      for (let i = 0; i < len; i++) {
        const element = this._form.elements[i];
        if (element.name) {
          values[element.name] = this.getInputValue(element);
        }
      }

      this.props.onSubmit(confirmed, values);
    }
  };

  close = () => {
    this.setState({
      show: false
    });
    this.props.onClose();
  };

  render() {
    const {
      title,
      text,
      label,
      bsStyle = 'danger',
      bsSize = 'sm',
      requireNote,
      children
    } = this.props;
    return (
      <Modal show={this.state.show} onHide={this.close}>
        <form
          ref={(node) => (this._form = node)}
          onSubmit={this.submit}
          onChange={this.onChange}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {children}
            {requireNote ? (
              <div className="form-group">
                <label>Reason / internal notes</label>
                <textarea
                  className="form-control"
                  name="notes"
                  rows="3"
                  required
                  minLength="10"
                />
              </div>
            ) : null}
            {text ? (
              <FormInput
                name="confirmation"
                inline
                type="checkbox"
                label={text}
                onChange={this.confirm}
              />
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-default btn-sm"
              onClick={this.close}
            >
              Close
            </button>
            <button
              type="submit"
              className={`btn btn-${bsStyle} btn-${bsSize}`}
            >
              {label}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
