/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Panel, Table } from 'react-bootstrap';

const excludeKeysForMainView = ['auto', 'manuel', 'accountId'];

const excludeKeysForOtherView = ['id', 'autoSuggest', 'active'].concat(
  excludeKeysForMainView
);

function prefixValue(key, data) {
  const unknown = '-';
  if (key === 'rent') {
    return data ? `< ${data} DKK` : unknown;
  }
  if (key === 'rooms') {
    return data ? `> ${data} rooms` : unknown;
  }
  if (key === 'size') {
    return data ? `> ${data} m2` : unknown;
  }
  return data || unknown;
}

function prettifyText(text: string) {
  switch (text) {
    case 'autoSuggest':
      return 'Bolig butler';
    case 'dwellingTypes':
      return 'dwelling types';
    case 'rentalPeriod':
      return 'rental period';
    default:
      return text;
  }
}

const RentalPreferenceTableContent = ({ col, data }) => {
  return (
    <tbody>
      {col?.map((item) => {
        if (!data[item]) {
          return null;
        }
        if (['locations', 'dwellingTypes', 'requirements'].includes(item)) {
          return (
            <tr key={item}>
              <th>{prettifyText(item)}</th>
              <td>{data[item]?.join(', ') || '-'}</td>
            </tr>
          );
        }
        if (['autoSuggest', 'active'].includes(item)) {
          return (
            <tr key={item}>
              <th>{prettifyText(item)}</th>
              <td>
                {data[item] ? (
                  <>
                    <i
                      className="fa fa-check-circle"
                      style={{ color: 'darkGreen' }}
                    />{' '}
                    Active
                  </>
                ) : (
                  <>
                    <i
                      className="fa fa-times-circle"
                      style={{ color: 'rgb(220, 0, 0)' }}
                    />{' '}
                    Inactive
                  </>
                )}
              </td>
            </tr>
          );
        }
        return (
          <tr key={item}>
            <th>{prettifyText(item)}</th>
            <td>{prefixValue(item, data[item])}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

const RentalPreference = (props) => {
  const { rentalPreference } = props.user || {};
  const keys = rentalPreference && Object.keys(rentalPreference);
  if (!keys || keys?.length === 0) {
    return null;
  }

  return (
    <Panel expanded collapsible header={<h4>Rental Preference</h4>}>
      <Table striped condensed responsive hover fill>
        <RentalPreferenceTableContent
          col={keys.filter((key) => !excludeKeysForMainView.includes(key))}
          data={rentalPreference}
        />
      </Table>
      <Panel header={<h4>Auto</h4>} collapsible>
        <Table striped condensed responsive hover fill>
          <RentalPreferenceTableContent
            col={keys.filter((key) => !excludeKeysForOtherView.includes(key))}
            data={rentalPreference.auto || {}}
          />
        </Table>
      </Panel>
      <Panel header={<h4>Manuel</h4>} collapsible>
        <Table striped condensed responsive hover fill>
          <RentalPreferenceTableContent
            col={keys.filter((key) => !excludeKeysForOtherView.includes(key))}
            data={rentalPreference.manuel || {}}
          />
        </Table>
      </Panel>
    </Panel>
  );
};

export default RentalPreference;
