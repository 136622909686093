import fetch from 'isomorphic-fetch';

type FetchMethod = 'GET' | 'POST' | 'DELETE' | 'OPTIONS';

export function getCurrentUser() {
  try {
    const lsUser = global.localStorage.getItem('user');
    return lsUser ? JSON.parse(lsUser) : null;
  } catch (e) {
    if (__DEV__) {
      console.error(e);
    }
    return null;
  }
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
function formatUrl(path: string, params?: any) {
  const adjustedPath = path[0] !== '/' ? '/' + path : path;
  return adjustedPath;
}

const DEFAULT_OPTIONS = { params: undefined, data: undefined };

type Options = {
  params?: string;
  data?: string | any;
};

export class ApiClient {
  // constructor() {}

  get(path: string, { params, data }: Options = DEFAULT_OPTIONS) {
    return this.fetch(formatUrl(path, params), data, 'GET', {
      'Content-Type': 'application/json'
    });
  }

  post(path: string, { params, data }: Options = DEFAULT_OPTIONS) {
    return this.fetch(formatUrl(path, params), data, 'POST', {
      'Content-Type': 'application/json'
    });
  }

  options(path: string, { params, data }: Options = DEFAULT_OPTIONS) {
    return this.fetch(formatUrl(path, params), data, 'OPTIONS', {
      'Content-Type': 'application/json'
    });
  }

  delete(path: string, { params, data }: Options = DEFAULT_OPTIONS) {
    return this.fetch(formatUrl(path, params), data, 'DELETE', {
      'Content-Type': 'application/json'
    });
  }

  async fetch(
    path: string,
    payload: void | string | any,
    method: FetchMethod = payload ? 'POST' : 'GET',
    headers = {}
  ) {
    const user = getCurrentUser();

    const res = await fetch(path, {
      method,
      headers: {
        Authorization: user && user.token ? `Bearer ${user.token}` : '',
        ...headers
      },
      credentials: 'same-origin',
      body: payload
        ? headers['Content-Type'] === 'application/json'
          ? JSON.stringify(payload)
          : payload
        : undefined
    });
    const body = await res.json();
    return body;
  }

  graph(path: string, payload: { query: string; variables: {} }) {
    return this.post(path, { data: payload });
  }

  form(path: string, payload: any) {
    return this.fetch(path, payload, 'POST', {
      // 'Content-Type': 'application/x-www-form-urlencoded'
    });
  }
}

export default new ApiClient();
