import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router';

type Props = {
  label?: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  link?: { to: string | number };
  onClick?: (ev: any) => void;
  children?: React.ReactNode;
};

const TCell = (props: Props) => {
  const { link, label, children, ...rest } = props;

  return (
    <TableCell {...rest}>
      {children || (link ? <Link {...link}>{label}</Link> : label)}
    </TableCell>
  );
};

export default TCell;
