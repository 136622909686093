import React from 'react';

type Props = {
  cssStyles: { [key: string]: any };
  children: any;
  innerStyle?: { [key: string]: any };
};

const CustomLayout = (props: Props): JSX.Element => {
  const { cssStyles: styles, children, innerStyle = {} } = props;
  return (
    <div className={styles.container} style={{ paddingBottom: '5em', ...innerStyle }}>
      {React.Children.map(children, (child) => {
        if (child) {
          const { layoutClass, ...props } = child.props;
          return (
            <div className={`${styles.childContainer} ${layoutClass}`}>
              {React.cloneElement(child, { ...props })}
            </div>
          );
        }
      })}
    </div>
  );
};

export default CustomLayout;
