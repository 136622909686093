export function intervalSerializer(v: number) {
  const days = v / 86400000;
  if (days > 1) {
    return `${days} D`;
  }

  const hours = v / 3600000;
  if (hours > 1) {
    return `${hours} H`;
  }

  const minutes = v / 60000;
  if (minutes > 1) {
    return `${minutes} M`;
  }

  const seconds = v / 1000;
  if (seconds > 1) {
    return `${seconds} S`;
  }
}

export function weekdaysSerializer(v: number[]) {
  if (!v) {
    return '-';
  }
  if (v.length === 0) {
    return 'Everyday';
  }
  return v
    .map((day) => {
      switch (day) {
        case 0:
          return 'Sunday';
        case 1:
          return 'Monday';
        case 2:
          return 'Tuesday';
        case 3:
          return 'Wednesday';
        case 4:
          return 'Thuesday';
        case 5:
          return 'Friday';
        case 6:
          return 'Saturday';
        default:
          return 'Invalid Weekday';
      }
    })
    .join(', ');
}

export function timetableTimeSerializer(v) {
  const { hour, minute } = v;
  return `${hour > 9 ? hour : `0${hour}`}:${
    minute > 9 ? minute : `0${minute}`
  }`;
}
