import React, { Component } from 'react';
import { connect } from 'react-redux';

class PasswordChange extends Component {
  constructor() {
    super();
    this.state = {
      error: null
    };
  }

  handleSubmit = (ev) => {
    ev.preventDefault();

    const password = this.password.value;

    if (password) {
      fetch('/api/auth/me', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.token
        },
        body: JSON.stringify({
          password
        })
      });

      // todo: remove fetch logic to make component more stateless
      // and move this logic into parent component which passes
      // the onsubmit function to this which we call from here
      //this.props.onSubmit({ oldPassword, newPassword });

      this.setState({
        error: null,
        success: true
      });
    } else {
      this.setState({
        error: 'Error: password cannot be empty',
        success: false
      });
    }
  };

  render() {
    const {
      state: { error, success }
    } = this;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label>
            New password
            <input
              className={`form-control ${
                error ? 'error' : success ? 'success' : null
              }`}
              ref={(i) => (this.password = i)}
              type="password"
            />
          </label>
          <button
            className="btn btn-primary"
            type="button"
            onClick={this.handleSubmit}
          >
            Change Password
          </button>
        </div>
      </form>
    );
  }
}

export default connect(({ auth: { user: { token } } }) => ({ token }))(
  PasswordChange
);
