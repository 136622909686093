import React from 'react';

type Props = {
  children: JSX.Element | JSX.Element[];
  place: 'left' | 'right' | 'center' | 'space-between';
  style?: {};
};

function places(place: string) {
  switch (place) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'space-between':
      return 'space-between';
    default:
      return 'flex-start';
  }
}

const Placement = (props: Props): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: places(props.place),
        ...props.style
      }}
    >
      {props.children}
    </div>
  );
};

export default Placement;
