export const UPDATE_USER_INIT = 'boligbesked/UPDATE_USER_INIT';
export const UPDATE_USER_SUCCESS = 'boligbesked/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'boligbesked/UPDATE_USER_FAIL';

export const GET_USER_INIT = 'boligbesked/GET_USER_INIT';
export const GET_USER_SUCCESS = 'boligbesked/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'boligbesked/GET_USER_FAIL';

export const DELETE_USER_INIT = 'boligbesked/DELETE_USER_INIT';
export const DELETE_USER_SUCCESS = 'boligbesked/DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'boligbesked/DELETE_USER_FAIL';

export const CLEAR_USER = 'boligbesked/CLEAR_USER';

export const GET_USERS_INIT = 'boligbesked/GET_USERS_INIT';
export const GET_USERS_SUCCESS = 'boligbesked/GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'boligbesked/GET_USERS_FAIL';
