import React from 'react';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PopUpContainer from './modal';

const PopUp = (props) => {
    const [value, setValue] = React.useState(null);

  return (
    <PopUpContainer open={props.open} onClose={props.onRequestClose}>
      <h2 id="simple-modal-title" style={{ marginBottom: '1em' }}>
        {props.title}
      </h2>
        <form noValidate autoComplete="off">
        <TextField
            id={props.id}
            label={props.label}
            placeholder={props.placeholder}
            multiline
            fullWidth
            type="text"
            rows="20"
            InputLabelProps={{
            shrink: true
            }}
            value={value || ''}
            onChange={({ target: { value } }) => setValue(value)}
            margin="normal"
            variant="outlined"
        />
        <Button onClick={() => { props.onSuccess(value); }}>Sumbit</Button>
        </form>
    </PopUpContainer>
  );
};

export default PopUp;
