import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Loading from '../common/loading';
import createChart from './charts';
import detailForm from './details/detailForm';
import { createDetails } from './details/createDetails';
import Button from '@material-ui/core/Button';
import PopUp from './popup';

// TODO: rewrite to use hooks (?)

export default class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      report: null,
      options: null,
      accountIds: [],
      showModal: false,
      slug: props.routeParams.report
    };

    this.fetchOptions(props.routeParams.report);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.routeParams.report !== nextProps.routeParams.report) {
      this.setState({ loading: false, report: null, options: null, slug: null }, () => {
        this.fetchOptions(nextProps.routeParams.report);
      });
    }
  }

  async fetchOptions(report) {
    // TODO: Fetch details about generating charts
    // (eg type of chart and mapping of values and axis labels)
    const { options } = await apiClient.options(`/api/reports/${report}`);
    this.setState({ options, slug: report });
  }

  async fetch(slug, { data }) {
    try {
      this.setState({ loading: true, report: null });

      const report = await apiClient[
        data ? 'post' : 'get'
      ](`/api/reports/${slug}`, { data });

      this.setState({
        report: Array.isArray(report) ? report.join('\n') : report,
        loading: false
      });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();

    const { elements } = ev.target;

    const data = Object.keys(elements).reduce((acc, key) => {
      const element = elements[key];
      const { name, value, type, checked } = element;
      if (name) {
        if (type === 'date' /* || ['start', 'end'].includes(key)*/) {
          if (value) {
            acc[name] = new Date(value);
          }
        } else if (type === 'number') {
          if (value) {
            acc[name] = Number(value);
          }
        } else if (type === 'checkbox') {
          acc[name] = checked ? true : false;
        } else {
          acc[name] = value;
        }
      }
      return acc;
    }, this.state.accountIds?.length ? { accountIds: this.state.accountIds } : {});

    // TODO: Append to window.location.query
    // TODO: Auto-submit using location.query (initial load)

    this.fetch(this.props.routeParams.report, { data });
  };

  render() {
    const { loading, report, options, slug } = this.state;

    if (!options) {
      return <Loading />;
    }

    const form =
      options && options.args && options.args.length ? (
        <form style={{ display: 'flex' }} onSubmit={this.handleSubmit}>
          {options.args.map((arg, idx) => {
            if (arg.type === 'enum') {
              return (<label key={idx} style={{ padding: '4px 8px' }}>
                {arg.label || arg.name}
                <br />
                <select key={arg.name} name={arg.name}>
                  {arg.options.map(({ value, label = value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </label>
              );
            }
            if(arg.type === 'array') {
              return (
                <div style={{ padding: '4px 8px' }}>
                  <p>Using: {this.state.accountIds?.length} AccountIds</p>
                  <Button onClick={() => this.setState({ showModal: true })} variant="contained">
                    Filter with AccountIds
                  </Button>
                  <PopUp
                    title="Filter using AccountIds"
                    open={this.state.showModal}
                    onRequestClose={() => this.setState({ showModal: false })}
                    onSuccess={(res) => {
                      this.setState({
                        accountIds: res.split('\n'),
                        showModal: false
                       });
                    }}
                  />
                </div>
                );
            }
            const {
              type = 'text',
              name,
              label,
              defaultValue: defVal,
              ...props
            } = arg;
            const defaultValue =
              typeof defVal === 'function' ? defVal() : defVal;
            return (
              <label key={idx} style={{ padding: '4px 8px' }}>
                {label || name}
                <br />
                <input
                  style={{ minWidth: '100%' }}
                  key={name}
                  name={name}
                  type={type}
                  {...props}
                  defaultValue={defaultValue}
                />
              </label>
            );
          })}
          <button type="submit">Submit</button>
        </form>
      ) : null;

    let content = null;

    if (loading) {
      content = <Loading />;
    } else if (
      options &&
      (options.chart || options.charts) &&
      (report?.dataset || (typeof report === 'string' && report !== 'No results'))
    ) {
      content = createChart(report, options);
    } else if (typeof report === 'string') {
      content = <pre>{report}</pre>;
    } else if (report?.details) {
      content = (<div>{report.details.map((detail) => {
        return createDetails(options?.details, detail);
      })}</div>);
    } else if (report) {
      content = <pre>{JSON.stringify(report, null, 2)}</pre>;
    }

    const slugForm = detailForm(slug);

    return (
      <div>
        {slugForm}
        {form}
        {form ? <hr /> : null}
        {content}
      </div>
    );
  }
}
