import { differenceInMinutes, areIntervalsOverlapping } from 'date-fns';

function workingHours({ start, end }, deduction = 0) {
  return differenceInMinutes(new Date(end), new Date(start)) / 60 - deduction;
}

export function hoursPerStatus(
  shifts
): { [user: string]: { [status: string]: number } } {
  const results = shifts.reduce((acc, shift) => {
    if (!acc[shift.user]) {
      acc[shift.user] = [];
    }
    acc[shift.user].push(shift);
    return acc;
  }, {});

  return Object.keys(results).reduce((acc, user) => {
    acc[user] = results[user].reduce((acc, shift) => {
      acc[shift.status] = acc[shift.status] || 0;
      acc.lunchScheme = acc.lunchScheme || 0;
      acc.lunchBreak = acc.lunchBreak || 0;

      if (shift.status === 'ACCEPTED') {
        acc.lunchScheme += shift.lunchScheme ? 1 : 0;
        acc.lunchBreak += shift.lunchBreak ? 1 : 0;
      }
      acc[shift.status] += workingHours(shift, shift.lunchBreak ? 0.5 : 0);
      return acc;
    }, {});
    return acc;
  }, {});
}

export function withInLunch(start, end) {
  const lunchStart = new Date(start);
  lunchStart.setHours(11, 30, 0, 0);
  const lunchEnd = new Date(start);
  lunchEnd.setHours(12, 0, 0, 0);
  return areIntervalsOverlapping(
    { start, end },
    { start: lunchStart, end: lunchEnd }
  );
}

export function parseShift({ shift, user }) {
  if (shift) {
    const { start, end, ...rest } = shift;
    return {
      ...rest,
      date: new Date(start),
      startTime: new Date(start),
      endTime: new Date(end)
    };
  }
  const date = new Date();
  const startTime = new Date();
  const endTime = new Date();
  startTime.setHours(8, 0, 0, 0);
  endTime.setHours(16, 0, 0, 0);
  const inLunch = withInLunch(startTime, endTime);
  return {
    user,
    date,
    startTime,
    endTime,
    lunchBreak: inLunch,
    lunchScheme: inLunch
  };
}

export function createInitial(name) {
  const splitName = name.split(' ');
  const initial = splitName
    .map(split => {
      return split.charAt(0);
    })
    .join('');

  return initial;
}

export function capitalize(str: string): string {
  if (typeof str !== 'string') {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
