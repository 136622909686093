import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Loading from '../../common/loading';
import Table from 'app/components/mui/Table';
import DetailTab from './sms-boligbesked-row';
import { Button, MenuItem, Select } from '@material-ui/core';

// TODO: rewrite to use hooks (?)

const columns = [
  { label: 'Title', name: 'title', valueType: 'string' },
  { label: 'Users', name: 'users', valueType: 'number' }
];

export default class RentalPreferenceReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      type: 'sms',
      threshold: 1,
      report: null
    };
  }

  async fetch() {
    try {
      this.setState({ report: null, loading: true });
      const { type, threshold } = this.state;
      const report = await apiClient.post('/api/reports/boligbeskeder', { data: { type, threshold } });
      console.log(report);
      this.setState({ report, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  render() {
    const { loading, report } = this.state;

    if(loading) {
        return <Loading/>;
    }

    return (
      <div>
        <div style={{ display: 'flex' }}>
          {/* <Select value={this.state.type} onChange={(ev) => this.setState({ type: ev.target.value })}>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="sms">Sms</MenuItem>
          </Select> */}
          <Select value={this.state.threshold} onChange={(ev) => this.setState({ threshold: Number(ev.target.value) })}>
            <MenuItem value="30">30 Dage</MenuItem>
            <MenuItem value="14">14 Dage</MenuItem>
            <MenuItem value="7">7 Dage</MenuItem>
            <MenuItem value="1">1 Dag</MenuItem>
          </Select>
          <Button onClick={() => this.fetch()}>Søg</Button>
        </div>
        <Table
          title={'Boligbeskeder'}
          columns={columns}
          rows={report?.stats || []}
          detailOnClick={(row) => <DetailTab params={{ ...row.params, threshold: this.state.threshold }}/> }
        />
      </div>
    );
  }
}
