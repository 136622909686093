import React from 'react';
import Table from 'app/components/mui/Table';

const columns = [
    { label: 'User', name: 'account', valueType: 'string' },
    { label: 'Key', name: 'key', valueType: 'string' },
    { label: 'Value', name: 'value', valueType: 'string' }
];

export default (row) => {
    return (<tr>
      <td colSpan={1}/>
      <td colSpan={11}>
        <div>
          <Table
            columns={columns}
            rows={row.result.metas}
            />
        </div>
      </td>
    </tr>);
};