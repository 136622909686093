import React from 'react';
import ActionButton from './ActionButton';

const AdminActionsButtons = [
  {
    label: 'Godkend',
    type: 'button',
    name: 'ACCEPTED',
    className: 'accepted'
  },
  {
    label: 'Afvent',
    type: 'button',
    name: 'PENDING',
    className: 'pending'
  },
  {
    label: 'Afvis',
    type: 'button',
    name: 'REJECTED',
    className: 'rejected'
  }
];

const OptionsPanel = ({
  shift,
  update,
  create,
  remove,
  updateStatus,
  admin,
  id,
  currentShift,
  accountUser
}) => {
  const canEdit = shift && (shift.user === accountUser || admin);
  return (
    <>
      {canEdit ? (
        <ActionButton
          label="Opdater vagt"
          type="submit"
          onClick={() => update(currentShift())}
        />
      ) : (
        <ActionButton
          label="Tilføj vagtønske"
          type="submit"
          onClick={() => create()}
        />
      )}
      {canEdit && (
        <ActionButton
          label="Slet vagt"
          type="submit"
          onClick={() => remove()}
        />
      )}

      {admin && id && (
        <div>
          {AdminActionsButtons.map(({ label, type, name, className }) => {
            return (
              <ActionButton
                key={name}
                label={label}
                type={type}
                onClick={() => updateStatus(name)}
                disabled={shift.status === name}
                className={className}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default OptionsPanel;
