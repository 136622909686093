import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class FormCellphone extends Component {
  static displayName = 'FormCellphone';

  render() {
    const {
      defaultCallingCode,
      defaultCellphone,
      label = 'Cellphone',
      callingCodeName = 'callingCode',
      cellphoneName = 'cellphone'
    } = this.props;

    return (
      <div className="form-group">
        <label className="col-sm-4 control-label" htmlFor={cellphoneName}>
          {label}
        </label>
        <Col className="phoneContainer" sm={8}>
          <input
            className="callCode form-control"
            name={callingCodeName}
            type="text"
            id="callCode"
            defaultValue={defaultCallingCode}
            onChange={this.props.onChange}
            placeholder="000"
          />
          <input
            className="cellphone form-control"
            name={cellphoneName}
            type="text"
            defaultValue={defaultCellphone}
            onChange={this.props.onChange}
            placeholder="cellphone"
          />
        </Col>
      </div>
    );
  }
}

export default FormCellphone;
