import * as dates from 'react-big-calendar/lib/utils/dates';
import * as localizer from 'react-big-calendar/lib/localizer';

const dateRangeFormat = ({ start, end }, culture, local) => {
  return (
    local.format(start, 'L', culture) + ' — ' + local.format(end, 'L', culture)
  );
};

const timeRangeFormat = ({ start, end }, culture, local) => '';
// local.format(start, 'kk:mm', culture) +
// ' — ' +
// local.format(end, 'kk:mm', culture);

const timeRangeStartFormat = ({ start, end }, culture, local) =>
  local.format(start, 'h:mma', culture) + ' — ';

const timeRangeEndFormat = ({ start, end }, culture, local) =>
  ' — ' + local.format(end, 'h:mma', culture);

const weekRangeFormat = ({ start, end }, culture, local) =>
  local.format(start, 'MMMM dd', culture) +
  ' - ' +
  local.format(end, dates.eq(start, end, 'month') ? 'dd' : 'MMMM dd', culture);

export const formats = {
  dateFormat: 'dd',
  dayFormat: 'eee dd/MM',
  weekdayFormat: 'eee',

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: 'kk:mm',

  monthHeaderFormat: 'MMMM yyyy',
  dayHeaderFormat: 'eeee d. MMMM',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: 'ddd MMM dd',
  agendaTimeFormat: 'kk:mm',
  agendaTimeRangeFormat: timeRangeFormat
};

export default function ({
  startOfWeek,
  getDay,
  parse,
  format: _format,
  locales
}) {
  return new localizer.DateLocalizer({
    formats,

    firstOfWeek(culture) {
      return getDay(startOfWeek(new Date(), { locale: locales[culture] }));
    },

    parse(value, format, culture) {
      return parse(value, { locale: locales[culture] });
    },

    format(value, formatString, culture) {
      return _format(new Date(value), formatString, {
        locale: locales[culture]
      });
    }
  });
}

// return new DateLocalizer({
//   formats,
//   firstOfWeek(culture = 'da') {
//     return getDay(startOfWeek(new Date(), { locale: locales[culture] }));
//   },
//   parse(value, format, culture = 'da') {
//     return parse(value, { locale: locales[culture] });
//   },

//   format(value, formatString, culture = 'da') {
//     return _format(new Date(value), formatString, {
//       locale: locales[culture]
//     });
//   }
// });
