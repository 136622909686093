import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';

import { setSettings, logout } from '../redux/modules/auth';

import { getNavigation } from './navigation';

class Header extends Component {
  logout = () => {
    this.props.logout();
    browserHistory.replace('/');
  };

  showSettings = () => {
    this.props.setSettings({ show: true });
  };

  render() {
    const { user = {} } = this.props;
    return (
      <Navbar fluid fixedTop inverse>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">Kodyl</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse>
          {user.token ? (
            <Nav pullRight>
              <NavDropdown
                eventKey={6}
                title={
                  <span>
                    <span className="fa fa-fw fa-user" />{' '}
                    {user.name && user.name.split(' ')[0]}
                  </span>
                }
                id="basic-nav-dropdown"
              >
                {user.role === 'manager' ? (
                  <LinkContainer to="/auth/users">
                    <MenuItem eventKey={6.1}>
                      <span className="fa fa-fw fa-users" /> Auth users
                    </MenuItem>
                  </LinkContainer>
                ) : null}
                <MenuItem divider />
                <LinkContainer to="/user">
                  <MenuItem eventKey={6.2}>
                    <span className="fa fa-fw fa-edit" /> Change password
                  </MenuItem>
                </LinkContainer>
                <MenuItem eventKey={6.3} onClick={this.showSettings}>
                  <span className="fa fa-fw fa-cog" /> Settings
                </MenuItem>
                <MenuItem divider />
                <MenuItem eventKey={6.4} onClick={this.logout}>
                  <span className="fa fa-fw fa-lock" /> Logout
                </MenuItem>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav pullRight>
              <LinkContainer to="/">
                <NavItem eventKey={1}>Login</NavItem>
              </LinkContainer>
            </Nav>
          )}
          {user.token ? getNavigation(this.props.user) : null}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default connect(({ auth }) => ({ user: auth.user }), {
  setSettings,
  logout
})(Header);
