import React, { useState } from 'react';

import query from './query.graphql';
import styles from './styles.scss';

import { Query } from 'app/components/mui/Query';

function filterActions(action) {
  return action.name === 'facebook-share' && action.meta.primary;
}

function sortActions(a, b) {
  return /r6/.test(a.meta.link) ? -1 : /r6/.test(b.meta.link) ? 1 : 0;
}

const Report = (props) => {
  const [pureLinks] = useState(false);

  function renderLinks(dwellings) {
    return dwellings.map((dwelling) => {
      const action = dwelling.actions
        .filter(filterActions)
        .sort(sortActions)[0];
      const link = action && `https://www.facebook.com/${action.meta.postId}`;
      return (
        link && (
          <a key={dwelling.id} href={link}>
            {link}
          </a>
        )
      );
    });
  }

  function renderDetails(dwellings) {
    return dwellings.map((dwelling) => {
      const action = dwelling.actions
        .filter(filterActions)
        .sort(sortActions)[0];
      return action ? (
        <a
          key={dwelling.id}
          href={`https://www.facebook.com/${action.meta.postId}`}
        >
          {dwelling.id} - <b>{dwelling.headline}</b> ({dwelling.city} /{' '}
          {action.meta.link})
        </a>
      ) : (
        <span key={dwelling.id}>
          {dwelling.id} - <b>{dwelling.headline}</b> ({dwelling.city})
        </span>
      );
    });
  }

  return (
    <Query
      query={query}
      variables={{ limit: props.location.query.limit || 25, inactive: false }}
    >
      {({ dwellings }) => {
        return (
          <div className={styles.list}>
            <button onClick={() => this.setState({ pureLinks: !pureLinks })}>
              {pureLinks ? 'Show details' : 'Only links'}
            </button>
            <hr />
            {pureLinks ? renderLinks(dwellings) : renderDetails(dwellings)}
          </div>
        );
      }}
    </Query>
  );
};

export default Report;
