import { fromJS } from 'immutable';

import './notifications';

import {
  UPDATE_USER_INIT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_USER,
  GET_USER_INIT,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL
} from './actions';
import { LOGOUT } from '../auth/actions';

const ENDPOINT = '/api/boligbesked/users';

const initialState = {
  updateUserStatus: null,
  user: null,
  users: fromJS([])
};

export function updateUser(id, data) {
  return {
    types: [UPDATE_USER_INIT, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL],
    promise: (client) =>
      client.post(`${ENDPOINT}/${id}`, { data }).then((body) => body.result)
  };
}

export function getUser(id) {
  return {
    types: [GET_USER_INIT, GET_USER_SUCCESS, GET_USER_FAIL],
    promise: (client) =>
      client.get(`${ENDPOINT}/${id}`).then((body) => body.result)
  };
}

export function deleteUser(id) {
  return {
    types: [DELETE_USER_INIT, DELETE_USER_SUCCESS, DELETE_USER_FAIL],
    promise: (client) =>
      client.delete(`${ENDPOINT}/${id}`).then((body) => body.result)
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER
  };
}

export function getUsers() {
  return {
    types: [GET_USERS_INIT, GET_USERS_SUCCESS, GET_USERS_FAIL],
    promise: (client) => client.get(`${ENDPOINT}`).then((body) => body.results)
  };
}

export default function bbUsers(state = initialState, action) {
  const { type, payload } = action;

  if (type === GET_USER_SUCCESS) {
    return { ...state, user: payload };
  } else if (type === UPDATE_USER_SUCCESS || type === DELETE_USER_SUCCESS) {
    return {
      ...state,
      user: payload,
      users: state.users.size
        ? state.users.map((user) =>
            user.get('_id') === payload._id ? fromJS(payload) : user
          )
        : state.users
    };
  } else if (type === CLEAR_USER) {
    return { ...state, user: null };
  } else if (type === GET_USERS_INIT) {
    return { ...state, users: fromJS([]) };
  } else if (type === GET_USERS_FAIL) {
    return { ...state, users: fromJS([]) };
  } else if (type === GET_USERS_SUCCESS) {
    return { ...state, users: fromJS(action.payload || []) };
  } else if (type === LOGOUT) {
    return initialState;
  }
  return state;
}
