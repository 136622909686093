import React from 'react';

import Form from 'app/components/mui/Form/Form';
import FieldsArray from 'app/components/mui/FieldsArray';

type Props = {
  title?: string;
  namespace: string;
  data: any;
  fieldOptions: (values: any) => any;
  addValueHandler: (values: any, dirty: boolean, namespace: string) => void;
};

export default ({
  data,
  namespace,
  addValueHandler,
  fieldOptions,
  title
}: Props) => {
  return (
    <Form initValues={data} enableReinitialize>
      {({ values, dirty }) => {
        return (
          <FieldsArray
            style={{ marginBottom: '32px' }}
            onBlur={() => {
              addValueHandler(values[namespace], dirty, namespace);
            }}
            namespace={namespace}
            title={
              title ||
              namespace.replace(/^([a-z])/, (_, p1) => p1.toUpperCase())
            }
            fields={values}
            fieldOptions={fieldOptions(values)}
          />
        );
      }}
    </Form>
  );
};
