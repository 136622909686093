import React from 'react';
import Grid, {
  GridSpacing,
  GridJustification,
  GridDirection
} from '@material-ui/core/Grid';

type Props = {
  children: JSX.Element[];
  spacing?: GridSpacing;
  justify?: GridJustification;
  direction?: GridDirection;
};

const Layout = ({ children, ...props }: Props): JSX.Element => {
  return (
    <Grid
      {...props}
      style={{ paddingBottom: '5em', width: 'calc(100% + 31px)' }}
      container
    >
      {children &&
        [].concat(children).map((child, idx) => {
          return (
            child && (
              <Grid
                {...((child.props &&
                  child.props.gridProps &&
                  child.props.gridProps.col) || { xs: 12 })}
                className={
                  child.props &&
                  child.props.gridProps &&
                  child.props.gridProps.className
                }
                item
                key={idx}
              >
                {child}
              </Grid>
            )
          );
        })}
    </Grid>
  );
};

export default Layout;
