import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL
} from './actions';
import { subscribeAction, defaultStyle } from '../notification';

subscribeAction({
  [UPDATE_USER_SUCCESS]: {
    onPage: {
      ...defaultStyle,
      headline: 'Update Sucessful',
      description: 'The user was updated successfully'
    }
  },
  [UPDATE_USER_FAIL]: {
    onPage: {
      ...defaultStyle,
      bsStyle: 'danger',
      headline: 'Update Failed',
      description: 'The user update failed'
    }
  },
  [CREATE_USER_SUCCESS]: {
    onPage: {
      ...defaultStyle,
      headline: 'Create Successful',
      description: 'The user was created'
    }
  },
  [CREATE_USER_FAIL]: {
    onPage: {
      ...defaultStyle,
      bsStyle: 'danger',
      headline: 'Create Failed',
      description: 'Create user failed'
    }
  }
});
