// import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
// import update from 'immutability-helper';

type Props = {
  mutation: DocumentNode;
  children: (mutate: any) => JSX.Element;
};

export const Mutation = ({ mutation, children }: Props): JSX.Element => {
  const [mutate, { loading, error }] = useMutation(mutation);

  const options = { loading, error };

  if (error) {
    console.log(error);
    // TODO: Better error handling !!
    alert(JSON.stringify(error));
  }
  return children({ mutate, options });
};
