import PropTypes from 'prop-types';
import { Component } from 'react';
import { List } from 'immutable';

const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 250;

export default class TableContainer extends Component {
  static propTypes = {
    rows: PropTypes.instanceOf(List)
  };

  static defaultProps = {
    rows: new List()
  };

  constructor(props) {
    super(props);

    const state = {
      filter: props.filter || {},
      offset: props.offset || DEFAULT_OFFSET,
      limit: props.limit || DEFAULT_LIMIT,
      sortBy: props.sortBy || null,
      sortDir: props.sortDir || -1,
      sortDefault: ''
    };

    this.state = {
      ...state,
      rows: this.filterRows(state, props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rows !== this.props.rows) {
      this.setState({ rows: this.filterRows(this.state, nextProps) });
    }
  }

  filterRows(state = this.state, props = this.props) {
    const { filter, sortBy, sortDir, sortDefault } = state;
    let filteredRows = props.rows.filter((user) => {
      let valid = true;
      Object.keys(filter).forEach((key) => {
        if (
          filter[key] &&
          !filter[key].test(user.getIn(key.split('.')) || '')
        ) {
          valid = false;
        }
      });
      return valid;
    });

    // const proto = {};
    // const body = Object.keys(filter).map(key => {
    //   const f = filter[key];
    //   if (typeof f === 'function') {
    //     proto[`_${key}`] = filter[key];
    //     return `if (!this._${key}(row)) return false;`;
    //   }
    //   if (f) {
    //     return `if (!${f.toString()}.test(${key === 'user' ? '(row._userid && `${row._userid.name} ${row._userid.email}`)' : `row[${key}]`})) return false;`;
    //   }
    // }).join(';');
    // const fn = new Function('row', `
    //   ${body}
    //   return true;
    // `);

    // const filteredRows = props.rows.filter(fn.bind(proto));
    // const count = filteredRows.length;

    if (sortBy) {
      const arr = sortBy.split('.');
      filteredRows = filteredRows.sort((v1, v2) => {
        const a = v1.getIn(arr) || sortDefault;
        const b = v2.getIn(arr) || sortDefault;
        return a > b ? 1 : a < b ? -1 : 0;
      });
      if (sortDir === -1) {
        filteredRows = filteredRows.reverse();
      }
    }

    return filteredRows.slice(state.offset, state.limit);
  }

  onFilterChange = (ev) => {
    if (ev) {
      const { name, value } = ev.target;
      const filter = {
        ...this.state.filter,
        [name]: value ? new RegExp(value, 'i') : null
      };

      this.setState({
        filter,
        rows: this.filterRows({ ...this.state, filter })
      });
    }
  };

  // onFilterChangeX = (ev) => {
  //   const { name, value } = ev.target;
  //
  //   const filter = {
  //     ...this.state.filter,
  //     [name]: value ? new RegExp(value, 'i') : null
  //   };
  //
  //   const rows = this.props.rows.filter(user => {
  //     let valid = true;
  //     Object.keys(filter).forEach(key => {
  //       if (filter[key] && !filter[key].test(user.getIn(key.split('.')) || '')) {
  //         valid = false;
  //       }
  //     });
  //     return valid;
  //   }).slice(0, 100);
  //
  //   this.setState({ filter, rows });
  // }

  sortBy = (ev) => {
    const sortBy = ev.target.getAttribute('data-column');
    const sortDir =
      sortBy !== this.state.sortBy ? -1 : (this.state.sortDir || 1) * -1;
    const sortType = ev.target.getAttribute('data-type') || 'string';
    const sortDefault = sortType === 'number' ? 0 : '';

    this.setState({
      sortBy,
      sortDir,
      sortDefault,
      rows: this.filterRows({ ...this.state, sortBy, sortDir })
    });
  };
}
