import React from 'react';
import lib from 'lib/format-date';

function toOption(opt) {
  return typeof opt === 'string' ? (
    <option value={!opt ? 'ENUM_' : opt.toUpperCase()}>{opt}</option>
  ) : (
    <option value={opt.value}>{opt.label}</option>
  );
}

export const formatDateStr = lib.dateFormatStr;

export const FIELDS = {
  id: {
    label: 'User ID',
    type: 'text',
    readOnly: true
  },
  userId: {
    label: 'User',
    type: 'text',
    readOnly: true
  },
  leaseId: {
    label: 'Lease',
    type: 'text',
    readOnly: true
  },
  recieverEmail: {
    label: 'Reciever Email',
    type: 'text'
  },
  scraperId: {
    label: 'Scraper ID',
    type: 'text',
    readOnly: true
  },
  scraperSlug: {
    label: 'Scraper Slug',
    type: 'text',
    readOnly: true
  },
  status: {
    label: 'Status',type: 'select',
    children: [
      'PENDING', 'INQUEUE', 'APPROVED', 'SENT', 'REJECTED'
    ].map(toOption)
  },
  triggerReason: {
    label: 'Trigger Reason',
    type: 'text',
    readOnly: true
  },
  text: {
    label: 'Message',
    type: 'textarea'
  },
  sentAt: {
    label: 'Sent',
    type: 'text',
    transform: formatDateStr,
    readOnly: true
  },
  createdAt: {
    label: 'Created',
    type: 'text',
    transform: formatDateStr,
    readOnly: true
  }
};

export const LEASE_FIELDS = {
  address: {
    label: 'Address',
    type: 'text',
    readOnly: true
  },
  zip: {
    label: 'Zip',
    type: 'text',
    readOnly: true
  },
  city: {
    label: 'City',
    type: 'text',
    readOnly: true
  },
  rooms: {
    label: 'Rooms',
    type: 'text',
    readOnly: true
  },
  size: {
    label: 'Size',
    type: 'text',
    readOnly: true
  },
  rent: {
    label: 'Rent',
    type: 'text',
    readOnly: true
  },
  url: {
    label: 'URL',
    type: 'text',
    readOnly: true
  },
  listingType: {
    label: 'Listing Type',
    type: 'text',
    readOnly: true
  },
  typeDwelling: {
    label: 'Dwelling Type',
    type: 'text',
    readOnly: true
  }
};

export const SUBSCRIPTION_FIELDS = {
  id: {
    label: 'Subscription ID',
    type: 'text',
    readOnly: true
  },
  provider: {
    label: 'Provider',
    type: 'text',
    readOnly: true
  },
  plan: {
    label: 'Plan',
    type: 'text',
    readOnly: true,
    transform: (v) => v.id
  },
  active: {
    label: 'Active',
    type: 'checkbox',
    disabled: true
  },
  nextPaymentDate: {
    label: 'Next Payment',
    type: 'text',
    transform: formatDateStr,
    readOnly: true
  },
  canceledAt: {
    label: 'Canceled',
    type: 'da',
    transform: formatDateStr,
    readOnly: true
  },
  createdAt: {
    label: 'Created',
    type: 'text',
    transform: formatDateStr,
    readOnly: true
  }
};

export const CAPS_FIELDS = {
  leaseOneHour: {
    label: 'Lease Cap 1 hour',
    type: 'text',
    readOnly: true
  },
  leaseOneDay: {
    label: 'Lease Cap 24 Hours',
    type: 'text',
    readOnly: true
  },
  scraperOneHour: {
    label: 'Scraper Cap 1 Hour',
    type: 'text',
    readOnly: true
  },
  scraperOneDay: {
    label: 'Scraper Cap 24 Hours',
    type: 'text',
    readOnly: true
  }
};