import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

type Props = {
  onBlur?: (
    ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange?: (
    ev: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>
  ) => void;
  value?: string;
  margin?: 'dense';
  variant?: 'outlined';
  type?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  style?: { alignSelf?: string };
  options?: { value: any; label?: string }[];
  id?: string;
  helperText?: React.ReactNode;
};

const SimpleInputField = (props: Props): JSX.Element => {
  const { options, label, id, name, ...rest } = props;
  const isSelect = Boolean(options);
  return (
    <TextField
      style={{ width: '100%', margin: 'unset' }}
      fullWidth
      type="text"
      margin="dense"
      select={isSelect}
      InputLabelProps={{
        shrink: true
      }}
      variant="outlined"
      label={label || id}
      name={name || id}
      {...rest}
    >
      {isSelect &&
        options.map(({ label, value }) => {
          return (
            <MenuItem key={value} value={value}>
              {label || value}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

export default SimpleInputField;
