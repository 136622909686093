import React, { useState } from 'react';
import { List, Button, Collapse } from '@material-ui/core';
import { Title } from '../../../Title';
import ListItem from './ListItem/ListItem';
import { LogData } from '../Log';

type Props = {
  title: string;
  logs: LogData[];
};

const ListView = (props: Props) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <List component="div" aria-labelledby="log">
      {props.logs.map((log, idx) => {
        if (idx <= 5) {
          return <ListItem key={`${log.name}_${idx}`} {...log} />;
        }
        return null;
      })}
      <Collapse in={showAll}>
        {props.logs.map((log, idx) => {
          if (idx > 5) {
            return <ListItem key={`${log.name}_${idx}`} {...log} />;
          }
          return null;
        })}
      </Collapse>

      {props.logs.length > 6 && (
        <div style={{ textAlign: 'center' }}>
          <Button
            onClick={() => setShowAll((prev) => !prev)}
            size="small"
            variant="text"
            fullWidth
          >
            {!showAll ? 'Show all' : 'hide'}
          </Button>
        </div>
      )}
    </List>
  );
};

export default ListView;
