import apiClient from 'app/utils/api-client';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import { OptionsColumn } from './optionsColumn';

type Props = {
  id: string;
  status: string;
}

export default class ActionRow extends Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      result: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
      if(!this.props.id) {
          return;
      }
      this.setState({ report: null, loading: true });
      const result = await apiClient.post('/api/microservice', { data: { action: 'ActionDetails', data: { action: this.props.id } } });
      this.setState({ result: result?.data, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  async forceExecute() {
    await apiClient.post('/api/microservice', { data: { action: 'ExecuteAction', data: { id: this.props.id } } });
  }

  async discard() {
    await apiClient.post('/api/microservice', { data: { action: 'DiscardAction', data: { id: this.props.id } } });
  }

  render() {
    const { loading, result } = this.state;

    if(loading) {
        return <Loading/>;
    }

    return (<tr>
      <td colSpan={1}/>
      <td colSpan={11}>
        <OptionsColumn forceExecute={this.forceExecute} discard={this.discard} status={this.props.status}/>
      </td>
    </tr>);
  }
}