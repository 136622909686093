import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

import { requirePermission } from '../../shared/auth/permissions';

import { default as NAVIGATION } from './nav';

function trimArray(arr) {
  return arr
    .reduce((acc, item) => {
      if (item || acc.length) {
        acc.push(item);
      }
      return acc;
    }, [])
    .reverse()
    .reduce((acc, item) => {
      if (item || acc.length) {
        acc.push(item);
      }
      return acc;
    }, [])
    .reverse();
}

function traverse(permissions, group, parent) {
  return trimArray(
    group
      .map((item) => {
        if (item === null) {
          return item;
        }
        const granted = requirePermission(
          parent ? `${parent}/${item.key}` : item.key,
          permissions
        );
        if (!granted) {
          return false;
        }
        if (item.children) {
          return {
            ...item,
            children: traverse(permissions, item.children, item.key)
          };
        }
        return item;
      })
      .filter((item) => item !== false)
  );
}

export function filterNavigation(navigation, user) {
  const permissions = user.permissions
    .map((permission) => permission.replace(/^([^!:]+):(read|write)$/, '$1'))
    .reduce((acc, permission) => {
      // TODO: Other cases with wildcard and method ?
      if (!/\*/.test(permission)) {
        acc.push(
          ...permission.split('/').reduce((acc, perm) => {
            acc.push(acc.length ? `${acc[acc.length - 1]}/${perm}` : perm);
            return acc;
          }, [])
        );
      } else {
        acc.push(permission);
      }
      return acc;
    }, []);

  return traverse(permissions, navigation);
}

function renderNavigation(
  tree,
  parent,
  { eventKey = '0' /*, parentPermission*/ } = {}
) {
  const childs = tree.map((e, idx) => {
    if (!e) {
      return 'divider';
    }

    const evKey = `${eventKey}.${idx}`;
    // const requiredPermission = parentPermission
    //   ? `${parentPermission}/${e.key}`
    //   : e.key;
    // if (!requirePermission(requiredPermission, permissions)) {
    //   return null;
    // }
    if (e.children) {
      return renderNavigation(e.children, e, {
        // parentPermission: requiredPermission,
        eventKey: evKey
      });
    }
    const icon = e.icon ? <span className={`fa fa-fw fa-${e.icon}`} /> : null;
    const props = e.props || (e.to ? {} : { disabled: true });
    if (props.href) {
      return (
        <NavItem key={evKey} {...props}>
          {icon} {e.label}
        </NavItem>
      );
    }
    const item = (
      <MenuItem key={evKey} eventKey={evKey} {...props}>
        {icon} {e.label}
      </MenuItem>
    );
    return e.to ? (
      <LinkContainer key={evKey} to={e.to}>
        {item}
      </LinkContainer>
    ) : (
      item
    );
  });

  const nav = childs.filter(Boolean).reduce((acc, child, idx) => {
    if (child === 'divider') {
      const next = childs[idx + 1];
      if (next && next !== 'divider') {
        acc.push(<MenuItem key={`${idx}-divider`} divider />);
      }
    } else {
      acc.push(child);
    }
    return acc;
  }, []);

  if (parent) {
    const icon = parent.icon && (
      <span className={`fa fa-fw fa-${parent.icon}`} />
    );
    const title = parent.label ? (
      icon ? (
        <span>
          {parent.label} {icon}
        </span>
      ) : (
        parent.label
      )
    ) : (
      icon || ''
    );
    return (
      <NavDropdown
        key={eventKey}
        eventKey={eventKey}
        title={title}
        id="basic-nav-dropdown"
      >
        {nav}
      </NavDropdown>
    );
  }

  return <Nav pullRight>{nav}</Nav>;
}

export function getNavigation(user, tree = NAVIGATION) {
  return renderNavigation(filterNavigation(tree, user));
}
