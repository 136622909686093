import React from 'react';
import apiClient from 'app/utils/api-client';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const ONE_DAY = 86400000;

const columns = [
  { label: 'Key', name: '_id', valueType: 'custom', customValue: (value) => String(value) },
  { label: 'Leases', name: 'sum', valueType: 'number' },
  { label: '% of Total', name: 'percentage', valueType: 'number' },
  { label: 'Current Active', name: 'active', valueType: 'number' },
  { label: '% of Current', name: 'activePercentage', valueType: 'number' }
];

function parseDate(date) {
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    return `${date.getFullYear()}-${month}-${day}`;
}

export const LeaseReport = (props: Props): JSX.Element => {
    const now = new Date();
    const past = new Date(new Date().getTime() - 90 * ONE_DAY);
    const [list, setList] = React.useState([]);
    const [from, setFrom] = React.useState(parseDate(past));
    const [to, setTo] = React.useState(parseDate(now));

    const [leases, setLeases] = React.useState(0);
    const [active, setActive] = React.useState(0);
    const [groups, setGroups] = React.useState(0);
    const [key, setKey] = React.useState('city');

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
         apiClient.post('/api/microservice', { data: { action: 'getLeaseReport', data: { filter: { from: from, to: to, key: key } } } }).then((data) => {
          if(data) {
            setList(data.report);
            setLeases(data.leases);
            setActive(data.activeSum);
            setGroups(data.groups);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div>
        <span>From: </span>
        <TextField type="date" id="from" onChange={(ev) => setFrom(ev.target.value)} value={from} key="from" />
        <span>To: </span>
        <TextField type="date" id="to" onChange={(ev) => setTo(ev.target.value)} value={to} key="to" />
        <span>Key: </span>
        <Select value={key} onChange={(ev) => setKey(ev.target.value)}>
          <MenuItem value="rooms">Rooms</MenuItem>
          <MenuItem value="size">Size</MenuItem>
          <MenuItem value="city">City</MenuItem>
          <MenuItem value="zip">Zipcode</MenuItem>
          <MenuItem value="landlord">Landlord</MenuItem>
        </Select>
        <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
        </div>
        { loading ? <p>Loading...</p> : !list?.length ? <p>No active leases in period</p> : ( <Table
            title={`Active Lease Report (Leases: ${leases} | Actives: ${active} | Groups: ${groups})`}
            columns={columns}
            rows={list || []}
        />)}
    </div>
  );
};
