import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getSubscription,
  cancelSubscription
} from './../redux/modules/boligninja';
import { CANCEL_SUBSCRIPTION_SUCCESS } from '../redux/modules/boligninja/actions';
import Sub from './common/subscription';

class SubscriptionContainer extends Component {
  static fetchData({ dispatch, params }) {
    return dispatch(getSubscription(params.id));
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  unsubscribe = (id) => {
    this.setState({ error: false });
    this.props.cancelSubscription(id).then((action) => {
      if (action.type !== CANCEL_SUBSCRIPTION_SUCCESS) {
        this.setState({ error: true });
      }
      return action;
    });
  };

  render() {
    const { subscription } = this.props;

    return (
      <div>
        <h3>Subscription</h3>
        {subscription ? (
          <Sub sub={subscription} unsubscribe={this.unsubscribe} gotoUserBtn />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default connect(
  ({ boligninja: { subscription } }) => ({ subscription }),
  { getSubscription, cancelSubscription }
)(SubscriptionContainer);
