/* eslint-disable react/no-multi-comp */
import React, { SyntheticEvent } from 'react';
import styles from './styles.module.scss';
import { TextField, Button, MenuItem } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  dwellingTypeOptions,
  tenantTypeOptions,
  booleanOptions
} from 'app/utils/selectOptions';

type Props = {
  left: string | number;
  right: Props['left'];
  name: string;
  onClick: (ev: SyntheticEvent<HTMLButtonElement>) => void;
  onChange: (
    ev: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

const selectInput = [
  'pets',
  'furnished',
  'typeDwelling',
  'residenceduty',
  'typeTenant'
];

const Input = ({ readOnly = false, ...props }) => {
  const isSelect = selectInput.includes(props.label);
  const options =
    isSelect &&
    (props.label === 'typeDwelling'
      ? dwellingTypeOptions
      : props.label === 'typeTenant'
      ? tenantTypeOptions
      : booleanOptions);

  return (
    <TextField
      fullWidth
      margin="dense"
      multiline={props.type === 'text'}
      variant="outlined"
      select={isSelect}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        readOnly
      }}
      {...props}
    >
      {isSelect &&
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

const ReviewerItem: React.FC<Props> = React.memo((props) => {
  const { name, left, right, onChange, onClick } = props;
  const inputType = name === 'available' ? 'date' : 'text';
  return (
    <div className={styles.inputControl}>
      <Input
        name={`scraper_${name}`}
        label={name}
        value={left}
        type={inputType}
        readOnly
      />
      <Button
        onClick={onClick}
        name={name}
        value={left}
        variant="contained"
        color="primary"
        size="small"
      >
        <ArrowForwardIcon fontSize="inherit" />
      </Button>
      <Input
        name={name}
        label={name}
        onChange={onChange}
        value={right}
        type={inputType}
      />
    </div>
  );
});

export default ReviewerItem;
