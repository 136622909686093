import React, { FC } from 'react';
import Tabs from 'app/components/mui/Tabs';

type Props = {
  params: { landlordId: string };
  router: { push: (to: string) => void };
};

const SingleView: FC<Props> = (props): JSX.Element => {
  const landlordId = props.params.landlordId;
  const basePath = `/biz/mastertool/${landlordId}`;
  const TABS = landlordId
    ? [
        { label: 'Landlord', to: basePath },
        { label: 'Consent', to: `${basePath}/consent` },
        { label: 'Scraper', to: `${basePath}/scraper` },
        { label: 'Dwellings', to: `${basePath}/dwellings` }
      ]
    : [{ label: 'Landlord', to: '/biz/mastertool/new' }];
  return (
    <>
      <Tabs tabs={TABS} />
      {props.children}
    </>
  );
};

export default SingleView;
