import React from 'react';
import { Route, IndexRoute } from 'react-router';

import { session as validateSession } from './redux/modules/auth';

import dk from './dk/routes';
import r6 from './r6/routes';
import akutbolig from './akutbolig/routes';
import boligbesked from './boligbesked/routes';
import boligninja from './boligninja/routes';
import reports from './reports/routes';
import biz from './biz/routes';
import microservices from './microservices/routes';

import App from './App';
import Scrapers from './scrapers/containers/Scrapers';
import Dashboard from './pages/dashboard';
import User from './auth/User';
import Users from './auth/Users';
import { roles } from '../shared/auth/permissions';

export default function getRoutes(store) {
  let session = null;
  function getSession() {
    return Promise.resolve(store.getState()).then(({ auth: { user } }) => {
      if (user.loggedIn === true) {
        return user;
      }

      return (session =
        session ||
        store
          .dispatch(validateSession())
          .then(({ payload: { result: user } }) => {
            if (!user || !user.token) {
              throw new Error('Invalid or expired user token');
            }
            return {
              ...user,
              loggedIn: true
            };
          }));
    });
  }

  const authCheck = (nextState, replace, done) => {
    getSession()
      .catch((err) => {
        if (__DEV__) {
          console.error(err);
        }
      })
      .then(() => done());
  };

  const requireLogin = (nextState, replace, done) => {
    getSession()
      .catch((err) => {
        if (__DEV__) {
          console.error(err);
        }
        replace('/');
      })
      .then(() => done());
  };

  const requireRole = (role) => {
    return (nextState, replace, done) => {
      getSession()
        .then((user) => {
          const currentRole = user.role;
          const requiredLevel = roles.indexOf(role);
          if (roles.indexOf(currentRole) < requiredLevel) {
            throw new Error('Required authorization not granted');
          }
        })
        .catch((err) => {
          if (__DEV__) {
            console.error(err);
          }
          replace('/');
        })
        .then(() => done());
    };
  };

  return (
    <Route path="/" component={App} onEnter={authCheck} name="Dashboard">
      <Route onEnter={requireLogin}>
        <Route path="scrapers" component={Scrapers} name="Scrapers" />

        {dk}
        {r6}
        {akutbolig}
        {boligbesked}
        {boligninja}
        {reports}
        {biz}
        {microservices}

        <Route path="user" component={User} name="User" />
        <Route path="auth" onEnter={requireRole('manager')} name="Admin">
          <Route path="users(/:id)" component={Users} name="Users" />
        </Route>
      </Route>
      <IndexRoute component={Dashboard} />
    </Route>
  );
}
