import React from 'react';
import { Route, IndexRoute } from 'react-router';

import ListView from './ListView';
import SingleView from './SingleView';
import singleLandLord from './SingleView/routes';

export default (
  <>
    <Route path="new" component={SingleView} name="new">
      {singleLandLord}
    </Route>
    <Route path=":landlordId" component={SingleView}>
      {singleLandLord}
    </Route>
    <IndexRoute component={ListView} />
  </>
);
