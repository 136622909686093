import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

const httpLink = createHttpLink({ uri: '/api/graph/v1/dk' });
const middlewareLink = new ApolloLink((operation, forward) => {
  const lsUser = global.localStorage.getItem('user');
  const user = lsUser ? JSON.parse(lsUser) : null;

  operation.setContext({
    headers: {
      authorization: user && user.token ? `Bearer ${user.token}` : undefined
    }
  });
  return forward(operation);
});

const link = middlewareLink.concat(httpLink);

export const client = new ApolloClient({
  link,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    }
  },
  cache: new InMemoryCache({
    addTypename: false
  })
});
