import React, { Component } from 'react';
import { Link } from 'react-router';

import { formatDateStr } from '../user-helper';

export default class AuditTableRow extends Component {
  shouldComponentUpdate() {
    return false;
  }

  renderAction() {
    const { action, timestamp, data } = this.props.item;
    try {
      switch (action) {
        case 'user-undo-deletion': {
          const json = JSON.parse(data);
          return (<span title={`Reason: ${json.reason}`}>
            {action}
          </span>);
        }
        case 'user-set-for-deletion': {
          const json = JSON.parse(data);
          return (<span title={`Reason: ${json.reason}`}>
            {action}
          </span>);
        }
        case 'email/weekly_recommendation': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={`Mail: ${json.email} - Antal Lejemål: ${json.dwellingIds.length || 0}`}>
              {action}
            </span>
          );
        }
        case 'email/boligbesked': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={`Lejemål: ${json.leaseId} - Mail: ${json.email}${json.criteria ? ' - Criteria:' + json.criteria: ''}`}>
              {action}
            </span>
          );
        }
        case 'sms/boligbesked': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={`Lejemål: ${json.leaseId} - Tlf: ${json.cellphone}`}>
              {action}
            </span>
          );
        }
        case 'user-resend-confirmation': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={json.cellphone ? `Tlf: ${json.cellphone}` : `Email: ${json.email}`}>
              {action}
            </span>
          );
        }
        case 'email/subscription/changed': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={`Email: ${json.email} - Current: ${json.current} - Next: ${json.next} - Previous: ${json.previous}`}>
              {action}
            </span>
          );
        }
        case 'email/subscription/deactivated':
        case 'email/subscription/reactivated':
        case 'email/subscription/activated': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={`Email: ${json.email} - Subscription: ${json.id || json.uuid}`}>
              {action}
            </span>
          );
        }
        case 'email/password-reset':
        case 'email/verify-email-change':
        case 'email/verify-email-resend':
        case 'email/verify-email-reminder':
        case 'email/verify-email': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={`Email: ${json.email}`}>
              {action}
            </span>
          );
        }
        case 'dwelling-contact': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span>
              contact:{' '}
              <Link to={`/dk/dwellings/${json.dwelling}`}>{json.dwelling}</Link>{' '}
              <small>({json.status})</small>
            </span>
          );
        }
        case 'user-login': {
          const json = JSON.parse(data);
          return !json ? (
            action
          ) : (
            <span title={json.userAgent}>
              <a
                href={`https://clicky.com/stats/visitors?site_id=66577212&date=${formatDateStr(
                  timestamp
                ).substr(0, 10)}&custom[userid]=${this.props.user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                login
              </a>{' '}
              <small>({json.strategy})</small>
            </span>
          );
        }
      }
    } catch (e) {
      if (__DEV__) {
        console.error('[AuditTableRow]', e);
      }
    }
    return action;
  }

  render() {
    return (
      <tr>
        <td>
          <small>{formatDateStr(this.props.item.timestamp)}</small>
        </td>
        <td>{this.renderAction()}</td>
      </tr>
    );
  }
}
