import React, { Component } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid
} from 'recharts';

export default class Chart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={this.props.data}
          margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis {...this.props.XAxis || {}} />
          <YAxis {...this.props.YAxis || {}} />
          <Tooltip />
          <Legend />
          {this.props.bars.map((bar, idx) => {
            return (
              <Bar {...bar}/>
            );
          })}
        </BarChart>
      </ResponsiveContainer> );
  }
}
