import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Checkbox
} from 'react-bootstrap';

export default class FormInput extends Component {
  static displayName = 'FormInput';

  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired
  };

  getValue() {
    return ReactDOM.findDOMNode(this._ref).value;
  }

  render() {
    const { id, label, help, inline, ...props } = this.props;

    const additionalProps = {};
    if (['select', 'textarea'].includes(props.type)) {
      additionalProps.componentClass = props.type;
    }

    if (props.type === 'checkbox') {
      if (inline) {
        return (
          <Checkbox
            {...additionalProps}
            {...props}
            ref={(node) => (this._ref = node)}
          >
            {label}
          </Checkbox>
        );
      }

      return (
        <FormGroup className="row" controlId={id}>
          <Col componentClass={ControlLabel} sm={4}>
            {label}
          </Col>
          <Col sm={8}>
            <Checkbox
              {...additionalProps}
              {...props}
              ref={(node) => (this._ref = node)}
            >
              &nbsp;
            </Checkbox>
            {help && <HelpBlock>{help}</HelpBlock>}
          </Col>
        </FormGroup>
      );
    }

    if (props.type === 'static') {
      return (
        <FormGroup className="row" controlId={id}>
          <Col componentClass={ControlLabel} sm={4}>
            {label}
          </Col>
          <Col sm={8}>
            <p className="form-control-static">{props.defaultValue}</p>
          </Col>
        </FormGroup>
      );
    }

    return (
      <FormGroup className="row" controlId={id}>
        <Col componentClass={ControlLabel} sm={4}>
          {label}
        </Col>
        <Col sm={8}>
          <FormControl
            {...additionalProps}
            {...props}
            ref={(node) => (this._ref = node)}
          />
          {help && <HelpBlock>{help}</HelpBlock>}
        </Col>
      </FormGroup>
    );
  }
}
