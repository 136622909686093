import apiClient from 'app/utils/api-client';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import Table from 'app/components/mui/Table';

const columns = [
  { label: 'UserId', name: 'user', valueType: 'string' },
  { label: 'Recieved', name: 'total', valueType: 'number' },
  { label: 'Clicked', name: 'clicked', valueType: 'number' }
];

export default class SmsBoligbeskedDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      report: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
      if(!this.props.params) {
          return;
      }
      this.setState({ report: null, loading: true });
      const report = await apiClient.post('/api/reports/boligbeskeder/details', { data: this.props.params });
      this.setState({ report, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  render() {
    const { loading, report } = this.state;

    if(loading) {
        return <Loading/>;
    }

    return (
    <Table
      title={''}
      columns={columns}
      limitRow={25}
      rows={report?.details || []}
    />);
  }
}