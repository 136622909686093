import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'app/components/mui/utils/colors';
// import { amber, green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      margin: theme.spacing(1)
    },
    accepted: {
      backgroundColor: colors.green[500],
      '&:hover': {
        backgroundColor: colors.green[700]
      }
    },
    pending: {
      backgroundColor: colors.amber[500],
      '&:hover': {
        backgroundColor: colors.amber[700]
      }
    },
    rejected: {
      backgroundColor: colors.red[500],
      '&:hover': {
        backgroundColor: colors.red[700]
      }
    }
  };
});

const ActionButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      size="small"
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      className={[classes.button, classes[props.className]].join(' ')}
      variant="contained"
      color="primary"
    >
      {props.label}
    </Button>
  );
};

export default ActionButton;
