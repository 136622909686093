import React from 'react';
import LineChart from './lineChart';
import BarChart from './barChart';
import PieChart from './pieChart';
import { createDetails } from '../details/createDetails';
import { compareableContent } from './compareableChart';
import { createCharData, deserializeDataset } from './utils';
import { tableContent } from './tableChart';

export default function createChart(report, options, withoutPre = false) {
  const inputData = report?.dataset || report;
  const details = report?.details || [];
  const opts = report?.options || options;
  const detailBeforeCharts = opts?.detailsBeforeCharts || false;

  const detailContent = details.length ? (<div>{details.map((detail) => {
        return createDetails(opts?.details, detail);
      })}</div>): opts?.details?.length ? (<div>{createDetails(opts?.details)}</div>) : null;

  if(opts.compareable && /#dataset/.test(report?.dataset)) {
   return compareableContent(report, opts, withoutPre);
  }

  const table = opts.table && /#dataset/.test(report?.dataset) ? tableContent(report, opts, withoutPre) : null;

  return (
    <>
      {detailContent && detailBeforeCharts ? detailContent: null}
      {(opts.charts || [opts.chart]).map((chart, idx) => {
        const chartData = createCharData(inputData, opts, chart);
        switch (chart.type) {
          case 'BarChart':
            return <BarChart key={idx} {...chart} data={chartData} />;
          case 'PieChart':
            return <PieChart key={idx} {...chart} data={chartData} />;
          case 'LineChart':
          default:
            return <LineChart key={idx} {...chart} data={chartData} />;
        }
      })}
      <hr />
      {table ? table : null}
      {detailContent && !detailBeforeCharts ? detailContent: null}
     {withoutPre ? null : <pre>{deserializeDataset(inputData, false)}</pre>}
    </>
  );
}
