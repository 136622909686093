import React from 'react';
import LinkModal from './LinkModal';
import FileUpload from './FileUpload';
import LinksUpload from './LinksUpload';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const LinkUpload = (props) => {
  const classes = useStyles({});

  return (
    <LinkModal open={props.open} onClose={props.onRequestClose}>
      <h2 id="simple-modal-title" style={{ marginBottom: '1em' }}>
        {props.title}
      </h2>
      <FileUpload
        classes={classes}
        inputLabel="Upload file"
        onSuccess={props.onSuccess}
        onError={props.onError}
      />
      <hr />
      <LinksUpload
        classes={classes}
        onSuccess={props.onSuccess}
        onError={props.onError}
      />
    </LinkModal>
  );
};

export default LinkUpload;
