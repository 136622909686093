import React, { Component } from 'react';
import {
  ResponsiveContainer,
  // ComposedChart,
  LineChart,
  // Area,
  Line,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  ReferenceLine,
  CartesianGrid
} from 'recharts';

function getMax({ data, lines }, yAxisId) {
  const values = lines
    .filter((line) => line.yAxisId === yAxisId)
    .map((line) => data.map((row) => row[line.dataKey]).filter(Boolean));
  return values.length
    ? Math.ceil(Math.max(0, ...values.flat(Infinity)) * 1.1)
    : 'auto';
}

function getMin({ data, lines }, yAxisId) {
  const values = lines
    .filter((line) => line.yAxisId === yAxisId)
    .map((line) => data.map((row) => row[line.dataKey]).filter(Boolean));
  return values.length
    ? Math.floor(Math.min(0, ...values.flat(Infinity)) * 1.1)
    : 'auto';
}

export default class Chart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={this.props.data}
          margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis {...this.props.XAxis} />
          <YAxis
            yAxisId="left"
            domain={[getMin(this.props), getMax(this.props)]}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            domain={[getMin(this.props, 'right'), getMax(this.props, 'right')]}
          />
          <Tooltip />
          <Legend />
          <ReferenceLine
            y={0}
            yAxisId="left"
            stroke="red"
            strokeDasharray="3 3"
          />
          {this.props.lines.map((line, idx) => {
            return (
              <Line
                type="monotone"
                dot={false}
                key={line.dataKey || idx}
                yAxisId="left"
                {...line}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
