import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const UploadButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClick}
      variant="contained"
      color="default"
      className={classes.button}
    >
      {props.loading ? (
        <CircularProgress />
      ) : (
        <>
          {props.label}
          <CloudUploadIcon className={classes.rightIcon} />
        </>
      )}
    </Button>
  );
};

export default UploadButton;
