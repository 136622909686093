import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { getUsers } from '../redux/modules/boligninja';
import { Table } from 'react-bootstrap';

const MAX_ROWS = 100;

class Users extends Component {
  static fetchData({ getState, dispatch }) {
    if (!getState().boligninja.users.length) {
      return dispatch(getUsers());
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      rows: props.rows ? props.rows.slice(0, MAX_ROWS) : []
    };
  }

  onFilterChange = (ev) => {
    const { name, value } = ev.target;

    const filter = {
      ...this.state.filter,
      [name]: value ? new RegExp(value, 'i') : null
    };

    const rows = this.props.rows
      .filter((user) => {
        let valid = true;
        Object.keys(filter).forEach((key) => {
          if (filter[key] && !filter[key].test(user[key] || '')) {
            valid = false;
          }
        });
        return valid;
      })
      .slice(0, MAX_ROWS);

    this.setState({ filter, rows });
  };

  render() {
    const { rows } = this.state;

    return (
      <div>
        <p>{`Showing ${rows.length} of ${this.props.rows.length} users`}</p>
        <Table striped condensed hover responsive>
          <thead>
            <tr>
              <th>Id</th>
              <th>Email</th>
              <th>Name</th>
              <th>Provider</th>
            </tr>
            <tr>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="_id"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by id"
                />
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="email"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by email"
                />
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="name"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by name"
                />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {rows.map((user) => {
              const status = user.paymill ? 'success' : '';
              return (
                <tr key={user._id} className={status}>
                  <td>
                    <Link to={`/boligninja/users/${user._id}`}>
                      {user._id}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/boligninja/users/${user._id}`}>
                      {user.email}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/boligninja/users/${user._id}`}>
                      {user.name}
                    </Link>
                  </td>
                  <td>{user.provider}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect(({ boligninja: { users } }) => ({ rows: users }), {
  getUsers
})(Users);
