import React from 'react';

export type DetailParam = {
  tag: string;
  text: string;
  params: string[];
  rows: string[][];
  headers: string[];
}

export function createDetails(details: DetailParam[] = [], params?: any): any {
  return details.length ? (
      <div>
        {details.map((detail) => {
            const text = detail.params?.length && params ? detail.params.reduce((acc, param, idx) => {
             return params[param] ? acc.replace(`$${idx+1}`, String(params[param])) : acc;
            }, detail.text) : detail.text || null;

            if(/\$[0-9]+/g.test(text)) {
              return null;
            }

            const { headers = [], rows = [] } = detail;

            switch(detail.tag) {
                case 'h3':
                return <h3>{text}</h3>;
                case 'h4':
                return <h4>{text}</h4>;
                case 'p':
                return <p>{text}</p>;
                case 'table':
                  const headerData = headers.map((header) => {
                    return <th>{header}</th>;
                  });
                  const rowData = rows?.map((row) => {
                      const data = row.map((data) => <td>{data}</td>);
                      return <tr> {data} </tr>;
                    });
                  return <table className="table table-striped table-condensed"><thead>{headerData}</thead><tbody>{rowData}</tbody></table>;
            }
        }).filter(Boolean)}
        <hr />
      </div>
    ) : (<div/>);
}
