import React from 'react';
import { browserHistory } from 'react-router';
import DwellingsTable from './dwellings-table';
import { GraphQLTableContainer } from '../../../common/table';

const LINK_PATH = '/dk/dwellings/';

class DwellingsDK extends GraphQLTableContainer {
  findById = (ev) => {
    ev.preventDefault();
    const site = ev.target.site.value;
    const id = ev.target.id.value;

    const dwellingId = site === 'boligninja' ? parseInt(id, 16) : id;

    browserHistory.push(`${LINK_PATH}${dwellingId}`);

    return false;
  };

  render() {
    return (
      <div>
        <form onSubmit={this.findById}>
          <label>
            Find lejemål ud fra ID&nbsp;
            <input name="id" />
            <select name="site">
              <option>akutbolig</option>
            </select>
          </label>
        </form>

        <hr />

        <DwellingsTable />
      </div>
    );
  }
}

export default DwellingsDK;
