import React from 'react';
import agentsQuery from './agents.graphql';
import { GraphQLTableContainer } from '../../../common/table';
import { Query } from 'app/components/mui/Query';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

class AgentsDK extends GraphQLTableContainer {
  static defaultProps = {
    asyncFilter: false
  };

  render() {
    const columns = [
      { label: 'Id', name: 'id' },
      {
        label: 'Name',
        onClick: this.sortBy,
        'data-column': 'name',
        name: 'name'
      },
      { label: 'Phone', name: 'phone' },
      { label: 'Email', name: 'email' },
      { label: 'Max dwellings', name: 'maxItems' },
      { label: 'Scraper', name: 'identifier', link: 'identifier', simpleLinkText: 'Settings', valueType: 'string', url: '/dk/agents/scraper-settings' }
    ];

    return (
      <Query
        query={agentsQuery}
        variables={{
          limit: 1000,
          offset: Number(this.props.location.query.offset),
          search: this.props.location.query.search
        }}
      >
        {({ agents }) => {
          return (
            <div>
              <div style={{display: 'flex'}}>
                <Button label={'Page 1'} onClick={() => {
                  this.props.router.push('/dk/agents');
                }}/>
                <Button label={'Page 2'} onClick={() => {
                  this.props.router.push('/dk/agents?offset=' + 1000);
                }}/>
              </div>
              <Table
              title={'Agents'}
              url="/dk/agents"
              createNew={
                <Button
                  style={{ height: '3em', margin: '.6em' }}
                  onClick={() => this.props.router.push('/dk/agents/new')}
                  label={'Create'}
                  size={'small'}
                />
              }
              columns={columns}
              rows={agents}
            />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default AgentsDK;
