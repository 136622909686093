import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import transactionsQuery from './transactions.graphql';

import Loading from 'app/common/loading';

import { formatDateStr } from './user-helper';

import { GraphQLTableContainer } from 'app/common/table';

import client from 'app/utils/api-client';

class Transactions extends GraphQLTableContainer {
  static defaultProps = {
    asyncFilter: false
  };

  static propTypes = {
    loading: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      rows = [],
      loading,
      location: { query }
    } = this.props;
    return (
      <div>
        <div className="table-responsive">
          <table className="table table-striped table-condensed table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>Subscription</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Provider</th>
                <th>Captured At</th>
                <th>Invoices</th>
              </tr>
              <tr>
                <th />
                <th />
                <th>
                  <select
                    onChange={this.onAsyncFilterChange}
                    defaultValue={query.status || ''}
                    name="status"
                    className="form-control"
                    style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  >
                    {['', 'captured', 'refunded', 'failed'].map((val) => (
                      <option key={val}>{val}</option>
                    ))}
                  </select>
                </th>
                <th />
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {rows.map((transaction) => {
                const {
                  id,
                  status,
                  amount,
                  currency,
                  provider,
                  capturedAt,
                  subscription,
                  invoices
                } = transaction;
                return (
                  <tr
                    key={id}
                    className={
                      status === 'captured'
                        ? 'success'
                        : status === 'pending'
                        ? 'warning'
                        : status === 'error'
                        ? 'danger'
                        : 'default'
                    }
                  >
                    <td>{id}</td>
                    <td>{subscription.id}</td>
                    <td>{status}</td>
                    <td style={{ textAlign: 'right' }}>
                      {(amount / 100).toFixed(0)} {currency}
                    </td>
                    <td>{provider}</td>
                    <td>{capturedAt && formatDateStr(capturedAt)}</td>
                    <td>
                      {invoices.map((invoice) => {
                        return (
                          <i
                            className="fa fa-fw fa-file-pdf-o"
                            style={{ color: 'red' }}
                            onClick={() => {
                              client.get('/api/auth/nonce').then((res) => {
                                if (res && res.status === 'OK') {
                                  global.location.href = `/api/invoices/akutbolig/${invoice}.pdf?nonce=${res.nonce}`;
                                }
                              });
                            }}
                          />
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {loading ? <Loading /> : null}
        </div>
      </div>
    );
  }
}

export default graphql(transactionsQuery, {
  options: (props) => {
    const { status } = props.location.query;
    return {
      variables: {
        limit: 250,
        sort: '-id',
        status: status || undefined
        // email: email || undefined
      }
    };
  },
  props: ({ ownProps, data: { loading, transactions, refetch } }) => ({
    ...ownProps,
    loading,
    rows: transactions || [],
    refetch
  })
})(Transactions);
