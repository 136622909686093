import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import update from 'immutability-helper';

import updateTransactionQuery from '../transaction-update.graphql';

import { Panel, Table } from 'react-bootstrap';
import ConfirmationButton from 'app/common/confirmation/button';

import { formatDateStr } from '../user-helper';

import { titleCase } from 'app/utils/format';

import TrailPanel from './trail';

// TODO: bsStyle based on transaction.status (green / yellow / red)

import client from 'app/utils/api-client';
import { fetcher } from 'app/utils/fetcher';
import { trailFragments } from '../fragements';

const MobilepayDelete = `mutation DeleteTransaction($providerId: String!, $subscription: String!) {
  mobilepayDeleteTransaction(
    providerId: $providerId
    subscription: $subscription
  )
}
`;
const MobilepayRecreate = `mutation RecreateTransaction($transaction: String!) {
  mobilepayRecreateTransaction(transaction: $transaction)
}`;

const DeclineTransaction = `mutation DeclineTransaction($transaction: String!) {
  declineTransaction(transaction: $transaction)
}
`;

const REFUND = `
  mutation RefundTrans($input: RefundInput!) {
    _admin_refundTransaction(input: $input) {
      id
      status
      provider
      amount
      currency
      balance
      subscription
      providerId
      meta
      trail { ${trailFragments} }
      card {
        bin
        last4
        expiry
        scheme
      }
      error {
        timestamp
        code
        client  
        merchant
        retryAt
      }
      createdAt
      capturedAt
    }
  }
`;

class TransactionPanel extends Component {
  static propTypes = {
    collapsible: PropTypes.bool,
    item: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      transaction: props.item,
      subscription: props.subscription
    };

    this.fieldHelpers = {
      status: (val) => (
        <span>
          {val}
          {['Rejected', 'Overdue', 'Failed', 'Created'].includes(val) ?
          <ConfirmationButton
            label="Decline"
            onSubmit={ async(confirmed, values) => {
              const { id } = this.state.transaction || {};
              const { notes } = values;
              if(notes && id && confirmed) {
                await fetcher('/api/graph/dk/v1', {
                  query: DeclineTransaction,
                  variables: { transaction: id }
                });
              }
            }}
            text="Please confirm, you want to decline the transaction"
            bsStyle="danger"
            bsSize="xs"
            inline
            className="pull-right"
          >
            <div className="form-group">
              <label>Reason / internal notes</label>
              <textarea
                className="form-control"
                name="notes"
                rows="4"
                required
                minLength="10"
              />
            </div>
          </ConfirmationButton> : null}
        </span>
      ),
      card: (_, { card }) => (
        <span style={{ fontFamily: 'monospace' }}>
          {card.bin.substr(0, 4)} {card.bin.substr(4, 6)}
          ** **** {card.last4} <i className={`fa fa-fw fa-cc-${card.scheme}`} />
        </span>
      ),
      amount: (val, txn) => (
        <span>
        {(val / 100).toFixed(2).replace('.', ',')} {txn.currency}
        {['Capture'].includes(txn.status) && !txn.balance ? <ConfirmationButton
            label="Refund"
            onSubmit={this.refund}
            text="Please confirm, you want to refund the transaction"
            bsStyle="danger"
            bsSize="xs"
            inline
            className="pull-right"
          >
            <div>
              <div className="form-group">
                <label>Amount to refund</label>
                <div className="input-group">
                  <input
                    name="refund"
                    type="number"
                    pattern="^[0-9]+$"
                    max={val / 100}
                    defaultValue="0"
                    className="form-control"
                    placeholder="Amount"
                  />
                  <div className="input-group-addon">kr.</div>
                </div>
              </div>
              <div className="form-group">
                <label>Reason / internal notes</label>
                <textarea
                  className="form-control"
                  name="notes"
                  rows="4"
                  required
                  minLength="10"
                />
              </div>
            </div>
          </ConfirmationButton> : null}
        </span>
      ),
      balance: (val, txn) => (
        <span>
          {(val / 100).toFixed(2).replace('.', ',')} {txn.currency}
          {['Capture'].includes(txn.status) ? <ConfirmationButton
            label="Refund"
            onSubmit={this.refund}
            text="Please confirm, you want to refund the transaction"
            bsStyle="danger"
            bsSize="xs"
            inline
            className="pull-right"
          >
            <div>
              <div className="form-group">
                <label>Amount to refund</label>
                <div className="input-group">
                  <input
                    name="refund"
                    type="number"
                    pattern="^[0-9]+$"
                    max={val / 100}
                    defaultValue="0"
                    className="form-control"
                    placeholder="Amount"
                  />
                  <div className="input-group-addon">kr.</div>
                </div>
              </div>
              <div className="form-group">
                <label>Reason / internal notes</label>
                <textarea
                  className="form-control"
                  name="notes"
                  rows="4"
                  required
                  minLength="10"
                />
              </div>
            </div>
          </ConfirmationButton> : null}
        </span>
      ),
      invoices: (invoices) =>
        invoices.map((invoice) => (
          <i
            key={invoice}
            className="fa fa-fw fa-file-pdf-o"
            style={{ color: 'red' }}
            onClick={() => {
              client.get('/api/auth/nonce').then((res) => {
                if (res && res.status === 'OK') {
                  global.location.href = `/api/invoices/akutbolig/${invoice.id}.pdf?nonce=${res.nonce}`;
                }
              });
            }}
          />
        )),
      provider: (val, txn) => {
        const pending = ['Pending'].includes(txn.status);
        return (<span>
              {`${val}(${txn.providerId})`}
              {val === 'MOBILEPAY' && !['Capture', 'Refunded', 'Declined', 'Expired'].includes(txn.status) ? 
              <ConfirmationButton
                label={pending ? 'Annullere' : 'Opret'}
                onSubmit={async (confirmed, values) => {
                  const { id, providerId } = this.state.transaction || {};
                  const subscription = this.state?.subscription;
                  const { notes } = values;
                  if(notes && id && subscription && confirmed) {
                    if(pending && !providerId) {
                      console.log('Failed due to no providerId to delete');
                      return null;
                    }
                    const data = await fetcher('/api/graph/dk/v1', {
                      query: pending ? MobilepayDelete : MobilepayRecreate,
                      variables: pending ? { providerId, subscription } : { transaction: id }
                    });
                  }
                }}
                text={`Please confirm, you want to ${pending ? 'cancel' : 'create'} this transaction from mobilepay`}
                bsStyle={pending ? 'danger' : 'success'}
                bsSize="xs"
                inline
                className="pull-right"
              >
                <div>
                  <div className="form-group">
                    <label>Reason / internal notes</label>
                    <textarea
                      className="form-control"
                      name="notes"
                      rows="4"
                      required
                      minLength="10"
                    />
                  </div>
                </div>
              </ConfirmationButton> : null}
            </span>);
        },
      createdAt: (val) => formatDateStr(val),
      updatedAt: (val) => formatDateStr(val),
      capturedAt: (val) => formatDateStr(val),
      dueDate: (val) => formatDateStr(val)
    };
  }

  refund = async (confirmed, values) => {
    const { meta, id } = this.state.transaction;
    const { refund, notes } = values;
    if (refund) {
      if (meta?.legacyId) {
        // convert to minor units, using * 100
        this.props.updateTransaction({ refund: refund * 100 }, notes);
        return;
      }
      const data = await fetcher('/api/akutbolig/graph-proxy', {
        query: REFUND,
        variables: {
          input: {
            transactionId: id,
            amount: Number(refund * 100),
            meta: { message: values.notes }
          }
        }
      });
      if (data?._admin_refundTransaction) {
        this.setState({
          transaction: data._admin_refundTransaction
        });
      }
    }
  };

  render() {
    const { collapsible } = this.props;
    const { transaction } = this.state;

    const panelProps = {
      collapsible,
      bsStyle:
        transaction.status === 'Capture'
          ? 'success'
          : transaction.status === 'Failed'
          ? 'danger'
          : transaction.status === 'Pending'
          ? 'warning'
          : 'warning',
      header: (
        <h4>
          Transaction <small>{transaction.id}</small>
        </h4>
      )
    };

    return (
      <Panel {...panelProps}>
        <Table striped condensed responsive hover fill>
          <tbody>
            {[
              'status',
              'createdAt',
              'updatedAt',
              'capturedAt',
              'dueDate',
              'amount',
              'balance',
              'invoices',
              'card',
              'provider'
            ].map((key) => {
              const value = transaction[key];
              const helper = this.fieldHelpers[key];
              return value ? (
                <tr key={key}>
                  <th>{titleCase(key)}</th>
                  <td>{helper ? helper(value, transaction) : value}</td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>

        {transaction.error ? (
          <Panel bsStyle="danger" header="Transaction error">
            <Table striped condensed responsive hover fill>
              <tbody>
                <tr>
                  <th colSpan="2">
                    {transaction.error.code}: {transaction.error.message}
                  </th>
                </tr>
                <tr>
                  <th>Timestamp</th>
                  <td>{formatDateStr(transaction.error.timestamp)}</td>
                </tr>
                <tr>
                  <th>Client / Merchant</th>
                  <td>
                    {transaction.error.client ? '1' : '0'} /{' '}
                    {transaction.error.merchant ? '1' : '0'}
                  </td>
                </tr>
                {transaction.error.retryAt ? (
                  <tr>
                    <th>Retry at</th>
                    <td>{formatDateStr(transaction.error.retryAt)}</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </Panel>
        ) : null}

        <TrailPanel trail={transaction.trail} />
      </Panel>
    );
  }
}

export default compose(
  // mutate subscription
  graphql(updateTransactionQuery, {
    options: (props) => ({
      variables: {
        transactionId: props.item.id,
        data: {},
        notes: ''
      }
    }),
    props: ({ ownProps, mutate }) => ({
      ...ownProps,
      updateTransaction: (data, notes) =>
        mutate({
          variables: { data, notes },
          updateQueries: {
            User: (prev, args) => {
              const { mutationResult } = args;
              const transaction = mutationResult.data.updateTransaction;

              let next = false;

              prev.user.subscriptions.forEach((subscription, i) => {
                const j = subscription.transactions.reduce((acc, txn, idx) => {
                  return txn.id === transaction.id ? idx : acc;
                }, null);
                if (j !== null) {
                  next = {
                    user: {
                      subscriptions: {
                        [i]: {
                          transactions: {
                            [j]: { $set: transaction }
                          }
                        }
                      }
                    }
                  };
                }
              });

              if (!next) {
                throw new Error(
                  'GraphQL merge failed as transaction could not be found'
                );
              }

              return update(prev, next);
            }
          }
        })
    })
  })
)(TransactionPanel);
