import React from 'react';

import { hoursPerStatus } from './helpers';
import { Chip } from '@material-ui/core';

function toFixed(numb = 0) {
  return numb === Math.round(numb) ? numb : numb.toFixed(2).replace('.', ',');
}

type Props = {
  getUserName: (string) => string;
};

class WorkingHoursInfo extends React.PureComponent<Props> {
  render() {
    const { shifts, editMode } = this.props;
    const hours = hoursPerStatus(shifts);
    const users = Object.keys(hours);
    users.sort((a, b) => {
      const left = this.props.getUserName(a);
      const right = this.props.getUserName(b);
      return left > right ? 1 : left === right ? 0 : -1;
    });

    return (
      <div style={{ width: '100%', overflow: 'auto' }}>
        <table className="mt-2 table table-sm">
          <tbody>
            <tr>
              <th />
              <th>Timer</th>
              {editMode ? (
                <>
                  <th>Afventer</th>
                  <th>Afvist</th>
                </>
              ) : (
                <>
                  <th>Pause</th>
                  <th>Spisning</th>
                </>
              )}
            </tr>
            {Object.keys(hours).map((user, idx) => {
              const statusHours = hours[user];
              return (
                <tr key={idx}>
                  <td className="list-inline-itemx">
                    <strong>{this.props.getUserName(user)}: </strong>
                  </td>

                  {(editMode
                    ? ['ACCEPTED', 'PENDING', 'REJECTED']
                    : ['ACCEPTED', 'lunchBreak', 'lunchScheme']
                  ).map((status, idx) => {
                    return (
                      <td key={idx}>
                        <Chip
                          size="small"
                          label={toFixed(statusHours[status])}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default WorkingHoursInfo;
