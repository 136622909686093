export function customDataFormat(type, name, value) {
  if(type === 'PieChart' && name === 'total') {
    return Number(value);
  }
  return value;
}

export function createDataSet(lines: string[], headers: string[], chartType: string) {
  return lines.map((line) => {
      const fields = line.split('\t');
      return headers.reduce((acc, name, idx) => {
        acc[name] = customDataFormat(chartType, name, fields[idx]);
        return acc;
      }, {});
    });
}

export function deserializeDataset(report, json = true) {
  return /#dataset/.test(report) ? json ? JSON.parse(report.replace('#dataset', '')): report.replace('#dataset', '') : report;
}

export function createCharData(report, options, chart) {
  if(chart.dataset) {
    const datasetName = chart.dataset;
    const dataSets = deserializeDataset(report);

    if(chart.json) {
      return dataSets ? dataSets[datasetName] : [];
    } else {
      return createDataSet(dataSets[datasetName].split('\n'), options.header, chart.type);
    }
  }
  return createDataSet(report.split('\n'), options.header, chart.type);
}