import React, { useState } from 'react';
import { connect } from 'react-redux';
import EmailBox from './disableEmails';
import { onPageNotify } from '../../redux/modules/notification';

type Props = {
  router: { push: (args: any) => void };
  onPageNotify: (options: {
    headline: string;
    description: string;
    bsStyle: string;
    dismissAfter: number;
  }) => void;
};

const DisableTargetTool: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, triggerReload] = useState(0);

  const onTriggerReload = () => {
    triggerReload(reload + 1);
  };


  return (
     <EmailBox
        title="Disable Emails"
        open={showModal}
        onRequestClose={() => setShowModal(false)}
        onSuccess={(res) => {
          onTriggerReload();
          props.onPageNotify({
            headline: 'Emails disabled',
            description: JSON.stringify(res),
            bsStyle: 'success',
            dismissAfter: 10000
          });
          setShowModal(false);
        }}
        onError={(err) => {
          if (typeof err === 'string') {
            props.onPageNotify({
              headline: 'Failed',
              description: err,
              bsStyle: 'danger',
              dismissAfter: 5000
            });
            return;
          }
          console.error(err);
          props.onPageNotify({
            headline: 'Failed',
            description: 'Something went wrong while fetching for API',
            bsStyle: 'danger',
            dismissAfter: 5000
          });
        }}
      />
  );
};

export default connect(() => ({}), { onPageNotify })(DisableTargetTool);
