import React from 'react';
import { Link as RouterLink } from 'react-router';

type Props = {
  outGoing?: boolean;
  text?: string;
  target?: string;
  rel?: string;
  href: string;
  style?: { [key: string]: any };
  onClick?: () => void;
};

export const Link: React.FC<Props> = (props): JSX.Element => {
  const { href, children, text, outGoing, ...rest } = props;
  const child = children ? children : text;
  return !outGoing ? (
    <RouterLink to={href} {...rest}>
      {child}
    </RouterLink>
  ) : (
    <a
      rel={'noopener noreferrer'}
      target={'_blank'}
      style={{}}
      href={href}
      {...rest}
    >
      {child}
    </a>
  );
};
