import { getCurrentUser } from 'app/utils/api-client';

import './notifications';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SESSION,
  SESSION_SUCCESS,
  SESSION_FAILED,
  LOGOUT,
  CREATE_USER_INIT,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  UPDATE_USER_INIT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CLEAR_UPDATE_USER_SUCCESS,
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_SETTINGS,
  CHANGE_PASSWORD
} from './actions';

export const ENDPOINT = '/api/auth';

const initialStateSettings = localStorage.getItem('settings')
  ? JSON.parse(localStorage.getItem('settings'))
  : { settings: { notifications: true, loggedIn: false } };

const defaultState = {
  user: {
    loggedIn: false
  },
  users: [],
  createUserSuccess: null,
  updateUserSuccess: null,
  settings: initialStateSettings
};

const initialState = localStorage.getItem('user')
  ? {
      ...defaultState,
      user: {
        ...JSON.parse(localStorage.getItem('user')),
        loggedIn: false
      }
    }
  : defaultState;

function persistUser({ user }) {
  localStorage.setItem('user', JSON.stringify(user));
}

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  if (
    type === LOGIN_SUCCESS ||
    (type === SESSION_SUCCESS && payload.status === 'OK')
  ) {
    const newState = {
      ...state,
      user: { ...payload.result, loggedIn: true }
    };
    persistUser(newState);
    return newState;
  } else if (
    type === LOGOUT ||
    type === LOGIN_FAILED ||
    type === SESSION_FAILED ||
    (type === SESSION_SUCCESS && payload.status !== 'OK')
  ) {
    persistUser(defaultState);
    return defaultState;
  } else if (type === CREATE_USER_SUCCESS) {
    return { ...state, createUserSuccess: payload };
  } else if (type === UPDATE_USER_SUCCESS) {
    return { ...state, updateUserSuccess: payload };
  } else if (type === CLEAR_UPDATE_USER_SUCCESS) {
    return { ...state, updateUserSuccess: payload };
  } else if (type === GET_USERS_SUCCESS) {
    return { ...state, users: payload };
  } else if (type === GET_SETTINGS) {
    const settings = { ...state.settings, ...payload };
    localStorage.setItem('settings', JSON.stringify(settings));
    return { ...state, settings };
  }
  return state;
}

export function setSettings(settings) {
  return {
    type: GET_SETTINGS,
    payload: settings
  };
}

export function createUser(data) {
  return {
    types: [CREATE_USER_INIT, CREATE_USER_SUCCESS, CREATE_USER_FAIL],
    promise: (client) =>
      client.post(`${ENDPOINT}/users`, { data }).then((data) => data.result)
  };
}

export function updateUser({ id, ...data }) {
  return {
    types: [UPDATE_USER_INIT, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL],
    promise: (client) =>
      client
        .post(`${ENDPOINT}/users/${id}`, { data })
        .then((data) => data.result)
  };
}

export function clearUpdateUserSuccess() {
  return {
    type: CLEAR_UPDATE_USER_SUCCESS,
    payload: false
  };
}

export function getUsers() {
  return {
    types: [GET_USERS_INIT, GET_USERS_SUCCESS, GET_USERS_FAIL],
    promise: (client) =>
      client.get(`${ENDPOINT}/users`).then((data) => data.results)
  };
}

export function login(data) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAILED],
    promise: (client) => client.post('/api/auth/login', { data })
  };
}

export function session() {
  return {
    types: [SESSION, SESSION_SUCCESS, SESSION_FAILED],
    promise: (client) => {
      const user = getCurrentUser();
      if (!user || !user.token) {
        throw new Error('Missing user token');
      }
      return client.get('/api/auth/me');
    }
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

export function changePassword(payload) {
  return {
    type: CHANGE_PASSWORD,
    payload
  };
}
