import apiClient from 'app/utils/api-client';

import { Panel, Table } from 'react-bootstrap';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import { Button, TableBody, TableRow } from '@material-ui/core';

export default class DetailTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      report: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
        if(!this.props.row) {
            return;
        }
      this.setState({ report: null, loading: true });
      const report = await apiClient.post('/api/dk/leaseReport', { data: { leaseId: this.props.row.leaseId, userId: this.props.row.userId }});
      const { lease, user } = report.result || {};
      this.setState({ lease, user, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  async deactivate() {
    try {
        if(!this.props.row) {
            return;
        }
      this.setState({ report: null, loading: true });
      const report = await apiClient.post('/api/dk/leaseReport/deactivate', { data: { leaseId: this.props.row.leaseId, userId: this.props.row.userId }});
      const { lease, user } = report.result || {};
      this.setState({ lease, user, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }

  }

  render() {
    const { loading, lease, user } = this.state;

    if(loading) {
        return <Loading/>;
    }

    if(!lease) {
      return <p>Error</p>;
  }
 

    return (<Panel>
        <Table condensed responsive hover>
            <TableBody>
                <TableRow key={'lease'}>
                    <td><a href={lease.contact.url}>{lease.contact.url}</a></td>
                </TableRow>
                <TableRow key={'user'}>
                    <td>User: {user?.name}</td>
                </TableRow>
                {lease.deletedAt ? null : 
                <TableRow key={'button'}>
                  {/rentals-/.test(lease?.agentUnique) ? 
                  <Button key='button-rental' variant="contained" href={'https://udlejer.akutbolig.dk/dwellings/' + lease.agentUnique.replace('rentals-', '') + '/preview'} >Go to Landlord-dashboard</Button> 
                  : <Button key='button-deactivate' variant="contained" onClick={() => this.deactivate()}>Deactivate</Button>}
                </TableRow>}
            </TableBody>
        </Table>
    </Panel>);
  }
}