import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

const MailLogColumns = [
  { label: 'Id', name: 'id', simpleLinkText: 'Details', valueType: 'string' },
  { label: 'Template', name: 'templateName', valueType: 'string' },
  { label: 'Recipients', name: 'recipients', valueType: 'stringify' },
  { label: 'Publisher', name: 'publisher', valueType: 'string' },
  { label: 'Executed', name: 'createdAt', valueType: 'date' }
];
const NotificationColumns = [
  { label: 'Id', name: 'id', simpleLinkText: 'Details', valueType: 'string' },
  { label: 'To', name: 'to', valueType: 'stringify' },
  { label: 'Action', key: 'type', name: 'trail', valueType: 'string', serialize: (trail) => trail[trail.length-1].type },
  { label: 'Timestamp', key: 'timestamp', name: 'trail', valueType: 'date', serialize: (trail) => trail[trail.length-1].timestamp }
];
const limitRow = 10;

export default class Mailer extends Component {
  constructor(props) {
    super(props);
    this.state = { boligbeskeder: true };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const { boligbeskeder } = this.state;
    apiClient.post('/api/microservice', { data: { action: 'Mailer', data: { boligbeskeder } } }).then((result) => {
      this.setState({ ...result?.data || {} });
    });
  }

  toggleBoligbeskeder() {
    const { boligbeskeder } = this.state;
    this.setState({ boligbeskeder: !boligbeskeder });
    this.refresh();
  }

  render() {
    const { latestExecuted = [], latestNotifications = [] } = this.state;
    return (
      <div>
        <div style={{ display: 'flex' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
          {this.toggleBoligbeskeder ? <Button style={{ margin: '6px' }} size={'small'} label={'Toogle BOLIGBESKEDER'} onClick={() => this.toggleBoligbeskeder()}/> : null}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Executed'}
                columns={MailLogColumns}
                limitRow={limitRow}
                hideable
                rows={latestExecuted}
              />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Notifications'}
                columns={NotificationColumns}
                limitRow={limitRow}
                hideable
                rows={latestNotifications}
              />
          </div>
        </div>
      </div>
    );
  }
}
