import { Mutation } from 'app/components/graphql/Mutation';
import React, { useEffect, useState } from 'react';
import updateGeographyPageMeta from './updateGeographyPageMeta.graphql';

type Props = {
    slug: string;
    type: string;
    active: boolean;
    metaTitle: string;
    metaDescription: string;
    headline: string;
    description: string;
};

const GeographyPageMeta = (props: Props) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [headline, setHeadline] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [saved, setSaved] = useState<boolean>(false);


  useEffect(() => {
    setTouched(false);
    setSaved(false);
    setMetaTitle(props.metaTitle);
    setMetaDescription(props.metaDescription);
    setHeadline(props.headline);
    setDescription(props.description);
    setActive(props.active);
  }, [props.slug, props.type]);

  const onChange = (value, setFunc) => {
    setFunc(value);
    setTouched(true);
    setSaved(false);
  };

  return (<Mutation mutation={updateGeographyPageMeta}>
        {({ mutate, options }) => {
            return (<div style={{ display: 'grid' }}>
        <h4 style={{ width: 'max-content' }}>Slug: {props.slug}  -  Type: {props.type}</h4>
        <div key={'active'} style={{ display: 'flex' }}>
            <label style={{ marginRight: '20px' }}>
            {'Active'}
            </label>
            <input
            style={{ alignSelf: 'baseline' }}
            key={'active'}
            name={'active'}
            type={'checkbox'}
            checked={active}
            onChange={(ev) => onChange(ev.target.checked, setActive)}
            />
            <button style={{ marginLeft: '12px' }} disabled={!props.slug || !props.type || !touched} onClick={() => {
                mutate({ variables: { input: [{ slug: props.slug, type: props.type, metaTitle, metaDescription, headline, description, active }]} }).then(({ data }) => {
                   setSaved(true);
                });
                setTouched(false);
            }}>Update</button>
            {saved && !touched ? <p style={{ marginLeft: '12px' }}> Page Meta Saved </p> : null}
        </div>
        <div key={'metaTitle'} style={{}}>
            <label style={{}}>
            {'Meta Title'}
            </label>
            <input
            style={{ width: '100%' }}
            key={'metaTitle'}
            name={'metaTitle'}
            type={'text'}
            value={metaTitle}
            onChange={(ev) => onChange(ev.target.value, setMetaTitle)}
            />
        </div>
        <div key={'metaDescription'} style={{}}>
            <label style={{}}>
            {'Meta Description'}
            </label>
            <input
            style={{ width: '100%' }}
            key={'metaDescription'}
            name={'metaDescription'}
            type={'text'}
            value={metaDescription}
            onChange={(ev) => onChange(ev.target.value, setMetaDescription)}
            />
        </div>
        <div key={'headline'} style={{}}>
            <label style={{}}>
            {'Headline'}
            </label>
            <input
            style={{ width: '100%' }}
            key={'headline'}
            name={'headline'}
            type={'text'}
            value={headline}
            onChange={(ev) => onChange(ev.target.value, setHeadline)}
            />
        </div>
        <div key={'description'} style={{}}>
            <label style={{}}>
            {'Description'}
            </label>
            <textarea
            style={{ width: '100%' }}
            key={'description'}
            name={'description'}
            rows={30}
            value={description}
            onChange={(ev) => onChange(ev.target.value, setDescription)}
            />
        </div>
    </div>);
        }}
    </Mutation>);
};

export default GeographyPageMeta;
