import React, { Component } from 'react';
import { Table, Button, Glyphicon } from 'react-bootstrap';
import lib from 'lib/format-date';

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, verifyUser } = this.props;
    const status = user.verification.status;
    return (
      <Table striped condensed bordered hover responsive>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{user._id}</td>
          </tr>

          <tr>
            <td>Name</td>
            <td>{user.name}</td>
          </tr>

          <tr>
            <td>Email</td>
            <td>{user.email}</td>
          </tr>

          {/*<tr>
            <td>Role</td>
            <td>{user.role}</td>
          </tr>*/}

          <tr>
            <td>Created</td>
            <td>{lib.dateFormatStr(user.createdAt)}</td>
          </tr>

          {user.deletedAt ? (
            <tr>
              <td>Deleted</td>
              <td>{lib.dateFormatStr(user.deletedAt)}</td>
            </tr>
          ) : null}

          <tr>
            <td>Provider</td>
            <td>{user.provider}</td>
          </tr>

          {user.paymill ? (
            <tr>
              <td>Paymill ID</td>
              <td>{user.paymill.id}</td>
            </tr>
          ) : null}

          <tr>
            <td>Verified</td>
            <td>
              {status ? (
                <i
                  className="fa fa-check-circle"
                  style={{ color: 'darkGreen' }}
                />
              ) : (
                <i
                  className="fa fa-times-circle"
                  style={{ color: 'rgb(220, 0, 0)' }}
                />
              )}
              &nbsp;
              <Button
                className="pull-right"
                bsSize="xsmall"
                bsStyle={status ? 'danger' : 'success'}
                onClick={() => verifyUser(user._id, !status)}
              >
                {status ? 'Unverify' : 'Verify'}
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default UserInfo;
