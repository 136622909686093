import React from 'react';
import { Paper } from '@material-ui/core';
import List from './List/List';
import { Title } from '../../Title';

export type LogData = {
  timestamp: string;
  name: string;
  meta: { [key: string]: string | number | boolean } | null;
};

type Props = {
  logData: LogData[];
  title: string;
  gridProps: {
    col: any;
  };
};

const Log = ({ logData, title }: Props): JSX.Element => {
  return (
    <Paper>
      <div style={{ padding: '6px 16px 12px' }}>
        <Title variant="h6" component="p" text={title} />
      </div>
      <List title={title} logs={logData.reverse()} />
    </Paper>
  );
};

export default Log;
