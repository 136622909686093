import React from 'react';
import apiClient from 'app/utils/api-client';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';
import SubscriptionStatsRow from './subscription-stats-row';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const ONE_DAY = 86400000;

const columns = [
  { label: 'User', name: 'accountId', valueType: 'string', url: 'https://cp.bbintern.net/akutbolig/users', link: 'accountId' },
  { label: 'Subscription', name: 'subscriptionId', valueType: 'string' },
  { label: 'Subscription LTV', name: 'lifeTimeValue', valueType: 'number', serialize: (value: number) => value / 100 },
  { label: 'Subscription Created', name: 'subscriptionCreated', valueType: 'date' },
  { label: 'Subscription Plan', name: 'subscriptionPlan', valueType: 'string' },
  { label: 'Subscription Provider', name: 'subscriptionProvider', valueType: 'string' },
  { label: 'Notification Source', name: 'notificationSource', valueType: 'string' },
  { label: 'Notification Indirect', name: 'notificationIndirectSource', valueType: 'boolean' },
  { label: 'Lease', name: 'leaseId', valueType: 'string', url: 'https://cp.bbintern.net/dk/dwellings', link: 'leaseId' },
  { label: 'Zip', name: 'zip', valueType: 'string' },
  { label: 'City', name: 'city', valueType: 'string' },
  { label: 'Rooms', name: 'rooms', valueType: 'number' },
  { label: 'Size', name: 'size', valueType: 'number' },
  { label: 'From Facebook', name: 'fromFacebook', valueType: 'boolean' },
  { label: 'Source', name: 'source', valueType: 'string' },
  { label: 'Source ID', name: 'sourceId', valueType: 'string', url: 'https://cp.bbintern.net/dk/agents', link: 'sourceId' },
];

function parseDate(date) {
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    return `${date.getFullYear()}-${month}-${day}`;
}

export const SubscriptionStatsDashboard = (props: Props): JSX.Element => {
    const now = new Date();
    const past = new Date(new Date().getTime() - 90 * ONE_DAY);
    const [list, setList] = React.useState([]);
    const [from, setFrom] = React.useState(parseDate(past));
    const [to, setTo] = React.useState(parseDate(now));

    const [total, setTotal] = React.useState(0);
    const [limit, setLimit] = React.useState('100');
    const [cursor, setCursor] = React.useState(null);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = (nextCursor?) => {
        setLoading(true);
         apiClient.post('/api/microservice', { data: { action: 'subscriptionStats', data: { filter: { from: from, to: to, limit: Number(limit), cursor: nextCursor } } } }).then((data) => {
          if(data) {
            const { result, cursor, total } = data;
            if(nextCursor) {
              setList([].concat(list, result));
            }else {
              setList(result);
            }
            setCursor(cursor);
            setTotal(total);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div>
        <span>From: </span>
        <TextField type="date" id="from" onChange={(ev) => setFrom(ev.target.value)} value={from} key="from" />
        <span>To: </span>
        <TextField type="date" id="to" onChange={(ev) => setTo(ev.target.value)} value={to} key="to" />
        <span>Load limit: </span>
        <Select value={limit} onChange={(ev) => setLimit(ev.target.value)}>
          <MenuItem value="25">25</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
          <MenuItem value="150">150</MenuItem>
        </Select>
        <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
        {cursor ? <Button style={{ margin: '6px' }} size={'small'} label={'Load more'} onClick={() => refresh(cursor)}/> : null}
        </div>
        { loading ? <p>Loading...</p> : !list?.length ? <p>No subscription stats</p> : ( <Table
            title={`Subscription Stats (${list.length}/${total})`}
            columns={columns}
            rows={list || []}
            detailOnClick={(row) => <SubscriptionStatsRow id={row.id}/> }
        />)}
        {list?.length && cursor ? <Button style={{ margin: '6px' }} size={'small'} label={'Load more'} onClick={() => refresh(cursor)}/> : null}
    </div>
  );
};
