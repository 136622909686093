import React from 'react';
import qs from 'qs';

function useHashState<S>({
  defaultValue,
  router,
  pathname = router?.location?.pathname
}): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = React.useState<S>(() => {
    const values = qs.parse(router.location.hash.replace(/^#/, ''));
    if (Object.keys(values).length) {
      return values;
    }
    return defaultValue;
  });

  React.useEffect(() => {
    router.push({
      pathname,
      hash: `#${qs.stringify(state, {
        encode: false,
        addQueryPrefix: false
      })}`
    });
  }, [state, pathname]);

  return [state, setState];
}

export { useHashState };
