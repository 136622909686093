import apiClient from 'app/utils/api-client';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import Table from 'app/components/mui/Table';
import processWaitingImage, { resultSerializer as processWaitingImageSerializer } from './processWaitingImage';
import deleteUser, { resultSerializer as deleteUserSerializer } from './deleteUser';
import expiredLeases from './expiredLeases';
import rotateFbLeases, { resultSerializer as rotateFbLeasesSerializer } from './rotateFbLeases';
import sendUnreadMessages from './sendUnreadMessages';
import deleteUserData from './deleteUserData';

function defaultSerializer(result) {
  const keys = Object.keys(result);
  return keys.filter((key) => result[key] === 0 || Number(result[key])).map((key) => `${key}: ${result[key]}`).join(', ');
}

function getTableData(action) {
  switch(action) {
    case 'PROCESS_WAITING_IMAGE': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Results', name: 'result', valueType: 'custom', customValue: processWaitingImageSerializer }], detailsOnClick: processWaitingImage };
    }
    case 'DISABLE_SMS_TARGETS': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Users Checked', name: 'result', valueType: 'custom', customValue: (result) => result['accountIds'] },
        { label: 'Disabled', name: 'result', valueType: 'custom', customValue: (result) => result['disabled'] }] };
    }
    case 'SEND_NEWACTIVE_BOLIGBESKED': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'New Actives', name: 'result', valueType: 'custom', customValue: (result) => result['newActives'] },
        { label: 'Inactive Target', name: 'result', valueType: 'custom', customValue: (result) => result['hasInactiveTargets'] },
        { label: 'Maxed Email', name: 'result', valueType: 'custom', customValue: (result) => result['hasMaxedEmail'] },
        { label: 'Has Subscription', name: 'result', valueType: 'custom', customValue: (result) => result['hasSubscription'] },
        { label: 'Valid Recievers', name: 'result', valueType: 'custom', customValue: (result) => result['validIds'] },
        { label: 'User Not Found',name: 'result', valueType: 'custom', customValue: (result) => result['hasNoUser'] },
        { label: 'No Prefered Lease',name: 'result', valueType: 'custom', customValue: (result) => result['hasNoPreferedLease'] },
        { label: 'Result', name: 'result', valueType: 'custom', customValue: (result) => result['results'] },
        { label: 'Recievers', name: 'result', valueType: 'custom', customValue: (result) => result['validResults'] }]
      };
    }
    case 'SEND_WEEKLY_RECOMMENDATION': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Users Checked', name: 'result', valueType: 'custom', customValue: (result) => result['accountIds'] },
        { label: 'Not Enough Leases', name: 'result', valueType: 'custom', customValue: (result) => result['notEnoughLeases'] },
        { label: 'Recievers', name: 'result', valueType: 'custom', customValue: (result) => result['mails'] }]
      };
    }
    case 'RENEW_LEGACY_SUBSCRIPTIONS': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Results', name: 'result', valueType: 'custom', customValue: defaultSerializer }] };
    }
    case 'DELETE_UNDO_DATA': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Undo to Delete', name: 'result', valueType: 'custom', customValue: (result) => result['undoToDelete'] },
        { label: 'Undo Deleted', name: 'result', valueType: 'custom', customValue: (result) => result['undoDeleted'] }]
      };
    }
    case 'DELETE_USER': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Users', name: 'result', valueType: 'custom', customValue: deleteUserSerializer }], detailsOnClick: deleteUser
      };
    }
    case 'DELETE_USER_DATA': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Users', name: 'result', valueType: 'custom', customValue: (result) => result['userDataDeleted'] }], detailsOnClick: deleteUserData
      };
    }
    case 'EXPIRED_LEASES': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Expired Locally', name: 'result', valueType: 'custom', customValue: (result) => result['expiredLocally']?.length },
        { label: 'Deactivated', name: 'result', valueType: 'custom', customValue: (result) => result['result']?.length }], detailsOnClick: expiredLeases
      };
    }
    case 'ROTATE_FB_LEASES': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Deleted', name: 'result', valueType: 'custom', customValue: rotateFbLeasesSerializer }], detailsOnClick: rotateFbLeases
      };
    }
    case 'SEND_UNREAD_MESSAGES': {
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Has Unread Messages', name: 'result', valueType: 'custom', customValue: (result) => result['mails']?.length },
        { label: 'Notification Sent', name: 'result', valueType: 'custom', customValue: (result) => result['results']?.length }], detailsOnClick: sendUnreadMessages
      };
    }
    default:
      return { columns: [
        { label: 'Executed', name: 'executedAt', valueType: 'date' },
        { label: 'Results', name: 'result', valueType: 'custom-row', customValue: () => 'Work in Progress' }] };
    }
}

export default class ResultTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
      if(!this.props.id) {
          return;
      }
      this.setState({ report: null, loading: true });
      const result = await apiClient.post('/api/microservice', { data: { action: 'CronjobDetail', data: { action: this.props.id } } });
      this.setState({ result: result.data.data.cronjobDetail, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }
  render() {
    const { loading, result } = this.state;

    if(loading) {
        return <Loading/>;
    }

    const data = getTableData(this.props.id);

    return (<Table
        columns={data.columns}
        limitRow={25}
        rows={result?.latestResults === 'Work in Progress' ? [{ executedAt: new Date(), results: null }]: result?.latestResults}
        detailOnClick={data.detailsOnClick}
     />);
  }
}