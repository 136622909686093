import React from 'react';
import MuiPaper from '@material-ui/core/Paper';
import apiClient from 'app/utils/api-client';
import { TextField, Typography } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import { Table } from 'react-bootstrap';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};


export const Analysis = (props: Props): JSX.Element => {
    const [data, setData] = React.useState(null);
    const [from, setFrom] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [to, setTo] = React.useState('');
    const [showDetail, setShowDetail] = React.useState('');
    const [currentProvider, setProvider] = React.useState('');

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        const tempFrom = new Date(from);
        const tempTo = new Date(to);

        tempFrom.setHours(0, 0, 0);
        tempTo.setHours(0, 0, 0);
        setLoading(true);
         apiClient.post('/api/microservice', { data: { action: 'TransactionAnalysis', data: { from: tempFrom, to: tempTo } } }).then((result) => {
            setData({ ...result?.data || {} });
            setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <MuiPaper
      style={{ ...(props.style || {}), padding: !props.dense && '6px 12px' }}
    >
        <div>
            <span>From: </span>
            <TextField type="date" id="from" onChange={(ev) => setFrom(ev.target.value)} value={from} key="from" />
            <span>To: </span>
            <TextField type="date" id="to" onChange={(ev) => setTo(ev.target.value)} value={to} key="to" />
            <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
        </div>
        { loading ? <p>Loading...</p> : !data?.result?.length ? <p>No transactions</p> : data.result.map((res, idx) => {
            const keys = Object.keys(res);
            return (<><Typography variant="h6">Transaction Analysis for {res._id}</Typography><Table condensed responsive hover fill key={idx}>
                <tbody>
                    {keys.map((key) => {
                    if(key === '_id') {
                        return null;
                    }
                    return Array.isArray(res[key]) ? (<tr key={key + idx}>
                      {showDetail === key.replace('Ids', '') && currentProvider === res._id ? <td style={{ width: '40%' }}>{key}</td> : null}
                      {showDetail === key.replace('Ids', '') && currentProvider === res._id? res[key].map((detail, idx) => {
                          if(typeof detail === 'string') {
                              return (
                                    <tr key={key + idx}>
                                    <td></td>
                                    <td>{detail}</td>
                                    </tr>
                                );
                          }
                        const detailKeys = Object.keys(detail);
                        return (<Table condensed responsive hover fill key={idx}>
                            <tbody>
                                {detailKeys.map((detailKey) => {
                                return (
                                    <tr key={key + idx}>
                                    <td style={{ width: '40%' }} onClick={() => {setShowDetail(showDetail === key ? '' : key); setProvider(res._id); }}>{detailKey}</td>
                                    <td>{detail[detailKey]}</td>
                                    </tr>
                                );
                                }).filter(Boolean)}
                            </tbody>
                            </Table>); }) : null}
                        </tr>) :(
                        <tr key={key + idx} onClick={() => {setShowDetail(showDetail === key ? '' : key); setProvider(res._id); }}>
                        <td style={{ width: '40%' }}>{key}</td>
                        <td>{res[key]}</td>
                        </tr>
                    );
                    }).filter(Boolean)}
                </tbody>
                </Table></>);
        })}
        { loading ? <p>Loading...</p> : !data?.result?.length ? <p>No multiple transactions on unique users</p> : (<>
        <Typography variant="h6">Multiple Transactions per Unique user</Typography>
        {data.multipleTransaction.map((res, idx) => {
            const keys = Object.keys(res);
            return (
            <Table condensed responsive hover fill key={idx}>
                <tbody>
                    {keys.map((key) => {
                    if(key === '_id') {
                        return null;
                    }
                    return (
                        <tr key={key + idx}>
                        <td style={{ width: '40%' }}>{key}</td>
                        <td>{Array.isArray(res[key]) ? JSON.stringify(res[key]) : res[key]}</td>
                        </tr>
                    );
                    }).filter(Boolean)}
                </tbody>
                </Table>
                );
        })}
        </>)}
    </MuiPaper>
  );
};
