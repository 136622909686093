import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Breadcrumbs from 'react-breadcrumbs';

import Header from './core/header';
import DisplaySetting from './core/display-settings';
import Notify from './scrapers/components/Notification';
import OnPageNotify from './common/OnPageNotify';

export default class App extends Component {
  render() {
    const { children, ...props } = this.props;
    return (
      <div>
        <Header />

        <Breadcrumbs
          routes={this.props.routes}
          params={this.props.params}
          excludes={['Dashboard']}
          wrapperElement="ol"
          wrapperClass="breadcrumb hidden-xs"
          itemElement="li"
          separator=""
        />

        <Grid fluid>
          <Row>
            <Col xs={12}>{React.cloneElement(children, { ...props })}</Col>
          </Row>
        </Grid>

        <Notify />
        <OnPageNotify />
        <DisplaySetting />
      </div>
    );
  }
}
