import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import update from 'immutability-helper';

import updateSubscriptionQuery from '../subscription-update.graphql';

import { Panel, Table } from 'react-bootstrap';
import ConfirmationButton from 'app/common/confirmation/button';

import { formatDateStr } from '../user-helper';

import TrailPanel from './trail';
import TransactionsPanel from './transactions';
import { serialzSub } from '../user';
import { CANCEL, EXTEND, REACTIVATE } from '../fragements';
import { fetcher } from 'app/utils/fetcher';
import apiClient from 'app/utils/api-client';

class SubscriptionPanel extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      subscription: props.item
    };
  }

  toggleSub = async (confirmed, values) => {
    const { renew, meta, id } = this.state.subscription;
    if (meta?.legacyId) {
      this.props.updateSubscription({ renew: !renew }, values.notes);
      return;
    }
    const query = renew ? CANCEL : REACTIVATE;
    const namespace = `_admin_${
      renew ? 'cancelSubscription' : 'reactivateSubscription'
    }`;

    const endDate = values.actuallyEndDate || new Date();
    const variables = renew ? {
        id,
        meta: {
          message: values.notes,
          actuallyEndDate: renew
            ? new Date(endDate).toISOString()
            : null
        },
        cancelAt: renew
            ? new Date(endDate).toISOString()
            : null,
        approved: values.autoHandle
    } : {
        id,
        meta: {
          message: values.notes,
          actuallyEndDate: null
        }
    };

    const data = await fetcher('/api/akutbolig/graph-proxy', {
      query,
      variables
    });
    const sub = data && serialzSub([data[namespace]]);
    if (sub) {
      this.setState({ subscription: sub[0] });
    }
  };

  extendSub = async (confirmed, values) => {
    if (this.state.subscription.meta?.legacyId) {
      this.props.updateSubscription(
        { extend: Number(values.extend) },
        values.notes
      );
      return;
    }

    const data = await fetcher('/api/akutbolig/graph-proxy', {
      query: EXTEND,
      variables: {
        id: this.props.item.id,
        days: Number(values.extend),
        meta: { message: values.notes }
      }
    });
    const sub = data && serialzSub([data?._admin_extendSubscription]);
    if (sub) {
      this.setState({ subscription: sub[0] });
    }
  };

  examineCancelDate = async (e, cancelDate) => {
    const { subscription } = this.state;

    if(subscription.renew) {
      this.setState({ examineText: 'Loading...' });
      if(cancelDate) {
        const data = await apiClient.post('/api/ms', { data: { action: 'ValidateCancelSubscription', data: { cancelDate: new Date(cancelDate).toISOString(), id: subscription.id }} });
        this.setState({ examineText: data.data || 'Error: Missing validation' });
      } else {
        this.setState({ examineText: 'Error: Missing Cancel Date' });
      }
    }
  }

  render() {
    const { subscription } = this.state;

    const btnExtend = !subscription.deletedAt ? (
      <ConfirmationButton
        label="Extend"
        onSubmit={this.extendSub}
        text="Please confirm, you want to extend the expiration date for the subscription"
        bsStyle="warning"
        bsSize="xs"
        inline
        className="pull-right"
      >
        <div>
          <div className="form-group">
            <label>Additional days</label>
            <div className="input-group">
              <input
                name="extend"
                type="number"
                pattern="^[0-9]+$"
                max="30"
                defaultValue="0"
                className="form-control"
                placeholder="Extend expiration date"
              />
              <div className="input-group-addon">days</div>
            </div>
          </div>
          <div className="form-group">
            <label>Reason / internal notes</label>
            <textarea
              className="form-control"
              name="notes"
              rows="2"
              required
              minLength="10"
            />
          </div>
        </div>
      </ConfirmationButton>
    ) : null;

    const examineText = this.state.examineText || 'If no date is picked, it will be now';
    const btnToggleSub = !subscription.deletedAt ? (
      <ConfirmationButton
        label={subscription.renew ? 'Deactivate' : 'Activate'}
        onSubmit={this.toggleSub}
        text="Please confirm, you want to change renewable status for the subscription"
        bsStyle={subscription.renew ? 'danger' : 'success'}
        bsSize="xs"
        inline
        className="pull-right"
      >
        <textarea
          className="form-control"
          name="notes"
          rows="2"
          required
          minLength="10"
          onChange={e => { this.setState({ notes: e.target.value }); } }
        />
        <label
          style={{ marginTop: '1em' }}
          htmlFor="actuallyEndDate"
          className="form-label"
        >
          Subscription End Date
        </label>
        <input
          className="form-control"
          name="actuallyEndDate"
          id="actuallyEndDate"
          type="datetime-local"
          onChange={e => { this.setState({ cancelDate: e.target.value }); this.examineCancelDate(this.state, e.target.value); }}
        />

        <div className="form-text" style={{ color: '#aaa' }}>
          {examineText.split('\n').map((i,key) => {
            return <div key={key}>{i}</div>;
        })}
        </div>

        {
          ['Subscription has not been renewed since cancel date', 'If no date is picked, it will be now'].includes(examineText) ? null :
          (<label>
            <input
              name="autoHandle"
              id="autoHandle"
              type="checkbox"
              onChange={e => { this.setState({ autoHandle: e.target.checked }); }}
            />
            {'Please confirm, if you want to use auto handling'}
        </label>)
        }
      </ConfirmationButton>
    ) : null;

    return (
      <Panel
        bsStyle={
          subscription.active
            ? subscription.renew
              ? 'success'
              : 'info'
            : 'default'
        }
        header={
          <h4>
            Subscription <small>{subscription.id}</small>
          </h4>
        }
      >
        <Table striped condensed responsive hover fill>
          <tbody>
            <tr>
              <th>Plan</th>
              <td>{subscription.plan.id}</td>
            </tr>

            <tr>
              <th style={{ width: '40%' }}>Status</th>
              <td>
                {subscription.active ? (
                  <i
                    className="fa fa-check-circle"
                    style={{ color: 'darkGreen' }}
                  />
                ) : (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: 'rgb(220, 0, 0)' }}
                  />
                )}
                &nbsp;
                {subscription.active ? 'Valid' : 'Expired'}
              </td>
            </tr>
            <tr>
              <th>Starts</th>
              <td>{formatDateStr(subscription.starts)}</td>
            </tr>
            <tr>
              <th>Ends</th>
              <td>
                {formatDateStr(subscription.ends)}
                {btnExtend}
              </td>
            </tr>
            <tr>
              <th>Renew</th>
              <td>
                {subscription.renew ? (
                  <i
                    className="fa fa-check-circle"
                    style={{ color: 'darkGreen' }}
                  />
                ) : (
                  <i
                    className="fa fa-times-circle"
                    style={{ color: 'rgb(220, 0, 0)' }}
                  />
                )}
                &nbsp;
                {subscription.renew ? 'Yes' : 'No'} {btnToggleSub}
              </td>
            </tr>
            {subscription.canceledAt ? (
              <tr>
                <th>Canceled</th>
                <td>{formatDateStr(subscription.canceledAt)}</td>
              </tr>
            ) : null}
          </tbody>
        </Table>

        <TrailPanel trail={subscription.trail} />
        {subscription.transactions.length > 0 ? (
          <TransactionsPanel
            collapsible
            subscription={subscription.id}
            transactions={subscription.transactions}
          />
        ) : null}
      </Panel>
    );
  }
}

export default compose(
  // mutate subscription
  graphql(updateSubscriptionQuery, {
    options: (props) => ({
      variables: {
        subscriptionId: props.item.id,
        data: {},
        notes: ''
      }
    }),
    props: ({ ownProps, mutate }) => ({
      ...ownProps,
      updateSubscription: (data, notes) =>
        mutate({
          variables: { data, notes },
          updateQueries: {
            User: (prev, args) => {
              const { mutationResult } = args;
              const subscription = mutationResult.data.updateSubscription;

              const idx = prev.user.subscriptions.reduce((acc, txn, idx) => {
                return txn.id === subscription.id ? idx : acc;
              }, null);

              if (idx === null) {
                return update(prev, {
                  user: { subscriptions: { $unshift: [subscription] } }
                });
              }

              return update(prev, {
                user: {
                  subscriptions: {
                    [idx]: { $set: subscription }
                  }
                }
              });
            }
          }
        })
    })
  })
)(SubscriptionPanel);
