import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';

export default class SubPanels extends Component {
  static propTypes = {
    ItemPanel: PropTypes.func.isRequired,
    collapsible: PropTypes.bool,
    header: PropTypes.oneOfType([PropTypes.string]).isRequired,
    list: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      list,
      collapsible,
      ItemPanel,
      itemPanelProps = {},
      header
    } = this.props;

    const panelHeader = collapsible ? (
      `${header} (${list.length})`
    ) : (
      <h4>{`${header} (${list.length})`}</h4>
    );

    return list.length ? (
      <Panel collapsible={collapsible} header={panelHeader}>
        {list.map((item, idx) => (
          <ItemPanel key={item.id || idx} item={item} {...itemPanelProps} />
        ))}
      </Panel>
    ) : null;
  }
}
