export type Reviewable = {
  id: string | null;
  agentUnique: string | null;
  headline: string | null;
  description: string | null;
  snippet: string | null;
  address: string | null;
  street: string | null;
  streetNo: string | null;
  floor: number | null;
  zip: string | null;
  city: string | null;
  geo: GeoShape | null;
  geoPrecision: GeoPrecision | null;
  size: number | null;
  rooms: number | null;
  rent: number | null;
  expenses: number | null;
  expensesAc: number | null;
  expensesHeat: number | null;
  expensesWater: number | null;
  expensesElectricity: number | null;
  expensesTv: number | null;
  expensesImprovements: number | null;
  expensesMisc: number | null;
  prepaidRent: number | null;
  deposit: number | null;
  downpayment: number | null;
  available: string | null;
  timeframe: Timeframe | null;
  remarks: string | null;
  meta: string | null;
  typeDwelling: DwellingType | null;
  typeTenant: TenantType | null;
  residenceduty: boolean | null;
  pets: boolean | null;
  furnished: boolean | null;
  contact: Contact | null;
  createdAt: string | null;
  deletedAt: string | null;
  checksum: string | null;
  images: LeaseImage[] | null;
  relatedIds: string[] | null;
  listingType: ListingType | null;
  listingFlags: ListingFlag[] | null;
  review: Review | null;
};

export type ReviewableInput = {
  id?: string | null;
  agentUnique?: string | null;
  headline?: string | null;
  description?: string | null;
  snippet?: string | null;
  address?: string | null;
  street?: string | null;
  streetNo?: string | null;
  floor?: number | null;
  zip?: string | null;
  city?: string | null;
  geo?: GeoShapeInput | null;
  geoPrecision?: GeoPrecision | null;
  size?: number | null;
  rooms?: number | null;
  rent?: number | null;
  expenses?: number | null;
  expensesAc?: number | null;
  expensesHeat?: number | null;
  expensesWater?: number | null;
  expensesElectricity?: number | null;
  expensesTv?: number | null;
  expensesImprovements?: number | null;
  expensesMisc?: number | null;
  prepaidRent?: number | null;
  deposit?: number | null;
  downpayment?: number | null;
  available?: string | null;
  timeframe?: TimeframeInput | null;
  remarks?: string | null;
  meta?: string | null;
  typeDwelling?: DwellingType | null;
  typeTenant?: TenantType | null;
  residenceduty?: boolean | null;
  pets?: boolean | null;
  furnished?: boolean | null;
  contact?: ContactInput | null;
  createdAt?: string | null;
  deletedAt?: string | null;
  checksum?: string | null;
  images?: LeaseImageInput[] | null;
  relatedIds?: string[] | null;
  listingType?: ListingType | null;
  listingFlags?: ListingFlag[] | null;
  review?: ReviewInput | null;
};

type Review = {
  reviewer: string | null;
  approved: boolean | null;
  difference: JSON;
  createdAt: string | null;
  updatedAt: string | null;
};

type ReviewInput = {
  reviewer: string | null;
  approved: boolean | null;
};

type LeaseImage = {
  hash: string | null;
  type: ImageType | null;
  url: string;
};

type LeaseImageInput = {
  hash?: string | null;
  type?: ImageType | null;
  url: string;
};

type GeoShape = {
  type: string | null;
  coordinates: number[] | null;
};

type GeoShapeInput = {
  type: string | null;
  coordinates: number[] | null;
};

type Contact = {
  agentId: number | null;
  agentRef: string | null;
  name: string | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  email: string | null;
  url: string | null;
};

type ContactInput = {
  agentId: number | null;
  agentRef: string | null;
  name: string | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  email: string | null;
  url: string | null;
};

type Timeframe = {
  type: TimeframeType | null;
  min: string | null;
  max: string | null;
  text: string | null;
};

type TimeframeInput = {
  type: TimeframeType | null;
  min: string | null;
  max: string | null;
  text: string | null;
};

export enum DwellingType {
  ROOM = 'ROOM',
  SHARED = 'SHARED',
  APARTMENT = 'APARTMENT',
  VILLA = 'VILLA',
  TERRACED = 'TERRACED',
  EXCHANGE = 'EXCHANGE'
}
export enum TenantType {
  SENIOR = 'SENIOR',
  FAMILY = 'FAMILY',
  ELDER = 'ELDER',
  YOUTH = 'YOUTH',
  STUDENT = 'STUDENT',
  PUBLIC = 'PUBLIC'
}
enum ImageType {
  FLOORPLAN = 'FLOORPLAN',
  PRIMARY = 'PRIMARY'
}
enum GeoPrecision {
  STREET = 'STREET',
  STREETNO = 'STREETNO',
  ZIP = 'ZIP'
}
enum TimeframeType {
  RAW = 'RAW',
  MONTH = 'MONTH',
  DATE = 'DATE',
  UNLIMITED = 'UNLIMITED'
}
enum ListingType {
  STANDARD = 'STANDARD',
  PLUS = 'PLUS',
  PREMIUM = 'PREMIUM'
}
enum ListingFlag {
  FRESH = 'FRESH',
  HIGHLIGHT = 'HIGHLIGHT',
  FREE_CONTACT = 'FREE_CONTACT'
}

export enum StringBoolean {
  _FALSE = '_FALSE',
  _TRUE = '_TRUE',
  _NULL = '_NULL'
}

export type ReviewableSerializer = {
  id: string | null;
  agentUnique: string | null;
  headline: string | null;
  description: string | null;
  snippet: string | null;
  address: string | null;
  street: string | null;
  streetNo: string | null;
  floor: string | null;
  zip: string | null;
  city: string | null;
  geoType: string | null;
  geoCoordinates: string | null;
  geoPrecision: GeoPrecision | null;
  size: string | null;
  rooms: string | null;
  rent: string | null;
  expenses: string | null;
  expensesAc: string | null;
  expensesHeat: string | null;
  expensesWater: string | null;
  expensesElectricity: string | null;
  expensesTv: string | null;
  expensesImprovements: string | null;
  expensesMisc: string | null;
  prepaidRent: string | null;
  deposit: string | null;
  downpayment: string | null;
  available: string | null;
  timeframeType: TimeframeType | null;
  timeframeMin: string | null;
  timeframeMax: string | null;
  timeframeText: string | null;
  remarks: string | null;
  meta: string | null;
  typeDwelling: DwellingType | null;
  typeTenant: TenantType | null;
  residenceduty: StringBoolean;
  pets: StringBoolean;
  furnished: StringBoolean;
  contactAgentId: string | null;
  contactAgentRef: string | null;
  contactName: string | null;
  contactPrimaryPhone: string | null;
  contactSecondaryPhone: string | null;
  contactEmail: string | null;
  contactUrl: string | null;
  createdAt: string | null;
  deletedAt: string | null;
  checksum: string | null;
  images: string | null;
  listingType: ListingType | null;
  reviewReviewer: string | null;
  reviewApproved: boolean | null;
  reviewDifference: JSON | null;
};

export type User = {
  initials: string;
  loggedIn: boolean;
  name: string;
  permissions: string[];
  role: 'admin';
  token: string;
  _id: string;
};

export type Variables = {
  reviewable: ReviewableInput;
  approved: boolean;
  reviewer?: string;
};

export type Values = ReviewableSerializer;
