import apiClient from 'app/utils/api-client';
import React, { useEffect } from 'react';
import Loading from 'app/common/loading';
import Table from 'app/components/mui/Table';
import Input from '@material-ui/core/TextField';
import { serializeDwellings } from 'app/components/mui/utils/serializeDwelling';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from 'app/components/mui/Button';

const DWELLINGS = `query DwellingsDK($limit: Limit!, $inactive: Boolean!, $filter: JSON) {
  leases(limit: $limit, inactive: $inactive, filter: $filter) {
    id
    headline
    address
    zip
    city
    rent
    rooms
    m2
    expireAt
    inactive
  }
}
`;

async function getData(variables = {}) {
  const filter = Object.keys(variables).reduce((acc, key) => {
    const value = variables[key];
    if(value === '') {
      return acc;
    }

    if(value === 0) {
      return acc;
    }
    acc[key] = value;
    return acc;
  }, {});

  const res = await apiClient.graph('/api/graph/dk/v1', {
    query: DWELLINGS,
    variables: { inactive: true, limit: 250, filter }
  });

  return serializeDwellings(res.data?.leases || []);
}

const DwellingsTable: React.FC = () => {
  const timerRef = React.useRef();
  const [stillWaiting, setStillWaiting] = React.useState(false);
  const [values, setValues] = React.useState({
        id: '',
        address: '',
        zip: '',
        city: '',
        rooms: 0
    });
  const [state, setState] = React.useState({
    status: 'idle',
    data: []
  });

  useEffect(async () => {
    setState({ data: [], status: 'pending' });
    const data = await getData(values);
    setState({ data, status: 'resolved' });
  }, []);

  const onChangeHandler = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { id, value } = ev.target;
    setValues((prev) => ({ ...prev, [id]: value }));
  };

  const onClickHandler = async () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setStillWaiting(true);
    }, 5000);

    setState({ status: 'pending' });
    try {
      const data = await getData(values);
      setState({ data, status: 'resolved' });
      if (stillWaiting === true) {
        setStillWaiting(false);
      }
      clearTimeout(timerRef.current);
    } catch (err) {
      setState({ error: err.message, status: 'rejected' });
    }
    clearTimeout(timerRef.current);
  };

  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div style={{ marginBottom: '.5em', display: 'flex' }}>
        <Input
          style={{ marginRight: '1em' }}
          id={'address'}
          label={'Address'}
          type="text"
          onChange={onChangeHandler}
          margin="normal"
          variant="outlined"
        />
        <Input
          style={{ marginRight: '1em' }}
          id={'city'}
          label={'City'}
          type="text"
          onChange={onChangeHandler}
          margin="normal"
          variant="outlined"
        />
        <Input
          style={{ marginRight: '1em' }}
          id={'zip'}
          label={'Zipcode'}
          type="text"
          onChange={onChangeHandler}
          margin="normal"
          variant="outlined"
        />
        <Input
          style={{ marginRight: '1em' }}
          id={'rooms'}
          label={'Rooms'}
          type="number"
          onChange={onChangeHandler}
          margin="normal"
          variant="outlined"
        />
        {<Button
          style={{ marginRight: '1em', marginBottom: '1em' }}
          onClick={onClickHandler}
          label={
            <>
              Refresh
              <RefreshIcon />
            </>
          }
          size="small"
        />}
      </div>
      {state.status === 'rejected' ? (
        <div style={{ color: 'red' }}>OH NO!: {state.error}</div>
      ) : state.status === 'pending' ? (
        <div style={{ textAlign: 'center' }}>
          {stillWaiting && (
            <p style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              We are working hard to get your data
            </p>
          )}
          <Loading />
        </div>
      ) : state.status === 'resolved' ? (
        <Table
                url="/dk/dwellings"
                title="Dwellings"
                columns={[
                  { name: 'id', label: 'Id' },
                  { name: 'headline', label: 'Headline' },
                  { name: 'address', label: 'Address' },
                  { name: 'zip', label: 'Zip' },
                  { name: 'city', label: 'City' },
                  { name: 'rent', label: 'Rent' },
                  { name: 'rooms', label: 'Rooms' },
                  { name: 'm2', label: 'M2' },
                  { name: 'inactive', label: 'Inactive', valueType: 'checkmark' },
                  { name: 'expireIn', label: 'Expire in' },
                ]}
                rows={state.data}
              />
      ) : (
        <div>This message should not be possible 🤔, bad developer</div>
      )}
    </>
  );
};

export default DwellingsTable;
