import React from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory, match } from 'react-router';

import handleDataDependencies from './fetch-data';

export default function getProvider({
  history = browserHistory,
  store,
  routes,
  DevTools
}) {
  function locationChange(location, callback, reload = false) {
    match(
      { history, location, routes },
      (error, redirectLocation, renderProps = {}) => {
        Promise.resolve()
          // pre-fetch
          .then(() =>
            handleDataDependencies({ location, store, renderProps, reload })
          )
          // post-fetch
          // on-error
          .then(() => callback())
          .then(() =>
            handleDataDependencies({
              location,
              store,
              renderProps,
              deferred: true,
              reload
            })
          )
          .then(() => {
            // TODO: final
            if (__CLIENT__ && typeof window !== 'undefined') {
              window.scrollTo(0, 0);
            }
          })
          .catch((err) => {
            if (__DEV__) {
              console.error(err);
            }
            // TODO: Catch ?
          });
      }
    );
  }

  function fetchData(reload) {
    return new Promise((rs) => {
      const loc = window.location;
      const loca = history.createLocation(
        `${loc.pathname}${loc.search}${loc.hash}`
      );
      locationChange(loca, () => rs(), reload);
    });
  }

  history.listenBefore((location, callback) =>
    locationChange(location, callback)
  );

  const router = (
    <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
      {routes}
    </Router>
  );

  const component = (
    <Provider store={store}>
      {DevTools ? (
        <div>
          {router}
          <DevTools />
        </div>
      ) : (
        router
      )}
    </Provider>
  );

  return { component, fetchData };
}
