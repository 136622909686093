import React from 'react';
import { browserHistory } from 'react-router';


const LINK_PATH = '/reports/details/';
function detailPageBySlug(detailType) {
  return (ev) => {
    ev.preventDefault();
    const detailKey = ev.target.detailKey.value;
    browserHistory.push(`${LINK_PATH}${detailType}/${detailKey}`);
    return false;
  };
}

function getReportParams(report: string): { text: string, detailType: string } {
  let text = 'Detaljer ud fra slug ';
  let detailType = 'scraper';

  switch(report) {
    case 'scraper':
    case 'contacts-scrapers':
      break;
    case 'landlord':
    case 'contacts-landlords':
      text = 'Detaljer ud fra landlord id ';
      detailType = 'landlord';
      break;
    case 'city':
    case 'contacts-cities':
      text = 'Detaljer ud fra by ';
      detailType = 'city';
      break;
  }

  return { text, detailType };
}

export default function form(report: string): any {
  if(!['contacts-scrapers', 'contacts-cities', 'contacts-landlords', 'scraper', 'city', 'landlord'].includes(report)) {
      return null;
  }
  const { text, detailType } = getReportParams(report);

  return (<form onSubmit={detailPageBySlug(detailType)}>
          <label>
            {text}
            <input name="detailKey" />
          </label>
        </form>);
};