import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import daLocale from 'date-fns/locale/da';

const DatePicker = ({
  field,
  inputProps: { component, options, ...inputProps },
  ...props
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
      <KeyboardDatePicker
        {...inputProps}
        {...(field || {})}
        disableToolbar
        onChange={(date) =>
          props.form.setFieldValue(field.name, date.toISOString())
        }
        variant="inline"
        inputVariant={props.variant || 'outlined'}
        margin={props.margin || 'dense'}
        autoOk={props.autoOk || true}
        InputAdornmentProps={{ position: 'start' }}
        InputLabelProps={{
          shrink: true
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
