import React from 'react';

export default function statusIcon({ status }) {
  let icon = 'minus';
  let color = 'grey';

  if (status === 'success') {
    icon = 'check';
    color = 'darkGreen';
  } else if (status === 'warning') {
    icon = 'exclamation';
    color = 'orange';
  } else if (status === 'danger') {
    icon = 'times';
    color = 'rgb(220, 0, 0)';
  }

  return (
    <i
      className={`fa fa-${icon}-circle`}
      style={{ color }}
      aria-hidden="true"
    />
  );
}
