import apiClient from 'app/utils/api-client';

import { Panel, Table } from 'react-bootstrap';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import { TableBody, TableRow } from '@material-ui/core';

export default class LeaseUnwantedDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      report: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
        if(!this.props.row) {
            return;
        }
      this.setState({ report: null, loading: true });
      const report = await apiClient.get('/api/reports/lease-unwanted/' + this.props.row?._id);
      this.setState({ report, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  render() {
    const { loading, report } = this.state;

    if(loading) {
        return <Loading/>;
    }
    if(!report?.stats) {
        return <p>{JSON.stringify(report)}</p>;
    }

    return (<Panel>
        <Table condensed responsive hover>
            <TableBody>
            {report.stats.map((unwanted) => {
                return (
                <TableRow key={unwanted.userId}>
                    <td style={{ width: '40%' }}>{unwanted.userId}</td>
                    <td>{unwanted.createdAt}</td>
                    <td>{unwanted.from}</td>
                </TableRow>
                );
            })}
            </TableBody>
        </Table>
    </Panel>);
  }
}