import apiClient from './api-client';

export async function fetcher(url: string, options) {
  try {
    const res = await apiClient.graph(url, options);
    const { data, errors } = res;
    if (errors) {
      alert(JSON.stringify(errors));
      return null;
    }

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
