import multimatch from 'multimatch';

export const roles = [
  'guest', // @legacy
  'freelancer',
  'employee',
  'editor', // @legacy
  'supervisor',
  'admin', // @legacy
  'manager'
];

export function hasRole(required: string, role: string): boolean {
  return roles.indexOf(role) >= roles.indexOf(required);
}

export function requireRole(required: string) {
  return (role: string) => hasRole(required, role);
}

function serialize(arr: string[]) {
  return arr && arr.filter(Boolean).map((r) => r.replace(':', '/'));
}

export function check(required: string[], permissions: string[]) {
  const matches = multimatch(serialize(required), serialize(permissions));
  return matches.length === required.length;
}

export function requirePermission(
  requiredPermission: string,
  permissions: string[]
  // role?: string
) {
  return check([requiredPermission], permissions);

  // const t = requiredPermission.split(':');
  // const section = new RegExp(`^${t[0]}(\\.[_a-z]+)*(:|$)`);
  // const method = t[1] && new RegExp(`:${t[1]}$`);
  //
  // const hasPermissions = Array.isArray(permissions) && permissions.length;
  //
  // // console.log(role, permissions, requiredPermission, hasPermissions);
  // // console.log(
  // //   t[1] === 'write',
  // //   !hasPermissions,
  // //   !hasRole('manager', role),
  // //   t[1]
  // // );
  //
  // if (t[1] === 'write' && !hasPermissions && !hasRole('manager', role)) {
  //   return false;
  // }
  //
  // if (
  //   !hasPermissions ||
  //   permissions.filter(permission => {
  //     if (!section.test(permission)) {
  //       return false;
  //     }
  //     if (method && !method.test(permission)) {
  //       return false;
  //     }
  //     return true;
  //   }).length
  // ) {
  //   return true;
  // }
  // return false;
}
