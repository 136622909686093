import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';
import { Analysis } from './analysis';

const subscriptionColumns = [
  { label: 'Id', name: 'id', simpleLinkText: 'Details', valueType: 'string' },
  { label: 'Status', name: 'status', valueType: 'string' },
  { label: 'ExternalId', name: 'externalId', valueType: 'string' },
  { label: 'Plan', name: 'plan', valueType: 'string' }
];

const transactionColumns = [
  { label: 'Id', name: 'id', simpleLinkText: 'Details', valueType: 'string' },
  { label: 'Status', name: 'status', valueType: 'string' },
  { label: 'ExternalId', name: 'externalId', valueType: 'string' },
  { label: 'Amount', name: 'amount', valueType: 'number' }
];

function getTrailValues(keys) {
  return (v) => Object.assign(v, keys.reduce((acc, key) => {
    acc[key] = v.latestTrail[key];
    return acc;
  }, {}));
}

export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    apiClient.post('/api/microservice', { data: { action: 'Payments', data: {} } }).then((result) => {
      this.setState({ ...result?.data || {} });
    });
  }
  toggleAnalysis() {
    this.setState({ showAnalysis: !this.state.showAnalysis });
  }

  render() {
    const { subscription = {}, transaction = {} } = this.state;
    const { latestCreated = [], latestRenewals= [], latestDeactivated = [] } = subscription;
    const { latestPending = [], latestCaptured = [], latestFailures = [] } = transaction;
    const limitRow = 10;
    return (
      <div>
       <Button style={{ margin: '6px' }} size={'small'} label={'Toggle Analysis'} onClick={() => this.toggleAnalysis()}/>
        {this.state.showAnalysis ? <Analysis><div>Analysis Page</div></Analysis>: <>
          <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/>
          <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'New Subscriptions'}
                columns={[].concat(subscriptionColumns, [
                  { label: 'Created', name: 'createdAt', valueType: 'date' }
                ])}
                hideable
                limitRow={limitRow}
                rows={latestCreated}
              />
          </div>
          <div style={{ margin: '6px' }}>
            <Table
                url={'actionqueue'}
                title={'Latest Renewals'}
                columns={[].concat(subscriptionColumns, [
                  { label: 'Captured', name: 'timestamp', valueType: 'date' }
                ])}
                limitRow={limitRow}
                hideable
                rows={latestRenewals.map(getTrailValues(['timestamp', 'status']))}
              />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Deactivations'}
                columns={[].concat(subscriptionColumns, [
                  { label: 'Canceled', name: 'canceledAt', valueType: 'date' }
                ])}
                limitRow={limitRow}
                hideable
                rows={latestDeactivated}
              />
          </div>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Captured'}
                columns={[].concat(transactionColumns, [
                  { label: 'Captured', name: 'timestamp', valueType: 'date' }
                ])}
                limitRow={limitRow}
                hideable
                rows={latestCaptured.map(getTrailValues(['timestamp', 'status']))}
              />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Transactions on Pending'}
                columns={[].concat(transactionColumns, [
                  { label: 'DueDate', name: 'dueDate', valueType: 'date' }
                ])}
                limitRow={limitRow}
                hideable
                rows={latestPending}
              />
          </div>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest Failed Transaction'}
                columns={[].concat(transactionColumns, [
                  { label: 'Timestamp', name: 'timestamp', valueType: 'date' }
                ])}
                limitRow={limitRow}
                hideable
                rows={latestFailures.map(getTrailValues(['timestamp', 'status']))}
              />
          </div>
        </div>
        </>}
      </div>
    );
  }
}
