import React from 'react';
import { connect } from 'react-redux';
import { loadPages } from './redux';

import { Link } from 'react-router';
import { Table } from 'react-bootstrap';

import { TableContainer } from '../common/table';

import { dateFormatStr } from 'lib/format-date';

class Pages extends TableContainer {
  static fetchData({ getState, dispatch }) {
    if (!getState().r6.pages.size) {
      return dispatch(loadPages());
    }
  }

  static defaultProps = {
    sortBy: 'stats.fans',
    sortDir: -1
  };

  render() {
    const { rows } = this.state;

    if (!this.props.rows.size) {
      return null;
    }

    return (
      <div>
        <Table striped condensed hover>
          <thead>
            <tr>
              <th>Project</th>
              <th onClick={this.sortBy} data-column="geo.name">
                Geo area
              </th>
              <th>Facebook name</th>
              <th
                onClick={this.sortBy}
                data-column="stats.fans"
                data-type="number"
              >
                Fans
              </th>
              <th
                onClick={this.sortBy}
                data-column="stats.fans_last_week"
                data-type="number"
              >
                Fans (7D)
              </th>
              <th
                onClick={this.sortBy}
                data-column="stats.posts30d"
                data-type="number"
              >
                Posts (30D)
              </th>
              <th onClick={this.sortBy} data-column="stats.lastPost">
                Last post
              </th>
              <th onClick={this.sortBy} data-column="stats.error">
                Error
              </th>
            </tr>
            <tr>
              <th>
                <select
                  onChange={this.onFilterChange}
                  name="project"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                >
                  <option />
                  <option>r6</option>
                  <option>j6</option>
                  <option>boligninja</option>
                </select>
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="geo.name"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by area"
                />
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="facebookId"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by Facebook ID"
                />
              </th>
              <th />
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.get('_id')}>
                  <td>{row.get('project')}</td>
                  <td>
                    <Link to={`/r6/pages/${row.get('_id')}`}>
                      {row.getIn(['geo', 'name']) ||
                        `...${row.get('_id').substr(-12)}`}
                    </Link>
                  </td>
                  <td>
                    <a
                      href={row.getIn(['link'])}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.getIn(['name']) || row.getIn(['facebookId'])}
                    </a>
                  </td>
                  <td>{row.getIn(['stats', 'fans'])}</td>
                  <td>{row.getIn(['stats', 'fans_last_week'])}</td>
                  <td>{row.getIn(['stats', 'posts30d'])}</td>
                  <td>{dateFormatStr(row.getIn(['stats', 'lastPost']))}</td>
                  <td>{JSON.stringify(row.getIn(['error']) || '')}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect(({ r6: { pages } }) => ({ rows: pages }))(Pages);
