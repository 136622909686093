import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { getTransactions } from './../redux/modules/boligninja';

import client from 'app/utils/api-client';

class Transactions extends Component {
  static fetchData({ dispatch }) {
    return dispatch(getTransactions());
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { transactions } = this.props;

    return (
      <div>
        {transactions ? (
          <Table striped condensed hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Provider ID</th>
                <th>PDF invoice</th>
                <th>Order nr.</th>
                <th>Origin Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((trans, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <Link to={`/boligninja/transactions/${trans._id}`}>
                        {trans._id}
                      </Link>
                    </td>
                    <td>{trans._txnid}</td>
                    <td>
                      <i
                        className="fa fa-file-pdf-o"
                        style={{ color: 'darkRed' }}
                        onClick={() => {
                          client.get('/api/auth/nonce').then((res) => {
                            if (res && res.status === 'OK') {
                              global.location.href = `/api/invoices/boligninja/${trans._raw.id}.pdf?nonce=${res.nonce}`;
                            }
                          });
                        }}
                      />
                    </td>
                    <td style={{ textAlign: 'right' }}>{trans.orderno}</td>
                    <td style={{ textAlign: 'right' }}>
                      {trans._raw
                        ? (trans._raw.origin_amount || trans._raw.amount || 0) /
                          100
                        : trans.amount}{' '}
                      kr.
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : null}
      </div>
    );
  }
}

export default connect(({ boligninja: { transactions } }) => ({
  transactions
}))(Transactions);
