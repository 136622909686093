import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

const httpLink = createHttpLink({ uri: '/api/akutbolig/graph' });
const middlewareLink = new ApolloLink((operation, forward) => {
  const lsUser = global.localStorage.getItem('user');
  const user = lsUser ? JSON.parse(lsUser) : null;

  operation.setContext({
    headers: {
      authorization: user && user.token ? `Bearer ${user.token}` : undefined
    }
  });
  return forward(operation);
});

const link = middlewareLink.concat(httpLink);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({})
});

// import ApolloClient, { createNetworkInterface } from 'apollo-client';

// const networkInterface = createNetworkInterface({
//   uri: '/api/akutbolig/graph'
// });

// networkInterface.use([
//   {
//     applyMiddleware(req, next) {
//       const lsUser = global.localStorage.getItem('user');
//       const user = lsUser ? JSON.parse(lsUser) : null;

//       req.options.headers = {
//         ...(req.options.headers || {}),
//         authorization: user && user.token ? `Bearer ${user.token}` : undefined
//       };
//       next();
//     }
//   }
// ]);

// export const client = new ApolloClient({
//   networkInterface
// });

// export const reducer = client.reducer();
