import {
  toNumber,
  toBoolean,
  fromBoolean
} from '../../../../components/mui/utils/serializeHelpers';

export const serializeAgent = (agent) => {
  const { mailer, disabled, nonprofit, ...rest } = agent;

  return {
    ...rest,
    nonprofit: toBoolean(nonprofit),
    mailer: toBoolean(mailer),
    disabled: toBoolean(disabled)
  };
};

export const deserializeAgent = (agent) => {
  const {
    maxItems,
    parent,
    mailer, // TODO: Add back when API accepts
    disabled, // TODO: Add back when API accepts
    dataSanitizer, // @legacy
    agent404,
    agent404Regex,
    checksum, // Is accepted, but should not be modified by client
    nonprofit,
    partner,
    type, // TODO: Add back when API accepts
    last_status_change,
    scraper_status,
    ...rest
  } = agent;

  return {
    ...rest,
    parent: toNumber(parent),
    maxItems: toNumber(maxItems, 10)
  };
};
