import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from '../../common';
import { Table } from 'react-bootstrap';
import lib from 'lib/format-date';
import { Link } from 'react-router';

class Transaction extends Component {
  static propTypes = {
    subscriptions: PropTypes.array,
    trans: PropTypes.object,
    transaction: PropTypes.object,
    userId: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { userId } = this.props;
    const transaction = this.props.transaction || this.props.trans;
    const { _raw = {} } = transaction;

    const url = `/boligninja/transactions/${transaction._id}`;

    return (
      <div>
        <Table
          striped
          condensed
          hover
          responsive
          style={{ borderBottom: '1px solid #ddd' }}
        >
          <tbody>
            {userId ? (
              <tr>
                <td>User ID</td>
                <td>
                  <Link to={`/boligninja/users/${userId}`}>{userId}</Link>
                </td>
              </tr>
            ) : null}
            <tr>
              <td>Transaction ID</td>
              <td>
                <Link to={url}>{transaction._id}</Link>
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{transaction.status || _raw.status}</td>
            </tr>
            <tr>
              <td>Created</td>
              <td>{lib.dateFormatStr(transaction.createdAt)}</td>
            </tr>
            <tr>
              <td>Updated</td>
              <td>{lib.dateFormatStr(transaction.updatedAt)}</td>
            </tr>
            <tr>
              <td>Payment provider</td>
              <td>{transaction.provider || 'Paymill'}</td>
            </tr>
            <tr>
              <td>Provider ID</td>
              <td>{transaction._txnid}</td>
            </tr>
            <tr>
              <td>Order no.</td>
              <td>{transaction.orderno}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>
                {_raw.amount / 100} {_raw.currency}
              </td>
            </tr>
            {_raw.created_at ? (
              <tr>
                <td>Transaction time</td>
                <td>
                  {transaction.provider === 'paylike'
                    ? lib.dateFormatStr(transaction.updatedAt)
                    : lib.dateUnixStr(_raw.created_at)}
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>

        <div className="panel-body">
          {_raw && _raw.payment ? (
            <Panel collapsible header="Payment">
              <Table striped condensed hover className="table">
                <tbody>
                  <tr>
                    <td>Payment ID</td>
                    <td>{_raw.payment.id}</td>
                  </tr>
                  <tr>
                    <td>Usable for preauthorization</td>
                    <td>
                      {lib.boolFormat(
                        _raw.payment.is_usable_for_preauthorization
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Recurring</td>
                    <td>{lib.boolFormat(_raw.payment.is_recurring)}</td>
                  </tr>
                  <tr>
                    <td>Client data</td>
                    <td>{_raw.payment.client && _raw.payment.client.data}</td>
                  </tr>
                  <tr>
                    <td>App ID</td>
                    <td>{_raw.payment.app_id}</td>
                  </tr>
                  <tr>
                    <td>Created at</td>
                    <td>{_raw.payment.created_at}</td>
                  </tr>
                  <tr>
                    <td>Updated at</td>
                    <td>{_raw.payment.updated_at}</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{_raw.payment.type}</td>
                  </tr>
                  <tr>
                    <td>Card Type</td>
                    <td>{_raw.payment.card_type}</td>
                  </tr>
                  <tr>
                    <td>Last 4</td>
                    <td>{_raw.payment.last4}</td>
                  </tr>
                  <tr>
                    <td>Card holder</td>
                    <td>{_raw.payment.card_holder}</td>
                  </tr>
                  <tr>
                    <td>Expire year</td>
                    <td>{_raw.payment.expire_year}</td>
                  </tr>
                  <tr>
                    <td>Expire month</td>
                    <td>{_raw.payment.expire_month}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{_raw.payment.country}</td>
                  </tr>
                </tbody>
              </Table>
            </Panel>
          ) : null}

          {_raw.refunds ? (
            <Panel collapsible header="Refunds">
              {_raw.refunds.map((e, i) => (
                <Table striped condensed hover className="table" key={i}>
                  <tbody>
                    <tr>
                      <td>Transaction ID</td>
                      <td>{e.transaction}</td>
                    </tr>
                    <tr>
                      <td>App ID</td>
                      <td>{e.app_id}</td>
                    </tr>
                    <tr>
                      <td>Response code</td>
                      <td>{e.response_code}</td>
                    </tr>
                    <tr>
                      <td>Short ID</td>
                      <td>{e.short_id}</td>
                    </tr>
                    <tr>
                      <td>Updated at</td>
                      <td>{lib.dateUnixStr(e.updated_at)}</td>
                    </tr>
                    <tr>
                      <td>Created at</td>
                      <td>{lib.dateUnixStr(e.created_at)}</td>
                    </tr>
                    <tr>
                      <td>Livemode</td>
                      <td>{lib.boolFormat(e.livemode)}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>{e.description}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{e.status}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>{e.amount}</td>
                    </tr>
                    <tr>
                      <td>ID</td>
                      <td>{e.id}</td>
                    </tr>
                  </tbody>
                </Table>
              ))}
            </Panel>
          ) : null}

          {_raw.client ? (
            <Panel collapsible header="Client">
              <Table striped condensed hover className="table">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{_raw.client.id}</td>
                  </tr>
                  <tr>
                    <td>Created at</td>
                    <td>{lib.dateUnixStr(_raw.client.created_at)}</td>
                  </tr>
                  <tr>
                    <td>Updated at</td>
                    <td>{lib.dateUnixStr(_raw.client.updated_at)}</td>
                  </tr>
                  <tr>
                    <td>App ID</td>
                    <td>{_raw.client.app_id}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{_raw.client.description}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{_raw.client.email}</td>
                  </tr>
                </tbody>
              </Table>

              <div className="panel-body">
                <h5>Subscriptions</h5>
                {_raw.client.subscription ? (
                  <Table striped condensed hover className="table">
                    <tbody>
                      {_raw.client.subscription.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td>#{_raw.client.subscription.length - i}, ID</td>
                            <td>{e.id}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : null}

                <h5>Payment</h5>
                {_raw.client.payment ? (
                  <Table striped condensed hover bordered>
                    <tbody>
                      {_raw.client.payment.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td>#{_raw.client.payment.length - i}, ID</td>
                            <td>{e}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : null}
              </div>
            </Panel>
          ) : null}

          {_raw &&
          (transaction.provider === 'paymill' || !transaction.provider) ? (
            <Panel collapsible header="Other">
              <Table striped condensed hover className="table">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{_raw.id}</td>
                  </tr>
                  <tr>
                    <td>Short ID</td>
                    <td>{_raw.short_id}</td>
                  </tr>
                  <tr>
                    <td>Markable as fraud</td>
                    <td>{lib.boolFormat(_raw.is_markable_as_fraud)}</td>
                  </tr>
                  <tr>
                    <td>Refundable</td>
                    <td>{lib.boolFormat(_raw.is_refundable)}</td>
                  </tr>
                  <tr>
                    <td>Is fraud</td>
                    <td>{lib.boolFormat(_raw.is_fraud)}</td>
                  </tr>
                  <tr>
                    <td>response code</td>
                    <td>{_raw.response_code}</td>
                  </tr>
                  <tr>
                    <td>Updated at</td>
                    <td>{lib.dateUnixStr(_raw.updated_at)}</td>
                  </tr>
                  <tr>
                    <td>Created at</td>
                    <td>{lib.dateUnixStr(_raw.created_at)}</td>
                  </tr>
                  <tr>
                    <td>Currency</td>
                    <td>{_raw.currency}</td>
                  </tr>
                  <tr>
                    <td>Livemode</td>
                    <td>{lib.boolFormat(_raw.livemode)}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{_raw.description}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{_raw.status}</td>
                  </tr>
                  <tr>
                    <td>Origin amount</td>
                    <td>{_raw.origin_amount}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{_raw.amount}</td>
                  </tr>
                </tbody>
              </Table>
            </Panel>
          ) : (
            'No Raw information'
          )}
        </div>
      </div>
    );
  }
}

export default Transaction;
