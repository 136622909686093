import React from 'react';
import styles from './styles.module.scss';

type Props = {
  left?: () => JSX.Element;
  right?: () => JSX.Element;
};

const Footer = (props: Props): JSX.Element => {
  return (
    <footer className={styles.footer}>
      {props.left ? (
        <div style={{ display: 'inherit' }}>{props.left()}</div>
      ) : (
        <span />
      )}
      <div>{props.right()}</div>
    </footer>
  );
};

export default Footer;
