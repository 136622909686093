export const trailFragments = `timestamp
action
remoteAddr: ip
providerId
descriptor
message
status
statusText
`;

export const subscriptionFragments = `id
client
status
plan { id }
externalId
provider
providerId
ends: nextPaymentDate
starts: startDate
createdAt
updatedAt
canceledAt
active
meta
trail { ${trailFragments} }
transactions {
  id
  provider
  providerId
  status
  balance
  currency
  amount
  capturedAt
  createdAt
  dueDate
  meta
  invoices { id }
  card {
    scheme
    bin
    last4
  }
  trail { ${trailFragments} }
}`;

const rentalPreferenceFragments = `
id
active
accountId
locations
rent
size
rooms
rentalPeriod
available
dwellingTypes
requirements`;

const notificationTargetsFragments = `
id
lists
type
active
updatedAt
createdAt
disabledBy
disabledAt
meta
`;

const leaseAccessFragments = `
userId
leaseId
from
createdAt
`;
const leaseUnwantedFragments = `
userId
leaseId
from
createdAt
`;

const userFragments = `
id
name
email
emailStatus
firstName
middleName
lastName
username
cellphone: phone
meta
facebookId
facebookLink
createdAt
audit {
  id
}
facebookId
subscription {
  ${subscriptionFragments}
}
subscriptionPlan {
  id
}
rentalPreference {
  ${rentalPreferenceFragments}
  autoSuggest
  auto {
    ${rentalPreferenceFragments}
  }
  manuel {
    ${rentalPreferenceFragments}
  }
}
notificationTargets {
  ${notificationTargetsFragments}
}
leaseAccess {
  ${leaseAccessFragments}
}
leaseUnwanted{
  ${leaseUnwantedFragments}
}
pendingEmail
pendingCellphone
`;

export const GET_SUBSCRIPTIONS = `
  query Subscriptions($ids: [ID!]!) {
    _admin_subscriptions(ids: $ids) {
      ${subscriptionFragments}
    }
  }
`;

export const CANCEL = `
  mutation CancelSubscription($id: ID!, $meta: JSON, $cancelAt: Date, $approved: Boolean) {
    _admin_cancelSubscription(id: $id, meta: $meta, cancelAt: $cancelAt, approved: $approved) {
      ${subscriptionFragments}
    }
  }
`;

export const REACTIVATE = `
mutation ReactivateSubscription($id: ID!, $meta: JSON) {
  _admin_reactivateSubscription(id: $id, meta: $meta) {
    ${subscriptionFragments}
  }
}
`;
export const EXTEND = `
mutation ExtendSubscription($id: ID!, $days: Int!, $meta: JSON) {
  _admin_extendSubscription(id: $id, days: $days, meta: $meta) {
    ${subscriptionFragments}
  }
}
`;

export const GET_USER = `
query User($id: ID!) {
  _admin_user(id: $id) {
${userFragments}
  }
}
`;

export const TOGGLE_ALERTS = `
mutation ToggleAlerts($input: adminNotificationTargetInput!) {
  _admin_updateNotificationTarget(input: $input)
}
`;

export const GET_USERS = `
query Users($filter: FilterInput) {
  _admin_users(filter: $filter) {
    id
    name
    email
    cellphone: phone
    facebookId
    subscription {
      id
    }
    subscriptionPlan {
      id
    }
  }
}
`;
