import React, { Component } from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import { createClient } from 'app/utils/ApolloProvider';

export default class Biz extends Component {
  render() {
    //TODO: change to new url to get prefix --> mastertool_ <--  url: /api/graph/v1 when rest can supports is
    const client = createClient('/api/biz/mastertool', {
      cache: { addTypename: false }
    });
    return (
      <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
    );
  }
}
