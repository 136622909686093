import React, { Component } from 'react';
import ImageDasboard from './compare';

export default class Images extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <ImageDasboard />;
  }
}