import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Panel, Table } from 'react-bootstrap';

import client from 'app/utils/api-client';
import { titleCase } from 'app/utils/format';
import { dateFormatStr } from 'lib/format-date';
import ConfirmationButton from 'app/common/confirmation/button';

import TrailPanel from './TrailPanel';
import { fetcher } from 'app/utils/fetcher';
import { trailFragments } from 'app/boligninja/fragements';

// TODO: bsStyle based on transaction.status (green / yellow / red)

const REFUND = `
  mutation RefundTrans($input: RefundInput!) {
    _admin_refundTransaction(input: $input) {
      id
      status
      provider
      amount
      currency
      balance
      subscription
      providerId
      meta
      trail { ${trailFragments} }
      card {
        bin
        last4
        expiry
        scheme
      }
      error {
        timestamp
        code
        client
        merchant
        retryAt
      }
      createdAt
      capturedAt
    }
  }
`;

class TransactionPanel extends Component {
  static propTypes = {
    collapsible: PropTypes.bool,
    item: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      transaction: props.item
    };

    this.fieldHelpers = {
      card: (_, { card }) => (
        <span style={{ fontFamily: 'monospace' }}>
          {card.bin.substr(0, 4)} {card.bin.substr(4, 6)}
          ** **** {card.last4} <i className={`fa fa-fw fa-cc-${card.scheme}`} />
        </span>
      ),
      amount: (val, txn) => txn.balance ?
        `${(val / 100).toFixed(2).replace('.', ',')} ${txn.currency}` : 
        (
          <span>
            {(val / 100).toFixed(2).replace('.', ',')} {txn.currency}
            <ConfirmationButton
              label="Refund"
              onSubmit={this.refund}
              text="Please confirm, you want to refund the transaction"
              bsStyle="danger"
              bsSize="xs"
              inline
              className="pull-right"
            >
              <div>
                <div className="form-group">
                  <label>Amount to refund</label>
                  <div className="input-group">
                    <input
                      name="refund"
                      type="number"
                      pattern="^[0-9]+$"
                      max={val / 100}
                      defaultValue="0"
                      className="form-control"
                      placeholder="Amount"
                    />
                    <div className="input-group-addon">kr.</div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Reason / internal notes</label>
                  <textarea
                    className="form-control"
                    name="notes"
                    rows="4"
                    required
                    minLength="10"
                  />
                </div>
              </div>
            </ConfirmationButton>
          </span>
        )
        ,
      balance: (val, txn) => (
        <span>
          {(val / 100).toFixed(2).replace('.', ',')} {txn.currency}
          <ConfirmationButton
            label="Refund"
            onSubmit={this.refund}
            text="Please confirm, you want to refund the transaction"
            bsStyle="danger"
            bsSize="xs"
            inline
            className="pull-right"
          >
            <div>
              <div className="form-group">
                <label>Amount to refund</label>
                <div className="input-group">
                  <input
                    name="refund"
                    type="number"
                    pattern="^[0-9]+$"
                    max={val / 100}
                    defaultValue="0"
                    className="form-control"
                    placeholder="Amount"
                  />
                  <div className="input-group-addon">kr.</div>
                </div>
              </div>
              <div className="form-group">
                <label>Reason / internal notes</label>
                <textarea
                  className="form-control"
                  name="notes"
                  rows="4"
                  required
                  minLength="10"
                />
              </div>
            </div>
          </ConfirmationButton>
        </span>
      ),
      invoices: (invoices) =>
        invoices.map((invoice) => (
          <i
            key={invoice}
            className="fa fa-fw fa-file-pdf-o"
            style={{ color: 'red' }}
            onClick={() => {
              client.get('/api/auth/nonce').then((res) => {
                if (res && res.status === 'OK') {
                  global.location.href = `/api/invoices/boligninja/${invoice}.pdf?nonce=${res.nonce}`;
                }
              });
            }}
          />
        )),
      provider: (val, txn) => `${val}(${txn.providerId})`,
      createdAt: (val) => dateFormatStr(val),
      updatedAt: (val) => dateFormatStr(val),
      capturedAt: (val) => dateFormatStr(val)
    };
  }

  refund = async (confirmed, values) => {
    const { meta, id } = this.state.transaction;
    const { refund, notes } = values;
    if (refund) {
      if (meta?.legacyId) {
        // convert to minor units, using * 100
        this.props.updateTransaction({ refund: refund * 100 }, notes);
        return;
      }
      const data = await fetcher('/api/boligninja/graph-proxy', {
        query: REFUND,
        variables: {
          input: {
            transactionId: id,
            amount: Number(refund * 100),
            meta: { message: values.notes }
          }
        }
      });
      if (data?._admin_refundTransaction) {
        this.setState({
          transaction: data._admin_refundTransaction
        });
      }
    }
  };

  render() {
    const { collapsible } = this.props;
    const { transaction } = this.state;

    const panelProps = {
      collapsible,
      bsStyle:
        transaction.status === 'Capture'
          ? 'success'
          : transaction.status === 'Failed'
          ? 'danger'
          : transaction.status === 'Pending'
          ? 'warning'
          : 'warning',
      header: (
        <h4>
          Transaction <small>{transaction.id}</small>
        </h4>
      )
    };

    return (
      <Panel {...panelProps}>
        <Table striped condensed responsive hover fill>
          <tbody>
            {[
              'status',
              'createdAt',
              'updatedAt',
              'capturedAt',
              'amount',
              'balance',
              'invoices',
              'card',
              'provider',
              'orderno'
            ].map((key) => {
              const value = transaction[key];
              const helper = this.fieldHelpers[key];
              return value ? (
                <tr key={key}>
                  <th>{titleCase(key)}</th>
                  <td>{helper ? helper(value, transaction) : value}</td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>

        {transaction.error ? (
          <Panel bsStyle="danger" header="Transaction error">
            <Table striped condensed responsive hover fill>
              <tbody>
                <tr>
                  <th colSpan="2">
                    {transaction.error.code}: {transaction.error.message}
                  </th>
                </tr>
                <tr>
                  <th>Timestamp</th>
                  <td>{dateFormatStr(transaction.error.timestamp)}</td>
                </tr>
                <tr>
                  <th>Client / Merchant</th>
                  <td>
                    {transaction.error.client ? '1' : '0'} /{' '}
                    {transaction.error.merchant ? '1' : '0'}
                  </td>
                </tr>
                {transaction.error.retryAt ? (
                  <tr>
                    <th>Retry at</th>
                    <td>{dateFormatStr(transaction.error.retryAt)}</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </Panel>
        ) : null}

        <TrailPanel trail={transaction.trail} />
      </Panel>
    );
  }
}

export default TransactionPanel;
