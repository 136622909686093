import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';
import CronjobRow from './cronjob-row';
import { intervalSerializer } from './helper';

const columns = [
  { label: 'Client', name: 'clientName', valueType: 'string' },
  { label: 'Cronjob', name: 'action', valueType: 'string' },
  { label: 'Timing Type', name: 'timingType', valueType: 'string' },
  { label: 'Interval', name: 'interval', valueType: 'custom', customValue: intervalSerializer },
  { label: 'Created At', name: 'createdAt', valueType: 'date' },
  { label: 'Last Checked', name: 'lastChecked', valueType: 'date' },
  { label: 'Last Executed', name: 'lastExecuted', valueType: 'date' },
  { label: 'Active', name: 'active', valueType: 'checkmark' }
];

export default class Cron extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({ loading: true });
    apiClient.post('/api/microservice', { data: { action: 'Cron', data: {} } }).then((result) => {
      this.setState({ list: result.data.data.dashboard, loading: false });
    }).catch(err => { console.log(err); this.setState({ loading: false }); });
  }

  render() {
    const { loading, list } = this.state;
    return (
    <div>
        <div>
        <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/>
        </div>
        { loading ? <p>Loading...</p> : !list?.length ? <p>No Cronjobs</p> : (<Table
            title={`Cron jobs (${list.length})`}
            columns={columns}
            rows={list || []}
            detailOnClick={(row) => <CronjobRow id={row.action}/> }
        />)}
    </div>
    );
  }
}
