export type Scraper = {
  agent: string; // to be deprecated to be landlord and config
  subAgents?: Agent[];
  active: boolean | null; // to be deprecated to config.enabled
  note?: string;
  isScrapeable: boolean | null;
  config: ScraperConfig;
};

type ScraperConfig = {
  slug: string;
  project?: string;
  enabled: boolean;
  dataDest: DataDestination;
  maxItems: number;
  contactChecksum?: string;
  listChecksum?: string;
};

enum DataDestination {
  UDLEJERDK = 'UDLEJERDK',
  BACKEND = 'BACKEND',
  JSON = 'JSON'
}

type Agent = {
  id: number;
  name: string;
  phone: string;
  email: string;
  identifier: string;
  checksum?: string;
  maxItems: number;
  project?: string;
  enabled: boolean;
  dataDest: DataDestination;
};

export type deserializedScraper = {
  agent: string; // to be deprecated to be landlord and config
  subAgents?: Agent[];
  active?: boolean | null; // to be deprecated to config.enabled
  note?: string;
  isScrapeable: '_NULL' | '_FALSE' | '_TRUE';
  id: string; // SLUG
  project?: string;
  enabled: boolean;
  dataDest: DataDestination;
  maxItems: number;
  contactChecksum?: string;
  listChecksum?: string;
};

export function deserialized(scraper: Scraper): deserializedScraper {
  const { config, isScrapeable, ...rest } = scraper;
  return {
    isScrapeable:
      isScrapeable === null
        ? '_NULL'
        : isScrapeable === false
        ? '_FALSE'
        : '_TRUE',
    id: config?.slug || null,
    ...config,
    ...rest
  };
}

export function serialized(scraper: deserializedScraper): Scraper {
  const { active, agent, note, isScrapeable, maxItems, id, ...rest } = scraper;
  return {
    agent,
    note,
    active,
    isScrapeable:
      isScrapeable === '_NULL'
        ? null
        : isScrapeable === '_FALSE'
        ? false
        : true,
    config: {
      slug: id,
      maxItems: Number(maxItems),
      ...rest
    }
  };
}
