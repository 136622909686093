import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Link } from 'react-router';

import { formatDateStr } from './user-helper';

export default class Users extends Component {
  static propTypes = {
    subscription: PropTypes.object.isRequired
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { subscription } = this.props;
    const userId = subscription.user?.id;
    const userName = subscription.user?.name;
    const { canceledAt, id, status, stats, active } = subscription;
    const { renews, lifetimeValue } = stats || {};
    const rowClassName = canceledAt
      ? 'info'
      : status === 'Failed'
      ? 'fatal'
      : status === 'Pending'
      ? 'warning'
      : active === true
      ? 'success'
      : 'default';
    return (
      <tr className={rowClassName}>
        <td>
          {id}
          {subscription.meta?.legacyId ? ' (deprecated)' : ''}
        </td>
        <td>
          {userId ? (
            <Link to={`/akutbolig/users/${userId}`}>{userName || userId}</Link>
          ) : (
            userName || '-'
          )}
        </td>
        <td>{formatDateStr(subscription.starts)}</td>
        <td>{formatDateStr(subscription.ends)}</td>
        <td>
          {subscription.canceledAt && formatDateStr(subscription.canceledAt)}
        </td>
        <td>{subscription.plan.id}</td>
        <td>
          {renews || '-'} / {lifetimeValue || '-'}
        </td>
      </tr>
    );
  }
}
