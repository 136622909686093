import React, { SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router';

import { browserHistory } from 'react-router';
import debounce from 'lib/debounce';

// import { Row } from 'app/components/mui/Table/types'

type Row = {
  id: string;
  [index: string]: any;
};

type TableProps = RouteComponentProps & {
  sortable?: false;
  rows: Row[];
  persistState?: boolean;
  columns?: {
    name: string;
    filter?: boolean;
  }[];
};

type TableState = {
  rows: Row[];
  count: number;
  sortBy: string | null;
  sortDir: -1 | 1;
  filter: { [index: string]: any };
  offset: number;
  limit: number;
  filterValues: any;
};

const _setUrlSearchParams = (name: string, value: string): void => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.set(name, value);
  url.search = params.toString();
  const newURL = url.toString();
  window.history.pushState({}, '', newURL);
};

export default class GraphQLTableContainer<
  P extends TableProps
> extends React.Component<P, TableState> {
  constructor(props: P) {
    super(props);

    const rows = props.rows || [];

    const params = new URLSearchParams(document.location.search.substring(1));

    const filter = props.columns
      ? props.columns.reduce((acc, column) => {
          if (column.filter === true) {
            acc[column.name] = (params.get(column.name) as string) || '';
          }
          return acc;
        }, {})
      : {};

    const state = {
      rows,
      count: rows.length,
      sortBy: (params.get('sortBy') as string) || null,
      sortDir: (parseInt(params.get('sortDir'), 10) as -1 | 1) || -1,
      filter: filter,
      offset: 0,
      limit: 1000,
      filterValues: {}
    };

    Object.assign(state, this.filterRows(state, props));

    this.state = state;

    this.onFilterChange = this.onFilterChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ ...this.filterRows(this.state, nextProps) });
  }

  filterRows(state = this.state, props = this.props) {
    if (!props.rows) {
      return {};
    }

    const { filter, sortBy, sortDir } = state;

    const filters = Object.keys(filter).reduce((acc, key) => {
      if (filter[key]) {
        acc[key] =
          typeof filter[key] === 'string'
            ? new RegExp(filter[key], 'i')
            : filter[key];
      }
      return acc;
    }, {});
    const filterKeys = Object.keys(filters);

    let filteredRows = !filterKeys.length
      ? props.rows
      : props.rows.filter((row) => {
          let valid = true;
          filterKeys.forEach((key) => {
            if (typeof filters[key] === 'function') {
              if (!filters[key](row)) {
                valid = false;
              }
            } else if (filters[key] && !filters[key].test(row[key] || '')) {
              valid = false;
            }
          });
          return valid;
        });

    // const proto = {};
    // const body = Object.keys(filter).map(key => {
    //   const f = filter[key];
    //   if (typeof f === 'function') {
    //     proto[`_${key}`] = filter[key];
    //     return `if (!this._${key}(row)) return false;`;
    //   }
    //   if (f) {
    //     return `if (!${f.toString()}.test(${key === 'user' ? '(row._userid && `${row._userid.name} ${row._userid.email}`)' : `row[${key}]`})) return false;`;
    //   }
    // }).join(';');
    // const fn = new Function('row', `
    //   ${body}
    //   return true;
    // `);

    // const filteredRows = props.rows.filter(fn.bind(proto));
    // const count = filteredRows.length;

    if (sortBy) {
      // const arr = sortBy.split('.');
      const nullRows = [];

      filteredRows = filteredRows
        .map((row) => {
          if (row[sortBy] === null) {
            nullRows.push(row);
            return null;
          }
          return row;
        })
        .filter(Boolean)
        .sort((v1, v2) => {
          // const a = v1.getIn(arr);
          // const b = v2.getIn(arr);
          const a = v1[sortBy] || '';
          const b = v2[sortBy] || '';
          return a > b ? 1 : a < b ? -1 : 0;
        });

      if (sortDir === -1) {
        filteredRows = filteredRows.reverse();
      }
      filteredRows = filteredRows.concat(nullRows);
    }

    const rows = filteredRows.slice(state.offset, state.limit);
    const count = filteredRows.length;

    return { rows, count };
  }

  sortBy = (ev: SyntheticEvent<HTMLTableHeaderCellElement>) => {
    if (this.props.sortable === false) {
      return;
    }

    const sortBy = ev.currentTarget.getAttribute('data-column');
    const sortDir =
      sortBy !== this.state.sortBy ? -1 : (this.state.sortDir || 1) * -1;

    if (this.props.persistState === true) {
      _setUrlSearchParams('sortBy', sortBy);
      _setUrlSearchParams('sortDir', String(sortDir));
    }
    this.setState({
      sortBy,
      sortDir,
      ...this.filterRows({ ...this.state, sortBy, sortDir })
    });
  };

  refetch = debounce(() => {
    const {
      location: { pathname, query }
    } = this.props;
    browserHistory.push({
      pathname,
      query: { ...query, ...this.state.filterValues }
    });
    this.props.refetch();
  }, 1000);

  // Async filter
  _filterChange = (name, value) => {
    const { filterValues } = this.state;
    const changed = filterValues[name] !== value;
    if (changed) {
      this.setState(
        { filterValues: { ...filterValues, [name]: value } },
        this.refetch
      );
    }
  };

  onAsyncFilterChange = (ev) => {
    const { name, value } = ev.currentTarget;
    this._filterChange(name, value);
  };

  onFilterChange(ev) {
    if (ev) {
      const { name, value } = ev.currentTarget;
      if (this.props.persistState === true) {
        _setUrlSearchParams(name, value);
      }
      const filter = {
        ...this.state.filter,
        [name]: value || null
      };

      this.setState({ filter, ...this.filterRows({ ...this.state, filter }) });
    }
  }
}
