/*
- Liste over seneste lejemål, som kvalificeret til posting på en FB gruppe
- For hvert lejemål, visning af: id, timestamp, shortlink, klikbart link til fb gruppe
*/

import React from 'react';

import { graphql } from 'react-apollo';
import dwellingsQuery from './dwellings.graphql';

import List from './list';

class PostingListConnected extends React.Component {
  render() {
    return <List {...this.props} />;
  }
}

export default graphql(dwellingsQuery, {
  options: (/*props*/) => {
    return {
      variables: {
        inactive: false,
        limit: 50
      }
    };
  },
  props: ({ ownProps, data: { loading, dwellings } }) => ({
    ...ownProps,
    loading,
    rows: dwellings || []
  })
})(PostingListConnected);
