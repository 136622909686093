import React, { useRef, useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  makeStyles,
  MenuItem
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  }
}));

const Filter = (props) => {
  const classes = useStyles();
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (ev) => {
    props.setFilterParam(ev.target.name, ev.target.value);
  };

  return (
    <FormControl
      margin="dense"
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel ref={inputLabel} htmlFor="category-select">
        Category
      </InputLabel>
      <Select
        value={props.filterValue || ''}
        onChange={handleChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="category"
            id="category-select"
          />
        }
      >
        <MenuItem value={null} />
        {props.categories.map(({ label, value }, idx) => {
          return (
            <MenuItem key={value} value={value}>
              {`${idx + 1}. ${label}`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Filter;
