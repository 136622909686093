import React, { Component } from 'react';
import Table from './table';
import { connect } from 'react-redux';
import { getUsers } from '../../redux/modules/auth';
import ModalCreateUser from './UserModal';

class Users extends Component {
  static fetchData({ getState, dispatch }) {
    if (!getState().auth.users.length) {
      return dispatch(getUsers());
    }
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.users) {
      this.setState({ filteredRows: nextProps.users });
    }
  }

  modalCreateUser = () => {
    this.setState({ create: true });
  };

  modalClose = () => this.setState({ create: false });

  handleCreated = () => {
    this.modalClose();
    this.props.getUsers();
  };

  render() {
    const { users } = this.props;
    return (
      <div>
        <Table data={users} />

        <button
          type="button"
          className="btn btn-primary"
          onClick={this.modalCreateUser}
        >
          Create New User
        </button>

        {this.state.create === true ? (
          <ModalCreateUser
            onClose={this.modalClose}
            onCreate={this.handleCreated}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(
  ({ auth }, router) => ({
    users: auth.users,
    userId: router.params.id
  }),
  { getUsers }
)(Users);
