import React from 'react';
import Table from 'app/components/mui/Table';

const columns = [
    { label: 'Image', name: 'id', valueType: 'string' },
    { label: 'Lease', name: 'leaseId', valueType: 'string' },
    { label: 'Status', name: 'status', valueType: 'string' }
];

export function resultSerializer(result: { images: { id: string, leaseId: string, status: string }[]}): string {
    const allStatus = {};

    for (let idx = 0; idx < result.images.length; idx++) {
        const image = result.images[idx];
        if(allStatus[image.status]) {
            allStatus[image.status]++;
        }else {
            allStatus[image.status] = 1;
        }
    }

    const keys = Object.keys(allStatus);
    return keys.map((key) => `${key}: ${allStatus[key]}`).join(', ');
}

export default (row) => {
    return (<tr>
      <td colSpan={1}/>
      <td colSpan={11}>
        <div>
          <Table
            columns={columns}
            rows={row.result.images}
            />
        </div>
      </td>
    </tr>);
};