import { amber, green, red, blue } from '@material-ui/core/colors';

export const colors = {
  green: {
    '500': green[500],
    '600': green[600],
    '700': green[700]
  },
  amber: {
    '500': amber[500],
    '700': amber[700]
  },
  red: {
    '500': red[500],
    '700': red[700]
  },
  blue: {
    '500': blue[500]
  }
};
