import React, { useState } from 'react';
import Table from 'app/components/mui/Table';
import ConfirmationButton from 'app/common/confirmation/button';
import apiClient from 'app/utils/api-client';
import { deserializeGeography } from '../geography/serializeGeography';

type Props = {
  loading: boolean;
  geography: any;
  params: { slug: string };
  updateGeography: any;
};

const isBoolean = v => (/true/i.test(v) ? true : false);
const isNumber = v => parseFloat(v || 0);
const isDefault = v => v;

const LoadGeographies = (props: Props) => {
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const [newGeo, setNewGeo] = useState('');
    const [updatedGeo, setUpdatedGeo] = useState('');
    const [current, setCurrent] = useState(0);

    const fileReader = new FileReader();

    const handleOnChange = (e) => {
      setFile(e.target.files[0]);
    };

    const csvFileToArray = async data => {
      const keyMapping = new Map([
        ['Slug', { key: 'slug', parse: isDefault }],
        ['Area', { key: 'area', parse: isDefault }],
        ['Base', { key: 'base', parse: isDefault }],
        ['Type', { key: 'type', parse: isDefault }],
        ['Zipcodes', { key: 'zipcodes', parse: isDefault }],
        ['Coordinates', { key: 'coordinates', parse: isNumber }],
        ['Radius (meter)', { key: 'radius', parse: isNumber }],
        ['Listed', { key: 'listed', parse: isBoolean }],
        ['Suggested', { key: 'suggest', parse: isBoolean }],
        ['Homepage', { key: 'homepage', parse: isBoolean }],
        ['RentalPreference', { key: 'rentalpreference', parse: isBoolean }]
      ]);
      const array = data.split('\r');

      const result = [];
      const headers = array[0].split(',');

      for (let i = 1; i < array.length - 1; i++) {
        const obj = {};
        const str = array[i];
        let s = '';
        let flag = 0;

        for (let ch of str) {
          if (ch === '"' && flag === 0) {
            flag = 1;
          } else if (ch === '"' && flag == 1) {
            flag = 0;
          }
          if (ch === ',' && flag === 0) {
            ch = '|';
          }
          if (ch !== '"') {
            s += ch;
          }
        }

        const properties = s.split('|');
        for (const j in headers) {
          const { key, parse } = keyMapping.get(headers[j]) || {};
          if (!key) {
            continue;
          }
          if (properties[j]?.includes(',')) {
            obj[key] = properties[j].split(', ').map(item => parse(item.trim()));
          } else if (key === 'coordinates') {
            obj[key] = [0, 0];
          }else if (key === 'zipcodes') {
            if(properties[j]?.includes('-')) {
              const [start, end] = properties[j].split('-');
              const zipcodes = [];
              for (let idx = parseInt(start); idx <= parseInt(end); idx++) {
                zipcodes.push(`${idx}`);
              }
              obj[key] = zipcodes;
            } else {
              obj[key] = [parse(properties[j].replace('\n', ''))];
            }
          } else {
            obj[key] = parse(properties[j].replace('\n', ''));
          }
        }

        obj['active'] = false;
        result.push(obj);
      }

      setArray(result);
      const body = await apiClient.graph('/api/graph/dk/v1', { query: `query Geographies($filter: GeographyFilterInput) {
        geographies(filter: $filter) {
          area: name
          slug
          type
          base
          zipcodes
          coordinates
          radius
          listed
          suggest
          homepage
          rentalpreference
        }
      }
      `, variables: { filter: {} } });
      const { geographies } = body.data;
      const existingSlugs = geographies.map((g) => g.slug);

      const newGeos: string[] = [];
      const updatedGeos: string[] = [];
      for (let idx = 0; idx < result.length; idx++) {
        const geo = result[idx];
        if(existingSlugs.includes(geo.slug)) {
          updatedGeos.push(geo.slug);
        }else {
          newGeos.push(geo.slug);
        }
      }

      setNewGeo(newGeos.join(', '));
      setUpdatedGeo(updatedGeos.join(', '));
      setCurrent(0);
    };

    const handleOnSubmit = (e) => {
      e.preventDefault();

      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          csvFileToArray(text);
        };

        fileReader.readAsText(file);
      }
    };

    const handleOnSave = async() => {
      if(array.length) {
        setCurrent(0);
        for (let idx = 0; idx < array.length; idx++) {
          const geo = array[idx];
          const input = deserializeGeography(geo);
          await apiClient.graph('/api/graph/dk/v1', { query: `
            mutation AddGeographies($input: GeographyInput!) {
              addGeography(input: $input) {
                area: name
                slug
                type
                base
                zipcodes
                coordinates
                radius
                listed
                suggest
                homepage
                rentalpreference
              }
            }
          `, variables: { input } });
          setCurrent(idx + 1);
        }
      }
    };

    const columns = [
      { label: 'Slug', name: 'slug', link: 'slug' },
      { label: 'Area', name: 'area' },
      { label: 'Base', name: 'base' },
      { label: 'Type', name: 'type' },
      { label: 'Zipcodes', name: 'zipcodes', valueType: 'stringify' },
      { label: 'Coordinates', name: 'coordinates', valueType: 'stringify' },
      { label: 'Radius', name: 'radius', valueType: 'number' },
      { label: 'Listed', name: 'listed', valueType: 'checkmark' },
      { label: 'Suggested', name: 'suggest', valueType: 'checkmark' },
      { label: 'Homepage', name: 'homepage', valueType: 'checkmark' },
      { label: 'RentalPreference', name: 'rentalpreference', valueType: 'checkmark' }
    ];

    return (
      <div style={{ textAlign: 'left' }}>
        <form style={{ display: 'inlineGrid' }}>
          <input
            type={'file'}
            id={'csvFileInput'}
            accept={'.csv'}
            onChange={handleOnChange}
          />
          <br />
          <button
            onClick={(e) => {
              handleOnSubmit(e);
            }}
          >
            Load csv geographies
          </button>
          {
            array.length ? <ConfirmationButton
              label={'Save geographies'}
              onSubmit={handleOnSave}
              text="Please confirm, you want to save these changes"
              bsStyle={'success'}
              bsSize="xs"
              inline
              className="pull-right"
            >

            <label>New Geographies</label>
              <textarea
                className="form-control"
                name="New geographies"
                rows="10"
                minLength="10"
                disabled
                value={newGeo}
                />
                <br/>

            <label>Updated Geographies</label>
              <textarea
                className="form-control"
                name="Updated geographies"
                rows="10"
                minLength="10"
                disabled
                value={updatedGeo}
              />
            </ConfirmationButton> : null
        }
        {current ? <br/> : null }
        {current ? <label>Saving ... {current}/{array.length}</label> : null}
        </form>

        <br />

        <Table
          title={'Geographies example'}
          columns={columns}
          rows={array}
        />
      </div>
    );
  };

export default LoadGeographies;
