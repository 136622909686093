import React from 'react';
import { Route, IndexRoute } from 'react-router';

import Root from './root';
import Single from './single';
import List from './list';
import DetailPage from './details';
import v2 from './v2';

export default (
  <Route path="reports" component={Root} name="Reports">
    <Route path="details" name="details">
      <Route path=":detailType/:detailKey" component={DetailPage} />
      <IndexRoute component={List} />
    </Route>
    {v2}
    <Route path=":report" component={Single} />
    <IndexRoute component={List} />
  </Route>
);
