import React, { FC } from 'react';
import { Paper } from './Paper';
import InputField from 'app/components/mui/InputFields/InputField';
import DateTimePicker from 'app/biz/roster/Inputs/DateTimePicker';
import Button from 'app/components/mui/Button';
import Placement from './Placement';

type Props = {
  title?: string;
  inputs: { [key: string]: any }[];
  initValues: { [key: string]: any };
  nameSpace?: string;
  buttonProps: { label: string };
  onSubmitReq: (values: { [key: string]: any }) => Promise<any>;
  listProps: {
    lists: any[];
    component: any;
    onActionClick: (values: { [key: string]: any }) => void;
  };
};

export const FormWithList: FC<Props> = (props): JSX.Element => {
  const [state, setState] = React.useState(props.initValues);

  const ListComponent = props.listProps.component;
  const handleChange = (
    ev: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    if (ev.target && ev.target.name) {
      const { name, value } = ev.target;

      setState((prev) => ({ ...prev, [name]: value }));
    } else {
      setState((prev) => ({ ...prev, date: ev }));
    }
  };
  //TODO: change InputField to SimpleInputField to get out of formik
  const renderField = props.inputs.map((input) => {
    return input.name !== 'date' ? (
      <InputField
        key={input.id}
        onChange={handleChange}
        inputProps={{
          ...input,
          value: state[input.name] || ''
        }}
      />
    ) : (
      <DateTimePicker
        key={input.id}
        onChange={handleChange}
        value={state[input.name]}
        {...input}
      />
    );
  });

  return (
    <>
      <Paper title={props.title}>
        <form
          onSubmit={async (ev) => {
            ev.preventDefault();
            const res = await props.onSubmitReq(state);
            props.listProps.lists.push(
              (res && res.data && res.data[props.nameSpace]) || {}
            );
            setState(props.initValues);
          }}
        >
          {renderField}

          <Placement style={{ marginTop: '8px' }} place="right">
            <Button type="submit" size="small" {...props.buttonProps} />
          </Placement>
        </form>
        <div>
          <hr />
          {props.listProps &&
            props.listProps.lists.map((list) => {
              return (
                <ListComponent
                  key={list.id}
                  onActionReq={props.listProps.onActionClick}
                  {...list}
                />
              );
            })}
        </div>
      </Paper>
    </>
  );
};
