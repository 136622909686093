import * as actions from './actions';
import { subscribeAction, defaultStyle } from '../notification';

subscribeAction(
  Object.keys(actions).reduce((acc, action) => {
    // if (/_SUCCESS?$/.test(action)) {
    //   acc[actions[action]] = {
    //     onPage: {
    //       ...defaultStyle,
    //       headline: 'Success',
    //       description: action
    //     }
    //   };
    // }
    if (/_(FALSE|FAIL|FAILURE)$/.test(action)) {
      acc[actions[action]] = {
        onPage: {
          ...defaultStyle,
          bsStyle: 'danger',
          headline: 'Error',
          description: action
        }
      };
    }

    return acc;
  }, {})
);
