import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import ShowMore from './ShowMore';
import ImgTiles from './ImgTiles';
import Modal from '../Modal/Modal';
import Image from './Image';
import ImgTile from './ImgTile';
import Button from '../Button';
import apiClient from 'app/utils/api-client';

type Props = {
  images: { hash: string; status: string }[];
  imgSize?: string | { height: string; width: string };
  imgTileProps: any;
  lease?: string;
  gridProps: {
    col: any;
  };
};

const Gallery = (props: Props) => {
  const [show, setShow] = useState(false);
  const [displayedImage, setDisplayedImage] = useState(null);

  const deletedImages = props.images.filter((img) => img.status === 'DELETED');
  const activeImages = props.images.filter((img) => img.status === 'ACTIVE');

  const imgTileProps = {
    onDisplayImage: (image) => setDisplayedImage(image),
    ...props.imgTileProps
  };

  return (
    <>
      {
        props.lease ? <Button
          label={'Opdatere billeder'}
          size="small"
          onClick={() => apiClient.post(`api/dk/image/update/${props.lease}`)}
        /> : null
      }
      <Modal
        open={Boolean(displayedImage)}
        onClose={() => setDisplayedImage(null)}
      >
        <Image
          src={`https://img.akut-bolig.dk/297x225/${
            displayedImage && displayedImage.hash
          }.jpg`}
          srcSet={`https://img.akut-bolig.dk/597x450/${
            displayedImage && displayedImage.hash
          }.jpg 2x`}
        />
      </Modal>

      <Paper style={{ padding: '1em' }}>
        {deletedImages.length > 0 && (
          <ShowMore
            label="Show deleted"
            checked={show}
            onChange={() => setShow((prevShow) => !prevShow)}
          />
        )}
        <ImgTiles images={activeImages} imgTileProps={imgTileProps} />
        {show && (
          <ImgTiles images={deletedImages} imgTileProps={imgTileProps} />
        )}
      </Paper>
    </>
  );
};

export default Gallery;
