
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubscriptionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Subscription"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"plan"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"starts"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ends"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"renew"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canceledAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"deletedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"active"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"trail"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timestamp"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"action"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"remoteAddr"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"transactions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TransactionFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":282}};
    doc.loc.source = {"body":"#import \"./transaction-fragment.graphql\"\nfragment SubscriptionFields on Subscription {\n  id\n  plan\n  starts\n  ends\n  renew\n  canceledAt\n  deletedAt\n  active\n  status\n\n  trail {\n    timestamp\n    action\n    message\n    remoteAddr\n  }\n\n  transactions {\n    ...TransactionFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./transaction-fragment.graphql").definitions));


      module.exports = doc;
    
