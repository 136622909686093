import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { getUsers, updateUser } from 'app/redux/modules/boligbesked';
import Loading from 'app/common/loading';
import { TableContainer } from 'app/common/table';
import Row from './users-row';

class Users extends TableContainer {
  static fetchDataDeferred({ getState, dispatch }) {
    if (!getState().boligbesked.users.length) {
      return dispatch(getUsers());
    }
  }

  toggleSeeking(user) {
    this.props.updateUser(user.get('_id'), { seeking: !user.get('seeking') });
  }

  render() {
    const { rows } = this.state;

    if (!this.props.rows || !this.props.rows.size) {
      return <Loading />;
    }

    return (
      <div>
        <p>{`Showing ${rows.size} of ${this.props.rows.size} users`}</p>

        <Table striped condensed hover>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Cellphone</th>
              <th>Market</th>
              <th>Seeking</th>
            </tr>
            <tr>
              <th />
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="name"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by name"
                />
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="email"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by email"
                />
              </th>
              <th>
                <input
                  onChange={this.onFilterChange}
                  name="cellphone"
                  className="form-control"
                  style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                  placeholder="Filter by cellphone"
                />
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {rows.map((user) => (
              <Row
                key={user.get('_id')}
                user={user}
                updateUser={this.props.updateUser}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect(({ boligbesked: { users: rows } }) => ({ rows }), {
  updateUser
})(Users);
