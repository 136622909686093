import { format, parse } from 'date-fns';
import { StringBoolean } from 'app/reviewer/type';

export function toNumber(val: string, defaultValue = 0): number {
  return val ? Number(val) : defaultValue;
}

export function toBoolean(val: 0 | 1 | -1): true | false | null {
  return val === 1 ? true : val === -1 ? false : null;
}

export function fromBoolean(val: true | false | null): 0 | -1 | 1 {
  return val === true ? 1 : val === false ? -1 : 0;
}

export function fromDate(
  date: Date | string | number,
  dateFormat = 'dd-MM-yyyy HH:mm'
): string {
  if (date) {
    return format(new Date(date), dateFormat);
  }
  return null;
}

export function toDate(date: string, dateFormat = 'dd-MM-yyyy HH:mm') {
  if (date) {
    return parse(date, dateFormat, new Date());
  }
  return null;
}

export function fromBooleanToString(val: true | false | null): StringBoolean {
  return val === true
    ? StringBoolean._TRUE
    : val === false
    ? StringBoolean._FALSE
    : StringBoolean._NULL;
}
export function fromStringToBoolean(val: StringBoolean): boolean {
  return val === StringBoolean._TRUE
    ? true
    : val === StringBoolean._FALSE
    ? false
    : null;
}

export function fromNumberToString(val: number): string {
  if (val === null || val === undefined) {
    return '';
  }
  return String(val);
}
export function fromStringToNumber(val: string): number {
  if (val === '') {
    return null;
  }
  return Number(val);
}
