import React, { FC } from 'react';
import { format } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'app/components/mui/Icon';
import { Archive } from '@material-ui/icons';
import styles from './styles.module.scss';

type Props = {
  id: string;
  onActionReq: (id: string) => void;
  type: string;
  approval: {
    date: string;
    contact: string;
    admin: string;
    note?: string;
  };
  withdrawn?: {
    date: string;
    contact: string;
    admin: string;
    note?: string;
  };
};

export const ConsentItem: FC<Props> = React.memo(
  (props): JSX.Element => {
    return (
      <>
        <div className={styles.consentItem}>
          {props.withdrawn && <p style={{ color: 'red' }}>withdrawn</p>}
          <div className={styles.header}>
            <p>{`${format(
              new Date(
                props.withdrawn ? props.withdrawn.date : props.approval.date
              ),
              'yyyy-MM-dd HH:mm'
            )}`}</p>
            <p>{props.approval.contact}</p>
            <p>{props.type}</p>
            {!props.withdrawn && (
              <Tooltip title="withdraw" placement="top">
                <IconButton
                  onClick={() => props.onActionReq(props.id)}
                  aria-label="withdraw"
                  size="small"
                >
                  <Icon
                    icon={Archive}
                    fontSize="inherit"
                    style={{ color: '#ccc' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div>
            <p>{props.approval.note}</p>
          </div>
        </div>
        <hr />
      </>
    );
  }
);
