import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, } from 'react-apollo';

// import { Form, FormInput } from '../../common/form';
import Form from '../common/Form';
import FormInput from '../common/FormInput';
// import FormCellphone from '../common/FormCellphone';
import {
  Grid,
  Row,
  Col,
} from 'react-bootstrap';
import apiClient from 'app/utils/api-client';
import { FIELDS, LEASE_FIELDS, SUBSCRIPTION_FIELDS, CAPS_FIELDS } from './scraperChat-helper';
import Loading from 'app/common/loading';
import RentalPreference from './panels/RentalPrefernce';

class ScraperChat extends Component {
  static propTypes = {
    loading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      data: null,
      id: props.params.id,
      loading: false
    };

    if (props.params.id) {
      this.state.loading = true;
      this.getChat( props.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id && nextProps.id !== this.props.id) {
      this.setState({ loading: true });
      this.getChat(nextProps.id);
    }
  }

  getChat = (id) => {
    apiClient.post('/api/microservice', { data: { action: 'getScraperChat', data: { id } } })
        .then((result) => {
            this.setState({ ...result.data });
            this.setState({ loading: false });
        })
        .catch((err) => {console.log(err); this.setState({ loading: false });});
  };

  handleSubmit = (values) => {
    const data = {};
    values.forEach((value, key) => {
      data[key] = value;
    });

    this.setState({ updated: false });
    apiClient.post('/api/microservice', { data: { action: 'updateScraperChat', data: { id: this.props.params.id, input: data } } })
      .then((res) => {
        if(res.data.nModified) {
            this.setState({ updated: true });
        }
        this.getChat(this.props.params.id);
    })
      .catch((error) => alert(error));
  };

  render() {
    if (!this.state.chat) {
      return null;
    }
    if(this.state.loading) {
        return <Loading />;
    }
    const inputs = Object.keys(FIELDS).map((key) => {
      const { transform, ...props } = FIELDS[key];
      const value = this.state.chat[key];

      return (
        <FormInput
          key={key}
          name={key}
          defaultValue={transform ? transform(value) : value}
          {...props}
        />
      );
    });

    const leaseInputs = Object.keys(LEASE_FIELDS).map((key) => {
      const { transform, ...props } = LEASE_FIELDS[key];
      const value = key === 'url' ? this.state.lease?.contact?.url : this.state.lease?.[key];

      return (
        <FormInput
          key={key}
          name={key}
          defaultValue={transform ? transform(value) : value}
          {...props}
        />
      );
    });
    const capsInputs = Object.keys(CAPS_FIELDS).map((key) => {
      const { transform, ...props } = CAPS_FIELDS[key];
      const value = this.state.caps?.[key];

      return (
        <FormInput
          key={key}
          name={key}
          defaultValue={transform ? transform(value) : value}
          {...props}
        />
      );
    });

    const subscriptionInputs = Object.keys(SUBSCRIPTION_FIELDS).map((key) => {
      const { transform, ...props } = SUBSCRIPTION_FIELDS[key];
      const value = this.state.subscription?.[key];

      return (
        <FormInput
          key={key}
          name={key}
          defaultValue={transform ? transform(value) : value}
          {...props}
        />
      );
    });

    return (
      <div>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <Form onSubmit={this.handleSubmit} submitTxt="Update Chat" updated={this.state.updated} onChange={() => this.setState({ updated: false })}>
                {inputs}
              </Form>
            </Col>
            <Col sm={6}>
              <Form noButton alignLeft>
              {leaseInputs}
              {capsInputs}
              </Form>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form noButton>
              {subscriptionInputs}
              </Form>
            </Col>
            <Col sm={6}>
              <RentalPreference user={{ rentalPreference: this.state.preference }} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose()(ScraperChat);