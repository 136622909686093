import React, { FC } from 'react';
import Button from 'app/components/mui/Button';
import { Mutation } from 'app/components/graphql/Mutation';
import { ScraperForm } from './ScraperForm';
import gql from 'graphql-tag';
import {
  deserialized,
  serialized,
  Scraper as ScraperType,
  deserializedScraper
} from './helpers';

type Props = {
  landlord: string; //ID
  scrapers: ScraperType[];
  changeDataDest: (variables: { [key: string]: any }) => {};
};

type Inputs = {
  id: string;
  type?: string;
  name?: string;
  label?: string;
  options?: { value: string | boolean; label?: string }[];
}[];

const INPUTS: Inputs = [
  { id: 'id', name: 'id', label: 'slug' },
  {
    id: 'dataDest',
    options: [{ value: 'UDLEJERDK' }, { value: 'BACKEND' }, { value: 'JSON' }]
  },
  { id: 'project' },
  { id: 'agent' },
  {
    id: 'isScrapeable',
    options: [
      { value: '_TRUE', label: 'TRUE' },
      { value: '_FALSE', label: 'FALSE' },
      { value: '_NULL', label: '-' }
    ]
  },
  { id: 'note' },

  { id: 'maxItems', type: 'number' },
  { id: 'listChecksum' },
  { id: 'contactChecksum' }
];

const INIT_VALUES = {
  isScrapeable: '_NULL'
};

const CREATE_SCRAPER = gql`
  mutation($landlordId: ID!, $slug: ID!, $scraper: ScraperInput!) {
    addScraper(landlord: $landlordId, scraper: $scraper) {
      scraper(slug: $slug) {
        agent
        active
        note
        isScrapeable
        config {
          slug
          project
          enabled
          dataDest
          maxItems
          listChecksum
          contactChecksum
        }
      }
    }
  }
`;

const UPDATE_SCRAPER = gql`
  mutation($slug: ID!, $scraper: ScraperInput!) {
    updateScraper(slug: $slug, scraper: $scraper) {
      scraper(slug: $slug) {
        agent
        active
        note
        isScrapeable
        config {
          slug
          project
          enabled
          dataDest
          maxItems
          listChecksum
          contactChecksum
        }
      }
    }
  }
`;

const TYPE = {
  update: 'UPDATE',
  create: 'CREATE'
};

export const Scraper: FC<Props> = (props): JSX.Element => {
  const hasScraper = props.scrapers.length === 0;
  const [addNew, setAddNew] = React.useState(hasScraper);
  const [scrapers, setScrapers] = React.useState<Props['scrapers']>(
    props.scrapers
  );

  const submitHandler = async (
    values: deserializedScraper,
    {
      mutate,
      type
    }: {
      mutate: (variables: { [key: string]: any }) => Promise<any>;
      type: string;
    },
    slug?: string
  ) => {
    try {
      if (
        type === TYPE.update &&
        values.dataDest === 'UDLEJERDK' &&
        !/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.test(
          values.project
        )
      ) {
        alert('Please provide a project id');
        return;
      }

      if (type === TYPE.update) {
        const scraper = scrapers.find(
          (scraper) => scraper.config.slug === slug
        );
        const deserializedScraper = deserialized(scraper);
        if (
          deserializedScraper.dataDest !== values.dataDest ||
          deserializedScraper.project !== values.project
        ) {
          props.changeDataDest({
            variables: {
              slug,
              dataDestination: values.dataDest,
              project: values.project
            }
          });
        }
      }

      const serializedValues = serialized(values);
      const { data } = await mutate({
        variables: {
          landlordId: props.landlord,
          slug,
          scraper: { ...serializedValues }
        }
      });

      if (type === TYPE.create && data.addScraper) {
        setScrapers((prev) => [...prev, data.addScraper.scraper]);
        setAddNew(false);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!hasScraper && (
        <Button
          style={{ marginBottom: '1em' }}
          size="small"
          label={`${addNew ? 'close' : 'open'} for new Scraper`}
          onClick={() => setAddNew((prev) => !prev)}
        />
      )}
      {addNew && (
        <Mutation mutation={CREATE_SCRAPER}>
          {({ mutate, options }) => (
            <ScraperForm
              onSubmitReq={(values) =>
                submitHandler(values, { mutate, type: 'CREATE' }, values.id)
              }
              inputs={INPUTS}
              initValues={INIT_VALUES}
              loading={options.loading}
            />
          )}
        </Mutation>
      )}
      <Mutation mutation={UPDATE_SCRAPER}>
        {({ mutate, options }) => (
          <>
            {scrapers.map((scraper) => {
              const deserializedScraper = deserialized(scraper);
              return (
                <ScraperForm
                  onSubmitReq={(values) =>
                    submitHandler(
                      values,
                      { mutate, type: 'UPDATE' },
                      deserializedScraper.id
                    )
                  }
                  loading={options.loading}
                  key={deserializedScraper.id}
                  inputs={INPUTS}
                  initValues={deserializedScraper}
                />
              );
            })}
          </>
        )}
      </Mutation>
    </>
  );
};
