import React from 'react';
import leaseReportQuery from './leaseReportQuery.graphql';
import { GraphQLTableContainer } from '../../../common/table';
import Table from 'app/components/mui/Table';
import { Query } from 'app/components/mui/Query';
import DetailTab from './DetailTab';

const LINK_PATH = '/dk/reports';

class Reports extends GraphQLTableContainer {
  render() {
    const columns = [
      { label: 'Lease', name: 'leaseId' },
      { label: 'User', name: 'userId' },
      { label: 'Reason', name: 'reason' },
      { label: 'From', name: 'from' },
      { label: 'Created', name: 'createdAt' }
    ];
    return (
      <div>
        <Query
          query={leaseReportQuery}
          variables={{ filter: {} }}
        >
          {({ leaseReports: rows }) => (
            <Table
              url={LINK_PATH}
              title={'Lease Reports'}
              columns={columns}
              rows={rows}
              detailOnClick={(row) => <DetailTab row={row}/>}
            />
          )}
        </Query>
      </div>
    );
  }
}

export default Reports;
