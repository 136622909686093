import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Title } from '../Title';
import { fieldBuilder } from 'app/components/mui/utils/fieldBuilder';
import Button from 'app/components/mui/Button';
import InputField from './InputFields/InputField';
import { FieldArray, Field } from 'formik';
import Placement from '../Placement';
import Icon from './Icon';
import { Add } from '@material-ui/icons';
import OutgoingLink from './OutgoingLink';

type Props = {
  fields: { [key: string]: any };
  fieldOptions: any;
  onBlur?: () => void;
  onChange?: () => void;
  title?: JSX.Element | string;
  children?: JSX.Element | JSX.Element[];
  namespace: string;
  style?: {};
  gridProps?: {
    col: {};
  };
};

const FieldsArray = (props: Props) => {
  return (
    <Paper style={{ padding: '6px 16px 12px', ...props.style }}>
      {props.title && <Title variant="h6" component="p" text={props.title} />}
      <FieldArray
        name={props.namespace}
        render={(arrayHelper) => {
          return (
            <>
              {props.fields[arrayHelper.name].map((field, idx) => {
                return (
                  <React.Fragment key={`${arrayHelper.name}.${idx}`}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {fieldBuilder(field, props.fieldOptions).map((f) => {
                        return props.fieldOptions.isFormik ? (
                          <Field
                            {...f}
                            name={`${arrayHelper.name}.${idx}.${f.inputProps.name}`}
                            key={f.inputProps.name}
                            onBlur={props.onBlur || null}
                            onChange={props.onChange || null}
                            isFormik={props.fieldOptions.isFormik}
                            helperText={
                              f.inputProps.hasHelperText && (
                                <OutgoingLink
                                  text={'Åben siden'}
                                  href={f.inputProps.hasHelperText[idx].value}
                                />
                              )
                            }
                            component={InputField}
                          />
                        ) : (
                          <InputField key={f.inputProps.id} {...f} />
                        );
                      })}
                    </div>
                    <hr />
                  </React.Fragment>
                );
              })}

              {props.fieldOptions.isFormik && props.fieldOptions.newFields && (
                <Placement place={'right'}>
                  <Button
                    fab
                    onClick={() => {
                      arrayHelper.push(props.fieldOptions.newFields);
                    }}
                    size={'small'}
                  >
                    <Icon icon={Add} />
                  </Button>
                </Placement>
              )}
            </>
          );
        }}
      />
      {props.children}
    </Paper>
  );
};

export default FieldsArray;
