import lib from 'lib/format-date';

export const formatDateStr = lib.dateFormatStr;

export const FIELDS = {
  id: {
    label: 'ID',
    type: 'text',
    readOnly: true
  },
  trigger: {
    label: 'Trigger',
    type: 'text'
  },
  reason: {
    label: 'Reason',
    type: 'text'
  },
  active: {
    label: 'Active',
    type: 'checkbox'
  },
  createdAt: {
    label: 'Created',
    type: 'text',
    transform: formatDateStr,
    readOnly: true
  }
};