import React from 'react';
import { Paper } from '@material-ui/core';
import Icon from './Icon';

type Props = {
  color: string;
  bold?: boolean;
  text: string;
  icon?: any;
};

const Banner = (props: Props): JSX.Element => {
  return (
    <Paper
      style={{
        padding: '12px 16px',
        color: '#fff',
        marginRight: '1em',
        backgroundColor: props.color
      }}
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: props.bold ? 'bold' : 'normal'
        }}
      >
        {props.icon && (
          <Icon icon={props.icon} style={{ marginRight: '.5em' }} />
        )}
        {props.text}
      </span>
    </Paper>
  );
};

export default Banner;
