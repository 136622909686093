import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../redux/modules/auth';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validation: {
        username: null,
        password: null
      }
    };
  }

  login = (ev) => {
    ev.preventDefault();
    const username = this.refs.username.value;
    const password = this.refs.password.value;
    this.props.login({ username, password }).then((action) => {
      if (!action.payload || action.payload.status === 'ERROR') {
        this.setState({ validation: { username: 'error', password: 'error' } });
      }

      return action;
    });
  };

  render() {
    const { user } = this.props;
    const { validation } = this.state;

    return !user.token ? (
      <form className="login-form" onSubmit={this.login}>
        <div
          className={`form-group${
            validation.username
              ? ` has-feedback has-${validation.username}`
              : ''
          }`}
        >
          <label className="control-label" htmlFor="username">
            Username
          </label>
          <div className="input-group">
            <span className="input-group-addon" style={{ minWidth: 40 }}>
              @
            </span>
            <input
              type="email"
              ref="username"
              placeholder="Username"
              className="form-control"
              name="username"
            />
          </div>
          {validation.username === 'error' ? (
            <span
              className="glyphicon glyphicon-remove form-control-feedback"
              aria-hidden="true"
            />
          ) : null}
        </div>
        <div
          className={`form-group${
            validation.password
              ? ` has-feedback has-${validation.password}`
              : ''
          }`}
        >
          <label className="control-label" htmlFor="username">
            Password
          </label>
          <div className="input-group">
            <span className="input-group-addon" style={{ minWidth: 40 }}>
              <i className="fa fa-key" />
            </span>
            <input
              type="password"
              ref="password"
              placeholder="Password"
              className="form-control"
              name="password"
            />
          </div>
          {validation.password === 'error' ? (
            <span
              className="glyphicon glyphicon-remove form-control-feedback"
              aria-hidden="true"
            />
          ) : null}
        </div>
        <div className="form-group">
          <button className="btn btn-success btn-block" onClick={this.login}>
            <i className="fa fa-sign-in" /> Sign In
          </button>
        </div>
      </form>
    ) : null;
  }
}
export default connect(({ auth: { user } }) => ({ user }), { login })(Login);
