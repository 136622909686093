import { toBoolean, fromBoolean } from './serializeHelpers';

const MS_ONE_DAY = 86400000;
const MS_ONE_HOUR = 3600000;

function expireIn(a, b) {
  if (!a) {
    return null;
  }
  const timeDiff = new Date(a).getTime() - new Date(b).getTime();
  const hours = Math.floor(timeDiff / MS_ONE_HOUR);
  const days = Math.floor(timeDiff / MS_ONE_DAY);
  if (hours < 0) {
    return 'Expired';
  }

  return hours > 24 && days > 0 ? `${days} days` : `${hours} hours`;
}

export const serializeDwelling = (dwelling) => {
  const {
    id,
    agent,
    address,
    street,
    streetNo,
    zip,
    city,
    geoLat,
    geoLng,
    geoPrecision,
    geoPov,
    geoTs,
    m2,
    rooms,
    rent,
    expenses,
    expensesAc,
    expensesHeat,
    expensesWater,
    expensesElectricity,
    expensesTv,
    expensesImprovements,
    expensesMisc,
    prepaidRent,
    deposit,
    downpayment,
    available,
    timeframe,
    timeframeMin,
    remarks,
    meta,
    type,
    typeDwelling,
    typeTenant,
    residenceduty,
    animals,
    furnished,
    url,
    phone1,
    phone2,
    email,
    agentRef,
    agentUnique,
    createdAt,
    updatedAt,
    deletedAt,
    expireAt,
    liveCheck,
    inactive,
    contact,
    images,
    actions = [],
    description,
    headline,
    deactivatedBy,
    ...rest
  } = dwelling;

  return {
    id,
    agent: agent && {
      ...agent,
      nonprofit: toBoolean(agent.nonprofit)
    },
    contactInfo: {
      agentRef,
      contact,
      phone1,
      phone2,
      email,
      url
    },
    location: {
      address,
      street,
      streetNo,
      zip,
      city,
      geo: {
        lat: geoLat,
        lng: geoLng,
        precision: geoPrecision
        // Pov: geoPov,
        // Ts: geoTs
      }
    },
    economy: {
      rent,
      prepaidRent,
      deposit,
      downpayment,
      expenses,
      ac: expensesAc,
      heat: expensesHeat,
      water: expensesWater,
      electricity: expensesElectricity,
      tv: expensesTv,
      improvements: expensesImprovements,
      misc: expensesMisc
    },
    extra: {
      m2,
      rooms,
      animals: toBoolean(animals),
      residenceduty: toBoolean(residenceduty),
      furnished: toBoolean(furnished),
      available,
      timeframeMin,
      timeframe,
      type,
      typeDwelling,
      typeTenant
    },
    description: {
      headline,
      remarks,
      description
    },
    images,
    inactive,
    technicalData: {
      createdAt,
      updatedAt,
      expireAt,
      expireIn: expireIn(expireAt, new Date()),
      deletedAt,
      deactivatedBy,
      liveCheck,
      agentUnique
    },
    actions:
      !actions.length && __DEV__
        ? [
            {
              timestamp: '2019-10-03T08:46:45.866Z',
              name: 'geocoding',
              meta: null,
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:46.154Z',
              name: 'images',
              meta: null,
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:48.069Z',
              name: 'notification',
              meta: {
                consumer: 'akutbolig',
                medium: 'smartphone',
                accepted: 11,
                rejected: 0
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:48.066Z',
              name: 'notification',
              meta: {
                consumer: 'akutbolig',
                medium: 'email',
                accepted: 374,
                rejected: 0
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:49.010Z',
              name: 'notification',
              meta: {
                consumer: 'boligninja',
                medium: 'email',
                accepted: 25,
                rejected: 0
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:49.136Z',
              name: 'facebook-share',
              meta: {
                link: 'http://r6.dk/fZJH36',
                pageId: '1663591713870855',
                postId: '1663591713870855_2620203494876334',
                primary: true,
                repost: false
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:50.289Z',
              name: 'notification',
              meta: {
                consumer: 'boligbesked',
                medium: 'email',
                accepted: 13,
                rejected: 0
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:49.010Z',
              name: 'notification',
              meta: {
                consumer: 'boligninja',
                medium: 'email',
                accepted: 25,
                rejected: 0
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:49.136Z',
              name: 'facebook-share',
              meta: {
                link: 'http://r6.dk/fZJH36',
                pageId: '1663591713870855',
                postId: '1663591713870855_2620203494876334',
                primary: true,
                repost: false
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:50.289Z',
              name: 'notification',
              meta: {
                consumer: 'boligbesked',
                medium: 'email',
                accepted: 13,
                rejected: 0
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:49.010Z',
              name: 'notification',
              meta: {
                consumer: 'boligninja',
                medium: 'email',
                accepted: 25,
                rejected: 0
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:49.136Z',
              name: 'facebook-share',
              meta: {
                link: 'http://r6.dk/fZJH36',
                pageId: '1663591713870855',
                postId: '1663591713870855_2620203494876334',
                primary: true,
                repost: false
              },
              __typename: 'DwellingAction'
            },
            {
              timestamp: '2019-10-03T08:46:50.289Z',
              name: 'notification',
              meta: {
                consumer: 'boligbesked',
                medium: 'email',
                accepted: 13,
                rejected: 0
              },
              __typename: 'DwellingAction'
            }
          ]
        : actions,
    meta,
    rest
  };
};

export function serializeDwellings(leases) {
  return leases.map((lease) => {
    const { expireAt, ...rest } = lease;
    return {
      ...rest,
      expireIn: expireIn(expireAt, new Date())
    };
  });
}

export const deserializeDwellingEconomy = (values) => {
  const {
    ac,
    heat,
    water,
    electricity,
    tv,
    improvements,
    misc,
    rent,
    prepaidRent,
    deposit,
    expenses
  } = values;
  return {
    rent: Number(rent),
    prepaidRent: Number(prepaidRent),
    deposit: Number(deposit),
    expenses: Number(expenses),
    expensesAc: Number(ac),
    expensesHeat: Number(heat),
    expensesWater: Number(water),
    expensesElectricity: Number(electricity),
    expensesTv: Number(tv),
    expensesImprovements: Number(improvements),
    expensesMisc: Number(misc)
  };
};

export const deserializeDwellingInfo = (values) => {
  const {
    animals,
    // available,
    furnished,
    m2,
    residenceduty,
    // rooms,
    // timeframe,
    // timeframeMin,
    // typeDwelling,
    // typeTenant,
    type,
    ...rest
  } = values;
  return {
    m2: Number(m2),
    animals: fromBoolean(animals),
    residenceduty: fromBoolean(residenceduty),
    furnished: fromBoolean(furnished),
    ...rest
  };
};
