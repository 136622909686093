/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import Table from './Table';
import './styles/App.css';
import SelectField from './Inputs/Select';
import { options, icons, parentChildData } from './TableOptions';
import { getData } from './fetchData';
import { connect } from 'react-redux';
import { onPageNotify } from '../../redux/modules/notification';
import OptionsPanel from './OptionsPanel';
import { Chip } from '@material-ui/core';
import { Row } from './types';
import Button from '@material-ui/core/Button';

type RowData = Row;

type Props = {
  router: {
    push: (to: string) => void;
  };
  location: {
    query: { [key: string]: any };
    pathname: string;
    search: string;
  };
  onPageNotify: (options: {
    headline: string;
    description: string;
    bsStyle: string;
    dismissAfter: number;
  }) => void;
};

const LINKS = `
query LinkManager($hostname: String, $category: Category, $page: Int) {
  links(hostname: $hostname, category: $category, page: $page) {
    id
    href
    hostname
    category
    count
  }
  categories
}`;

const EXPAND_LINKS = `
query ExtraLinkManager($hostname: String, $category: Category) {
  links(hostname: $hostname, category: $category) {
    id
    href
    hostname
  }
}`;

const PADDING = '1em';

export const placeholder = (curr) => {
  return {
    id: `${curr.id}_1`,
    href: 'Loading...',
    hostname: '',
    parentId: curr.id
  };
};

const mapFetchData = (data) => {
  return data.reduce((arr, curr) => {
    arr.push(curr);
    if (!curr.parentId) {
      arr.push(placeholder(curr));
    }
    return arr;
  }, []);
};

const LinkManager: React.FC<Props> = (props) => {
  const { router, location } = props;
  const [showModal, setShowModal] = useState(false);
  const [reload, triggerReload] = useState(0);
  const [page, setPage] = useState(1);
  const [links, setLinks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const onTriggerReload = () => {
    triggerReload(reload + 1);
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const category: string = location.query.category;
        const hostname: string = location.query.hostname;
        const data = await getData({
          query: LINKS,
          variables: {
            category: category === 'null' ? null : category,
            page,
            hostname
          }
        });
        const categories = data.categories.map((category) => ({
          label: category,
          value: category
        }));
        const mappedData = mapFetchData(data.links);
        setLinks(mappedData);
        setCategories(categories);
      } catch (error) {
        console.error(error);
        props.onPageNotify({
          headline: 'Failed',
          description: 'Something went wrong while fetching for API',
          bsStyle: 'danger',
          dismissAfter: 5000
        });
      }
      setLoading(false);
    })();
  }, [reload, location.query.hostname, location.query.category]);

  const onNavigate = React.useCallback((name: string, value: string) => {
    router.push(`${location.pathname}?${name}=${value}`);
  }, []);

  const goBack = React.useCallback(() => {
    router.push('/biz/linktool');
  }, []);

  const onExpandChange = React.useCallback(
    async (parent: RowData, expand: boolean) => {
      const filter = (row) => row.parentId !== parent.id;
      if (expand) {
        const data = await getData({
          query: EXPAND_LINKS,
          variables: { hostname: parent.hostname }
        });
        setLinks((prev) => {
          return prev
            .filter(filter)
            .concat(
              data.links.map((link) => ({ ...link, parentId: parent.id }))
            );
        });
      } else {
        setLinks((prev) => prev.filter(filter).concat([placeholder(parent)]));
      }
    },
    []
  );

  const columns = [
    {
      title: 'Count',
      field: 'count',
      sorting: true,
      width: '65px',
      render: (rowData: RowData) => {
        return (
          !rowData.parentId && (
            <Chip
              variant="outlined"
              style={{ fontWeight: 300, height: '18px' }}
              label={rowData.count || '--'}
              color="primary"
              size="small"
              onClick={() => onNavigate('hostname', rowData.hostname)}
            />
          )
        );
      }
    },
    {
      title: 'Category',
      sorting: false,
      width: '200px',
      render: (rowData: RowData) => {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {!rowData.parentId && (
              <SelectField data={rowData} options={categories} />
            )}
          </span>
        );
      }
    },
    {
      title: 'Urls',
      field: 'href',
      filter: true,
      render: (rowData: RowData) => {
        const url = rowData.href;
        const match = url.match(/^([^:]+:\/\/)([^/]+)(\/.*)?$/);
        const link = url.match(/^http/);

        return (
          <>
            <span style={{ marginLeft: rowData.parentId && PADDING }}>
              {link ? (
                <a
                  style={{ marginLeft: rowData.parentId && PADDING }}
                  href={url}
                  target="_black"
                  rel="noopener noreferrer"
                >
                  {match ? (
                    <>
                      {match[1]}
                      <b>{match[2]}</b>
                      {match[3]}
                    </>
                  ) : (
                    url
                  )}
                </a>
              ) : (
                url
              )}
            </span>
          </>
        );
      }
    }
  ];
  return (
    <>
      <OptionsPanel
        onTriggerReload={onTriggerReload}
        onPageNotify={props.onPageNotify}
        setShowModal={setShowModal}
        showModal={showModal}
        categories={categories}
        filterValue={props.location.query.category}
        setFilterParam={onNavigate}
        onBack={goBack}
        showBack={location.search}
      />
      <Table
        isLoading={loading}
        title="Link Tool"
        data={links}
        columns={columns}
        parentChildData={parentChildData}
        onTreeExpandChange={onExpandChange}
        options={options}
        icons={icons}
      />
      <br />
        <div className="right">
        {page > 1 ? <Button onClick={() => { setPage(page - 1); onTriggerReload(); }}>Forrige side</Button> : null}
        <span style={{ margin: '30px' }}>SIDE: {page}</span>
        <Button onClick={() => { setPage(page + 1); onTriggerReload(); }}>Næste side</Button>
      </div>
    </>
  );
};

export default connect(() => ({}), { onPageNotify })(LinkManager);
