import React from 'react';
import styles from './styles.module.scss';
import { List, ListItem, ListItemText } from '@material-ui/core';
import MainView from './MainView';
import { Values, ReviewableSerializer } from './type';
import { format } from 'date-fns';
import { patch } from 'jsondiffpatch';
import { Link } from 'app/components/Link';

type Props = {
  reviewables: Values[];
  loading: boolean;
  onSubmit: (
    approved: boolean,
    values: ReviewableSerializer
  ) => Promise<boolean>;
  // onReject: (values: ReviewableSerializer) => Promise<boolean>;
};
function getValues(values: Values) {
  return {
    left: values.reviewDifference
      ? patch(JSON.parse(JSON.stringify(values)), values.reviewDifference)
      : values,
    right: values
  };
}

const excludeFields = [
  'agentUnique',
  'createdAt',
  'listingFlags',
  'reviewDifference',
  'geoPrecision',
  'geoType',
  'geoCoordinates',
  'snippet',
  'listingType'
];

const Reviewer: React.FC<Props> = (props) => {
  const init = props.reviewables[0];
  const [sidebarList, setSidebarList] = React.useState(props.reviewables);
  const [selectedReview, setSelectedReview] = React.useState(() => {
    const data = getValues(init);
    return {
      fields: Object.keys(init || {}).filter(
        (key) => !excludeFields.includes(key)
      ),
      ...data
    };
  });

  const pickReviewHandler = React.useCallback(
    (id: string) => {
      const reviewable = sidebarList.find(
        (reviewable) => reviewable.agentUnique === id
      );
      const data = getValues(reviewable);
      setSelectedReview((prev) => ({
        ...prev,
        ...data
      }));
    },
    [sidebarList]
  );

  const removeItem = React.useCallback((id: string) => {
    setSidebarList((prev) => {
      const filtedList = prev.filter((item) => item.agentUnique !== id);
      if (filtedList.length !== 0) {
        pickReviewHandler(filtedList[0].agentUnique);
      }
      return filtedList;
    });
  }, []);

  const onApproveHandler = React.useCallback(
    async (approved: boolean, values: ReviewableSerializer) => {
      if (await props.onSubmit(approved, values)) {
        removeItem(values.agentUnique);
      }
    },
    []
  );

  return (
    <div className={styles.outerWrap}>
      <div className={styles.sideMenu}>
        <List component="ul">
          {sidebarList.map((reviewable) => {
            const id = reviewable.agentUnique;
            const selected = id === selectedReview.left.agentUnique;
            const createdAt =
              reviewable.createdAt &&
              format(new Date(Number(reviewable.createdAt)), 'dd-MM-yyy HH:mm');
            return (
              <ListItem
                button
                dense
                selected={selected}
                key={id}
                onClick={() => pickReviewHandler(id)}
              >
                <ListItemText
                  primary={
                    <div className={styles.listItemContent}>
                      <div>
                        {reviewable.address}
                        <span className={styles.subPrimary}>({id})</span>
                      </div>
                      <div>
                        <Link
                          outGoing
                          href={reviewable.contactUrl}
                          className={styles.link}
                        >
                          <i className="fa fa-external-link" />
                        </Link>
                      </div>
                    </div>
                  }
                  secondary={createdAt}
                />
              </ListItem>
            );
          })}
        </List>
      </div>

      <MainView
        loading={props.loading}
        {...selectedReview}
        onApproved={onApproveHandler}
      />
    </div>
  );
};

export default Reviewer;
