import React from 'react';
import { GET_USERS } from './fragements';
import { fetcher } from 'app/utils/fetcher';
import UsersTable from './UsersTable';

const User = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const { name, email, cellphone } = props.location.query;
  React.useEffect(() => {
    setLoading(true);
    fetcher('/api/akutbolig/graph-proxy', {
      query: GET_USERS,
      variables: {
        filter: {
          limit: 250,
          sort: '-id',
          name: name || undefined,
          email: email || undefined,
          cellphone: cellphone || undefined
        }
      }
    })
      .then((data) => {
        setRows(data?._admin_users || []);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [name, email, cellphone]);

  return <UsersTable loading={loading} rows={rows} location={props.location} />;
};

export default User;
