export const ON_PAGE_NOTIFY = 'notification/ON_PAGE_NOTIFY';
export const ON_PAGE_HIDE = 'notification/ON_PAGE_HIDE';

export const DESKTOP_NOTIFY = 'notification/DESKTOP_NOTIFICATION_STATUS';

let __notificationCounter = 0;

export const defaultStyle = {
  bsStyle: 'success',
  dismissAfter: 4000,
  show: true
};

const initialState = {
  desktop: {
    title: null
  },
  onPage: {
    show: null,
    headline: null,
    description: null,
    bsStyle: null,
    dismissAfter: null
  }
};

export function onPageNotify(data) {
  return {
    type: ON_PAGE_NOTIFY,
    payload: data
  };
}

export function onPageHide() {
  return {
    type: ON_PAGE_HIDE
  };
}

export function desktopNotify(data) {
  return {
    type: DESKTOP_NOTIFY,
    response: data
  };
}

export const notificationHooks = new Map();

export function subscribeAction(hooks) {
  Object.keys(hooks).forEach((type) => {
    if (__DEV__ && notificationHooks.has(type)) {
      console.error(
        `Notification hook for '${type}' has already been declared`
      );
    }
    notificationHooks.set(type, hooks[type]);
  });
}

export default function notification(state = initialState, action) {
  const type = action.type;

  if (type === ON_PAGE_NOTIFY) {
    const payload = action.payload;
    return {
      ...state,
      onPage: {
        __notificationCounter: ++__notificationCounter,
        headline: payload.headline,
        description: payload.description,
        bsStyle: payload.bsStyle,
        dismissAfter: payload.dismissAfter,
        show: true
      }
    };
  }
  if (type === ON_PAGE_HIDE) {
    return {
      ...state,
      onPage: {
        show: false
      }
    };
  }
  if (type === DESKTOP_NOTIFY) {
    return {
      ...state,
      desktop: {
        ...action.response,
        timestamp: Date.now()
      }
    };
  }

  if (notificationHooks.has(type)) {
    const notification = notificationHooks.get(type);
    const newState = {
      ...state,
      ...notification
    };
    if (notification.onPage) {
      newState.onPage.__notificationCounter = ++__notificationCounter;
    }
    return newState;
  }

  return state;
}
