import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Panel, Table, Popover, Overlay } from 'react-bootstrap';

import LeaseAccessRow from './lease-access-row';

export default class LeaseAccessPanel extends Component {
  static propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.string.isRequired,
        leaseId: PropTypes.string.isRequired,
        from: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired
      })
    ).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: null
    };
  }

  expand = (ev, data) => {
    try {
      const target = ev.target;
      const content = <pre>{JSON.stringify(data, null, 2)}</pre>;
      this.setState({ expanded: { target, content } });
    } catch (e) {
      if (__DEV__) {
        console.error(e);
      }
    }
  };

  collapse = (ev) => {
    this.setState({ expanded: null });
  };

  render() {
    const { list = [] } = this.props;

    return list.length ? (
      <div>
        <Panel collapsible header={`Lease Access (${list.length})`}>
          <Table striped condensed responsive hover fill>
            <tbody>
              <tr>
                <th style={{ width: '40%' }}>Time</th>
                <th>Lease</th>
                <th>From</th>
              </tr>
              {list.map((t) => (
                <LeaseAccessRow
                  key={t.id}
                  item={t}
                  onExpand={this.expand}
                />
              ))}
            </tbody>
          </Table>
        </Panel>

        {this.state.expanded && (
          <Overlay target={this.state.expanded.target} placement="top" show>
            <Popover onClick={this.collapse} id="popover-positioned-top">
              {this.state.expanded.content}
            </Popover>
          </Overlay>
        )}
      </div>
    ) : null;
  }
}
