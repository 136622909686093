import { TextFieldProps } from 'app/components/mui/InputFields/InputField';

// create an array of what to NOT include
const fieldNotWanted = [
  'agents',
  'dwellings',
  '__typename',
  'images',
  'geo',
  'utilities'
];

export const fieldBuilder = (
  fields: { [key: string]: any },
  fieldOptions: any
): TextFieldProps[] => {
  const field = Object.keys(fields).map((key) => {
    if (fieldNotWanted.includes(key)) {
      return null;
    }

    const { options = false, parent, ...inputProps } = fieldOptions[key] || {};

    const prefixedOptions = [
      { value: true, label: 'TRUE' },
      { value: false, label: 'FALSE' },
      { value: '_null', label: '-' }
    ];

    return {
      inputProps: {
        id: key,
        name: parent ? `${parent}.${key}` : key,
        label: key,
        type: 'text',
        fullWidth: true,
        value: fieldOptions.isFormik ? null : fields[key],
        multiline: true,
        options:
          options && typeof options === 'boolean' ? prefixedOptions : options,
        ...inputProps
      }
    } as TextFieldProps;
  });
  const filtered = field.filter(Boolean);
  return filtered;
};
