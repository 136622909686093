import React, { useState } from 'react';
import SCRAPER_SETTINGS from './scraper-settings.graphql';
import SCRAPER_SETTINGS_UPDATE from './scraper-settings-update.graphql';
import Layout from 'app/components/mui/Layout/Layout';
import Fields from 'app/components/mui/Fields';
import Form from 'app/components/mui/Form/Form';
import styles from './styles.module.scss';
import Footer from 'app/components/mui/Footer/Footer';
import Button from 'app/components/mui/Button';
import { Query } from 'app/components/mui/Query';
import { Mutation } from 'app/components/graphql/Mutation';

type Props = {
  loading: boolean;
  params: { 
    slug: string;
  };
};

const NEWSETTINGS = {
  imageLimit: 'ALL',
  imageExpire: 'NEVER',
  description: 'ALL',
  contact: 'ALL',
  chatable: 'INACTIVE',
  overwriteEmail: '',
  chatableRequirement: 'ALL',
  disabled: 'FALSE',
  comment: ''
};

const serialize = (settings) => {
  if(!settings) {
    return null;
  }
  const { imageLimit, imageExpire, description, contact, chatable, overwriteEmail, chatableRequirement, disabled, comment } = settings;
  return { imageLimit, imageExpire, description, contact, chatable: chatable ? 'ACTIVE' : 'INACTIVE', overwriteEmail, chatableRequirement, disabled: disabled ? 'TRUE' : 'FALSE', comment };
};

const deserialize = (settings) => {
  const { imageLimit, imageExpire, description, contact, chatable, overwriteEmail, chatableRequirement, disabled, comment } = settings;
  return { imageLimit, imageExpire, description, contact, chatable: chatable === 'ACTIVE', overwriteEmail, chatableRequirement, disabled: disabled === 'TRUE', comment };
};

const ScraperSettings = (props: Props) => {
  const slug = props.params.slug;
  const [touched, setTouched] = useState<boolean>(false);
  const [newSettings, setNewSettings] = useState<{ [key: string]: any }>(NEWSETTINGS);
  
  if(!slug) {
    return <p>No scraper for this agent</p>;
  }

  const valueHandler = (values) => {
    setNewSettings({ ...newSettings, ...values });
    if (!touched) {
      setTouched(true);
    }
  };


  return (
    <Query
      query={SCRAPER_SETTINGS}
      variables={{ slug }}
      serializer={{ fn: serialize, namespace: 'scraperSettings' }}
    >
      {({ scraperSettings }) => {
        return (
          <Mutation mutation={SCRAPER_SETTINGS_UPDATE}>
            {({ mutate, options }) => {
              return (
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    const { ...values } = newSettings;
                    const variables = {
                      slug,
                      settings: deserialize(values)
                    };
                    mutate({ variables });
                  }}
                >
                  <Layout spacing={4}>
                    <Form
                      gridProps={{
                        className: styles.form,
                        col: {
                          xs: 12,
                          md: 3
                        }
                      }}
                      initValues={scraperSettings || NEWSETTINGS}
                    >
                      {({ initialValues, values }) => {
                        return (
                          <Fields
                            onBlur={() => {
                              valueHandler(values);
                            }}
                            onChange={() => {
                              valueHandler(values);
                            }}
                            fields={initialValues}
                            fieldOptions={{
                              isFormik: true,
                              imageLimit: {
                                options: [
                                  { value: 'ALL' },
                                  { value: 'MAX1' },
                                  { value: 'MAX2' },
                                  { value: 'NONE' }
                                ]
                              },
                              imageExpire: {
                                options: [
                                  { value: 'NEVER' },
                                  { value: 'HOUR24' },
                                  { value: 'DAYS28' }
                                ]
                              },
                              description: {
                                options: [
                                  { value: 'ALL' },
                                  { value: 'SNIPPET' },
                                  { value: 'AUTOGENERATION' }
                                ]
                              },
                              contact: {
                                options: [
                                  { value: 'ALL' },
                                  { value: 'EMAILURL' },
                                  { value: 'EMAIL' },
                                  { value: 'URL' }
                                ]
                              },
                              chatable: {
                                options: [
                                  { value: 'ACTIVE' },
                                  { value: 'INACTIVE' }
                                ]
                              },
                              chatableRequirement: {
                                options: [
                                  { value: 'ALL' },
                                  { value: 'NEWACTIVE' },
                                  { value: 'SUBSCRIPTION' }
                                ]
                              },
                              disabled: {
                                options: [
                                  { value: 'TRUE' },
                                  { value: 'FALSE' }
                                ]
                              },
                              comment: {
                                label: 'Comment',
                                type: 'textarea'
                              }
                            }}
                            title="Scraper Settings"
                          />
                        );
                      }}
                    </Form>
                  </Layout>
                  <Footer
                    right={() => {
                      return (
                        <Button
                          disabled={!touched || options.loading}
                          isLoading={options.loading}
                          label={scraperSettings ? 'save' : 'create'}
                          type={'submit'}
                        />
                      );
                    }}
                  />
                </form>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default ScraperSettings;
