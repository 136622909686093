import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getTransaction,
  getUserForTransaction
} from './../redux/modules/boligninja';
import Trans from './common/transaction';

class Transaction extends Component {
  static fetchData({ dispatch, params }) {
    return dispatch(getTransaction(params.id)).then((res) => {
      return dispatch(getUserForTransaction(res.payload.transaction._id));
    });
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { transaction, user } = this.props;
    return (
      <div>
        <h3>Transaction</h3>
        {transaction ? (
          <Trans
            trans={transaction}
            gotoUserBtn
            userId={user ? user._id : ''}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default connect(
  ({ boligninja: { transaction, user } }) => ({ transaction, user }),
  { getTransaction }
)(Transaction);
