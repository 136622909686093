import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Loading from '../../common/loading';
import Table from 'app/components/mui/Table';
import LeaseUnwanted from './lease-unwanted-row';

// TODO: rewrite to use hooks (?)

const columns = [
  { label: 'Lease', name: '_id', valueType: 'string' },
  { label: 'Users', name: 'total', valueType: 'number' }
];

export default class LeaseUnwantedReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      report: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
      this.setState({ report: null, loading: true });
      const report = await apiClient.get('/api/reports/lease-unwanted');
      this.setState({ report, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }

  render() {
    const { loading, report } = this.state;

    if(loading) {
        return <Loading/>;
    }

    return (
      <div>
        <Table
          title={'Lease Unwanted'}
          columns={columns}
          rows={report?.leases || []}
          detailOnClick={(row) => <LeaseUnwanted row={row}/>}
        />
      </div>
    );
  }
}
