import React from 'react';
import { Formik } from 'formik';

type Props = {
  onSubmit?: (values: any, actions: any) => void;
  initValues: {};
  children: (formikProps: any) => JSX.Element;
  enableReinitialize?: boolean;
};

const Form = (props: Props) => {
  const onSubmit = () => {};
  return (
    <Formik
      initialValues={props.initValues}
      onSubmit={props.onSubmit || onSubmit}
      enableReinitialize={props.enableReinitialize}
    >
      {(formikProps) => {
        return props.children(formikProps);
      }}
    </Formik>
  );
};

export default Form;
