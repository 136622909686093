import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Panel, Button } from 'react-bootstrap';
import lib from 'lib/format-date';

import {
  changeBoligAgent,
  changeBoligAgentPref
} from '../../redux/modules/boligninja';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function addDKCharacters(string) {
  return string.replace(/ae/g, 'æ').replace(/oe/g, 'ø').replace(/aa/g, 'å');
}

class Boligagents extends Component {
  static propTypes = {
    boligagents: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  deactivate = () => {
    this.props.changeBoligAgent(this.props.boligagents._id, { status: false });
  };

  togglePref = (ev) => {
    const pref = this.props.boligagents.prefs.filter(
      (pref) => pref._id === ev.target.value
    )[0];
    if (pref) {
      this.props.changeBoligAgentPref(this.props.boligagents._id, pref._id, {
        status: !pref.status
      });
    }
  };

  render() {
    const { boligagents, cancelSingle, cancelAll } = this.props;
    const activePrefs = Boolean(
      boligagents.prefs.filter((pref) => pref.status === true).length
    );

    return (
      <Panel
        collapsible
        bsStyle={activePrefs ? 'success' : 'warning'}
        header="Boligagents"
      >
        <Table
          striped
          condensed
          hover
          responsive
          fill
          style={{ borderBottom: '1px solid #ddd' }}
        >
          <tbody>
            <tr>
              <td>BoligAgent ID</td>
              <td>{boligagents._id}</td>
            </tr>
            <tr>
              <td>Date of creation</td>
              <td>{lib.dateFormatStr(boligagents.createdAt)}</td>
            </tr>
            <tr>
              <td>Last edited</td>
              <td>{lib.dateFormatStr(boligagents.editedAt)}</td>
            </tr>
          </tbody>
        </Table>

        {activePrefs ? (
          <Button
            bsStyle="danger"
            onClick={this.deactivate}
            style={{ marginBottom: 10 }}
          >
            Deactivate all BoligAgents
          </Button>
        ) : null}

        {boligagents.prefs.map((pref, idx) => {
          const status = pref.status;
          return (
            <Panel
              collapsible
              bsStyle={status ? 'success' : 'warning'}
              header={capitalizeFirstLetter(addDKCharacters(pref.area))}
              key={idx}
            >
              {/* { pref.status ? (
                <Button bsStyle='info' onClick={ () => cancelSingle(pref._id, boligagents._id) } style={{ marginBottom: 10 }}>Unsubscribe</Button>
              ) : null } */}
              <Table striped condensed responsive hover className="table" fill>
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>
                      {status ? (
                        <i
                          className="fa fa-check-circle"
                          style={{ color: 'darkGreen' }}
                        />
                      ) : (
                        <i
                          className="fa fa-times-circle"
                          style={{ color: 'rgb(220, 0, 0)' }}
                        />
                      )}
                      &nbsp;
                      {status ? 'Active' : 'Inactive'}
                      <Button
                        className="pull-right"
                        bsSize="xsmall"
                        bsStyle={status ? 'danger' : 'success'}
                        value={pref._id}
                        onClick={this.togglePref}
                      >
                        {status ? 'Deactivate' : 'Activate'}
                      </Button>
                    </td>
                  </tr>
                  {pref.rent ? (
                    <tr>
                      <td>Rent</td>
                      <td>{pref.rent} kr</td>
                    </tr>
                  ) : null}
                  {pref.rooms ? (
                    <tr>
                      <td>Rooms</td>
                      <td>{pref.rooms}</td>
                    </tr>
                  ) : null}
                  {pref.size ? (
                    <tr>
                      <td>Size</td>
                      <td>{pref.size} m2</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>Token</td>
                    <td>{pref.token}</td>
                  </tr>
                </tbody>
              </Table>
            </Panel>
          );
        })}
      </Panel>
    );
  }
}

export default connect(() => ({}), { changeBoligAgent, changeBoligAgentPref })(
  Boligagents
);
