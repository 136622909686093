import apiClient from 'app/utils/api-client';
import Loading from 'app/common/loading';
import React, { Component } from 'react';
import Table from 'app/components/mui/Table';
import { weekdaysSerializer, intervalSerializer, timetableTimeSerializer } from './helper';
import ResultTable from './resultTable';

const columns = [
  { label: 'Weekdays', name: 'weekdays', valueType: 'custom', customValue: weekdaysSerializer },
  { label: 'From', name: 'from', valueType: 'custom', customValue: timetableTimeSerializer },
  { label: 'To', name: 'to', valueType: 'custom', customValue: timetableTimeSerializer },
  { label: 'Interval', name: 'interval', valueType: 'custom', customValue: intervalSerializer }
];

export default class CronjobRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: null
    };
  }

  componentDidMount(): void {
    this.fetch();
  }

  async fetch() {
    try {
      if(!this.props.id) {
          return;
      }
      this.setState({ report: null, loading: true });
      const result = await apiClient.post('/api/microservice', { data: { action: 'CronjobDetail', data: { action: this.props.id } } });
      this.setState({ result: result.data.data.cronjobDetail, loading: false });
    } catch (err) {
      if (__DEV__) {
        console.error(err);
      }
    }
  }
  render() {
    const { loading, result } = this.state;

    if(loading) {
        return <Loading/>;
    }

    return (<tr>
      <td colSpan={1}/>
      <td colSpan={11}>
        <div>
          <ResultTable id={this.props.id}/>
          {result?.timetable ? <Table
            columns={columns}
            limitRow={25}
            rows={result?.timetable || []}
          />: null}
        </div>
      </td>
    </tr>);
  }
}