import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Input from '../../common/FormInput';
import {
  createUser,
  updateUser,
  clearUpdateUserSuccess
} from '../../redux/modules/auth';
import { connect } from 'react-redux';

class UserModal extends Component {
  constructor(props) {
    super(props);

    if (props.userId) {
      const user = props.users.find((user) => user._id === props.userId);

      this.state = {
        errors: {},
        name: user.name,
        email: user.email,
        role: user.role,
        permissions: (user.permissions || []).filter(Boolean).join('\n')
      };
    } else {
      this.state = {
        errors: {}
      };
    }
  }

  hide = () => {
    this.props.clearUpdateUserSuccess();
    this.props.onClose();
  };

  validateInput = (key, value) => {
    return Boolean(value) || `Error: ${key} cannot be empty`;
  };

  validateForm = () => {
    const fields = ['name', 'email', 'role'];
    const errors = {};
    const data = fields.reduce((acc, field) => {
      const value = this[field].getValue();
      const validation = this.validateInput(field, value);
      if (validation !== true) {
        errors[field] = validation;
      }
      acc[field] = value;
      return acc;
    }, {});

    data.permissions = this.permissions.getValue().split('\n').filter(Boolean);

    if (this.props.userId) {
      // if there is a new password, add it. Otherwise we will not update the pwd
      if (this.password.getValue()) {
        data.password = this.password.getValue();
      }
    } else {
      data.password = this.password.getValue();
    }

    const success = !Object.keys(errors).length;
    this.setState({ errors, success });
    return success && data;
  };

  handleSubmit = () => {
    const data = this.validateForm();

    if (data !== false) {
      if (this.props.userId) {
        // add id to user object so that we can find the user in the backend
        data.id = this.props.userId;
        this.props.updateUser(data).catch((err) => {
          console.log(err);
        });
        return;
      }
      this.props
        .createUser(data)
        .then(() => this.props.onCreate())
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const { userId } = this.props;
    const { errors, name, email, role, permissions } = this.state;

    return (
      <Modal onHide={this.hide} show aria-labelledby="contained-modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">New Auth User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref="form" onSubmit={this.handleSubmit}>
            <Input
              defaultValue={name}
              className="form-control"
              ref={(i) => (this.name = i)}
              type="text"
              name="name"
              label="Name"
              bsStyle={errors.name && 'error'}
              help={errors.name && 'Error: Name cannot be empty'}
            />
            <Input
              defaultValue={email}
              className="form-control"
              ref={(i) => (this.email = i)}
              type="email"
              name="email"
              label="Email"
              bsStyle={errors.email && 'error'}
              help={errors.email && 'Error: Email cannot be empty'}
            />
            <Input
              defaultValue={role}
              className="form-control"
              ref={(i) => (this.role = i)}
              type="select"
              name="role"
              label="Role"
              placeholder="guest"
            >
              <option value="guest">Guest</option>
              <option value="freelancer">Freelancer</option>
              <option value="employee">Employee</option>
              <option value="supervisor">Supervisor</option>
              <option value="manager">Manager</option>
            </Input>
            <Input
              defaultValue={permissions}
              className="form-control"
              ref={(i) => (this.permissions = i)}
              type="textarea"
              label="Permissions"
            />
            <Input
              className="form-control"
              ref={(i) => (this.password = i)}
              name="password"
              bsStyle={errors.password && 'error'}
              type="password"
              label={
                userId ? 'Password (leave blank to keep current)' : 'Password'
              }
              help={errors.password && 'Error: Password cannot be empty'}
            />

            <div className="row form-group">
              <div className="col-xs-8 col-xs-offset-4">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.handleSubmit}
                >
                  {userId ? 'Save user' : 'Create user'}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.hide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  ({ auth }) => ({
    users: auth.users,
    updateUserSuccess: auth.updateUserSuccess
  }),
  { createUser, updateUser, clearUpdateUserSuccess }
)(UserModal);
