import React from 'react';
import { CircularProgress, TextField, Button, Checkbox, Typography } from '@material-ui/core';
import apiClient from 'app/utils/api-client';

const path = 'api/biz/disable-target';

function convertValue(data: string): string[] {
    return data?.split('\n').filter((email) => /.+@.+[.].+/.test(email)) || [];
}

const EmailBox = (props) => {
  const [value, setValue] = React.useState(null);
  const [ignoreClicks, setIgnoreClicks] = React.useState(false);
  const [period, setPeriod] = React.useState(30);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    const emails = convertValue(value);
    if (emails?.length) {
      setLoading(true);
      apiClient.post(path, { data: { emails, ignoreClicks, period } })
        .then((res) => {
          setLoading(false);
          props.onSuccess(res);
        })
        .catch((err) => {
          setLoading(false);
          props.onError(err);
        });
    } else {
      props.onError('Please insert emails to disable');
    }
  };


  return (
    <form noValidate autoComplete="off">
      <TextField
        id="Emails"
        label="Emails"
        placeholder="Indsæt emails..."
        multiline
        fullWidth
        type="text"
        rows="20"
        InputLabelProps={{
          shrink: true
        }}
        value={value || ''}
        onChange={({ target: { value } }) => setValue(value)}
        margin="normal"
        variant="outlined"
      />
      <TextField
        id="period"
        label="Period"
        type="number"
        value={period || 30}
        onChange={({ target: { value } }) => setPeriod(Number(value))}
        margin="normal"
      />
      <Typography variant="p">Ignore Email Interactions{<Checkbox
        checked={ignoreClicks}
        onChange={({ target }) => setIgnoreClicks(target.checked)}
        inputProps={{ 'aria-label': 'controlled' }}
        />} </Typography>
      <br></br>
      <Button
        onClick={onSubmit}
        variant="contained"
        color="default"
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {'Disable emails'}
          </>
        )}
      </Button>
    </form>
  );
};

export default EmailBox;
