import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import subscriptionsQuery from './subscriptions.graphql';

import Loading from 'app/common/loading';
import { GraphQLTableContainer } from 'app/common/table';

import Row from './subscriptions-table-row';

const STATUSES = [
  { key: 'active', label: 'Active', status: 'success', statusValue: /^ACTIVE/ },
  {
    key: 'overdue',
    label: 'Over due',
    status: 'warning',
    statusValue: /OVER_DUE/
  },
  {
    key: 'canceled',
    label: 'Canceled',
    status: 'info',
    statusValue: /CANCELED/
  },
  {
    key: 'inactive',
    label: 'Expired',
    status: 'fatal',
    statusValue: /INACTIVE/
  }
];

class Subscriptions extends GraphQLTableContainer {
  static defaultProps = {
    asyncFilter: false
  };

  static propTypes = {
    loading: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  updateFilter(name, value) {
    const filter = {
      ...this.state.filter,
      [name]: value
    };

    this.setState({ filter }, () => {
      this.setState({ ...this.filterRows() });
    });
  }

  toggleStatusFilter = (ev, statusValue) => {
    const { filter } = this.state;
    const { props } = ev._targetInst._currentElement;

    const { name } = props;
    const value =
      filter[name] === statusValue ? null : statusValue || props.filter;

    this.updateFilter(name, value);
  };

  handleFilterChange = (ev) => {
    const { name, value } = ev.target;

    if (name === 'user') {
      const pattern = new RegExp(value.replace(/([\+\.\*])/g, '\\$1'), 'i');
      return this.updateFilter(name, (row) => {
        return row.custom.reduce((valid, entry) => {
          return valid || pattern.test(entry.value);
        }, false);
      });
    }
  };

  render() {
    const { loading /*, location: { query }*/ } = this.props;
    const { rows = [], filter, count: total } = this.state;
    const visible = rows.length;

    return (
      <div>
        <div
          className="btn-toolbar"
          role="toolbar"
          style={{ marginBottom: '5px' }}
        >
          <div className="btn-group" role="group">
            {STATUSES.map(({ key, status, label, statusValue }) => {
              return (
                <button
                  key={key}
                  type="button"
                  className={`btn btn-${
                    filter.status === statusValue ? status : 'default'
                  }`}
                  onClick={(ev) => this.toggleStatusFilter(ev, statusValue)}
                  name="status"
                >
                  {label}
                </button>
              );
            })}
          </div>
        </div>
        Showing {visible}
        {visible !== total ? ` of ${total}` : ''} subscriptions
        <div className="table-responsive">
          <table className="table table-striped table-condensed table-hover">
            <thead>
              <tr>
                <th onClick={this.sortBy} data-column="id">
                  ID
                </th>
                <th>User</th>
                <th onClick={this.sortBy} data-column="starts">
                  Starts
                </th>
                <th onClick={this.sortBy} data-column="ends">
                  Ends
                </th>
                <th onClick={this.sortBy} data-column="canceledAt">
                  Canceled
                </th>
                <th onClick={this.sortBy} data-column="plan">
                  Subscription plan
                </th>
                <th>Renews / Lifetime value</th>
              </tr>
              <tr>
                <th />
                <th>
                  <input
                    onChange={this.handleFilterChange}
                    defaultValue={filter.user || ''}
                    name="user"
                    className="form-control"
                    style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                    placeholder="Filter by name"
                  />
                </th>
                <th />
                <th />
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {rows.map((subscription) => (
                <Row key={subscription.id} subscription={subscription} />
              ))}
            </tbody>
          </table>

          {loading ? <Loading /> : null}
        </div>
      </div>
    );
  }
}

export default graphql(subscriptionsQuery, {
  options: (/*props*/) => {
    // const { name, email } = props.location.query;
    return {
      variables: {
        limit: 5000,
        sort: '-id'
        // name: name || undefined,
        // email: email || undefined
      }
    };
  },
  props: ({ ownProps, data: { loading, subscriptions, refetch } }) => ({
    ...ownProps,
    loading,
    rows: subscriptions || [],
    refetch
  })
})(Subscriptions);
