import React from 'react';
import { Route, IndexRoute } from 'react-router';

import { LandlordIndex as Landlord } from './Landlord';
import { ConsentIndex as Consent } from './Consent';
import { ScraperIndex as Scraper } from './Scraper';
import { DwellingIndex as Dwelling } from './Dwelling';

export default (
  <>
    <Route path="consent" component={Consent} name="Consent" />
    <Route path="scraper" component={Scraper} name="Scraper" />
    <Route path="dwellings" component={Dwelling} name="Dwellings" />
    <IndexRoute component={Landlord} />
  </>
);
