import React, { useState } from 'react';
import agentQuery from './agent.graphql';
import mutateAgentUpdate from './mutateAgentUpdate.graphql';
import mutateAgentCreate from './mutateAgentCreate.graphql';
import Table from 'app/components/mui/Table';
import Layout from 'app/components/mui/Layout/Layout';
import Fields from 'app/components/mui/Fields';
import Form from 'app/components/mui/Form/Form';
import styles from './styles.module.scss';
import Footer from 'app/components/mui/Footer/Footer';
import Button from 'app/components/mui/Button';
import { Query } from 'app/components/mui/Query';
import { Mutation } from 'app/components/graphql/Mutation';
import { serializeAgent, deserializeAgent } from './serializeAgent';
import CustomLayout from 'app/components/mui/Layout/CustomLayout';
import Switch from 'app/components/mui/InputFields/Switch';

type Props = {
  loading: boolean;
  agent: any;
  params: { agentId: number };
  updateAgent: any;
};

type Col = { xs: number; sm?: number; md: number; lg?: number };

const NEW_AGENT = {
  name: '',
  email: '',
  phone: '',
  parent: '',
  maxItems: 0,
  identifier: '',
  contactNote: ''
};

function serializeMeta(meta) {
  const { scraper_status } = meta;
  if(scraper_status) {
    const value = /^("|')?{/.test(scraper_status.value) ? JSON.parse(scraper_status.value) : scraper_status.value;
    return { scraper_status: value?.status || value, last_status_change: scraper_status.timestamp };
  }

  return {};
}

const AgentDK = (props: Props) => {
  const agentId = props.params.agentId;
  if(!/^[0-9]+$/.test(String(agentId)) && /^[0-9a-z-]+$/.test(String(agentId)) && /[0-9]+/.test(String(agentId))) {
    window.location.replace(`https://udlejer.akutbolig.dk/users/${agentId}`);
  }

  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [showMysql, setShowMysql] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [newAgentValues, setNewAgentValues] = useState<{ [key: string]: any }>(
    {}
  );

  const valueHandler = (values) => {
    setNewAgentValues({ ...newAgentValues, ...values });
    if (!touched) {
      setTouched(true);
    }
  };

  let col: Col = { xs: 12, md: 9 };
  return agentId ? (
    <Query
      query={agentQuery}
      variables={{
        agentId: Number(agentId),
        inactive: showInactive,
        limit: 250
      }}
      serializer={{ fn: serializeAgent, namespace: 'agent' }}
    >
      {({ agent: { dwellings, leases, agents, __typename: _, meta = {}, ...agent } }) => {
        const rows = (showMysql ? dwellings : leases).map(({ zip, city, ...rest }) => ({
          ...rest,
          city: `${zip} ${city}`
        }));
        const hasSubAgents = Boolean(agents.length);
        const agentDetails = { ...serializeMeta(meta), ...agent };

        if (hasSubAgents) {
          col = { xs: 12, md: 6, lg: 4 };
        }
        return (
          <Mutation mutation={mutateAgentUpdate}>
            {({ mutate, options }) => {
              return (
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    const { id, ...values } = newAgentValues;
                    const variables = {
                      agentId: Number(agentId),
                      agentInput: deserializeAgent(values)
                    };
                    mutate({ variables });
                  }}
                >
                  <Layout spacing={4}>
                    <Table
                      gridProps={{
                        className: styles.dwellingTable,
                        col
                      }}
                      actions={[
                        {
                          name: 'showInactive',
                          component: Switch,
                          label: `${
                            !showInactive ? 'Show' : 'Hide'
                          } inactive dwellings`,
                          color: 'secondary',
                          onChange: () => setShowInactive((prev) => !prev),
                          value: 'showInactive',
                          checked: showInactive
                        },
                        {
                          name: 'mysql',
                          component: Switch,
                          label: 'Mysql',
                          color: 'secondary',
                          onChange: () => setShowMysql((prev) => !prev),
                          value: 'showMysql',
                          checked: showMysql
                        }
                      ]}
                      title={'Dwellings'}
                      url="/dk/dwellings"
                      columns={[
                        { label: 'Id', name: 'id' },
                        { label: 'Adresse', name: 'address' },
                        { label: 'By', name: 'city' },
                        { label: 'Overskrift', name: 'headline' }
                      ]}
                      rows={rows}
                    />
                    {hasSubAgents && (
                      <Table
                        gridProps={{
                          className: styles.agentTable,
                          col
                        }}
                        title={'Agents'}
                        url="/dk/agents"
                        columns={[
                          { label: 'Id', name: 'id' },
                          { label: 'Navn', name: 'name' }
                        ]}
                        rows={agents}
                        tableProps={{
                          size: 'small',
                          stickyHeader: true
                        }}
                      />
                    )}

                    <Form
                      gridProps={{
                        className: styles.form,
                        col: {
                          xs: 12,
                          md: hasSubAgents ? 4 : 3
                        }
                      }}
                      initValues={agentId ? agentDetails : NEW_AGENT}
                    >
                      {({ initialValues, values /*, dirty*/ }) => {
                        // Take those fields out that are not needed
                        const { id, ...rest } = values;
                        // /Take those fields out that are not needed
                        return (
                          <div>
                          {agentId ? <Button label={'Scraper Settings'} onClick={() => {
                            props.router.push('/dk/agents/scraper-settings/' + agentDetails.identifier);
                          }}/> : null}
                          <Fields
                            onBlur={() => {
                              valueHandler(rest);
                            }}
                            onChange={() => {
                              valueHandler(rest);
                            }}
                            fields={initialValues}
                            fieldOptions={{
                              isFormik: true,
                              id: { disabled: true },
                              maxItems: { type: 'number' },
                              // nonprofit: { options: true },
                              mailer: { options: true },
                              disabled: { options: true },
                              agent404: {
                                disabled: true,
                                options: [
                                  { value: 'ACTIVE' },
                                  { value: 'INACTIVE' }
                                ]
                              },
                              agent404Regex: { disabled: true },
                              type: {
                                options: [
                                  { value: 'NONPROFIT' },
                                  { value: 'COMPANY' },
                                  { value: 'PERSONAL' },
                                  { value: 'REALESTATE' },
                                  { value: 'COMMERCIAL' }
                                ]
                              },
                              nonprofit: { disabled: true, options: true },
                              partner: {
                                disabled: true,
                                options: true
                                // options: [
                                //   { value: 'ENUM_' },
                                //   { value: 'COMMERCIAL' }
                                // ]
                              },
                              checksum: { disabled: true },
                              dataSanitizer: { disabled: true },
                              scraper_status: { disabled: true },
                              last_status_change: { disabled: true }
                            }}
                            title="Agent info"
                          />
                        </div>);
                      }}
                    </Form>
                  </Layout>
                  <Footer
                    right={() => {
                      return (
                        <Button
                          disabled={!touched || options.loading}
                          isLoading={options.loading}
                          label={'save'}
                          type={'submit'}
                        />
                      );
                    }}
                  />
                </form>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  ) : (
    <Mutation mutation={mutateAgentCreate}>
      {({ mutate, options }) => {
        return (
          <form
            onSubmit={(ev) => {
              ev.preventDefault();

              const variables = {
                agentInput: deserializeAgent(newAgentValues)
              };
              mutate({ variables }).then(({ data }) => {
                if (data) {
                  props.router.push(`/dk/agents/${data.createAgent.id}`);
                }
              });
            }}
          >
            <CustomLayout
              cssStyles={{
                container: styles.container
              }}
            >
              <Form initValues={NEW_AGENT}>
                {({ initialValues, values, dirty }) => {
                  return (
                    <Fields
                      onBlur={() => {
                        valueHandler(values, dirty);
                      }}
                      onChange={() => {
                        valueHandler(values, dirty);
                      }}
                      fields={initialValues}
                      fieldOptions={{
                        isFormik: true,
                        id: { disabled: true },
                        maxItems: { type: 'number' },
                        nonprofit: { options: true },
                        mailer: { options: true },
                        disabled: { options: true },
                        agent404: {
                          options: [{ value: 'ACTIVE' }, { value: 'INACTIVE' }]
                        },
                        type: {
                          options: [
                            { value: 'NONPROFIT' },
                            { value: 'COMPANY' },
                            { value: 'PERSONAL' },
                            { value: 'REALESTATE' },
                            { value: 'COMMERCIAL' }
                          ]
                        },
                        partner: {
                          options: [{ value: 'ENUM_' }, { value: 'COMMERCIAL' }]
                        }
                      }}
                      title="New Agent"
                    />
                  );
                }}
              </Form>
            </CustomLayout>
            <Footer
              right={() => (
                <Button
                  disabled={!touched || options.loading}
                  isLoading={options.loading}
                  label="Create"
                  type="submit"
                />
              )}
            />
          </form>
        );
      }}
    </Mutation>
  );
};

export default AgentDK;
