import { createStore as _createStore, applyMiddleware } from 'redux';
import createMiddleware from './middleware/clientMiddleware';

export default function createStore({ client, data }) {
  const middleware = [createMiddleware(client)];

  const finalCreateStore = applyMiddleware(...middleware)(_createStore);

  const reducer = require('./modules/reducer').default;
  const store = finalCreateStore(reducer, data);
  store.client = client;

  if (__DEV__ && module.hot) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(require('./modules/reducer').default);
    });
  }

  return store;
}
