import React from 'react';
import LineChart from './lineChart';
import BarChart from './barChart';
import PieChart from './pieChart';
import { deserializeDataset, createCharData } from './utils';

export function compareableContent(report, opts, withoutPre) {
  const dataSets = deserializeDataset(report.dataset);
  const first = dataSets['first'] || dataSets;
  const second = dataSets['second'] || null;

  return (
    <>
      {
        first ? <div style={{ float: 'left' }}>
      <h3>First</h3>
        {(opts.charts || [opts.chart]).map((chart, idx) => {
          const chartData = createCharData(first, opts, chart);
          switch (chart.type) {
            case 'BarChart':
              return <BarChart key={idx} {...chart} data={chartData} />;
            case 'PieChart':
              return <PieChart key={idx} {...chart} data={chartData} />;
            case 'LineChart':
            default:
              return <LineChart key={idx} {...chart} data={chartData} />;
          }
        })}
        <hr />
      {withoutPre ? null : <pre>{deserializeDataset(first, false)}</pre>}
        </div> : null
      }
      {
        second ? <div style={{ float: 'left' }}>
      <h3>Second</h3>
        {(opts.charts || [opts.chart]).map((chart, idx) => {
          const chartData = createCharData(second, opts, chart);
          switch (chart.type) {
            case 'BarChart':
              return <BarChart key={idx} {...chart} data={chartData} />;
            case 'PieChart':
              return <PieChart key={idx} {...chart} data={chartData} />;
            case 'LineChart':
            default:
              return <LineChart key={idx} {...chart} data={chartData} />;
          }
        })}
        <hr />
      {withoutPre ? null : <pre>{deserializeDataset(second, false)}</pre>}
      </div> : null
      }
    </>
  );
}