import React, { FC } from 'react';
import { Query } from 'app/components/mui/Query';
import gql from 'graphql-tag';
import { List } from 'app/components/List';
import CustomLayout from 'app/components/mui/Layout/CustomLayout';
import styles from './styles.module.scss';

const STATISTICS = gql`
  query LandlordUdlejerStats {
    landlordUdlejerStats {
      landlordsWithUdlejer {
        id
        name
      }
      landlordsWithoutUdlejer {
        id
        name
      }
      udlejerWithoutLandlord {
        id
        name
      }
    }
  }
`;

const Statistics: FC = () => {
  return (
    <Query query={STATISTICS}>
      {({
        landlordUdlejerStats: {
          landlordsWithUdlejer,
          landlordsWithoutUdlejer,
          udlejerWithoutLandlord
        }
      }) => {
        return (
          <div>
            <h1>Statistics</h1>
            <CustomLayout
              cssStyles={{
                container: styles.container,
                childContainer: styles.childContainer
              }}
            >
              <List
                list={landlordsWithoutUdlejer}
                title="landlords without udlejer"
                to={{
                  path: '/biz/mastertool',
                  outGoing: false
                }}
              />
              <List
                list={udlejerWithoutLandlord}
                to={{
                  path: __DEV__
                    ? 'https://udlejer.stage.bbintern.net/projects'
                    : 'https://udlejer.dk/projects',
                  outGoing: true
                }}
                title="udlejer Without Landlord"
              />
              <List
                list={landlordsWithUdlejer}
                to={{
                  path: '/biz/mastertool',
                  outGoing: false
                }}
                title="Landlords With Udlejer"
              />
            </CustomLayout>
          </div>
        );
      }}
    </Query>
  );
};

export default Statistics;
