import React, { FC } from 'react';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';
import { Query } from 'app/components/mui/Query';
import gql from 'graphql-tag';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Modal from 'app/biz/linktool/LinkUpload/LinkModal';
import OutgoingLink from 'app/components/mui/OutgoingLink';
import Input from 'app/components/mui/InputFields/SimpleInputField';
import { Mutation } from 'app/components/graphql/Mutation';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Title } from 'app/components/Title';
import Placement from 'app/components/Placement';
import { generatePostId } from './utils';

enum categoryType {
  LATEST = 'LATEST',
  REPOST = 'REPOST'
}

type CandidateItem = {
  id: string;
  zipCity: string;
  headline: string;
  createdAt: string;
};

type CandidatePost = {
  target: string;
  content: string;
  link: string;
  message: string;
  secondary?: string;
};

const CANDIDATES = gql`
  query candidates($category: j6_Category!) {
    j6_candidates(category: $category) {
      id
      zipCity
      headline
      createdAt
    }
  }
`;

const CANDIDATES_POST = gql`
  query candidatePost($candidate: ID!, $primary: Boolean, $repost: Boolean) {
    j6_candidatePost(
      candidate: $candidate
      primary: $primary
      repost: $repost
    ) {
      target
      content
      link
      message
      secondary
    }
  }
`;

const DECLARE_POSTED = gql`
  mutation declarePosted($candidate: ID!, $post: j6_PostInput!) {
    j6_declarePosted(dwelling: $candidate, post: $post)
  }
`;

function getAssetId(link: string): string {
  return /^.*\/([0-9]+)\/?$/.test(link)
    ? link.replace(/^.*\/([0-9]+)\/?$/, '$1')
    : null;
}

function copyToClipboard(ref: any): void {
  if (ref.current) {
    ref.current.select();
  } else {
    ref.currentTarget.select();
  }
  document.execCommand('copy');
}

export const GroupPosting: FC = () => {
  const textAreaRef = React.useRef(null);
  const [candidate, setCandidate] = React.useState<string>(null);
  const [category, setCategory] = React.useState<categoryType>(
    categoryType.LATEST
  );
  const [postId, setPostId] = React.useState<string>('');
  const [posted, setPosted] = React.useState<string[]>([]);

  const handleClick = (id: string): void => {
    if (!posted.includes(id)) {
      setCandidate(id);
    }
  };

  const closeModal = (posted?: boolean): void => {
    if (posted) {
      setPosted((prev) => prev.concat([candidate]));
    }
    setCandidate(null);
    setPostId('');
  };

  const LATEST = categoryType.LATEST;
  const REPOST = categoryType.REPOST;

  const categories = [
    { value: LATEST, label: 'latest' },
    { value: REPOST, label: 'repost' }
  ];

  return (
    <>
      <Query query={CANDIDATES} variables={{ category }}>
        {(
          { j6_candidates: candidates }: { j6_candidates: CandidateItem[] },
          refetch
        ) => {
          return (
            <>
              <div style={{ display: 'flex', marginBottom: '1em' }}>
                <Button
                  style={{ marginRight: '1em' }}
                  onClick={() => refetch()}
                  label={
                    <>
                      Refresh
                      <RefreshIcon />
                    </>
                  }
                  size="small"
                />
                <ButtonGroup>
                  {categories.map(({ value, label }) => {
                    return (
                      <Button
                        key={value}
                        onClick={() => setCategory(value)}
                        label={label}
                        variant={category === value ? 'contained' : undefined}
                        color="primary"
                      />
                    );
                  })}
                </ButtonGroup>
              </div>
              <Table
                // persistState
                // filterable
                sortable
                dimmedRows={posted}
                url="/dk/dwellings"
                title="Candidates"
                columns={[
                  { label: '', name: '' },
                  { label: 'Id', name: 'id' },
                  { label: 'City', name: 'zipCity' },
                  { label: 'Headline', name: 'headline' },
                  { label: 'Created At', name: 'createdAt' }
                ]}
                rowActions={[
                  {
                    component: Button,
                    fab: true,
                    size: 'small',
                    onClick: (row) => handleClick(row.id),
                    icon: <PostAddIcon fontSize="small" />
                  }
                ]}
                rows={candidates}
              />
            </>
          );
        }}
      </Query>
      {candidate && (
        <Query
          query={CANDIDATES_POST}
          variables={{
            candidate,
            primary: true,
            repost: category === 'REPOST'
          }}
        >
          {({
            j6_candidatePost: candidatePost
          }: {
            j6_candidatePost: CandidatePost;
          }) => {
            if (candidatePost === null) {
              closeModal(true);
              return null;
            }
            const assetId = getAssetId(candidatePost.target);
            return (
              <Modal open onClose={() => closeModal(false)}>
                <div>
                  <Title text="1. Post" component="h1" />
                  <textarea
                    id="postMessage"
                    ref={textAreaRef}
                    onFocus={copyToClipboard}
                    style={{ resize: 'none', width: '100%', minHeight: '8em' }}
                    readOnly
                    value={candidatePost.content}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <OutgoingLink
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '1em',
                        fontSize: '1.2em'
                      }}
                      href={candidatePost.target}
                      onClick={() => copyToClipboard(textAreaRef)}
                    />
                    <OutgoingLink
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '1em',
                        fontSize: '1.2em'
                      }}
                      href={`${candidatePost.target}/buy_sell_discussion`}
                      onClick={() => copyToClipboard(textAreaRef)}
                      text='Discussion Tab'
                    />
                    {candidatePost.secondary ? <OutgoingLink
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '1em',
                        fontSize: '1.2em'
                      }}
                      href={`${candidatePost.secondary}`}
                      onClick={() => copyToClipboard(textAreaRef)}
                      text='Facebook Page'
                    /> : null}
                  </div>
                </div>
                <hr />
                <div>
                  <Title text="2. Facebook Link" component="h1" />
                  <Mutation mutation={DECLARE_POSTED}>
                    {({ mutate, options }) => (
                      <form
                        onSubmit={async (ev) => {
                          ev.preventDefault();

                          if (!new RegExp(`^${assetId}_[0-9]+$`).test(postId)) {
                            return false;
                          }

                          const variables = {
                            candidate,
                            post: {
                              link: candidatePost.link,
                              message: candidatePost.message,
                              asset: assetId,
                              post: postId,
                              primary: true,
                              repost: category === 'REPOST'
                            }
                          };

                          const res = await mutate({ variables });
                          if (res && res.data) {
                            closeModal(true);
                          } else {
                            closeModal(false);
                          }
                        }}
                      >
                        <Input
                          label="facebook link"
                          id="post"
                          name="post"
                          onChange={(ev) => {
                            const { value } = ev.currentTarget;
                            const postId = generatePostId(value, assetId);
                            setPostId(postId);
                          }}
                          helperText={`Post ID: ${
                            postId || '(link is not valid)'
                          }`}
                        />
                        <Placement place="right">
                          <Button
                            label="declare Posted"
                            type="submit"
                            isLoading={options.loading}
                          />
                        </Placement>
                      </form>
                    )}
                  </Mutation>
                </div>
              </Modal>
            );
          }}
        </Query>
      )}
    </>
  );
};
