import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { getSubscriptions } from './../redux/modules/boligninja';
import StatusIcon from 'app/common/icon-status';
import lib from 'lib/format-date';
import isActiveSub from './utils/is-active';

const LIMIT_DEFAULT = 250;

const STATUSES = [
  {
    key: 'canceled',
    label: 'Canceled',
    status: 'info',
    fn: (row) => isActiveSub(row) === 'info'
  },
  {
    key: 'overdue',
    label: 'Over due',
    status: 'warning',
    fn: (row) => isActiveSub(row) === 'warning'
  },
  {
    key: 'active',
    label: 'Active',
    status: 'success',
    fn: (row) => isActiveSub(row) === 'success'
  }
];

// const now = new Date().toISOString();

class Subscriptions extends Component {
  static fetchData({ dispatch, getState }) {
    if (!getState().boligninja.subscriptions.length) {
      return dispatch(getSubscriptions());
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      output: 'table',
      filter: {},
      limit: LIMIT_DEFAULT,
      rows: props.rows ? props.rows.slice(0, LIMIT_DEFAULT) : [],
      count: props.rows.length
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: Use immutable for redux data
    const curr = this.props.rows;
    const next = nextProps.rows;
    if (next && (!curr || curr.length !== next.length)) {
      this.filterRows(undefined, nextProps);
    }
  }

  filterRows(filter = this.state.filter, props = this.props) {
    const proto = {};
    const body = Object.keys(filter)
      .map((key) => {
        const f = filter[key];
        if (typeof f === 'function') {
          proto[`_${key}`] = filter[key];
          return `if (!this._${key}(row)) return false;`;
        }
        if (f) {
          return `if (!${f.toString()}.test(${
            key === 'user'
              ? '(row._userid && `${row._userid.name} ${row._userid.email}`)'
              : `row[${key}]`
          })) return false;`;
        }
      })
      .join(';');
    const fn = new Function(
      'row',
      `
      ${body}
      return true;
    `
    );

    const filteredRows = props.rows.filter(fn.bind(proto));
    const count = filteredRows.length;

    const { sortBy, sortDir } = this.state;
    if (sortBy) {
      filteredRows.sort(({ [sortBy]: a }, { [sortBy]: b }) => {
        return a > b ? 1 : a < b ? -1 : 0;
      });
      if (sortDir === -1) {
        filteredRows.reverse();
      }
    }

    const rows =
      this.state.output === 'csv' || filter.status
        ? filteredRows
        : filteredRows.slice(0, this.state.limit);

    // const rows = props.rows.filter(row => {
    //   return Object.keys(filter).reduce((valid, key) => {
    //     const f = filter[key];
    //
    //     if (valid) {
    //       if (typeof f === 'function') {
    //         return f(row);
    //       }
    //
    //       if (f) {
    //         const value = key === 'user' ? (row._userid && `${row._userid.name} ${row._userid.email}`) : row[key];
    //         return f.test(value);
    //       }
    //
    //       return valid;
    //     }
    //   }, true);
    // }).slice(0, this.state.output === 'csv' ? props.rows.length : this.state.limit);

    this.setState({ filter, count, rows });
  }

  onFilterChange = (ev) => {
    const { filter } = this.state;

    if (ev) {
      const { name, value } = ev.target;
      filter[name] = value ? new RegExp(value, 'i') : null;
    }

    this.filterRows(filter);
  };

  toggleFilter = (ev, fn) => {
    const { filter } = this.state;
    const { props } = ev._targetInst._currentElement;

    filter[props.name] = filter[props.name] === fn ? null : fn || props.filter;

    this.filterRows(filter);
  };

  sortBy = (ev) => {
    const sortBy = ev.target.getAttribute('data-column');
    const sortDir =
      sortBy !== this.state.sortBy ? -1 : (this.state.sortDir || 1) * -1;
    this.setState({ sortBy, sortDir }, () => this.filterRows());
  };

  render() {
    const { rows, filter, count, output } = this.state;

    return (
      <div>
        <div
          className="btn-toolbar"
          role="toolbar"
          style={{ marginBottom: '5px' }}
        >
          <div className="btn-group" role="group">
            {STATUSES.map(({ key, status, label, fn }) => {
              return (
                <button
                  key={key}
                  type="button"
                  className={`btn btn-${
                    filter.status === fn ? status : 'default'
                  }`}
                  onClick={(ev) => this.toggleFilter(ev, fn)}
                  name="status"
                >
                  {label}
                </button>
              );
            })}
          </div>
          <div className="btn-group" role="group">
            {['Table', 'CSV'].map((label) => {
              const key = label.toLowerCase();
              return (
                <button
                  key={key}
                  type="button"
                  className={`btn btn-${
                    output === key ? 'primary' : 'default'
                  }`}
                  onClick={() =>
                    this.setState({ output: key }, () => this.filterRows())
                  }
                >
                  {label}
                </button>
              );
            })}
          </div>
        </div>

        {output === 'csv' ? (
          <pre>
            {'ID;User;Offer;Starts;Canceled;Ends;Status\n'}
            {rows
              .map((sub) =>
                [
                  sub._id,
                  sub._userid.name || sub._userid.email || sub._userid._id,
                  sub.offer,
                  lib.dateFormatStr(sub.starts),
                  lib.dateUnixStr(sub.canceled),
                  lib.dateFormatStr(sub.ends),
                  isActiveSub(sub)
                ].join(';')
              )
              .join('\n')}
          </pre>
        ) : (
          <div className="table-responsive">
            <p>
              {count !== rows.length
                ? `Showing ${rows.length} of ${count} subscriptions (${this.props.rows.length} in total)`
                : `Showing ${rows.length} of ${this.props.rows.length} subscriptions`}
            </p>

            <table className="table table-striped table-condensed table-hover">
              <thead>
                <tr>
                  <th onClick={this.sortBy} data-column="_id">
                    ID
                  </th>
                  <th>User</th>
                  <th onClick={this.sortBy} data-column="offer">
                    Subscription Plan
                  </th>
                  <th onClick={this.sortBy} data-column="starts">
                    Starts
                  </th>
                  <th onClick={this.sortBy} data-column="canceledAt">
                    Canceled
                  </th>
                  <th onClick={this.sortBy} data-column="ends">
                    Ends
                  </th>
                  <th>Renew</th>
                </tr>
                <tr>
                  <th />
                  <th>
                    <input
                      onChange={this.onFilterChange}
                      name="user"
                      className="form-control"
                      style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                      placeholder="Filter by users name or email"
                    />
                  </th>
                  <th>
                    <input
                      onChange={this.onFilterChange}
                      name="offer"
                      className="form-control"
                      style={{ margin: 0, fontWeight: 'normal', width: '100%' }}
                      placeholder="Filter by offer"
                    />
                  </th>
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {rows.map((sub) => {
                  const user = sub._userid;
                  const status = isActiveSub(sub);

                  return (
                    <tr key={sub._id} className={status}>
                      <td>
                        <Link to={`/boligninja/subscriptions/${sub._id}`}>
                          ...
                          {sub._id.substr(-12)}
                        </Link>
                      </td>
                      <td>
                        {user ? (
                          <Link to={`/boligninja/users/${user._id}`}>
                            {user.name || user.email || user._id}
                          </Link>
                        ) : null}
                      </td>
                      <td>{sub.offer}</td>
                      <td>{lib.dateFormatStr(sub.starts)}</td>
                      <td>
                        {sub.canceledAt
                          ? lib.dateFormatStr(sub.canceledAt)
                          : ''}
                      </td>
                      <td>{lib.dateFormatStr(sub.ends)}</td>
                      <td>
                        <StatusIcon
                          status={status || (!sub.renew ? 'danger' : '')}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({ boligninja: { subscriptions } }) => ({ rows: subscriptions }),
  { getSubscriptions }
)(Subscriptions);
