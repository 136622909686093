import React from 'react';
import styles from './styles.module.scss';
import ReviewerItem from './ReviewerItem';
import { Values } from './type';
import SideOptions from './SideOptions';

type Props = {
  fields: string[];
  left: Values;
  right: Values;
  onApproved: (approved: boolean, values: Values) => void;
  loading: boolean;
};

const MainView: React.FC<Props> = (props) => {
  const [editable, setEditable] = React.useState(props.right);

  React.useEffect(() => {
    setEditable(props.right);
  }, [props.right]);

  const handleChange = React.useCallback(
    (
      ev: React.SyntheticEvent<
        HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
      >
    ) => {
      const name = ev.currentTarget.name || ev.target.name;
      const value = ev.currentTarget.value || ev.target.value;

      setEditable((prev) => ({ ...prev, [name]: value }));
    },
    []
  );
  const onSyncFields = React.useCallback(
    (ev: React.SyntheticEvent<HTMLButtonElement>) => {
      const { value, name } = ev.currentTarget;
      setEditable((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  return (
    <>
      <div className={styles.main}>
        <div className={styles.reviewer}>
          {props.fields.map((key) => {
            return (
              <ReviewerItem
                key={key}
                name={key}
                left={props.left[key] ?? ''}
                right={editable[key] ?? ''}
                onChange={handleChange}
                onClick={onSyncFields}
              />
            );
          })}
        </div>
        <SideOptions
          loading={props.loading}
          onActionClick={props.onApproved}
          upper={{ left: props.left, right: editable }}
          lower={props.left.reviewDifference}
        />
      </div>
    </>
  );
};

export default MainView;
