import React from 'react';
import Button from 'app/components/mui/Button';

type Props = {
    status: string;
    forceExecute: () => void;
    discard: () => void;
}

export const OptionsColumn = (props: Props): JSX.Element => {
  return (<div style={{ display: 'flex' }}>
        {<Button style={{ margin: '6px' }} size={'small'} label={'EXECUTE'} onClick={() => props.forceExecute()}/>}
        {props.status !== 'DISCARDED' ? <Button style={{ margin: '6px' }} size={'small'} label={'DISCARD'} onClick={() => props.discard()}/> : null}
    </div>);
};
