function convertArray(values, type?) {
  return type === 'listingTypes'
    ? Object.keys(values).reduce((listingTypes, key) => {
        if (values[key]) {
          listingTypes.push(key);
        }
        return listingTypes;
      }, [])
    : typeof values === 'string'
    ? type === 'POLYGON'
      ? [values.split('&').map((value) => value.split(','))]
      : values.split(',')
    : Array.isArray(values)
    ? values
    : [];
}

export const deserializeGeography = (geography, listingTypes) => {
  const { area, zipcodes, coordinates, radius, geo, ...rest } = geography;

  return {
    name: area,
    ...rest,
    radius: Number(radius),
    zipcodes: convertArray(zipcodes),
    listingTypes: convertArray(
      listingTypes || rest.listingTypes,
      'listingTypes'
    ),
    coordinates: convertArray(coordinates, geography.type)
  };
};

export const serializeGeography = (row) => {
  if (!row) {
    return {};
  }
  const { coordinates, radius, ...rest } = row;
  const coords =
    coordinates && Array.isArray(coordinates[0])
      ? coordinates[0].map((coordinates) => coordinates.join(',')).join('&')
      : coordinates;
  const listingTypes = {
    RAEKKEHUS: rest.listingTypes.includes('RAEKKEHUS'),
    HUS: rest.listingTypes.includes('HUS'),
    LEJLIGHED: rest.listingTypes.includes('LEJLIGHED'),
    VAERELSE: rest.listingTypes.includes('VAERELSE'),
    DELEBOLIG: rest.listingTypes.includes('DELEBOLIG'),
    ROOM1: rest.listingTypes.includes('ROOM1'),
    ROOM2: rest.listingTypes.includes('ROOM2'),
    ROOM3: rest.listingTypes.includes('ROOM3'),
    ROOM4: rest.listingTypes.includes('ROOM4'),
    ROOM5: rest.listingTypes.includes('ROOM5'),
    ALMEN: rest.listingTypes.includes('ALMEN'),
    UNGDOMSBOLIG: rest.listingTypes.includes('UNGDOMSBOLIG'),
    STUDIEBOLIG: rest.listingTypes.includes('STUDIEBOLIG'),
    SENIORBOLIG: rest.listingTypes.includes('SENIORBOLIG'),
    AELDREBOLIG: rest.listingTypes.includes('AELDREBOLIG'),
    FAMILIEBOLIG: rest.listingTypes.includes('FAMILIEBOLIG')
  };

  return {
    ...rest,
    radius: Number(radius),
    coordinates: coords,
    listingTypes
  };
};
