// @todo: this reducer/module should be integrated with redux/modules/notification

import { SET_MODAL } from './actions';
import { LOGOUT } from './auth/actions';

export function setError(data) {
  return {
    type: SET_MODAL,
    response: data
  };
}

export default function displayError(state = [], action) {
  if (action.type === SET_MODAL) {
    return action.response;
  } else if (action.type === LOGOUT) {
    return [];
  }
  return state;
}
