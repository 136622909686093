import React, { Component } from 'react';
import apiClient from 'app/utils/api-client';
import Table from 'app/components/mui/Table';
import Button from 'app/components/mui/Button';

const threadColumns = [
  { label: 'Id', name: 'id', simpleLinkText: 'Details', valueType: 'string' },
  { label: 'Lease', name: 'leaseId', valueType: 'string' },
  { label: 'AgentUnique', name: 'agentUnique', valueType: 'string' },
  { label: 'Latest message', name: 'latestMessage', valueType: 'custom', customValue: (v) => v.text },
  { label: 'Active', name: 'active', valueType: 'boolean' }
];

const messageColumns = [
  { label: 'Id', name: 'id', simpleLinkText: 'Details', valueType: 'string' },
  { label: 'From', name: 'from', valueType: 'string' },
  { label: 'To', name: 'to', valueType: 'string' },
  { label: 'From client', name: 'clientFrom', valueType: 'string' },
  { label: 'To client', name: 'clientTo', valueType: 'string' },
  { label: 'Text', name: 'text', valueType: 'string' },
  { label: 'Active', name: 'active', valueType: 'boolean' }
];

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    apiClient.post('/api/microservice', { data: { action: 'Chat', data: {} } }).then((result) => {
      this.setState({ ...result.data || {} });
    });
  }

  render() {
    const { 
        newestThreads = [],
        latestUpdatedThreads = [],
        latestMessages= [] } = this.state;
    const limitRow = 20;

    return (
      <div>
        <div style={{ display: 'flex' }}>
          {this.refresh ? <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => this.refresh()}/> : null}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Newest threads'}
                columns={threadColumns}
                limitRow={limitRow}
                hideable
                rows={newestThreads}
              />
          </div>
          <div style={{ margin: '6px' }}>
            <Table
                url={'Latest updated threads'}
                title={'Newest Actions'}
                columns={threadColumns}
                limitRow={limitRow}
                hideable
                rows={latestUpdatedThreads}
              />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '6px' }}>
            <Table
                title={'Latest messages'}
                columns={messageColumns}
                limitRow={limitRow}
                hideable
                rows={latestMessages}
              />
          </div>
        </div>
      </div>
    );
  }
}
