import React, { useState } from 'react';
import styles from '../styles.module.scss';
import Fields from 'app/components/mui/Fields';
import Footer from 'app/components/mui/Footer/Footer';
import Button from 'app/components/mui/Button';
import Note from './Note';
import Tags from 'app/components/mui/Tags';
import Form from 'app/components/mui/Form/Form';
import CustomLayout from 'app/components/mui/Layout/CustomLayout';
import Banner from 'app/components/mui/Banner';
import landlordQuery from './landlord.graphql';
import landlordsQuery from '../../landlords.graphql';
import { tagProps } from './Note/NoteItem';
import { deserializeLandlord } from './serializeLandlord';
import {
  Landlord as LandlordType,
  Mood,
  Type,
  Tags as TagsType,
  Connection
} from 'app/components/mui/utils/tsTypes';
import Alert from 'app/components/mui/Snackbar';

import LandlordFieldArray from './LandlordFieldArray';
import { Mutation } from 'app/components/graphql/Mutation';
import { gql } from 'react-apollo';

type Props = {
  data: any;
  moodTypes?: [Mood];
  noteTypes?: [Type];
  connectionTypes?: [Connection];
  leasingTags: [TagsType];
  subscriptionTypes: string[];
  landlordId?: string;
  mutation: {
    mutate: (mutateData: { [key: string]: any }) => Promise<{ data: any }>;
    options: { loading: boolean; error: any };
  };
  router: { push: (to: string) => void };
};

const CLEAR_NEXT_FOLLOW_UP = gql`
  mutation RemoveNextFollowUp($landlordId: ID!) {
    removeNextFollowUp(landlord: $landlordId) # return boolean
  }
`;

const archiveNote = gql`
  mutation ArchiveNote($noteId: ID!) {
    archiveNote(id: $noteId) #return boolean
  }
`;

function toggleList<T>(list: T[], toggleItem: T): T[] {
  const idx = list.indexOf(toggleItem);
  if (idx !== -1) {
    list.splice(idx, 1);
  } else {
    list.push(toggleItem);
  }
  return list;
}

const Landlord = ({ data, leasingTags, ...props }: Props): JSX.Element => {
  const [alert, setAlert] = useState({
    message: '',
    type: 'success',
    open: false,
    autoHideDuration: 4000
  });
  const [touched, setTouched] = useState<boolean>(false);
  const [newLandlordValues, setNewLandlordValues] = useState<LandlordType>(
    data
  );
  function onSubmit(): void {
    const variables = {
      landlordId: props.landlordId || null,
      landlordInput: deserializeLandlord(newLandlordValues)
    };
    const refetchQueries = props.landlordId
      ? [
          {
            query: landlordQuery,
            variables: { landlordId: props.landlordId || null }
          }
        ]
      : [{ query: landlordsQuery }];

    props.mutation.mutate({ variables, refetchQueries }).then(({ data }) => {
      if (data.updateLandlord) {
        setAlert({
          ...alert,
          message: 'Landlord updated',
          type: 'success',
          open: true
        });
      }
      if (data.createLandlord && data.createLandlord.id) {
        props.router.push(`/biz/mastertool/${data.createLandlord.id}`);
      }
      setTouched(false);
    });
  }

  function addValueHandler(
    values: { [key: string]: any },
    valueAdded: boolean,
    namespace?: string
  ): void {
    if (namespace) {
      setNewLandlordValues({ ...newLandlordValues, [namespace]: values });
    } else {
      setNewLandlordValues({ ...newLandlordValues, ...values });
    }
    setTouched(valueAdded);
  }

  return (
    <>
      <Alert
        message={alert.message}
        open={alert.open}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={alert.autoHideDuration}
        type={alert.type}
      />
      <CustomLayout
        cssStyles={{
          container: styles.container,
          childContainer: styles.childContainer
        }}
      >
        <Form
          layoutClass={styles.info}
          initValues={{ ...data.basic, tags: data.leases }}
          enableReinitialize
        >
          {({ values: { tags, ...values }, setFieldValue, dirty }) => {
            return (
              <>
                <Fields
                  onBlur={() => {
                    addValueHandler(values, dirty, 'basic');
                  }}
                  title="Basic info"
                  fields={values}
                  fieldOptions={{
                    isFormik: true,
                    id: { disabled: true },
                    name: { required: true },
                    // isScrapeable: { options: true, parent: 'scraper' },
                    // active: { options: true, parent: 'scraper' },
                    // scraperNote: { parent: 'scraper' },
                    type: {
                      options: props.subscriptionTypes.map((value) => ({
                        value
                      })),
                      parent: 'subscription',
                      required: true
                    },
                    note: { parent: 'subscription' },
                    lastContact: { disabled: true },
                    nextFollowUp: {
                      disabled: true,
                      helperText: (
                        <Mutation mutation={CLEAR_NEXT_FOLLOW_UP}>
                          {({ mutate }) => {
                            return (
                              <Button
                                color="default"
                                variant="text"
                                size="small"
                                label="Clear next follow up"
                                onClick={async () => {
                                  const { data } = await mutate({
                                    variables: { landlordId: props.landlordId }
                                  });
                                  if (data?.removeNextFollowUp === true) {
                                    setFieldValue('nextFollowUp', null);
                                  }
                                }}
                              />
                            );
                          }}
                        </Mutation>
                      )
                    }
                  }}
                />
                <Tags
                  labels={leasingTags}
                  values={tags}
                  onClick={(tag: string): void => {
                    setFieldValue('tags', toggleList(tags, tag));
                    addValueHandler(tags, true, 'tags');
                  }}
                />
              </>
            );
          }}
        </Form>
        {props.landlordId && (
          <Mutation mutation={archiveNote}>
            {({ mutate }) => (
              <Note
                onError={() =>
                  setAlert({
                    autoHideDuration: 8000,
                    message: 'could not create note',
                    type: 'error',
                    open: true
                  })
                }
                landlordId={props.landlordId}
                title={'Notes'}
                onArchiveReq={mutate}
                options={{
                  moodTypes: props.moodTypes,
                  noteTypes: props.noteTypes
                }}
                buttonProps={{ label: 'Add comment' }}
                data={data.notes}
              />
            )}
          </Mutation>
        )}
        <div>
          <LandlordFieldArray
            data={data}
            addValueHandler={addValueHandler}
            namespace="contacts"
            fieldOptions={() => ({
              isFormik: true,
              name: { fullWidth: false },
              title: { fullWidth: false },
              email: { fullWidth: false },
              phone: { fullWidth: false },
              newFields: { name: '', title: '', email: '', phone: '' }
            })}
          />
          <LandlordFieldArray
            data={data}
            addValueHandler={addValueHandler}
            namespace="connections"
            fieldOptions={(values) => ({
              isFormik: true,
              type: {
                options: props.connectionTypes.map((value) => ({ value }))
              },
              value: { hasHelperText: values.connections },
              newFields: { type: '', value: '' }
            })}
          />
        </div>
      </CustomLayout>
      <Footer
        left={() => {
          const { icon, style } = tagProps(data.mood);
          return (
            props.landlordId &&
            data.mood && (
              <Banner
                text={data.mood}
                color={style.backgroundColor}
                icon={icon}
              />
            )
          );
        }}
        right={() => {
          return (
            <>
              {props.landlordId && (
                <Button
                  style={{ marginRight: '1em' }}
                  onClick={() => props.router.push('/biz/mastertool/new')}
                  label={'New Landlord'}
                  color="default"
                />
              )}
              <Button
                disabled={!touched || props.mutation.options.loading}
                label={`${props.landlordId ? 'save' : 'create'}`}
                onClick={() => {
                  onSubmit();
                }}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default Landlord;
