import React from 'react';
import Typography from '@material-ui/core/Typography';

type Props = {
  text: JSX.Element | string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  addPadding?: boolean;
};

export const Title = (props: Props): JSX.Element => {
  return (
    <Typography
      style={{
        padding: props.addPadding && '6px 16px',
        fontWeight: 'unset',
        marginBottom: '0.6em'
      }}
      variant={props.variant}
      component={props.component || 'h5'}
    >
      {props.text}
    </Typography>
  );
};
