import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './common/styles/main.less';

import React from 'react';
import ReactDOM from 'react-dom';

import getRoutes from './routes';
import getProvider from 'lib/react-ssr/provider';
import createStore from './redux/create';
import client from './utils/api-client';

if (__DEV__) {
  window.React = React; // enable debugger
}

const dest = document.getElementById('content');

const store = createStore({ client });

const routes = getRoutes(store);

const DevTools = null;

const { component: provider, fetchData } = getProvider({
  store,
  routes,
  DevTools
});
fetchData().then(() => {
  ReactDOM.render(provider, dest);
});
