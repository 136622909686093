import PropTypes from 'prop-types';
import React from 'react';

const PERMISSION_GRANTED = 'granted';

function sequence() {
  let i = 0;
  return () => ++i;
}

const seq = sequence();

class Notification extends React.Component {
  static propTypes = {
    disableActiveWindow: PropTypes.bool,
    ignore: PropTypes.bool,
    notSupported: PropTypes.func,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onError: PropTypes.func,
    onPermissionDenied: PropTypes.func,
    onPermissionGranted: PropTypes.func,
    onShow: PropTypes.func,
    options: PropTypes.object,
    timeout: PropTypes.number,
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    ignore: false,
    disableActiveWindow: false,
    notSupported: () => {},
    onPermissionGranted: () => {},
    onPermissionDenied: () => {},
    onShow: () => {},
    onClick: () => {},
    onClose: () => {},
    onError: () => {},
    timeout: 5000,
    options: {}
  };

  constructor(props) {
    super(props);

    let supported = false;
    let granted = false;
    if ('Notification' in window && window.Notification) {
      supported = true;
      if (window.Notification.permission === PERMISSION_GRANTED) {
        granted = true;
      }
    } else {
      supported = false;
    }

    this.state = {
      supported: supported,
      granted: granted
    };

    // Do not save Notification instance in state
    this.notifications = new Map();
  }

  componentDidMount() {
    if (this.props.disableActiveWindow) {
      if (window.addEventListener) {
        window.addEventListener('focus', this.onWindowFocus);
        window.addEventListener('blur', this.onWindowBlur);
      } else if (window.attachEvent) {
        window.attachEvent('focus', this.onWindowFocus);
        window.attachEvent('blur', this.onWindowBlur);
      }
    }

    if (!this.state.supported) {
      this.props.notSupported();
    } else {
      if (this.state.granted) {
        this.props.onPermissionGranted();
      } else {
        window.Notification.requestPermission((permission) => {
          const result = permission === PERMISSION_GRANTED;
          this.setState(
            {
              granted: result
            },
            () => {
              if (result) {
                this.props.onPermissionGranted();
              } else {
                this.props.onPermissionDenied();
              }
            }
          );
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.props.disableActiveWindow) {
      if (window.removeEventListner) {
        window.removeEventListener('focus', this.onWindowFocus);
        window.removeEventListener('blur', this.onWindowBlur);
      } else if (window.detachEvent) {
        window.detachEvent('focus', this.onWindowFocus);
        window.detachEvent('blur', this.onWindowBlur);
      }
    }
  }

  onWindowBlur = () => {
    this.setState({ windowFocus: false });
  };

  onWindowFocus = () => {
    this.setState({ windowFocus: true });
  };

  close(tag) {
    if (this.notifications.has(tag)) {
      const notification = this.notifications.get(tag);
      if (typeof notification.close === 'function') {
        notification.close();
      }
      this.notifications.delete(tag);
    }
  }

  // for debug
  _getNotificationInstance(tag) {
    return this.notifications.get(tag);
  }

  render() {
    const { ignore, title, disableActiveWindow } = this.props;
    const { windowFocus, supported, granted } = this.state;
    const doNotShowOnActiveWindow = disableActiveWindow && windowFocus;

    if (!ignore && title && supported && granted && !doNotShowOnActiveWindow) {
      const opt = this.props.options;
      if (typeof opt.tag !== 'string') {
        opt.tag = 'web-notification-' + seq();
      }

      if (!this.notifications.has(opt.tag)) {
        const n = new window.Notification(this.props.title, opt);
        n.onshow = (e) => {
          this.props.onShow(e, opt.tag);
          setTimeout(() => {
            this.close(opt.tag);
          }, this.props.timeout);
        };
        n.onclick = (e) => {
          this.props.onClick(e, opt.tag);
        };
        n.onclose = (e) => {
          this.props.onClose(e, opt.tag);
        };
        n.onerror = (e) => {
          this.props.onError(e, opt.tag);
        };

        this.notifications.set(opt.tag, n);
      }
    }

    // return null cause
    // Error: Invariant Violation: Notification.render(): A valid ReactComponent must be returned. You may have returned undefined, an array or some other invalid object.
    return <span />;
  }
}

export default Notification;
